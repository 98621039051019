import React from 'react';
import {
    TextField,
    Button,
    Typography,
    Autocomplete,
    Box
} from '@mui/material'
import { connect, useSelector } from 'react-redux'
import { styled } from '@mui/system';
import Toast from '../../mixins/Toast';

const Root = styled(Box)({
    backgroundColor: 'white',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
})

const Form = styled(Box)({
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column'
})

const Submit = styled(Box)({
    width: '50%',
    display: 'flex',
    justifyContent: 'space-between'
})

const MyButton = styled(Button)({
    width: '30%',
    minHeight: '50px'
})
const MyDropDown = styled(Box)({
    display: 'flex',
    justifyContent: 'center'
})
const Input = styled(TextField)({
    margin: '10px'
})


const EditDevice = React.forwardRef((props, ref) => {
    const [id, setId] = React.useState(props.device.id);
    const [externalId, setExternalId] = React.useState(props.device.externalId)
    const [hardware, setHardware] = React.useState(props.device.hardwareVersionId);
    const [firmware, setFirmware] = React.useState(props.device.firmwareVersionId);
    const [name, setName] = React.useState(props.device.name);
    const [config, setConfig] = React.useState(props.device.config);
    const [versions, setVersions] = React.useState([]);
    const [group, setGroup] = React.useState(props.deviceEdit.groupId);
    const [canGroup, setCanGroup] = React.useState(props.deviceEdit.canGroupId);
    const token = useSelector((state) => state.setUser.token);
    const user = useSelector((state) => state.setUser.setUser);
    const groups = useSelector((state) => state.setUser.currentGroups);
    const canGroups = useSelector((state) => state.deviceList.canGroup);
    const currentGroup = useSelector((state) => state.setUser.currentGroup);
    
    const handleChange = (type, event) => {
        switch (type) {
            case 'id':
                return setId(event);
            case 'hardware':
                return setHardware(event);
            case 'firmware':
                return setFirmware(event);
            case 'name': 
                return setName(event.target.value);
            case 'config':
                return setConfig(event.target.value);
            case 'externalId':
                return setExternalId(event.target.value);
            case 'group':
                return setGroup(event)
            case 'canGroup':
                return setCanGroup(event)
            default:
                break;
        }
    }

    const handleSubmit = (id) => {
        if ( name === null ) {
            return Toast.fire({
                icon: 'error',
                title: 'Invalid Name'
            })
        }
        else if ( firmware === null ) {
            return Toast.fire({
                icon: 'error',
                title: 'Select Firmware'
            })
        }
        else if ( hardware === null ) {
            return Toast.fire({
                icon: 'error',
                title: 'Select Hardware'
            })
        }
        else if ( id === null ) {
            return Toast.fire({
                icon: 'error',
                title: 'Please Enter External ID'
            })
        }
        else if ( groups === null ) {
            return Toast.fire({
                icon: 'error',
                title: 'Select Group'
            })
        }
        const item = {
            Id: id,
            Name: name,
            FirmwareVersionId: firmware,
            HardwareVersionId: hardware,
            Configuration: config,
            ExternalId: externalId,
            GroupId: group,
            CanGroupId: canGroup

          };
        fetch('/api/device/'+ id, {
            method: 'PUT',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(item)
        })
        .then(response => {
            if(response.status === 200){
                    props.close(false)
                    Toast.fire({
                        icon: "success",
                        title: "Device Updated"
                    })
                }
            })
            .then(x => {
                props.dispatch({type: 'GRAB_DEVICES', payload: currentGroup})
            })
            .catch(error => console.error('Unable to edit item.', error));
    }

    return (
        <Root>
            <Box>
                <Typography variant="h1">
                    EDIT DEVICE
                </Typography>
            </Box>
            <Form>
                <MyDropDown>
                    <Autocomplete
                        id="Hardware-Family"
                        options={props.hardware}
                        style={{ width: '300px', margin: '5px' }}
                        onChange={(event, newValue) => {
                            if (!newValue) {
                                setVersions([])
                                return
                            }
                            else {
                                if (!newValue.version) {
                                    setVersions([])
                                }
                                else {
                                    setVersions(newValue.version)
                                }
                            }
                        }}
                        defaultValue={props.hardware.find((hardware) => hardware.id === props.deviceEdit.hardwareFamilyVersionId)}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Hardware Family" variant="outlined" />}
                    />
                    <Autocomplete
                        id="Hardware-Version"
                        options={versions}
                        defaultValue={props.device.hardwareVersion}
                        style={{ width: '300px', margin: '5px' }}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                handleChange('hardware', newValue.id)
                            }
                            else {
                                return
                            }
                        }}
                        getOptionLabel={(option) => option.name !== undefined ? option.name : option}
                        renderInput={(params) => <TextField {...params} label="Hardware Version" variant="outlined" />}
                    />
                </MyDropDown>
                <MyDropDown>
                    <Autocomplete
                        id="Firmware-Version"
                        options={props.firmware}
                        style={{ width: '300px', margin: '5px' }}
                        defaultValue={props.firmware.find((firmware) => firmware.id === props.deviceEdit.firmwareVersionId)}
                        onChange={(event, newValue) => {
                            handleChange('firmware', newValue.id)
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Firmware Version" variant="outlined" />}
                    />
                    <Autocomplete 
                        id="Group"
                        options={groups}
                        style={{ width: '300px', margin: '5px' }}
                        onChange={(event, newValue) => {
                            handleChange('group', newValue.id)
                        }}
                        defaultValue={groups.find((x) => x.id === group )}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Group" variant="outlined"/>}
                    />
                    <Autocomplete 
                        id="Can-Group"
                        options={canGroups}
                        style={{ width: '300px', margin: '5px' }}
                        onChange={(event, newValue) => {
                            handleChange('canGroup', newValue.id)
                        }}
                        defaultValue={canGroups.find((x) => x.id === canGroup )}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Can Group" variant="outlined"/>}
                    />
                </MyDropDown>
                <Box>
                    <Input
                        required
                        id="Name"
                        label="Name"
                        defaultValue={props.deviceEdit.name}
                        variant="outlined"
                        onChange={(event) => handleChange('name', event)}
                    />
                    {/* <Input
                        required
                        id="Config"
                        label="Configuration"
                        defaultValue={props.deviceEdit.configuration}
                        variant="outlined"
                        onChange={(event) => handleChange('config', event)}
                    /> */}
                    <Input 
                        required
                        id="ExternalId"
                        label="External Id"
                        defaultValue={props.deviceEdit.externalId}
                        variant="outlined"
                        onChange={(event) => handleChange('externalId', event)}
                    />
                    <div style={{display: 'none'}}>
                        <Input type="hidden" style={{ borderStyle: 'none' }} defaultValue={props.device.id} id="id" />
                    </div>
                </Box>
            </Form>
            <Submit>
                <MyButton
                    variant="contained"
                    color="primary"
                    onClick={() => props.close()}
                >
                    Cancel
                </MyButton>
                <MyButton
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit(props.device.id)}
                >
                    Update
                </MyButton>
            </Submit>
        </Root>
    )
})

export default connect()(EditDevice);