import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    Button,
    Modal
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Popup from '../../mixins/Popup';

const GroupList = ({ className, customers }, props) => {
    const [open, setOpen] = React.useState(false);
    const users = useSelector((state) => state.setUser.usersInGroup)
    const groups = useSelector((state) => state.setUser.allGroups)
    const dispatch = useDispatch()
    const handleOpen = (params) => {
        dispatch({
        type: 'GET_USERS_FOR_GROUP',
        payload: params
        })
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const userColumns=[
        { field: 'userName', headerName: 'Username', flex: 1, minWidth: 150 },
        { field: 'email', headerName: 'Email', flex: 1, minWidth: 150 },
        { field: 'phoneNumber', headerName: 'Phone Number', flex: 1, minWidth: 150 },
        { field: 'role', headerName: 'Role', flex: 1, minWidth: 150}
    ]
    const columns = [
        { field: 'name', headerName: 'Group Name', flex: 1, minWidth: 150 },
        { field: 'parentGroupId', headerName: 'Parent Group', flex: 1, minWidth: 150 },
        { field: 'isDefault', headerName: 'Is Default', type: 'boolean', flex: 1, minWidth: 150 },
        { field: 'created', headerName: 'Created', flex: 1, minWidth: 150, renderCell: (params) => (moment.utc(params.row.created).local().format('MM/DD/YY, h:mm a')) },
        { field: 'updated', headerName: 'Updated', flex: 1, minWidth: 150, renderCell: (params) => (moment.utc(params.row.updated).local().format('MM/DD/YY, h:mm a')) },
        { field: 'users', headerName: 'View Users', flex: 1, minWidth: 150, renderCell: (params) => (
        <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpen(params.id)}
        >
            Users
        </Button>
        ) }
    ]
    const data = [];
    customers.map((x, i) => {
        const parentName = groups.find((group) => group.id === x.parentGroupId)
        return data.push({id: x.id, name: x.name, parentGroupId: parentName ? parentName.name : '', isDefault: x.isDefault, created: x.created, updated: x.updated, users: x.id});
    });
    let userData = [];
    users.map((x, i) => {
        return userData.push({id: x.id, userName: x.userName, email: x.email, phoneNumber: x.phoneNumber, role: x.role.name});
    })
    return (
        <Card sx={{ height: 800 }} >
        <Popup
            open={open}
            close={handleClose}
        >
            <Card
                sx={{
                    height: '100%',
                    margin: '15px'
                }}
            >
            <DataGrid
                columns={userColumns}
                rows={userData}
                components={{
                    Toolbar: GridToolbar
                }}
            />
            </Card>
        </Popup>
        <DataGrid
            components={{
                Toolbar: GridToolbar
            }}
            columns={columns}
            rows={data}
        />
        </Card>
    );
};

GroupList.propTypes = {
    className: PropTypes.string,
    customers: PropTypes.array.isRequired
};

export default connect()(GroupList);
