import * as React from 'react';
import {
    Box,
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography,
    Card,
    TextField,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Checkbox,
    Paper,
    Grid,
    Autocomplete, 
    InputAdornment,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import { ArrowDown } from "react-feather";
import { useSelector, useDispatch } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import Toast from '../../../mixins/Toast';
import Popup from '../../../mixins/Popup';
import PgnDetails from '../CanFiles/Details';

const steps = ['Select Can PGNs', 'Set Triggers', 'Summary + Submit'];

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

let triggers = [
    // {name: "RSVD", id: 0},
    {name: "PERIODIC", id: 1},
    // {name: "REQUEST", id: 2},
    {name: "ON_WAKE", id: 3},
    {name: "ON_SLEEP", id: 4},
    // {name: "GREATER_THAN", id: 5},
    // {name: "LESS_THAN", id: 6},
    // {name: "ENGINE_ON", id: 7}
];

export default function CreateCanGroup(props) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);
    const [checked, setChecked] = React.useState([]);
    const [name, setName] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [pgn, setPgn] = React.useState([]);
    const [spns, setSpns] = React.useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const dispatch = useDispatch();
    const token = useSelector((state) => state.setUser.token);
    const canPGNs = useSelector((state) => state.deviceList.canPGNs);
    const files = useSelector((state) => state.deviceList.canFiles);
    const currentGroup = useSelector((state) => state.setUser.currentGroup);
    const [pgnArray, setPgnArray] = React.useState([]);

    if (pgnArray.length === 0){
        for(let z of files){
            for(let y of z.pgn){
                setPgnArray((array) => [...array, y])

            }
        }
    }

    React.useEffect(() => {
        setLeft(canPGNs)
    },[]);

    const pgnColumns = [
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 150 },
        { field: 'byteLength', headerName: 'Byte Length', flex: 1, minWidth: 150 },
        { field: 'canIdent', headerName: 'PGN ID', flex: 1, minWidth: 150  },
        { field: 'sender', headerName: 'Sender', flex: 1, minWidth: 150 },
        { field: 'spn', headerName: 'Details', flex: 1, minWidth: 150, renderCell: (params) => (
            <Button
                variant="contained"
                onClick={() => {
                    axios.get(`/api/CanSPN/${params.row.id}`,{headers: !token ? {} : { 'Authorization' : `Bearer ${token}`}})
                    .then((response) => setSpns(response.data))
                    .then(() => setPgn(params.row))
                    .then(() => setOpen(true))
                    .catch((error) => console.log(error))
                }}
            >
                VIEW
            </Button>
        ) }
    ]

    const columns = [
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 150 },
        { field: 'triggerType', headerName: 'Trigger Type', flex: 1, minWidth: 150 },
        { field: 'collectionFrequency', headerName: 'Collection Frequency', flex: 1, minWidth: 150 },
        { field: 'reportFrequency', headerName: 'Report Frequency', flex: 1, minWidth: 150 }
    ];

    const createColumns = [
        { field: 'name', headerName: "PGN", flex: 1, minWidth: 100 },
        { field: 'triggerType', headerName: 'Trigger Type', flex: 1, minWidth: 100, renderCell: (params) => (
            <Autocomplete 
                options={triggers}
                style={{ width: '100%' }}
                onChange={(event, newValue) => {
                    params.row.triggerType = newValue.id
                }}
                defaultValue={params.row.triggerType}
                isOptionEqualToValue={(option, value) => option.id == value}
                getOptionLabel={(option) => option.name ? option.name : 'PERIODIC'}
                renderInput={(params) => <TextField {...params} variant="standard" />}
            />
        )},
        { field: 'collectionFrequency', headerName: 'Collection Frequency', flex: 1, minWidth: 100, renderCell: (params) => (
            <TextField
                sx={{ width: '100%' }}
                variant="standard"
                type="number"
                defaultValue={params.row.collectionFrequency}
                onChange={(event) => params.row.collectionFrequency = event.target.value}
                InputProps={{
                    endAdornment: <InputAdornment position="end">ms</InputAdornment>
                }}  
            />
        )},
        { field: 'reportFrequency', headerName: 'Report Frequency', flex: 1, minWidth: 100, renderCell: (params) => (
            <TextField
                sx={{ width: '100%' }}
                variant="standard"
                type="number"
                defaultValue={params.row.reportFrequency}
                onChange={(event) => params.row.reportFrequency = event.target.value}
                InputProps={{
                    endAdornment: <InputAdornment position="end">ms</InputAdornment>
                }}
            />
        )}
    ]

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        }
        else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleNext = () => {
        if(activeStep === 0 && name === ''){
            return Toast.fire({
                icon: 'error',
                title: 'Please enter Name'
            })
        }
        if(activeStep === 0) {
            let array = []
            pgnArray.map((x) => selectionModel.forEach((y) => x.id === y ? array.push(x) : ''))
            setRight(array)
        }
        if(activeStep === steps.length - 1){
            return axios.post('/api/CanGroups',{
                Name: name,
                GroupId: currentGroup.id
            },{headers: !token ? {} : { 'Authorization': `Bearer ${token}` }}).then((response) => {
                for (const x of right) {
                    axios.post('/api/CanGroupPGN', {
                        Name: x.name,
                        CanGroupId: response.data,
                        CanPGNId: x.id,
                        CanTriggerType: x.triggerType,
                        CollectionFrequency: Number(x.collectionFrequency),
                        ReportFrequency: Number(x.reportFrequency)
                    }, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }})
                }
            }).then(() => dispatch({ type: "GRAB_CAN_GROUPS", payload: currentGroup.id }))
            .then(() => Toast.fire({ icon: 'success', title: "CAN Group Created" }))
            .then(() => props.close())
            .catch((error) => console.log(error))
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setName('');
        setActiveStep(0);
    };

    const customList = (items) => (
        <Paper sx={{ width: 300, height: 330, overflow: 'auto' }}>
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-item-${value.name}-label`;
                    return (
                        <ListItem
                            key={value.id}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value.name}`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );
    const renderView = (step) => {
        if (step === 0) {
            return (
                <Box sx={{ minHeight: 450, height: '100%', margin: '10px', overflow: 'scroll' }}>
                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '15px' }}>
                        <TextField defaultValue={name} label="Name" variant="outlined" sx={{ width: '25%' }} onChange={(event) => setName(event.target.value)} />
                    </Box>
                    <Box>
                    {files.map((x, i) => {
                        return (
                            <Accordion key={i} sx={{ border: '1px solid #e0e0e0', marginTop: '5px' }}>
                                <AccordionSummary expandIcon={<ArrowDown/>}>
                                    {x.name}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box sx={{ height: '400px' }}>
                                        <DataGrid
                                            rows={x.pgn}
                                            columns={pgnColumns}
                                            checkboxSelection
                                            keepNonExistentRowsSelected
                                            onSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel)}
                                            selectionModel={selectionModel}
                                        />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                    </Box>
                </Box>
            )
        }
        else if (step === 1) {
            right.map((x) => {
                x.reportFrequency = x.reportFrequency ? x.reportFrequency : 120000
                x.collectionFrequency = x.collectionFrequency ? x.collectionFrequency : 60000
                x.triggerType = x.triggerType ? x.triggerType : 1
            })
            return (
                <Box sx={{ minHeight: 450, height: '100%', margin: '10px', overflow: 'scroll' }}>
                    <DataGrid 
                        columns={createColumns}
                        rows={right}
                        density="compact"
                        components={{
                            Toolbar: GridToolbar
                        }}
                        style={{ minHeight: '400px', width: '100%' }}
                    />
                </Box>
            )
        }
        else if (step === 2) {
            return (
                <Box sx={{height: '100%', margin: '10px' }}>
                    <Box sx={{ textAlign: 'center', margin: '5px' }}>
                        <Typography variant="h2">{name}</Typography>
                    </Box>
                    <Box sx={{ minHeight: 450, height: '100%', marginTop: '10px' }}>
                        <DataGrid
                            style={{ minHeight: '450px' }}
                            rows={right}
                            columns={columns}
                        />
                    </Box>
                </Box>
            )
        }
    }

    return (
        <Box sx={{ width: '100%', margin: '5px' }}>
            <Popup open={open} close={() => setOpen(false)}>
                <PgnDetails pgn={pgn} spn={spns}/>
            </Popup>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {}; 
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {renderView(activeStep)}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleNext}>
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
}