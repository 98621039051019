import React from "react";
import { Autocomplete, Button, Card, Modal, TextField, Select, MenuItem, FormControl } from '@mui/material';
import { Plus, Save } from 'react-feather';
import CreateDevice from "./CreateDevice";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/system";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import moment from "moment";
import Toast from "../../../mixins/Toast";

const Popup = styled(Modal)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
})

const DeviceTables = (props) => {
    const firmware = useSelector((state) => state.admin.firmwareList);
    const hardware = useSelector((state) => state.admin.hardwareList);
    const allGroups = useSelector((state) => state.admin.adminGroups);
    const allFirmware = useSelector((state) => state.admin.allFirmware);
    const allVersions = useSelector((state) => state.admin.hardwareVersions);
    const allCanGroups = useSelector((state) => state.admin.canGroups);
    const dispatch = useDispatch();
    const token = useSelector((state) => state.setUser.token);
    const [group, setGroup] = React.useState('');
    const [editRowsModel, setEditRowsModel] = React.useState({});
    const [open, setOpen] = React.useState(false)
    let handleOpen = () => {
        return setOpen(true)
    }

    let handleClose = () => {
        return setOpen(false)
    }

    const columns = [
        { field: 'updated', headerName: 'Updated', flex: 1, minWidth: 150, renderCell: (params) => (moment.utc(params.row.updated).local().format('MM/DD/YY, h:mm a')) },
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 150, editable: true },
        { field: 'externalId', headerName: 'External ID', flex: 1, minWidth: 150, editable: true },
        {
            field: 'canGroupId', headerName: 'Can Group', flex: 1, minWidth: 150, renderCell: (params) => (
                <Autocomplete
                    sx={{ minWidth: '150px', width: '100%' }}
                    options={allCanGroups}
                    defaultValue={allCanGroups.find((x) => x.id === params.row.canGroupId )}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                        if (newValue){
                            axios.put(`/api/device/admin/CanGroup/${params.row.id}/${newValue.id}`,{}, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }})
                            .then(() => dispatch({type: "GRAB_ADMIN_DEVICES"}))
                            .then(() => Toast.fire({
                                icon: 'success',
                                title: 'Can Group Updated'
                            }))
                            .catch((error) => {
                                Toast.fire({
                                    icon: 'error',
                                    title: JSON.stringify(error)
                                })
                            })
                        }
                        else {
                            axios.put(`/api/device/admin/CanGroup/Remove/${params.row.id}`,{}, { headers: !token ? {} : { 'Authorization': `Bearer ${token}`}})
                            .then(() => dispatch({type: "GRAB_ADMIN_DEVICES"}))
                            .then(() => Toast.fire({
                                icon: 'success',
                                title: 'Can Group Updated'
                            }))
                            .catch((error) => {
                                console.log(`Problem with updating can group: ${error}`)
                                Toast.fire({
                                    icon: 'error',
                                    title: JSON.stringify(error)
                                })
                            })
                        }
                    }}
                    renderInput={(params) => <TextField {...params} label="Can Group" variant="standard" />}
                />
            )
        },
        {
            field: 'hardwareVersionId', headerName: 'Hardware Version', flex: 1, minWidth: 150, renderCell: (params) => (
                <Autocomplete
                    sx={{ minWidth: '150px', width: '100%' }}
                    options={allVersions}
                    defaultValue={params.row.hardwareVersionId}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                        axios.put(`/api/device/admin/hardware/${params.row.id}/${newValue.id}`, {}, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }})
                        .then(() => dispatch({type: "GRAD_ADMIN_DEVICES"}))
                        .then(() => Toast.fire({
                            icon: 'success',
                            title: 'Hardware Version Updated'
                        }))
                        .catch((error) => {
                            Toast.fire({
                                icon: 'error',
                                title: JSON.stringify(error)
                            })
                        })
                    }}
                    renderInput={(params) => <TextField {...params} label="Hardware Version" variant="standard" />}
                />
            )
        },
        {
            field: 'stm', headerName: 'STM Firmware', flex: 1, minWidth: 150, renderCell: (params) => (
                <FormControl fullWidth>
                    <Select
                        defaultValue={params.row.firmwareVersionId}
                        onChange={(event) => {
                            Object.values(editRowsModel)[0].stm.value = event.target.value
                        }}
                    >
                        {firmware.Signum.STM.map((x, i) => {
                            return <MenuItem key={i} value={x}>{x.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            )
        },
        // {
        //     field: 'firmwareVersionId', headerName: 'ESP Firmware', flex: 1, minWidth: 150, renderCell: (params) => (
        //         <Autocomplete
        //             sx={{ minWidth: '150px', width: '100%' }}
        //             options={firmware.Signum.ESP}
        //             defaultValue={params.row.esp.id ? params.row.esp.id : null}
        //             isOptionEqualToValue={(option, value) => option.id === value.id}
        //             getOptionLabel={(option) => option.name}
        //             onChange={(event, newValue) => {
        //                 axios.put(`/api/device/admin/firmware/${params.row.id}/${newValue.id}`, {}, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }})
        //                 .then((response) => dispatch({type: "GRAD_ADMIN_DEVICES"}))
        //                 .then(() => {
        //                     Toast.fire({
        //                         icon: 'success',
        //                         title: 'Firmware Version Updated'
        //                     })
        //                 })
        //                 .catch((error) => {
        //                     Toast.fire({
        //                         icon: 'error',
        //                         title: JSON.stringify(error)
        //                     })
        //                 })
        //             }}
        //             renderInput={(params) => <TextField {...params} label="ESP Firmware" variant="standard" />}
        //         />
        //     )
        // },
        // {
        //     field: 'firmwareVersionId', headerName: 'CAB Firmware', flex: 1, minWidth: 150, renderCell: (params) => (
        //         <Autocomplete
        //             sx={{ minWidth: '150px', width: '100%' }}
        //             options={firmware.Bobcat.CAB}
        //             defaultValue={params.row.cab.id ? params.row.cab.id : null}
        //             isOptionEqualToValue={(option, value) => option.id === value.id}
        //             getOptionLabel={(option) => option.name}
        //             onChange={(event, newValue) => {
        //                 axios.put(`/api/device/admin/firmware/${params.row.id}/${newValue.id}`, {}, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }})
        //                 .then((response) => dispatch({type: "GRAD_ADMIN_DEVICES"}))
        //                 .then(() => {
        //                     Toast.fire({
        //                         icon: 'success',
        //                         title: 'Firmware Version Updated'
        //                     })
        //                 })
        //                 .catch((error) => {
        //                     Toast.fire({
        //                         icon: 'error',
        //                         title: JSON.stringify(error)
        //                     })
        //                 })
        //             }}
        //             renderInput={(params) => <TextField {...params} label="CAB Firmware" variant="standard" />}
        //         />
        //     )
        // },
        // {
        //     field: 'firmwareVersionId', headerName: 'HUB Firmware', flex: 1, minWidth: 150, renderCell: (params) => (
        //         <Autocomplete
        //             sx={{ minWidth: '150px', width: '100%' }}
        //             options={firmware.Bobcat.HUB}
        //             defaultValue={params.row.hub.id ? params.row.hub.id : null}
        //             isOptionEqualToValue={(option, value) => option.id === value.id}
        //             getOptionLabel={(option) => option.name}
        //             onChange={(event, newValue) => {
        //                 axios.put(`/api/device/admin/firmware/${params.row.id}/${newValue.id}`, {}, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }})
        //                 .then((response) => dispatch({type: "GRAD_ADMIN_DEVICES"}))
        //                 .then(() => {
        //                     Toast.fire({
        //                         icon: 'success',
        //                         title: 'Firmware Version Updated'
        //                     })
        //                 })
        //                 .catch((error) => {
        //                     Toast.fire({
        //                         icon: 'error',
        //                         title: JSON.stringify(error)
        //                     })
        //                 })
        //             }}
        //             renderInput={(params) => <TextField {...params} label="HUB Firmware" variant="standard" />}
        //         />
        //     )
        // },
        {
            field: 'group', headerName: 'Group', flex: 1, minWidth: 150, renderCell: (params) => (
                <div style={{ minWidth: '150px', width: '100%' }}>
                    {params.row.group ?
                        <Autocomplete
                            sx={{ minWidth: '150px', width: '100%' }}
                            options={allGroups}
                            defaultValue={params.row.group}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                                if (!newValue) {
                                    return setGroup('')
                                }
                                else {
                                    axios.put(`/api/groupdevices/${params.row.id}/${newValue.id}`, {}, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }})
                                    .then(() => {
                                        Toast.fire({
                                            icon: 'success',
                                            title: 'Group Updated'
                                        })
                                    })
                                    .catch((error) => {
                                        Toast.fire({
                                            icon: 'error',
                                            title: JSON.stringify(error)
                                        })
                                    })
                                    dispatch({ type: 'GRAB_ADMIN_DEVICES' })
                                }

                            }}
                            renderInput={(params) => <TextField {...params} label="Group " variant="standard" />}
                        />
                        :
                        <Autocomplete
                            sx={{ minWidth: '150px', width: '100%' }}
                            options={allGroups}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                                if (!newValue) {
                                    return setGroup('')
                                }
                                else {
                                    axios.post(`/api/groupdevices/new/${params.row.id}/${newValue.id}`, {}, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }})
                                    .then(() => {
                                        Toast.fire({
                                            icon: 'success',
                                            title: 'Group Updated'
                                        })
                                    })
                                    .catch((error) => {
                                        Toast.fire({
                                            icon: 'error',
                                            title: JSON.stringify(error)
                                        })
                                    })
                                    dispatch({ type: 'GRAB_ADMIN_DEVICES' })
                                }

                            }}
                            renderInput={(params) => <TextField {...params} label="Group" variant="standard" />}
                        />}
                </div>
            )
        }
    ]
    let data = [];
    props.data.map((x, i) => {
        const firmwareName = allFirmware.find((firmware) => firmware.id === x.firmwareVersionId)
        const versionName = allVersions.find((version) => version.id === x.hardwareVersionId)
        return data.push({ id: x.id, updated: x.updated, name: x.name, externalId: x.externalId, canGroupId: x.canGroupId, hardwareVersionId: versionName, firmwareVersionId: firmwareName, group: x.group, configuration: x.configuration })
    })
    const handleSave = () => {
        const objId = Object.keys(editRowsModel)[0]
        const obj = Object.values(editRowsModel)[0]
        axios.put(`/api/device/admin/${objId}`, {
            Name: obj.name.value,
            ExternalId: obj.externalId.value
        },{headers: !token ? {} : { 'Authorization': `Bearer ${token}` }}).then((response) => dispatch({type: "GRAB_ADMIN_DEVICES"}))
        .catch((error) => console.log(error))
    }

    const handleEditRowsModelChange = React.useCallback((model) => {
        setEditRowsModel(model);
    }, []);

    return (
        <div>
            <div
                style={{ marginBottom: "15px", display: 'flex', justifyContent: 'space-between' }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpen}
                >
                    <Plus /> Device
                </Button>
                {Object.keys(editRowsModel).length !== 0 ?
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                    >
                        <Save /> save
                    </Button>
                    : ''}
            </div>
            <Popup
                open={open}
                close={handleClose}
            >
                <CreateDevice firmware={firmware} hardware={hardware} close={handleClose} />
            </Popup>
            <Card sx={{ height: 800 }}>
                <DataGrid
                    columns={columns}
                    label="Devices"
                    editRowsModel={editRowsModel}
                    editMode="row"
                    onRowEditCommit={handleSave}
                    onEditRowsModelChange={handleEditRowsModelChange}
                    rows={data}
                    components={{
                        Toolbar: GridToolbar
                    }}
                />
            </Card>
        </div>
    )
}

export default DeviceTables;