import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios'; 
import authService from "../../components/api-authorization/AuthorizeService";
import Toast from '../../mixins/Toast';
import Swal from 'sweetalert2';

function* showCoreWizard( action ) {
    try {
        yield put({
            type: 'CORE_DATA_LOADING',
            payload: false
        });
    } catch (error) {
        console.log(error)
    }
}

function* coreReport( action ) {
    try {
        //console.log('action.payload.endDate: ' + action.payload.endDate);
        yield put({
            type: 'CORE_REPORT_START',
            payload: action.payload.startDate
        });
        yield put({
            type: 'CORE_REPORT_END',
            payload: action.payload.endDate
        });
        yield put({
            type: 'CORE_DATA_LOADING',
            payload: true
        });
        let myDevices = [];
        yield put({
            type: 'SET_CORE_REPORT_DEVICES',
            payload: myDevices
        });
        let token = yield authService.getAccessToken(); //
        let goal = action.payload.deviceList.length;
        for(let i = 0; i < goal; i++) {
            Swal.fire({
                title: "Loading...",
                imageUrl: '/static/images/loading-gif.gif',
                text: 'Fetching device ' + (i+1) + " of " + goal,
                showConfirmButton: false,
                backdrop: "false",
            })
            // Toast.fire({ title: 'Fetching ' + (i+1) + " of " + goal, icon: 'info' });
            let target = action.payload.deviceList[i];
            let retval = yield axios.get('/api/CoreData/' + target + '/' + action.payload.startDate.startOf('day') + '/' + action.payload.endDate.endOf('day'), {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
            if(retval.status === 200) {
                myDevices = JSON.parse(JSON.stringify(myDevices));
                myDevices.push(retval.data);
                yield put({
                    type: 'SET_CORE_REPORT_DEVICES',
                    payload: myDevices
                });
            }
        }
        // Toast.fire({ title: 'CORE Report Complete', icon: 'success' });

        yield put({
            type: 'CORE_LOADING',
            payload: false
        });
        Swal.close();

    } catch (error) {
        console.log(error)
        Swal.close();

    }
}

function* CoreReportSaga() {
    yield takeLatest('CORE_REPORT', coreReport);
    yield takeLatest('CORE_WIZARD', showCoreWizard);
}

export default CoreReportSaga;
