import React, { useState, useEffect } from 'react';
import {
    Box,
} from "@mui/material";
import axios from 'axios';
import { useDispatch,useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { GrandCentralDispatch } from '../../../utils/GrandCentralDispatch';
import authService from '../../../components/api-authorization/AuthorizeService';
import { Tooltip, Typography } from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';


const ServiceInfo = (props) => {
    const loadingMessage = "loading . . .";
    const state = useSelector((store) => store.deviceList.searchClicked);    
    const info = useSelector((store) => store.deviceList.rawInfo)
    const dispatch = useDispatch();
    const status = useSelector((store) => store.deviceList.machineStatus); // has an object of whether FW is out of date
    const gpsParing = useSelector((store) => store.deviceList.gpsParing);
    // console.log('info in service info', info);
    // console.log("status", status);
    if(typeof status.serialNumber !== 'undefined')
    {
        dispatch({type: 'SEARCH_CLICKED', payload: false});
    }
    let colors = {};
    if(!status.noConfig && !status.noStat ){
        // console.log('status ', status.noConfig, status.noStat);
        if(status.cabCompare === 'match'){
            colors.cab = 'green';
        } else if(status.cabCompare === 'no match'){
            colors.cab = 'red';
        } else {
            colors.cab = 'black'
        }

        if(status.locusCompare === 'match'){
            colors.locus = 'green';
        } else if(status.locusCompare === 'no match'){
            colors.locus = 'red';
        } else {
            colors.locus = 'black'
        }

        if(status.evcmCompare === 'match'){
            colors.evcm = 'green';
        } else if(status.evcmCompare === 'no match'){
            colors.evcm = 'red';
        } else {
            colors.evcm = 'black'
        }

        if(status.teleCompare === 'match'){
            colors.tele = 'green';
        } else if(status.teleCompare === 'no match'){
            colors.tele = 'red';
        } else {
            colors.tele = 'black'
        }
    }
    // console.log("colors", colors);

    return(
        <Box sx={{ width:'70%', margin: '20px' }}>
            {state === true ? "Loading...":        
            <Typography variant="h4">
                <p>Serial Number: {!status.serialNumber ? "" : status.serialNumber}</p>
                <p>State of Charge: {!status.stateOfCharge ? "" : status.stateOfCharge === "Not Reported"? status.stateOfCharge : status.stateOfCharge + "%"}</p>
                Total Operating Hours: {!status.operatingHours ? "" : status.operatingHours === "Not Reported"? status.operatingHours : Number(status.operatingHours).toFixed(1)}<br />
                Total Machine Hours: {!status.machineHours ? "" : status.machineHours === "Not Reported"? status.machineHours : Number(status.machineHours).toFixed(1)}<br />
                Cab Controller FW Version: {!status.cabFWV ? "" : <span style={{color: colors.cab}}> {status.cabFWV}</span>} {status.cabExpect? "(" + status.cabExpect + " expected)" : "" }<br />
                Locus Controller FW Version: {!status.locusFWV ? "" : <span style={{color: colors.locus}}> {status.locusFWV} </span>} {status.locusExpect? "(" + status.locusExpect + " expected)" : "" }<br />
                EVCM FW Version: {!status.evcmfwv ? "" : <span style={{color: colors.evcm}}> {status.evcmfwv}</span>} {status.evcmExpect? "(" + status.evcmExpect + " expected)" : "" }<br />
                Telematics FW Version: {!status.teleFWV ? "" : <span style={{color: colors.tele}}> {status.teleFWV} </span>} {status.teleExpect? "(" + status.teleExpect + " expected)" : "" }<br />
                Last Reported: {!status.report ? "" : status.report} <br /> <br />
                <p style={{color: 'red'}}>{gpsParing === 0 ? "" : `GPS Data Points have been reduced to ${gpsParing.reduce} from ${gpsParing.total} to ${gpsParing.display} for map performance.`}</p>
            </Typography>
        }
        </Box>     
    )
}
export default ServiceInfo;