import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import getInitials from '../../../utils/getInitials'
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    Hidden,
    List,
    Typography,
    Button,
    Menu,
    Tooltip
} from '@mui/material';
import {
    Activity as ActivityIcon,
    Lock as LockIcon,
    User as UserIcon,
    Users as UsersIcon,
    Cpu as CpuIcon,
    HardDrive as HardDriveIcon,
    Settings as SettingsIcon,
    AlertCircle as AlertIcon,
    Database as DatabaseIcon,
    BarChart as BarChartIcon,
    ExternalLink,
    Map,
    Save,
    Globe,
    FileText,
    Key,
    Tool,
    Sliders,
    Home
} from 'react-feather';
import NavItem from './NavItem';
import { ApplicationPaths } from '../../../../src/components/api-authorization/ApiAuthorizationConstants';
import { styled } from '@mui/system';
import {
    TreeView,
    TreeItem
} from '@mui/lab';
import {
    ExpandMore,
    ChevronRight
} from '@mui/icons-material'

const items = [
    {
        href: '/app/home',
        icon: Home,
        title: 'Home'
    },
    {
        href: '/app/FleetManagement',
        icon: Map,
        title: 'Fleet Management'
    },
    {
        href: '/app/Service',
        icon: Tool,
        title: 'Service View'
    },
    {
        href: '/app/Reports',
        icon: FileText,
        title: 'Reports'
    },
    {
        href: '/app/EngineeringView',
        icon: Sliders,
        title: 'Engineering'
    },
    // {
    //     href: '/app/faults',
    //     icon: AlertIcon,
    //     title: 'Faults'
    // },
    {
        href: '/app/group',
        icon: UsersIcon,
        title: 'Groups'
    },
    {
        href: '/app/devices',
        icon: Globe,
        title: 'Devices'
    },
    {
        href: '/app/CanGroup',
        icon: DatabaseIcon,
        title: 'Can Configuration'
    },
    {
        href: '/app/data',
        icon: Save,
        title: 'Can Data'
    },
    {
        href: '/app/dashboard',
        icon: ExternalLink,
        title: 'Custom Dashboard'
    },
];
const accountItems = [
    {
        href: `${ApplicationPaths.Profile}`,
        icon: UserIcon,
        title: 'Account'
    },
    {
        href: `${ApplicationPaths.LogOut}`,
        icon: LockIcon,
        title: 'Logout'
    },
]

const SettingsText = styled(Typography)({
    fontSize: '0.875rem',
    marginTop: '5px',
    color: '#546e7a',
    fontWeight: '500',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
})

const MyDrawer = styled(Drawer)({
    width: 256,
    top: 64,
}, ` & > div {
  border: none;
  margin-top: 64px;
}`)

const NavBar = (props, { onMobileClose, openMobile }) => {
    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const group = useSelector((state) => state.setUser.currentGroup);
    const role = useSelector((state) => state.setUser.currentRole);
    const systemAdmin = useSelector((state) => state.setUser.systemAdmin);
    const [listItems, setListItems] = React.useState(items);
    const groups = useSelector((state) => state.setUser.groups);
    const user = useSelector((state) => state.setUser.setUser);
    const dispatch = useDispatch();
    const open = Boolean(anchorEl);
    const admin = {
        href: '/app/admin',
        icon: SettingsIcon,
        title: 'Admin'
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (x) => {
        if (x.name) {
            let string = JSON.stringify(x);
            localStorage.setItem("group", `${string}`)
            dispatch({
                type: 'CHANGE_GROUP',
                payload: x,
                user: user
            })
            setAnchorEl(null);
        }
        else {
            setAnchorEl(null);
        }
    }
    useEffect(() => {
        if ( (role.name === "Admin" && (listItems.includes(admin) === false) ) || systemAdmin ) {
            if (listItems[listItems.length - 1].title !== "Admin") {
                setListItems([...listItems, admin]);
            }
        }
        else if ( role.name !== "Admin" && listItems[listItems.length - 1].title === "Admin" ) {
            setListItems(items)
        }
    }, [role]);

    useEffect(() => {
        if (props.openMobile && props.onMobileClose) {
            props.onMobileClose();
        }
    }, [location.pathname]);

    const Tree = (x, multi) => {
        let treeArray = []
        if (x.subGroups) {
            for (const i of x.subGroups) {
                if (i.subGroups) {
                    treeArray.push(
                        <TreeItem key={Math.random()} nodeId={String(Math.random())} label={i.name}>
                            {Tree(i, true)}
                        </TreeItem>
                    )
                }
                else {
                    treeArray.push(
                        <TreeItem key={Math.random()} nodeId={String(Math.random())} onClick={() => handleClose(i)} label={i.name} />
                    )
                }
            }
            if ( multi ) {
                return (
                    <> 
                        <TreeItem key={Math.random()} nodeId={String(Math.random())} onClick={() => handleClose(x)} label={x.name} />
                        {treeArray}
                    </>
                )
            }
            else {
                return (
                    <TreeItem key={Math.random()} nodeId={String(Math.random())} label={x.name}>
                        <TreeItem key={Math.random()} nodeId={String(Math.random())} onClick={() => handleClose(x)} label={x.name} />
                        {treeArray}
                    </TreeItem>
                )
            }
        }
        else {
            return <TreeItem key={Math.random()} nodeId={String(x.id)} onClick={() => handleClose(x)} label={x.name} />
        }
    }

    const content = (
        <Box
            height="100%"
            width="256px"
            display="flex"
            flexDirection="column"
        >
            <Divider />
            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                width="100%"
                p={2}
            >
                <Avatar
                    sx={{
                        cursor: 'pointer',
                        width: 64,
                        height: 64,
                        backgroundColor: '#2196f3'
                    }}
                    component={RouterLink}
                    to={ApplicationPaths.Profile}
                >
                    {getInitials(user.name)}
                </Avatar>
                <Typography
                    color="textPrimary"
                    variant="body2"
                >
                    {user.name}
                </Typography>
                {group.name ?
                    <Tooltip title="Current Group">
                        <Button
                            onClick={handleClick}
                            variant="outlined"
                            sx={{
                                marginTop: 1,
                                minWidth: '150px'
                            }}
                        >
                            {group.name}
                        </Button>
                    </Tooltip>
                : 
                    <Button
                        disabled
                        variant="outlined"
                        sx={{
                            marginTop: 1,
                            minWidth: '150px'
                        }}
                    >
                        No Groups
                    </Button>
                }
                <Menu
                    id="menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    sx={{
                        minWidth: '150px',
                        maxHeight: '400px'
                    }}
                >
                    <TreeView
                        defaultCollapseIcon={<ExpandMore />}
                        defaultExpandIcon={<ChevronRight />}
                        sx={{
                            minWidth: '150px',
                            maxHeight: '400px'
                        }}
                    >
                        {groups.map((x, i) => {
                            return Tree(x)
                        })}
                    </TreeView>
                </Menu>
            </Box>
            <Divider />
            <Box p={2}>
                <List>
                    {listItems.map((item, i) => (
                        <span key={i}>
                            {item.title === "Home" ? <> <SettingsText variant='body1' > Monitor </SettingsText> <Divider />  </>: ""}  
                            {item.title === "Groups" ? <> <SettingsText variant='body1' > Manage </SettingsText> <Divider />  </>: ""}
                            <NavItem
                                href={item.href}
                                key={item.title}
                                title={item.title}
                                icon={item.icon}
                            />
                        </span>
                    ))}
                </List>
                <SettingsText variant='body1' >
                    Settings
                </SettingsText>
                <Divider />
                <List>
                    {accountItems.map((item) => (
                        <NavItem
                            href={item.href}
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                        />
                    ))}
                </List>
            </Box>
            <Box flexGrow={1} />
        </Box>
    );

    return <>
        <Hidden lgUp>
            <Drawer
                anchor="left"
                sx={{ width: 256, height: 'calc(100% - 64px)' }}
                onClose={props.onMobileClose}
                open={props.openMobile}
                variant="temporary"
            >
                {content}
            </Drawer>
        </Hidden>
        <Hidden mdDown>
            <MyDrawer
                anchor="left"
                open
                variant="persistent"
            >
                {content}
            </MyDrawer>
        </Hidden>
    </>;
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

NavBar.defaultProps = {
    onMobileClose: () => { },
    openMobile: false
};

export default NavBar;
