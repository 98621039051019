import React from "react";
import { Container, Grid } from "@mui/material";
import Page from "../../components/Page";
import { connect } from "react-redux";
import TempChart from './TempChart'
import VoltageChart from './VoltageChart'
import { styled } from "@mui/system";
import theme from "../../theme";

const MyPage = styled(Page)({
  backgroundColor: theme.palette.background.default,
  minHeight: '100%',
  paddingBottom: theme.spacing(3),
  paddingTop: theme.spacing(3)
})

const ChartsView = (props) => {
    return (
      <MyPage title="Charts">
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <TempChart />
            </Grid> 
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <VoltageChart />
            </Grid>
          </Grid>
        </Container>
      </MyPage>
    );
};
const mapStateToProps = (state) => ({
  data: state.deviceList,
});

export default connect(mapStateToProps)(ChartsView);
