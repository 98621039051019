import React from 'react';
import {
    TextField,
    Button,
    Typography,
    styled,
    Autocomplete,
} from '@mui/material';
import { Box } from '@mui/system';
import { connect, useSelector } from 'react-redux'
import Toast from '../../mixins/Toast';

const MyRoot = styled(Box)({
    backgroundColor: 'white',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    flexWrap: 'wrap',
    padding: '10px'
})

const MyForm = styled(Box)({
    width: '100%',
    textAlign: 'center'
})

const Submit = styled(Box)({
    width: '50%',
    display: 'flex',
    justifyContent: 'space-between'
})

const Input = styled(TextField)({
    margin: '10px'
})

const CreateDevice = React.forwardRef((props, ref) => {
    const [id, setId] = React.useState(null);
    const [hardware, setHardware] = React.useState(null);
    const [firmware, setFirmware] = React.useState(null);
    const [name, setName] = React.useState(null);
    const [config, setConfig] = React.useState(null);
    const [versions, setVersions] = React.useState([]);
    const [groups, setGroups] = React.useState(null);
    const [canGroup, setCanGroup] = React.useState(null)
    const token = useSelector((state) => state.setUser.token);
    const user = useSelector((state) => state.setUser.setUser)
    const canGroups = useSelector((state) => state.deviceList.canGroup);

    const handleChange = (type, event) => {
        switch (type) {
            case 'id':
                return setId(event.target.value);
            case 'hardware':
                return setHardware(event);
            case 'firmware':
                return setFirmware(event);
            case 'name': 
                return setName(event.target.value);
            case 'config':
                return setConfig(event.target.value);
            case 'group':
                return setGroups(event)
            case 'canGroup':
                return setCanGroup(event)
            default:
                break;
        }
    }

    const handleSubmit = () => {
        if ( name === null ) {
            return Toast.fire({
                icon: 'error',
                title: 'Invalid Name'
            })
        }
        else if ( firmware === null ) {
            return Toast.fire({
                icon: 'error',
                title: 'Select Firmware'
            })
        }
        else if ( hardware === null ) {
            return Toast.fire({
                icon: 'error',
                title: 'Select Hardware'
            })
        }
        else if ( id === null ) {
            return Toast.fire({
                icon: 'error',
                title: 'Please Enter External ID'
            })
        }
        else if ( groups === null ) {
            return Toast.fire({
                icon: 'error',
                title: 'Select Group'
            })
        }
        const item = {
            Name: name,
            FirmwareVersionId: firmware,
            HardwareVersionId: hardware,
            Configuration: config,
            ExternalId: id,
            GroupId: groups,
            CanGroupId: canGroup
          };
        fetch('/api/device', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(item)
          })
          .then(response => {
            if(response.status === 200){
                    Toast.fire({
                        icon: 'success',
                        title: 'Device Created'
                    })
                    props.close(false)
                    props.dispatch({type:'GRAB_DATA', payload: user})
                }
            })
            .catch((error) => {
                Toast.fire({
                    icon: 'error',
                    title: 'Error Creating Device'
                })
                console.error('Unable to add item.', error)
            });
        }
    
    return (
        <MyRoot>
            <Box
            sx={{
                width: '90%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap'
            }}
            >
            <Box>
                <Typography variant="h1">
                    ADD NEW DEVICE
                </Typography>
            </Box>
            <MyForm>
                <Box
                    sx={{display: 'flex', justifyContent: 'center'}}
                >
                    <Autocomplete
                        id="Hardware-Family"
                        options={props.hardware}
                        style={{ width: '300px', marginRight: '15px' }}
                        onChange={(event, newValue) => {
                            if (!newValue) {
                                setVersions([])
                                return
                            }
                            else {
                                if (!newValue.version) {
                                    setVersions([])
                                }
                                else {
                                    setVersions(newValue.version)
                                }
                            }
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Hardware Family" variant="outlined" />}
                    />
                    <Autocomplete
                        id="Hardware-Version"
                        options={versions}
                        style={{ width: '300px', marginRight: '15px' }}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                handleChange('hardware', newValue.id)
                            }
                            else {
                                return
                            }
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Hardware Version" variant="outlined" />}
                    />
                </Box>
                <Box
                    sx={{display: 'flex', justifyContent: 'center', marginTop: '15px'}}
                >
                    <Autocomplete
                        id="Firmware-Version"
                        options={props.firmware}
                        style={{ width: '300px', marginRight: '15px' }}
                        onChange={(event, newValue) => {
                            handleChange('firmware', newValue.id)
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Firmware Version" variant="outlined" />}
                    />
                    <Autocomplete
                        id="Groups"
                        options={props.groups}
                        sx={{ width: '300px', marginRight: '15px' }}
                        onChange={(event, newValue) => {
                            if(newValue){
                                handleChange('group', newValue.id)
                            }
                            else {
                                return 
                            }
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Groups" variant="outlined"/> }
                    />
                    <Autocomplete 
                        id="Can-Group"
                        options={canGroups}
                        sx={{ width: '300px' }}
                        onChange={(event, newValue) => {
                            handleChange('canGroup', newValue.id)
                        }}
                        defaultValue={canGroups.find((x) => x.id === canGroup )}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Can Group" variant="outlined"/>}
                    />
                </Box>
                <Box>
                    <Input
                        required
                        id="Name"
                        label="Name"
                        defaultValue={name}
                        variant="outlined"
                        onChange={(event) => handleChange('name', event)}
                    />
                    <Input 
                        required
                        id="ExternalId"
                        label="External Id"
                        variant="outlined"
                        onChange={(event) => handleChange('id', event)}
                    />
                </Box>
            </MyForm>
            <Submit>
                <Button
                    sx={{
                        width: '30%',
                        minHeight: "50px",
                        maxHeight: '75px'
                    }}
                    variant="contained"
                    color="primary"
                    onClick={props.close}
                >
                    Cancel
                </Button>
                <Button
                    sx={{
                        width: '30%',
                        minHeight: "50px",
                        maxHeight: '75px'
                    }}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </Submit>
            </Box>
        </MyRoot>
    )
})

export default connect()(CreateDevice);