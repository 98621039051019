import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Typography, styled, ListItemButton } from '@mui/material';
import theme from '../../../theme';

const Item = styled(ListItemButton)({
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    justifyContent: 'flex-start',
    width: '100%'
})

const MyItem = styled(Button)({
    color: 'rgb(84, 110, 122)',
    justifyContent: 'flex-start',
    width: '100%',
}) 

const NavItem = ({
    className,
    href,
    icon: Icon,
    title,
}) => {

    return (
        <Item
            disableGutters
            selected={href === window.location.pathname}
        >
            <MyItem
                component={RouterLink}
                to={href}
                startIcon={<Icon style={{marginRight: theme.spacing(1)}}/>}
            >
                <Typography sx={{marginRight: 'auto'}}>
                  {title}
                </Typography>
            </MyItem>
        </Item>
    );
};

NavItem.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    icon: PropTypes.elementType,
    title: PropTypes.string
};

export default NavItem;
