import React from "react";
import {
    Box,
    Button,
    FormControl,
    IconButton,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
    Select,
    Checkbox,
    Autocomplete,
    Step,
    Stepper,
    StepLabel
} from "@mui/material";
// import axios from "axios";
// import Toast from "../../mixins/Toast";
import { useSelector, useDispatch } from "react-redux";
import theme from '../../theme';
import Swal from "sweetalert2";

const Bulk = (props) => {
    // const [editRowsModel, setEditRowsModel] = React.useState({});
    const [activeStep, setActiveStep] = React.useState(0);
    const [STMFirmware, setSTMFirmware] = React.useState({});
    const [ESPFirmware, setESPFirmware] = React.useState({});
    const [HUBFirmware, setHUBFirmware] = React.useState({});
    const [CABFirmware, setCABFirmware] = React.useState({});
    const [EVCMFirmware, setEVCMFirmware] = React.useState({});
    const [groupSelect, setGroupSelect] = React.useState();
    const currentRole = useSelector((state) => state.setUser.currentRole);
    const firmware = useSelector((state) => state.admin.firmwareList);
    const token = useSelector((state) => state.setUser.token);
    const allGroups = useSelector((state) => state.setUser.allGroups);
    const dispatch = useDispatch();
    const steps = getSteps();
    // console.log('firmware', firmware);
    // console.log('all groups', allGroups);
    // console.log('active step', activeStep);
    // console.log('group select', groupSelect);

    function getSteps() {
        return ['Choose Group', 'Signum STM Firmware', 'Signum ESP Firmware', 'Bobcat Hub Firmware', 'Bobcat Cab Firmware', 'Moog Firmware',];
    }
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNext = () => {
        // Firwares DB column Controller Type: 0 = STM, 1 = ESP, 2 = CAB, 3 = HUB, 4 = EVCM
        if (activeStep === 0 && groupSelect.id === undefined ) {
            Swal.fire({
                title: 'Please Select a Group to Update, to Continue',
                icon: 'warning',
                timer: 10000,
                timerProgressBar: true,
                })
            return
        } else if (activeStep === 1) { // STM
            // console.log("step 1", STMFirmware);
            if (STMFirmware.id !== undefined){
                dispatch({ type: 'BATCH_FW_UPDATE', payload: {group: groupSelect, fw: STMFirmware, type: 0 }});
            } else { 
                Swal.fire({
                    title: 'Please Select a Firmware to Update and Continue, or Click Skip',
                    icon: 'warning',
                    timer: 10000,
                    timerProgressBar: true,
                    })
                return
            }
            

        } else if (activeStep === 2) { // ESP
            // console.log("step 2", ESPFirmware);
            if (ESPFirmware.id !== undefined){
            dispatch({ type: 'BATCH_FW_UPDATE', payload: {group: groupSelect, fw: ESPFirmware, type: 1 }});
            } else { 
                Swal.fire({
                    title: 'Please Select a Firmware to Update and Continue, or Click Skip',
                    icon: 'warning',
                    timer: 10000,
                    timerProgressBar: true,
                    })
                return
            }

        } else if (activeStep === 3) { // HUB
            // console.log("step 3", HUBFirmware);
            if (HUBFirmware.id !== undefined){
            dispatch({ type: 'BATCH_FW_UPDATE', payload: {group: groupSelect, fw: HUBFirmware, type: 3 }});
            } else { 
                Swal.fire({
                    title: 'Please Select a Firmware to Update and Continue, or Click Skip',
                    icon: 'warning',
                    timer: 10000,
                    timerProgressBar: true,
                    })
                return
            }

        } else if (activeStep === 4) { // CAB
            // console.log("step 4", CABFirmware);
            if (CABFirmware.id !== undefined){
            dispatch({ type: 'BATCH_FW_UPDATE', payload: {group: groupSelect, fw: CABFirmware, type: 2 }});
            } else { 
                Swal.fire({
                    title: 'Please Select a Firmware to Update and Continue, or Click Skip',
                    icon: 'warning',
                    timer: 10000,
                    timerProgressBar: true,
                    })
                return
            }

        } else if (activeStep === 5) { // Moog EVCM
            // console.log("step 5", EVCMFirmware);
            if (EVCMFirmware.id !== undefined){
            dispatch({ type: 'BATCH_FW_UPDATE', payload: {group: groupSelect, fw: EVCMFirmware, type: 4 }});
            } else { 
                Swal.fire({
                    title: 'Please Select a Firmware to Update and Continue, or Click Skip',
                    icon: 'warning',
                    timer: 10000,
                    timerProgressBar: true,
                    })
                return
            }

        } 

        if(activeStep >= steps.length -1 ){
            // console.log('reset');
            handleReset()
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }

    const handleSkip = () => {
        if ( activeStep === steps.length - 1 ){
            props.setBulk(!props.bulk);
        }
        return setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleReset = () => {
        setActiveStep(0);
    };

    function getStepContent(stepIndex) {
        let display = '';
        switch (stepIndex) {
            case 0:
                display = (
                    <Box
                        sx={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <Typography textAlign={"center"} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="h3">
                            Update all Devices in Group:
                        </Typography>
                        <Typography textAlign={"center"} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="h3">
                            <Select
                                style={{ minWidth: '200px'}}
                                defaultValue={{ label: "Select a Group", value: -1 }}
                                onChange={(event) => {
                                    setGroupSelect(event.target.value)
                                }}>
                                {allGroups.map((x, i) => {
                                    return <MenuItem key={i} value={x}>{x.name}</MenuItem>
                                })}
                            </Select>
                        </Typography>
                    </Box>
                )
                return display
            case 1:
                display = (
                    <Box
                        sx={{ height: '300px', width: '98%' }}
                    >
                        <Typography textAlign={"center"} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="h3">
                            with Signum STM Firmware:
                        </Typography>
                        <Typography textAlign={"center"} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="h3">
                            <Select
                                style={{ minWidth: '200px'}}
                                defaultValue={{ label: "Select Signum STM Firmware", value: -1 }}
                                onChange={(event) => {
                                    setSTMFirmware(event.target.value)
                                }}>
                                {firmware.Signum.STM.map((x, i) => {
                                    return <MenuItem key={i} value={x}>{x.name}</MenuItem>
                                })}
                            </Select>
                        </Typography>
                    </Box>
                )
                return display;
            case 2:
                display = (
                    <Box
                        sx={{ width: '100%', height: '300px', overflow: 'auto' }}
                    >
                        <Typography textAlign={"center"} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="h3">
                            with Signum ESP Firmware:
                        </Typography>
                        <Typography textAlign={"center"} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="h3">
                            <Select
                                style={{ minWidth: '200px'}}
                                defaultValue={{ label: "Select Signum ESP Firmware", value: -1 }}
                                onChange={(event) => {
                                    setESPFirmware(event.target.value)
                                }}>
                                {firmware.Signum.ESP.map((x, i) => {
                                    return <MenuItem key={i} value={x}>{x.name}</MenuItem>
                                })}
                            </Select>
                        </Typography>
                    </Box>
                )
                return display;
            case 3:
                display = (
                    <Box
                        sx={{ width: '100%', height: '300px', overflow: 'auto' }}
                    >
                        <Typography textAlign={"center"} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="h3">
                            Select Bobcat HUB Firmware:
                        </Typography>
                        <Typography textAlign={"center"} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="h3">
                            <Select
                                style={{ minWidth: '200px'}}
                                defaultValue={{ label: "Select Bobcat HUB Firmware", value: -1 }}
                                onChange={(event) => {
                                    setHUBFirmware(event.target.value)
                                }}>
                                {firmware.Bobcat.HUB.map((x, i) => {
                                    return <MenuItem key={i} value={x}>{x.name}</MenuItem>
                                })}
                            </Select>
                        </Typography>
                    </Box>
                )
                return display;
            case 4:
                display = (
                    <Box
                        sx={{ width: '100%', height: '300px', overflow: 'auto' }}
                    >
                        <Typography textAlign={"center"} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="h3">
                            Select Bobcat CAB Firmware:
                        </Typography>
                        <Typography textAlign={"center"} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="h3">
                            <Select
                                style={{ minWidth: '200px'}}
                                defaultValue={{ label: "Select Bobcat CAB Firmware", value: -1 }}
                                onChange={(event) => {
                                    setCABFirmware(event.target.value)
                                }}>
                                {firmware.Bobcat.CAB.map((x, i) => {
                                    return <MenuItem key={i} value={x}>{x.name}</MenuItem>
                                })}
                            </Select>
                        </Typography>
                    </Box>
                )
                return display;
            case 5:
                display = (
                    <Box
                        sx={{ width: '100%', height: '300px', overflow: 'auto' }}
                    >
                        <Typography textAlign={"center"} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="h3">
                            Select Moog EVCM Firmware:
                        </Typography>
                        <Typography textAlign={"center"} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="h3">
                            <Select
                                style={{ minWidth: '200px'}}
                                defaultValue={{ label: "Select Moog EVCM Firmware", value: -1 }}
                                onChange={(event) => {
                                    setEVCMFirmware(event.target.value)
                                }}>
                                {firmware.Moog.EVCM.map((x, i) => {
                                    return <MenuItem key={i} value={x}>{x.name}</MenuItem>
                                })}
                            </Select>
                        </Typography>
                    </Box>
                )
                return display;
            default:
                return display;
        }
    }

    return (
        <>
            <Box
                sx={{ width: '95%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}
            >

                <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                >
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}
                >
                    {getStepContent(activeStep)}
                </Box>
                <Box
                    sx={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}
                >
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ marginRight: theme.spacing(1) }}
                    >
                        Back
                    </Button>
                    <span>
                        <Button
                            disabled={activeStep === 0 }
                            variant="contained"
                            color="primary"
                            onClick={handleSkip}
                            style={{ marginRight: 15 }}
                        >
                            {activeStep === steps.length - 1 ? 'Exit' : 'Skip'}
                        </Button>
                        <Button
                            disabled={groupSelect === undefined}
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                        >
                            {activeStep === steps.length - 1 ? 'Update and End' : activeStep === 0 ? 'Continue' : 'Update and Continue'}
                        </Button>
                    </span>
                </Box>
            </Box>
        </>
    )

};
export default Bulk;
