class _GrandCentralDispatch {
    constructor() {
        this.topics = {};
        this.hOP = this.topics.hasOwnProperty;
    }

    subscribe(topic, listener) {
        // Create the topic's object if not yet created
        let self = this;
        if(!this.hOP.call(this.topics, topic)) this.topics[topic] = [];
  
        // Add the listener to queue
        var index = this.topics[topic].push(listener) -1;
  
        // Provide handle back for removal of topic
        return {
          remove: function() {
            delete self.topics[topic][index];
          }
        };
    }

    unsubscribe(topic, listener) {
        if(!this.hOP.call(this.topics, topic)) return;
        let index = this.topics[topic].indexOf(listener);
        if (index > -1) { 
            this.topics[topic].splice(index, 1); 
        }
    }

    publish(topic, info) {
        // If the topic doesn't exist, or there's no listeners in queue, just leave
        if(!this.hOP.call(this.topics, topic)) return;
        // console.log("GDC: " + topic + " | " + JSON.stringify(info));
  
        // Cycle through this.topics queue, fire!
        this.topics[topic].forEach(function(item) {
                item(info !== undefined ? info : {});
        });
      }
}

export const GrandCentralDispatch = new _GrandCentralDispatch();

/*

var subscription = GrandCentralDispatch.subscribe('<topic>', function(message) {
  console.log('got me a message');
});

window.GrandCentralDispatch.publish('<topic>', { <some object> });

*/

