import React from 'react';
import {
    Box,
    Container
} from '@mui/material';
import Page from '../../../components/Page';
import Results from './RawDataTable';
import { styled } from '@mui/system';
import theme from '../../../theme';
import Toolbar from './Toolbar';

const MyPage = styled(Page)({
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
})

const DataListView = () => {
    const [data, setData] = React.useState([]);

    return (
        <MyPage title="Data">
            <Container maxWidth={false}>
                <Toolbar data={data} setData={setData} />
                <Box mt={3}>
                    <Results data={data}/>
                </Box>
            </Container>
        </MyPage>
    );
};

export default DataListView;
