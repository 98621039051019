import React from 'react';
import {
  Box,
  Button,
  Card,
  MenuItem,
  Checkbox,
  TextField,
  Autocomplete,
  Chip,
} from '@mui/material';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    CheckBoxOutlineBlank,
    CheckBox as CheckBoxIcon
} from '@mui/icons-material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

const FaultToolbar = (props) => {
    const groups = useSelector((state) => state.setUser.allGroups);
    const devices = useSelector((state) => state.DashboardReducer.creationDevices);
    const deviceLoading = useSelector((state) => state.DashboardReducer.deviceListLoading);
    const [limit, setLimit] = React.useState(100);
    const [deviceList, setDeviceList] = React.useState([]);
    const [group, setGroup] = React.useState('');
    const [all, setAll] = React.useState(false);
    const [startValue, setStartValue] = React.useState(); 
    const [endValue, setEndValue] = React.useState();

    const dispatch = useDispatch(); 

    const icon = <CheckBoxOutlineBlank fontSize='small' />
    const checkedIcon = <CheckBoxIcon fontSize='small' />

    const handleSubmit = () => {
        // return dispatch({type: 'FAULT_DATA_TABLE', payload: deviceList, start: startValue, end: endValue })
        return dispatch({type: 'FAULT_DATA_TABLE', payload: deviceList, limit })
    }

  return (
    <Card sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <Box sx={{ padding: '10px', flex: 1 }}>
            <TextField
                label="Group"
                select
                focused
                variant="outlined"
                value={group}
                sx={{ width: '100%', color: 'black' }}
                onChange={(event) => {
                    dispatch({type: 'SET_DASH_DEVICES', payload: []});
                    dispatch({type: "GET_DASH_DEVICES", payload: { id: event.target.value}});
                    setDeviceList([]);
                    setAll(false);
                    setGroup(event.target.value);
                }}
            >
                {groups.map((x, i) => {
                    return <MenuItem key={i} value={x.id}>{x.name}</MenuItem>
                })}
            </TextField>
        </Box>
        <Box sx={{ padding: '10px', flex: 1 }}>
            <Autocomplete 
                sx={{ width: '100%' }}
                options={deviceLoading ? [] : [{externalId: all ? 'deselect' : 'all', name: all ? 'DESELECT' : 'ALL' }, ...devices]}
                multiple
                disableClearable={true}
                disableCloseOnSelect
                value={deviceList}
                isOptionEqualToValue={(option, value) => option.externalId === value}
                renderTags={(value, getTagProps) => {
                    const numTags = value.length;
                    const limitTags = 1;
                    return (
                      <>
                        {value.slice(0, limitTags).map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={index}
                            label={option}
                          />
                        ))}
                        {numTags > limitTags && ` +${numTags - limitTags}`}
                      </>
                    );
                  }}
                loading={deviceLoading}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                    let values = []
                    newValue.forEach((x) => x.externalId ? values.push(x.externalId) : values.push(x))
                    if (values.includes('all') && !values.includes('deselect')) {
                        let newList = []
                        setAll(true)
                        devices.map((x) => newList.push(x.externalId));
                        setDeviceList(newList)
                    }
                    else if (values.includes('deselect')) {
                        setAll(false)
                        setDeviceList([])
                    }
                    else {
                        setDeviceList(values)
                    }
                }}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox 
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={deviceList.indexOf(option.externalId) > -1}
                        />
                        {option.name}
                    </li>
                )}
                renderInput={(params) => <TextField {...params} focused label="Device" variant='outlined'/>}
            />
        </Box>
        <Box sx={{ padding: '10px', flex: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Start Date"
                    value={startValue}
                    onChange={(newValue) => {
                    setStartValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        </Box>
        <Box sx={{ padding: '10px', flex: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="End Date"
                    value={endValue}
                    onChange={(newValue) => {
                    setEndValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        </Box>
        {/* <Box sx={{ padding: '10px', flex: 1 }}>
            <TextField
                label="Limit"
                select
                focused
                variant="outlined"
                value={limit}
                sx={{ width: '100%', color: 'black' }}
                onChange={(event) => setLimit(event.target.value)}
            >
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={1000}>1,000</MenuItem>
                <MenuItem value={10000}>10,000</MenuItem>
                <MenuItem value={50000}>50,000</MenuItem>
                <MenuItem value={100000}>100,000</MenuItem>
            </TextField>
        </Box> */}
        <Box sx={{ padding: '10px' }}>
            <Button
                variant='contained'
                sx={{ height: '56px' }}
                onClick={handleSubmit}
            >
                Search
            </Button>
        </Box>
    </Card>
  );
};

export default FaultToolbar;
