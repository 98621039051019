import React from 'react';
import {
    Box,
    Container,
    Typography
} from '@mui/material';
import Page from '../../../src/components/Page';
import { styled } from '@mui/system';
import theme from '../../theme';

const MyPage = styled(Page)({
    backgroundColor: theme.palette.background.default,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
})

const Image = styled('img')({
    margingTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
})

const NotFoundView = () => {

    return (
        <MyPage
           title="404"
        >
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
            >
                <Container maxWidth="md">
                    <Typography
                        align="center"
                        color="textPrimary"
                        variant="h1"
                    >
                        404: The page you are looking for isn’t here
                    </Typography>
                    <Typography
                        align="center"
                        color="textPrimary"
                        variant="subtitle2"
                    >
                        You either tried some shady route or you came here by mistake.
                        Whichever it is, try using the navigation
                    </Typography>
                    <Box textAlign="center">
                        <Image
                        alt="Under development"
                        src="/static/images/5203299.jpg"
                        />
                    </Box>
                </Container>
            </Box>
        </MyPage>
    );
};

export default NotFoundView;
