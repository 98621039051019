import React from 'react';
import {
  Box,
  Button,
  Card,
  MenuItem,
  Checkbox,
  TextField,
  Autocomplete,
  Chip,  
} from '@mui/material';
import {
    useDispatch,
    useSelector
} from 'react-redux';
//import { Dayjs } from 'dayjs';
import {
    CheckBoxOutlineBlank,
    CheckBox as CheckBoxIcon
} from '@mui/icons-material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import Swal from 'sweetalert2';
// import axios from 'axios';
// import JSONToCSVConvertor from '../../utils/CSV';
import moment from 'moment';

const Toolbar = (props) => {
    const groups = useSelector((state) => state.setUser.allGroups);
    const devices = useSelector((state) => state.DashboardReducer.creationDevices);
    const deviceLoading = useSelector((state) => state.DashboardReducer.deviceListLoading);
    const spns = useSelector((state) => state.CanSPNReducer.CanSPN);
    const files = useSelector((state) => state.deviceList.canFiles);
    //const token = useSelector((state) => state.setUser.token);
    const canGroupList = useSelector((state) => state.CanSPNReducer.CanGroup);
    const canGroupPGNS = useSelector((state) => state.CanSPNReducer.CanGroupPGNS);

    //const [limit, setLimit] = React.useState(100);
    const [deviceList, setDeviceList] = React.useState([]);    
    const [group, setGroup] = React.useState("");
    const [selectPgn, setSelectPgn] = React.useState([]);
    const [selectCanGroup, setSelectCanGroup] = React.useState([]);
    const [pgnList, setPgnList] = React.useState([]);
    //const [canGroupList, setCanGroupList] = React.useState([]);
    const [selectSpn, setSelectSpn] = React.useState([]);
    const [spnName, setSpnName] = React.useState("");
    const [pgnName, setPgnName] = React.useState("");
    const [canGroupName, setCanGroupName] = React.useState("");
    const [all, setAll] = React.useState(false);
    const dispatch = useDispatch();    
    const [startValue, setStartValue] = React.useState(moment().subtract(7, "days").startOf('day')); 
    const [endValue, setEndValue] = React.useState(moment());
    
    // console.log('device List', deviceList);
    // console.log('devices', devices);

    const icon = <CheckBoxOutlineBlank fontSize='small' />
    const checkedIcon = <CheckBoxIcon fontSize='small' />

    if(pgnList.length <= 0 && group !== "" && selectCanGroup != "" && canGroupPGNS !="")
    {
        //console.log("Can Group PGNS", canGroupPGNS)
        setPgnList(canGroupPGNS);
    }
    
    let sortedPgns = pgnList.sort((a, b) => { //sorts the users into an alphabetical array by userName
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    
    let sortedCanGroups = canGroupList.sort((a, b) => { //sorts the can groups into an alphabetical array by name
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    
    // console.log('can group list', canGroupList);
    // console.log('sorted can groups', sortedCanGroups);

    if (selectSpn.length === 0){
        dispatch({type: 'SET_ENG_GRAPH', payload: []})
    }

    const handleSubmit = () => {    
        let submitDevice = []
        let submitId = []
        for(let x of devices){
            for(let y of deviceList){
                if(y === x.name){
                    console.log('x & y', x, ' & ', y);
                    submitDevice.push(x.externalId);
                    submitId.push(x.id);
                }
            }
        }
    
        // console.log("submit", submitDevice); 
        // console.log("submit id ", submitId); 
        let presetDate = '';
        if (endValue === undefined){
            presetDate = moment().format('MM/DD/YY, h:mm a')
            //console.log("presetDate in if: ", presetDate);
        }
        let dataToSend ={
            Id: submitId,
            ExternalId: submitDevice,
            PGN: pgnName,
            SPN: spnName,
            StartTime: startValue,
            EndTime: endValue || presetDate
        }
        // console.log("data send: ", dataToSend);
        // console.log("data send: ", dataToSend.Id[0], "&", dataToSend.Id[1]);
        dispatch({ type: "SET_ENG_LOADING", payload: true})
        dispatch({ type: "FETCH_ENG_GRAPH", payload: dataToSend}); //
        dispatch({ type: 'SET_ENG_EXTRA_DATA', payload: dataToSend}); //
    }
    
  return (
    <Card sx={{ display: 'flex', justifyContent: 'space-evenly' }} >
        <Box sx={{ padding: '10px', flex: 1 }} >
            <TextField //group field
                label="Group"
                select
                focused
                variant="outlined"
                value={group}
                sx={{ width: '100%', color: 'black' }}
                onChange={(event) => { // clears all the fields to the right before grabbing info to populate the direct next field
                    dispatch({type: 'SET_DASH_DEVICES', payload: []});
                    dispatch({type: 'SET_CAN_GROUP', payload: []});
                    dispatch({type: 'SET_CAN_GROUP_PGN', payload: []});
                    dispatch({type: 'SET_CAN_SPN', payload: []});
                    dispatch({type: "GET_DASH_DEVICES", payload: { id: event.target.value}});                    
                    setDeviceList([]);                    
                    setAll(false);
                    setGroup(event.target.value);
                    setPgnList([]);
                }}
            >
                {groups.map((x, i) => {
                    return <MenuItem key={i} value={x.id}>{x.name}</MenuItem>
                })}
            </TextField>
        </Box>
        <Box sx={{ padding: '10px', flex: 1 }}>
            <Autocomplete // device field
                sx={{ width: '100%' }}
                options={deviceLoading ? [] : [{name: all ? 'DESELECT' : 'ALL'}, ...devices]}
                multiple
                disableClearable={false}
                disableCloseOnSelect
                value={deviceList}
                isOptionEqualToValue={(option, value) => option.name === value}
                renderTags={(value, getTagProps) => {
                    const numTags = value.length;
                    const limitTags = 1;
                    return (
                      <>
                        {value.slice(0, limitTags).map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={index}
                            label={option}
                          />
                        ))}
                        {numTags > limitTags && ` +${numTags - limitTags}`}
                      </>
                    );
                }}
                loading={deviceLoading}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                    let values = []
                    let searchValues = []
                    newValue.forEach((x) => x.name ? values.push(x.name) : values.push(x))
                    // newValue.forEach((x) => x.externalId ? searchValues.push(x.externalId) : searchValues.push(x))
                    setPgnList([]);
                    // dispatch({type: 'SET_CAN_GROUP', payload: []});
                    // dispatch({type: 'SET_CAN_GROUP_PGN', payload: []});
                    // dispatch({type: 'SET_CAN_SPN', payload: []});
                    if (values.includes('ALL') && !values.includes('DESELECT')) {
                        let newList = []
                        setAll(true)
                        devices.map((x) => newList.push(x.name));
                        setDeviceList(newList)
                        dispatch({type: "FETCH_CAN_GROUP", payload: { id: searchValues}});                        
                    }
                    else if (values.includes('DESELECT')) {
                        setAll(false)
                        setDeviceList([])
                    }
                    else {
                        for(let x of devices){
                            for(let y of values){
                                if(y === x.name){
                                    searchValues.push(x.externalId);
                                }
                            }
                        }
                        setDeviceList(values)               
                        dispatch({type: "FETCH_CAN_GROUP", payload: { id: searchValues}});                        
                    }
                    //console.log("values ", values)
                }}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox 
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={deviceList.indexOf(option.name) > -1}
                        />
                        {option.name}
                    </li>
                )}
                renderInput={(params) => <TextField {...params} focused label="Machines" variant='outlined'/>}
            />
        </Box>
        <Box sx={{ padding: '10px', flex: 1 }} >
            <TextField
                label="Can Groups"
                select
                focused
                variant="outlined"
                value={sortedCanGroups.name}
                sx={{ width: '100%', color: 'black' }}
                onChange={(event) => {                                
                    dispatch({type: 'SET_CAN_GROUP_PGN', payload: []}); 
                    dispatch({type: 'SET_CAN_SPN', payload: []});   
                    setPgnList([]);   
                    setSelectCanGroup(event.target.value);
                    setCanGroupName(event.target.value.name);     
                    dispatch({type: "FETCH_CAN_GROUP_PGN", payload: { id: event.target.value.id}});   
                }}>
                
                {sortedCanGroups.map((x, i) => {
                    // console.log('x in sorted group', x);
                    return <MenuItem key={i} value={x}>{x.name}</MenuItem>
                })}
            </TextField>
        </Box>

        <Box sx={{ padding: '10px', flex: 1 }} >
            <TextField
                label="PGN"
                select
                focused
                variant="outlined"
                value={selectPgn.name}
                sx={{ width: '100%', color: 'black' }}
                onChange={(event) => {                  
                    //console.log("PGN ID:",event.target.value);  
                    dispatch({ type: 'FETCH_CAN_SPN', payload: event.target.value.canPGNId });
                    setSelectPgn(event.target.value);
                    setPgnName(event.target.value.name);
                }}
            >
                {sortedPgns.map((x, i) => {
                    return <MenuItem key={i} value={x}>{x.name}</MenuItem>
                })}
            </TextField>
        </Box>
        <Box sx={{ padding: '10px', flex: 1 }} >
            <TextField
                label="SPN"
                select
                focused
                variant="outlined"
                value={selectSpn.name}
                sx={{ width: '100%', color: 'black' }}
                onChange={(event) => {
                    setSelectSpn(event.target.value);
                    setSpnName(event.target.value.name);
                }}
            >
                {spns.map((x, i) => {
                    return <MenuItem key={i} value={x}>{x.name}</MenuItem>
                })}
            </TextField>
        </Box>
        <Box sx={{ padding: '10px', flex: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Start Date"
                    value={startValue}
                    onChange={(newValue) => {
                    setStartValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        </Box>
        <Box sx={{ padding: '10px', flex: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="End Date"
                    value={endValue}
                    onChange={(newValue) => {
                    setEndValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        </Box>
        <Box sx={{ padding: '10px' }}>
            <Button
                variant='contained'
                sx={{ height: '56px' }}
                onClick={() => handleSubmit()}>
                Search
            </Button>
        </Box>       
    </Card>
  );
};

export default Toolbar;
