import React from 'react';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    Typography,
    Button,    
    styled,
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import EditDevice from './EditDevice';
import { useSelector } from 'react-redux'
import theme from '../../theme';
import Swal from 'sweetalert2';
import axios from 'axios';
import JSONToCSVConvertor from '../../utils/CSV';
import moment from 'moment'
import Popup from '../../mixins/Popup';

const MyCard = styled(Card)({
    display: 'flex',
    flexDirection: 'column'
})

const MyGrid = styled(Grid)({
    alignItems: 'center',
    display: 'flex'
})
const deleteDevice = (id, token) => {
    fetch('/api/device/' + id, {
        method: 'DELETE',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
        }    
    })
    .then(response => {
        if(response.status === 200){
            window.location.reload();
        }
    })
    .catch(error => console.error('Unable to delete item.', error));
}

const DeviceCard = ({ className, product }) => {
    const [open, setOpen] = React.useState(false)
    const [deviceEdit, setDeviceEdit] = React.useState();
    const firmware = useSelector((state) => state.admin.firmwareList);
    const hardware = useSelector((state) => state.admin.hardwareList);
    const token = useSelector((state) => state.setUser.token);
    const userRole = useSelector((state) => state.setUser.currentRole);
    
    const edit = (id) => {
        fetch('/api/device/edit/' + id, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }    
        })
        .then(response => response.json())
        .then(data => {
        setDeviceEdit(data)
        setOpen(true)
        })
        .catch(error => console.error('Unable to edit item.', error));
    }
    
    let handleClose = () => {
        return setOpen(false)
    }
    const popup = (x) => {
        let filename = ''
        Swal.fire({
        title: 'Create CSV',
        input: 'text',
        icon: "question",
        inputLabel: 'Enter File Name',
        showCancelButton: true,
        confirmButtonColor: 'rgb(9, 65, 96)',
        confirmButtonText: 'Download',
        reverseButtons: true,
        inputValue: filename,
        inputValidator: (value) => {
            if (!value) {
            return 'Please Enter Name'
            }
        }
        }).then((value) => {
        if( value.isConfirmed === true ) {
            Swal.fire({
            imageUrl: '/static/images/loading-gif.gif',
            imageHeight: '100px',
            imageWidth: '100px',
            title: 'Preparing Data...',
            showConfirmButton: false
            })
            axios.get(`/api/DeviceData/All/${x}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }})
            .then((response) => {
            return JSONToCSVConvertor(JSON.stringify(response.data), value.value, true)
            })
            .then(() => Swal.close())
            .catch((error) => console.log(`Error with downloading data: ${error}`))
        }
        else {
            return;
        }
        })
    }

    return (
        <MyCard>
            <CardContent>
                <Box
                    display="flex"
                    justifyContent="center"
                    mb={3}
                >
                <Avatar
                    alt="Product"
                    src='/static/bobcat.jpeg'
                    variant="square"
                    sx={{
                    width: theme.spacing(10),
                    height: theme.spacing(10)
                    }}
                />
                
                </Box>
                <Typography
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                >
                    {product.name}
                </Typography>
                <Typography
                    align='center'
                    variant="h6"
                >
                    Lastest Check-in: {product.latest ? moment.utc(product.latest.created).local().format('lll') : "N/A"}
                </Typography>
                <Typography
                    align="center"
                    color="textPrimary"
                    variant="h6"
                >
                    Firmware Version: {product.latest ? product.latest.statusMessage['fw-ver'] : "N/A"}
                </Typography>
                <Typography
                    align="center"
                    color="textPrimary"
                    variant="h6"
                >
                    {product.latest ? product.firmwareVersion.name.includes(product.latest.statusMessage['fw-ver']) ? "Up to Date" : `Pending Firmware Update: ${product.firmwareVersion.name}` : "" }
                </Typography>
            </CardContent>
            <Box flexGrow={1} />
            <Divider />
            <Box p={2}>
                <Grid
                    container
                    justifyContent="space-between"
                    spacing={2}
                >
                    { userRole.name !== "Read" ?
                    <React.Fragment>
                        <MyGrid
                            item
                        >
                            <Button
                                variant="contained"
                                color="info"
                                startIcon={<EditIcon/>}
                                onClick={() => edit(product.id)}
                            >
                                Edit
                            </Button>
                        </MyGrid>
                        <MyGrid
                            item
                        >
                            <Button
                                variant="contained"
                                color="error"
                                startIcon={<DeleteIcon/>}
                                onClick={() => Swal.fire({
                                title: 'Are you sure?',
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: 'red',
                                cancelButtonColor: '#0e5e8a',
                                confirmButtonText: 'Delete'
                                }).then((result) => {
                                if (result.isConfirmed){
                                    Swal.fire({
                                    title: 'Your device has been deleted',
                                    icon: 'success',
                                    willClose: deleteDevice(product.id, token)
                                    })
                                }
                                })}
                            >
                                Delete
                            </Button>
                        </MyGrid>
                    </React.Fragment>
                    : "" }
                    <MyGrid
                        item
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<GetAppIcon/>}
                            onClick={() => popup(product.externalId)}
                        >
                            Data
                        </Button>
                    </MyGrid>
                </Grid>
                <Popup
                    open={open}
                    close={handleClose}
                >
                    <EditDevice firmware={firmware} hardware={hardware} device={product} deviceEdit={deviceEdit} close={handleClose}/>
                </Popup>
            </Box>
        </MyCard>
    );
};

DeviceCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default DeviceCard;
