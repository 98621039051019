import React from 'react';
import {
    Box,
    Container
} from '@mui/material';
import Page from '../../components/Page';
import FaultResults from './FaultDataTable';
import { styled } from '@mui/system';
import theme from '../../theme';
import FaultToolbar from './FaultToolbar';

const MyPage = styled(Page)({
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
})

const FaultDataListView = () => {
    const [faultdata, setFaultData] = React.useState([]);

    return (
        <MyPage title="Faults">
            <Container maxWidth={false}>
                <FaultToolbar data={faultdata} setData={setFaultData} />
                <Box mt={3}>
                    <FaultResults data={faultdata}/>
                </Box>
            </Container>
        </MyPage>
    );
};

export default FaultDataListView;
