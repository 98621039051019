import React from 'react';
import {
    Button,
    Stepper,
    Step,
    StepLabel,
    Typography,
    TextField,
    Box
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { ToastContainer } from 'react-toastify'
import { styled } from '@mui/system';
import theme from '../../theme';

const MyDiv = styled('div')({
    position: 'absolute',
    width: '50%',
    height: '50%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid black',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
})

const Input = styled(TextField)({
    margin: '10px'
})

function getSteps() {
    return ['Username and Email', 'Select user group', 'Select user access', 'Overview'];
}


const GroupPermission = React.forwardRef((props, ref) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [group, setGroup] = React.useState(null);
    const [permission, setPermission] = React.useState(null);
    const steps = getSteps();
    const [alter, setAlter] = React.useState(<p></p>);
    const [user, setUser] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const [pass, setPass] = React.useState(null);
    const [confirm, setConfirm] = React.useState(null);

    const handleNext = () => {
        if(activeStep === 0 && user === null){
            return setAlter(<p style={{color: 'red'}}>Please enter username</p>)
        }
        if(activeStep === 0 && email === null){
            return setAlter(<p style={{color: 'red'}}>Please enter email</p>)
        }
        if(activeStep === 0 && pass === null){
            return setAlter(<p style={{color: 'red'}}>Please enter password</p>)
        }
        if(activeStep === 0 && pass !== confirm){
            return setAlter(<p style={{color: 'red'}}>Passwords do not match</p>)
        }
        if (activeStep === 1 && group === null) {
            return setAlter(<p style={{color: 'red'}}>Please select user group</p>)
        }
        else if (activeStep === 2 && permission === null){
            return setAlter(<p style={{color: 'red'}}>Please select permissions</p>)
        }
        else {
            setAlter(<p></p>)
            return setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    function getStepContent(stepIndex) {
        const options = ['QED', "SIGNUM"]
        const permissions = ['READ', 'WRITE', 'ADMIN']
        let display = ''
        switch (stepIndex) {
        case 0:
            display = (
                <Box sx={{textAlign: 'center'}}>
                    <Input
                        variant="outlined"
                        label="Username"
                        onChange={(event) => setUser(event.target.value)}
                    />
                    <Input
                        variant="outlined"
                        label="Email"
                        onChange={(event) => setEmail(event.target.value)}
                    />
                    <br/>
                    <Input
                        variant="outlined"
                        label="Password"
                        type="password"
                        onChange={(event) => setPass(event.target.value)}
                    />
                    <Input 
                        variant="outlined"
                        label="Confirm Password"
                        type="password"
                        onChange={(event) => setConfirm(event.target.value)}
                    />
                </Box>
            )
            return display;
        case 1:
            display = (
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <Autocomplete
                        multiple
                        id="combo-box"
                        options={options}
                        onChange={(event, newValue) => {
                            setGroup(newValue)
                        }}
                        getOptionLabel={(option) => option}
                        style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Select Group" variant="outlined" />}
                    />
                </Box>
            )
            return display;
        case 2:
            display = ''
            display = (
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <Autocomplete
                        multiple
                        id="checkboxes-tags"
                        defaultValue={[]}
                        options={permissions}
                        onChange={(event, newValue) => {
                        setPermission(newValue)
                        }}
                        getOptionLabel={(option) => option}
                        style={{ width: 500 }}
                        renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Access" placeholder="Access" />
                        )}
                    />
                </Box>
            )
            return display;
        case 3:
            display = (
                <Box sx={{textAlign: 'center'}}>
                    <h2 style={{marginBottom: '20px'}}>User Confirmation</h2>
                    <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Box>
                            Username: {user}
                        <br/>
                            Email: {email}
                        </Box>
                        <Box>
                            Groups:
                            <ul>
                                {group.map((x) => {
                                return <li>{x}</li>
                                })}
                            </ul>
                        </Box>
                        <Box>
                            Permissions:
                            <ul>
                                {permission.map((x) => {
                                return <li>{x}</li>
                                })}
                            </ul>
                        </Box>
                    </Box>
                </Box>
            )
            return display;
        default:
            return 'Unknown stepIndex';
        }
    }

    const body = (
        <MyDiv>
            {alter}
            <ToastContainer />
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box>
                {activeStep === steps.length ? (
                    <Box>
                        <Typography 
                        sx={{
                            marginTop: theme.spacing(1),
                            marginBottom: theme.spacing(1)
                        }}
                        >
                        All steps completed
                        </Typography>
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                ) : (
                    <Box>
                        {getStepContent(activeStep)}
                        <Box sx={{ marginTop: '15px', textAlign: 'center' }}>
                        <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{marginRight: theme.spacing(1)}}
                        >
                            Back
                        </Button>
                        <Button 
                            variant="contained"
                            color="primary" 
                            onClick={handleNext}
                        >
                            {activeStep === steps.length - 1 ? 'Accept' : 'Next'}
                        </Button>
                        </Box>
                    </Box>
                )}
            </Box>
        </MyDiv>
    );

    return body;
});


export default GroupPermission;
