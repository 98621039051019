class _SignumUtils {
    constructor() {
        // nothing here yet
    }

    pgnIdDisplayValue(numberIn) {
        return numberIn.toString(16);
    }
}

export const SignumUtils = new _SignumUtils();

