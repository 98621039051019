import React from "react";
import {
    Box,
    Container,
    Tab,
    Tabs,
    AppBar,
    Card,
    Backdrop,
    CircularProgress,
} from "@mui/material";
import Page from '../../components/Page';
import CoreReport from "./CoreReport";
import FleetStatus from "../FleetManagement/FleetStatus";
import { connect, useSelector } from 'react-redux';
import theme from "../../theme";
import Toolbar from "../information/InformationtView/Toolbar";
import { maxHeight } from "@mui/system";
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';

const MyPage = styled(Page)({
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
})

const ReportView = (props) => {
    const [data, setData] = React.useState([]);
    const showWizard = useSelector((state) => state.setUser.showCoreWizard);
    const coreDataLoading = useSelector((state) => state.setUser.coreDataLoading);

    return (
        <MyPage title="Reports" >
            <Container maxWidth={false}>
                <Toolbar data={data} setData={setData} hideLimit={true} parent={"report"} />
                    <Box mt={3}>
                        <CoreReport />
                    </Box>
            </Container>
        </MyPage>
    );
}
export default ReportView;
