import React from 'react';
import { useSelector } from "react-redux"
import { LinearProgress } from '@mui/material';
import Moment from 'moment';
import { 
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridOverlay
} from '@mui/x-data-grid';
import { Box } from '@mui/system';

const Results = () => {
    const rawData = useSelector((state) => state.deviceList.rawData);
    const loading = useSelector((state) => state.setUser.rawDataLoading);
    const [sortModel, setSortModel] = React.useState([
        { field: 'created', sort: 'desc' },
    ]);

    function CustomLoadingOverlay() {
        return (
            <GridOverlay>
                <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                    <LinearProgress />
                </div>
            </GridOverlay>
        )
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    <GridToolbarExport />
                </Box>
            </GridToolbarContainer>
        );
    }
    
    const columns = [
        { field: 'deviceId', headerName: 'Device ID/IMEI', flex: 1, minWidth: 150 },
        // { field: 'name', headerName: 'Name', flex: 1, minWidth: 150 },
        // { field: 'value', headerName: 'Value', flex: 1, minWidth: 150 },
        { field: 'canFrame', headerName: 'Can Frame', flex: 1, minWidth: 150 },
        { field: 'canId', headerName: 'Can Id', flex: 1, minWidth: 150 },
        { field: 'canHex', headerName: 'Can Hex', flex: 1, minWidth: 200 },
        { field: 'latLong', headerName: 'Lat/Long/Accuracy', flex: 1, minWidth: 175 },
        { field: 'created', headerName: 'Created', flex: 1 , minWidth: 150, renderCell: (params) => (
            Moment.utc(params.row.created).local().format('M/D/YY, h:mm a')
        )}
    ]

    return (
        <Box sx={{ height: 800 }}>
            <DataGrid
                title="Raw Data List"
                rows={rawData}
                columns={columns}
                density="compact"
                loading={loading}
                sortModel={sortModel}
                onSortModelChange={(x) => {
                    if (x[0] !== sortModel[0]) {
                        setSortModel(x)
                    }
                }}
                components={{
                    Toolbar: CustomToolbar,
                    LoadingOverlay: CustomLoadingOverlay
                }}
            />
        </Box>
    );
};

export default Results;
