import React from 'react';
import {
    Container,
    Box,
    Button,
    Typography,
    IconButton,
    FormControl,
    Select,
    MenuItem,
    LinearProgress
} from '@mui/material';
import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import Page from '../../components/Page';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/system';
import theme from '../../theme';
import Toolbar from './Toolbar'
import DeviceCard from './DeviceCard';
import moment from "moment";
import { Edit, Plus, Save } from 'react-feather';
import axios from 'axios';
import Toast from '../../mixins/Toast';
import Popup from "../../mixins/Popup";
import GNSS from "./GNSS.js";
import { put } from 'redux-saga/effects';
import Bulk from './Bulk';

const MyPage = styled(Page)({
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
})

const ProductList = (props) => {
    const [page, setPage] = React.useState(1);
    const [filter, setFilter] = React.useState('');
    const [bulk, setBulk] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [editRowsModel, setEditRowsModel] = React.useState({});
    const userRole = useSelector((state) => state.setUser.currentRole);
    const devices = useSelector((state) => state.deviceList.deviceList);
    const firmware = useSelector((state) => state.admin.firmwareList);
    const hardware = useSelector((state) => state.admin.hardwareVersions);
    const canConfig = useSelector((state) => state.deviceList.canGroup);
    const token = useSelector((state) => state.setUser.token);
    const loading = useSelector((state) => state.deviceList.deviceTableLoading);
    const [gnssOpen, setGnssOpen] = React.useState(false);
    const [rows, setRows] = React.useState(devices);
    const dispatch = useDispatch();
    // console.log("***firmware***", firmware)
    // console.log('rows ', rows);
    // console.log('devices ', devices);
    let cabId = '';
    let hubId = '';
    let espId = '';
    let evcmId = '';
    React.useEffect(() => {        
        return setRows(devices)
    }, [devices])

    function CustomLoadingOverlay() {
        return (
            <GridOverlay>
                <div
                    style={{ position: 'absolute', top: 0, width: '100%' }}
                >
                    <LinearProgress />
                </div>
            </GridOverlay>
        )
    };

    const columns = [
        { field: 'name', headerName: 'Name', flex: 1, editable: edit },
        { field: 'externalId', headerName: 'External ID', flex: 1, minWidth: 150 },
        { field: 'groupName', headerName: 'Machine Groups', flex: 1, minWidth: 100},
        { field: 'serialNumber', headerName: 'Serial Number', flex: 1, minWidth: 150 },
        {
            field: 'canGroupId', headerName: 'Can Config', flex: 1, renderCell: (params) => {
                const deviceCan = canConfig.find((config) => config.id === params.row.canGroupId)
                if (deviceCan === undefined) {
                    return ''
                }
                else {
                    return deviceCan.name
                }
            }, renderEditCell: (params) => {
                return (
                    <FormControl fullWidth>
                        <Select
                            defaultValue={params.row.canGroupId}
                            onChange={(event) => {
                                params.row.canGroupId = event.target.value
                                Object.values(editRowsModel)[0].canGroupId.value = event.target.value
                            }}
                        >
                            {canConfig.map((x, i) => {
                                return <MenuItem key={i} value={x.id}>{x.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                )
            }, editable: edit
        },
        { field: 'latest', headerName: 'Checked In', flex: 1, valueGetter: (params) => params.row.latest ? moment.utc(params.row.latest.created).local().format('M/D/YY, h:mm a') : "" },
        {
            field: 'hardwareVersionId', headerName: 'Hardware', flex: 1, renderCell: (params) => {
                const deviceHardware = hardware.find((version) => version.id === params.row.hardwareVersionId)
                return deviceHardware.name
            }
        },
        {
            field: 'gpsInfo', headerName: 'GNSS', flex: 1, minWidth: 150, renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleGnssOpen(params.row.externalId)}
                >
                    View
                </Button>
            )
        },
        { field: 'gnssRate', headerName: 'GNSS Rate', flex: 1, editable: edit },
        {
            field: 'stm', headerName: 'STM Firmware', flex: 1, minWidth: 150, renderCell: (params) => params.row.stm ? params.row.stm.name : "N/A", editable: edit, renderEditCell: (params) => {
                return (
                    <FormControl fullWidth>
                        <Select
                            defaultValue={params.row.stm ? params.row.stm.id : "N/A"}
                            onChange={(event) => {
                               //console.log("***event target***", Object.values(editRowsModel)[0])
                                Object.values(editRowsModel)[0].stm.value = event.target.value
                            }}
                        >
                            {firmware.Signum.STM.map((x, i) => {
                                return <MenuItem key={i} value={x}>{x.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                )
            }
        },
        {
            field: 'esp', headerName: 'ESP Firmware', flex: 1, minWidth: 150, renderCell: (params) => params.row.esp ? params.row.esp.name : "N/A", editable: false, renderEditCell: (params) => {
                return (
                    <FormControl fullWidth>
                        <Select
                            disabled
                            defaultValue={espId = params.row.esp ? params.row.esp.id : "N/A"}
                            onChange={(event) => {
                                Object.values(editRowsModel)[0].esp.value = event.target.value
                            }}
                        >
                            {firmware.Signum.ESP.map((x, i) => {
                                return <MenuItem key={i} value={x}>{x.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                )
            }
        },
        {
            field: 'cab', headerName: 'CAB Firmware', flex: 1, minWidth: 150, renderCell: (params) => {
               return (params.row.cab ? params.row.cab.name : "N/A")
            }, editable: edit, renderEditCell: (params) => {
                return (
                    <FormControl fullWidth>
                        <Select
                            defaultValue={cabId = params.row.cab ? params.row.cab.id : "N/A"}
                            onChange={(event) => {
                                //params.row.cab.id = event.target.value.id
                                Object.values(editRowsModel)[0].cab.value = event.target.value
                            }}
                        >
                            {firmware.Bobcat.CAB.map((x, i) => {
                                return <MenuItem key={i} value={x}>{x.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                )
            }
        },
        {
            field: 'hub', headerName: 'HUB Firmware', flex: 1, minWidth: 150, renderCell: (params) => params.row.hub ? params.row.hub.name : "N/A", editable: edit, renderEditCell: (params) => {
                return (
                    <FormControl fullWidth>
                        <Select
                            defaultValue={hubId = params.row.hub ? params.row.hub.id : "N/A"}
                            onChange={(event) => {
                                //console.log("***event target***", Object.values(editRowsModel)[0])
                                Object.values(editRowsModel)[0].hub.value = event.target.value
                            }}
                        >
                            {firmware.Bobcat.HUB.map((x, i) => {
                                return <MenuItem key={i} value={x}>{x.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                )
            }
        },
        {
            field: 'evcm', headerName: 'EVCM Firmware', flex: 1, minWidth: 150, renderCell: (params) => params.row.evcm ? params.row.evcm.name : "N/A", editable: edit, renderEditCell: (params) => {
                return (
                    <FormControl fullWidth>
                        <Select
                            defaultValue={evcmId = params.row.evcm ? params.row.evcm.id : "N/A"}
                            onChange={(event) => {
                                //console.log("***event target***", Object.values(editRowsModel)[0])
                                Object.values(editRowsModel)[0].evcm.value = event.target.value
                            }}
                        >
                            {firmware.Moog.EVCM.map((x, i) => {
                                return <MenuItem key={i} value={x}>{x.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                )
            }
        }
    ]

    const handleCommit = () => {
        const objId = Object.keys(editRowsModel)[0];
        const obj = Object.values(editRowsModel)[0];
        //console.log("OBJ:",obj)
        let key = Object.keys(obj)[0]
        //console.log("KEY", key)
        let target = '';
        let updateId = false;
        let oldFirmwareFromArray = '';
        rows.map((x) => {
            if (x.id === objId) {
                target = x;
            }
        })
        //console.log("TARGET Cab", target.cab)
        switch (key) {
            case "cab":
                if (target.cab != null) {
                    if (target.cab.controllerType === 2 && target.cab.controllerType != null) {
                        updateId = target.cab.id
                    }
                    if (updateId) {
                        // update DeviceToFirmware where deviceId = objId and firmwareId = updateId
                        const oldFirmware = cabId
                        const newFirmware = obj.cab.value.id
                        return axios.put(`/api/FirmwareVersion/devicetofirmware/update/${objId}/${oldFirmware}/${newFirmware}`, {}, { headers: { 'Authorization': `Bearer ${token}` } })
                            .then((response) => response.status == 200 ? Toast.fire({ icon: 'success', title: 'firmware updated' }) : Toast.fire({ icon: 'error', title: 'update failed' }))
                            .then(() => dispatch({ type: 'UPDATE_DEVICELIST', id: objId, device: devices }))
                    }
                }
                else {
                    const newObj = {
                        DeviceId: objId,
                        FirmwareVersionId: obj.cab.value.id
                    }
                    // add to DeviceToFirmware
                    return axios.post('/api/FirmwareVersion/devicetofirmware/new', newObj, { headers: { 'Authorization': `Bearer ${token}` } })
                        .then((response) => response.status == 200 ? Toast.fire({ icon: 'success', title: 'firmware updated' }) : Toast.fire({ icon: 'error', title: 'update failed' }))
                        .then(() => dispatch({ type: 'UPDATE_DEVICELIST', id: objId, device: devices }))
                }                
            case "stm":
                //console.log("***stm***", obj.stm)
                if (target.stm != null && obj.stm !== undefined) {
                    if (target.stm.controllerType === 0) {
                        updateId = target.stm.id
                    }
                    if (updateId) {
                        // update DeviceToFirmware where deviceId = objId and firmwareId = updateId
                        const oldFirmware = updateId
                        const newFirmware = obj.stm.value.id
                        return axios.put(`/api/FirmwareVersion/devicetofirmware/update/${objId}/${oldFirmware}/${newFirmware}`, {}, { headers: { 'Authorization': `Bearer ${token}` } })
                            .then((response) => response.status == 200 ? Toast.fire({ icon: 'success', title: 'firmware updated' }) : Toast.fire({ icon: 'error', title: 'update failed' }))
                            .then(() => dispatch({ type: 'UPDATE_DEVICELIST', id: objId, device: devices }))
                    }
                }
                else {
                    // add to DeviceToFirmware
                    const newObj = {
                        DeviceId: objId,
                        FirmwareVersionId: obj.stm.value.id
                    }
                    return axios.post('/api/FirmwareVersion/devicetofirmware/new', newObj, { headers: { 'Authorization': `Bearer ${token}` } })
                        .then((response) => response.status == 200 ? Toast.fire({ icon: 'success', title: 'firmware updated' }) : Toast.fire({ icon: 'error', title: 'update failed' }))
                        .then(() => dispatch({ type: 'UPDATE_DEVICELIST', id: objId, device: devices }))
                }                
            case "esp":
                if (target.esp != null) {
                    if (target.stm.controllerType === 1) {
                        updateId = target.esp.id
                    }
                    if (updateId) {
                        // update DeviceToFirmware where deviceId = objId and firmwareId = updateId
                        const oldFirmware = espId
                        const newFirmware = obj.esp.value.id
                        return axios.put(`/api/FirmwareVersion/devicetofirmware/update/${objId}/${oldFirmware}/${newFirmware}`, {}, { headers: { 'Authorization': `Bearer ${token}` } })
                            .then((response) => response.status == 200 ? Toast.fire({ icon: 'success', title: 'firmware updated' }) : Toast.fire({ icon: 'error', title: 'update failed' }))
                            .then(() => dispatch({ type: 'UPDATE_DEVICELIST', id: objId, device: devices }))
                    }
                }
                else {
                    // add to DeviceToFirmware
                    const newObj = {
                        DeviceId: objId,
                        FirmwareVersionId: obj.esp.value.id
                    }
                    return axios.post('/api/FirmwareVersion/devicetofirmware/new', newObj, { headers: { 'Authorization': `Bearer ${token}` } })
                        .then((response) => response.status == 200 ? Toast.fire({ icon: 'success', title: 'firmware updated' }) : Toast.fire({ icon: 'error', title: 'update failed' }))
                        .then(() => dispatch({ type: 'UPDATE_DEVICELIST', id: objId, device: devices }))
                }
            case "hub":
                if (target.hub != null) {
                    if (target.hub.controllerType === 3) {
                        updateId = target.hub.id
                    }
                    if (updateId) {
                        // update DeviceToFirmware where deviceId = objId and firmwareId = updateId
                        const oldFirmware = hubId
                        const newFirmware = obj.hub.value.id
                        return axios.put(`/api/FirmwareVersion/devicetofirmware/update/${objId}/${oldFirmware}/${newFirmware}`, {}, { headers: { 'Authorization': `Bearer ${token}` } })
                            .then((response) => response.status == 200 ? Toast.fire({ icon: 'success', title: 'firmware updated' }) : Toast.fire({ icon: 'error', title: 'update failed' }))
                            .then(() => dispatch({ type: 'UPDATE_DEVICELIST', id: objId, device: devices }))
                    }
                }
                else {
                    // add to DeviceToFirmware
                    const newObj = {
                        DeviceId: objId,
                        FirmwareVersionId: obj.hub.value.id
                    }
                    return axios.post('/api/FirmwareVersion/devicetofirmware/new', newObj, { headers: { 'Authorization': `Bearer ${token}` } })
                        .then((response) => response.status == 200 ? Toast.fire({ icon: 'success', title: 'firmware updated' }) : Toast.fire({ icon: 'error', title: 'update failed' }))
                        .then(() => dispatch({ type: 'UPDATE_DEVICELIST', id: objId, device: devices }))
                }
            case "evcm":
                //console.log("target evcm", target.evcm)
                if (target.evcm != null) {
                    if (target.evcm.controllerType === 4) {
                        updateId = target.evcm.id
                    }
                    if (updateId) {
                        // update DeviceToFirmware where deviceId = objId and firmwareId = updateId
                        const oldFirmware = evcmId
                        const newFirmware = obj.evcm.value.id
                        return axios.put(`/api/FirmwareVersion/devicetofirmware/update/${objId}/${oldFirmware}/${newFirmware}`, {}, { headers: { 'Authorization': `Bearer ${token}` } })
                            .then((response) => response.status == 200 ? Toast.fire({ icon: 'success', title: 'firmware updated' }) : Toast.fire({ icon: 'error', title: 'update failed' }))
                            .then(() => dispatch({ type: 'UPDATE_DEVICELIST', id: objId, device: devices }))
                    }
                }
                else {
                    // add to DeviceToFirmware
                    // console.log("***** evcm new", obj.evcm.value.id)
                    const newObj = {
                        DeviceId: objId,
                        FirmwareVersionId: obj.evcm.value.id
                    }
                    return axios.post('/api/FirmwareVersion/devicetofirmware/new', newObj, { headers: { 'Authorization': `Bearer ${token}` } })
                        .then((response) => response.status == 200 ? Toast.fire({ icon: 'success', title: 'firmware updated' }) : Toast.fire({ icon: 'error', title: 'update failed' }))
                        .then(() => dispatch({ type: 'UPDATE_DEVICELIST', id: objId, device: devices }))
                }                
            case "name":
                // update device name               
                if (obj.name !== undefined)
                {
                    return axios.put(`/api/device/name/${objId}`, { Name: obj.name.value }, { headers: { 'Authorization': `Bearer ${token}` } })
                        .then((response) => response.status == 200 ? Toast.fire({ icon: 'success', title: 'name updated' }) : Toast.fire({ icon: 'error', title: 'update failed' }))
                }
            case "gnssRate":
                // update device gps reporting rate
                if (obj.gnssRate !== undefined)
                {
                    return axios.post(`/api/device/gnssrate/${objId}/${obj.gnssRate.value}`, {GnssRate: obj.gnssRate.value}, {headers: {'Authorization': `Bearer ${token}`}})
                    .then((response) => {response.status == 200 ? Toast.fire({icon: 'success', title: 'gnss rate updated'}) : Toast.fire({icon: 'error', title: 'update failed'})})
                    .then(() => { 
                        // since the data being grabbed doesn't seem to contain the gnss rate, going to pass it through the saga route.
                        let sendData = { type: 'gnssRate', value: obj.gnssRate.value } 
                        dispatch({type: 'UPDATE_DEVICELIST', id: objId, device: devices, obj: sendData});
                    })
                }
            case "canGroupId":
                //update can config
                if (obj.canGroupId !== undefined)
                {
                    return axios.put(`/api/device/can/${objId}`, { CanGroupId: obj.canGroupId.value }, { headers: { 'Authorization': `Bearer ${token}` } })
                        .then((response) => response.status == 200 ? Toast.fire({ icon: 'success', title: 'CAN updated' }) : Toast.fire({ icon: 'error', title: 'update failed' }))
                }
            default:
                break;
        }
    }

    const handleEditRowsModelChange = React.useCallback((model) => {
        //console.log("****model change", model)
        setEditRowsModel(model);
    }, []);

    const handleChange = (event, value) => {
        setPage(value)
    }

    const handleGnssOpen = (externalId) => {
        dispatch({
            type: 'GRAB_GNSS_RECORD',
            payload: externalId
        })
        setGnssOpen(true)
    };

    const gnssClose = () => {
        setGnssOpen(false)
    };

    return (
        <MyPage title="Devices">
            <Popup close={gnssClose} open={gnssOpen}>
                <GNSS />
            </Popup>
            <Container maxWidth={false}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant='h1'>
                        Devices
                    </Typography>
                    <IconButton onClick={() => setEdit(!edit)}>
                        <Edit />
                    </IconButton>
                    {edit ?
                        <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                            <Typography textAlign={"center"} color={"red"} variant='h4'>
                                Editing Mode
                            </Typography>
                            <Typography textAlign={"center"} variant='body2'>
                                double click to enter row, hit enter or click out to save changes.
                            </Typography>
                        </Box>
                        :
                        <></>
                    }
                    {/* <Button startIcon={<Plus/>} onClick={() => setBulk(!bulk)} variant="contained" color="primary">
                        Bulk Firmware Update
                    </Button> */}
                </Box>
                <Box mt={3} sx={{ height: 800, width: '120%', paddingRight: 3}}>
                    <DataGrid
                        editMode="cell"
                        columns={columns}
                        rows={rows}
                        loading={loading}
                        onCellEditCommit={handleCommit}
                        editRowsModel={editRowsModel}
                        onEditRowsModelChange={handleEditRowsModelChange}
                        components={{
                            LoadingOverlay: CustomLoadingOverlay
                        }}
                    />
                </Box>
                {/* <Popup open={bulk} close={() => setBulk(false)}>
                    <Bulk bulk={bulk} setBulk={setBulk} close={() => setBulk(false)}/>
                </Popup> */}
            </Container>
        </MyPage>
    );
};

export default ProductList;
