import React, { useState, useEffect } from 'react';
import {
    Box,
} from "@mui/material";
import axios from 'axios';
import { useSelector } from 'react-redux';
import { GrandCentralDispatch } from '../../../utils/GrandCentralDispatch';
import authService from '../../../components/api-authorization/AuthorizeService';
import { Tooltip, Typography } from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';
import ChartService from "../../../utils/DashboardChartService";
import moment from 'moment';
import Swal from 'sweetalert2';

const ServiceFaultStatus = (props) => {
    const loadingMessage = "loading . . .";
    const isLoading = useSelector((state) => state.FaultReducer.faultPage);
    const data = useSelector((state) => state.FaultReducer.faultData);
    const [deviceList, setDeviceList] = useState([]);
    const [faultList, setFaultList] = useState([]);
    const columns = [
        {field:"faultCode", headerName: "Fault Code", flex: 1},
        {field:"description", headerName: "Fault Description", flex: 1},
        {field:"reportDate", headerName: "Report Date", flex: 1},
        // {field:"description", headerName: "Description", flex: 1},
        {field:"machineHours", headerName: "Machine Hrs", flex: 1},
        {field:"operationHrs", headerName: "Operation Hrs", flex: 1}
    ];    
    let myTimeout = false;
    let allDevices = [];
    let limit = 0;
    let myStatusData = [];
    let faultData = [];
    let version = 0;
    let isActive = true;
    
    if (isLoading === true){
        Swal.fire({
            title: "Loading Faults...",
            imageUrl: '/static/images/loading-gif.gif',
            showConfirmButton: false,
            backdrop: "false",
        })
    };
    if (isLoading === false){
        Swal.close();
    };

    // console.log("?", faultList);
    // console.log("device list", deviceList);
    // console.log("dates:", faultDates);
    // let startTime = Math.floor(new Date(faultDates.start).getTime() / 1000);
    // let endTime = Math.floor(new Date(faultDates.end).getTime() / 1000);

    // console.log("test is: ", start1, end1);

    // const clearMyTimeout = () => {
    //     if(myTimeout) {
    //         clearTimeout(myTimeout);
    //         myTimeout = false;
    //     }
    // }

    // const updateFaults = (index) => {
    //     // console.log("the thing : ", startTime, endTime, index);
    //     // console.log("start and end in upload faults", start1, end1);
    //     let device = myStatusData[index];
    //     faultData = [];
    //     authService.getAccessToken()
    //     .then((token) => {
    //         // axios.get(`/api/Fault/faultcode/${device.externalId}/100`,
    //         axios.get(`/api/Fault/faultcode/${device.externalId}`,
    //         {
    //             headers: {
    //             Authorization: 'Bearer ' + token
    //             }
    //         })
    //         .then((retval) => {
    //             // console.log("in then for faultcode");
    //             let activeFaults = false;
    //             if(retval.code == 404) {
    //                 device.faults = "Clear";
    //             }
    //             else {
    //                 retval.data.forEach(fault => {
    //                     faultData.push(
    //                         {
    //                             id: fault.id,
    //                             faultCode: fault.faultCode,
    //                             faultName: fault.faultName, 
    //                             description: fault.description,
    //                             reportDate: fault.reportDate
    //                         }
    //                     );
                        
    //                 });
    //                 //device.faults = activeFaults ? "Faults" : "Clear";
    //             }
    //             setFaultList(JSON.parse(JSON.stringify(faultData)));
    //             //myTimeout = setTimeout(fillHoles, 15);
    //         });
    //     });
    // }

    // const fillHoles = () => {
    //     if(!isActive) { return; }
    //     //for(let i = 0; i < myStatusData.length; i++) {            
    //             // updateFaults(0);
    //       //  }                    
    // }

    // const update = () => {
    //     version++;
    //     authService.getAccessToken()
    //     .then((token) => {
    //         axios.put(`/api/Device/list/status/`, allDevices,
    //         {
    //             headers: {
    //             Authorization: 'Bearer ' + token
    //             }
    //         })
    //         .then((retval) => {
    //             myStatusData = [];
    //             let usedIds = {};
    //             retval.data.forEach(dto => {
    //                 let device = dto.device;
    //                 myStatusData.push(
    //                     {
    //                         id: device.id, 
    //                         commonName: device.name, 
    //                         externalId: device.externalId, 
    //                         status: loadingMessage, // getStatus(device.value), 
    //                         faults: loadingMessage, 
    //                         operationHours: loadingMessage, // device.operationHours,
    //                         idleHours: "TBD", // device.idleHours,
    //                         totalHours: "TBD", // device.totalHours,
    //                         reportDate: device.created
    //                         // faultCode: loadingMessage,
    //                         // faultName: loadingMessage, 
    //                         // description: loadingMessage,
    //                         // reportDate: device.created
    //                     }
    //                 );
    //             });
    //             setDeviceList(myStatusData);
    //             myTimeout = setTimeout(fillHoles, 500);
    //             // setTimeout(updateDevices, 10000);
    //          });
    //     })
    // }

    // const updateDevices = (payload) => {
    //     clearMyTimeout();
    //     allDevices = payload.devices;
    //     limit = payload.limit;
    //     myTimeout = setTimeout(update, 500);
    // }

    // useEffect(() => {
    //     GrandCentralDispatch.subscribe("SELECTED_SEARCH_DEVICES_UPDATED", updateDevices);
    //     return () => {
    //         //console.log("Cleanup Called for: FleetStatus");
    //         clearMyTimeout();
    //         GrandCentralDispatch.unsubscribe("SELECTED_SEARCH_DEVICES_UPDATED", updateDevices);
    //         isActive = false;
    //     }
    // }, []);

    return (
        <Box width={'100%'} height={'100%'}>
            <ChartService data={{type: 'chart', title: '', columns: columns, list: data}} />
        </Box>
    )
}

export default ServiceFaultStatus;
