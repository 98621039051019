import React from "react";
import {
    Button,
    Modal,
    Box,
    Autocomplete,
    TextField
} from "@mui/material";
import { Plus, XSquare } from "react-feather";
import NewGroup from "./NewGroup";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import axios from "axios";
import Toast from "../../../mixins/Toast";
import Popup from "../../../mixins/Popup";
import DeleteView from "./DeleteView";

const AdminGroups = (props) => {
    const users = useSelector((state) => state.setUser.usersInGroup);
    const groups = useSelector((state) => state.admin.adminGroups);
    const allUsers = useSelector((state) => state.admin.users);
    const roles = useSelector((state) => state.admin.roles);
    const dispatch = useDispatch();
    const token = useSelector((state) => state.setUser.token);
    const [open, setOpen] = React.useState(false);
    const [groupId, setGroupId] = React.useState('');
    const [openUser, setOpenUser] = React.useState(false);
    const [userToAdd, setUsersToAdd] = React.useState([]);
    const [userOptions, setUserOptions] = React.useState(allUsers);
    const [editRowsModel, setEditRowsModel] = React.useState({});
    const [deleteGroup, setDeleteGroup] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState(null);

    const saveUsers = () => {
      for (const x of userToAdd) {
            axios.post('/api/usergroup', {
                ApplicationUserId: x.id,
                GroupId: groupId,
                GroupRoleId: "8423fa91-8372-4ac6-9107-45e5b5c131f7"
            },{headers: !token ? {} : { 'Authorization': `Bearer ${token}` }}).then((response) => {
                dispatch({type: 'GET_USERS_FOR_GROUP', payload: groupId})
            })
            .then(() => Toast.fire({icon: 'success', title: 'User added'}))
            .then(() => setUsersToAdd([]))
            .catch((error) => {
                Toast.fire({
                    icon: 'error',
                    title: 'Problem adding user to group'
                })
                console.log(error)
            })
        }
    };

    const handleEditRowsModelChange = React.useCallback((model) => {
        setEditRowsModel(model)
    }, []);

    const handleSave = () => {
        const objId = Object.keys(editRowsModel)[0];
        const obj = Object.values(editRowsModel)[0];
        const name = obj.name.value;
        const item = {
            Name: obj.name.value
        };
        console.log(item)
        axios.put(`/api/group/name/${objId}/${name}`,{}, {headers: !token ? {} : { 'Authorization': `Bearer ${token}`}})
        .then(() => dispatch({type: "GRAB_ADMIN_GROUPS"}))
        .then(() => Toast.fire({ icon: 'success', title: 'Group name changed' }))
        .catch((error) => {
            console.log(error)
            Toast.fire({ icon: 'error', title: 'Problem with updating name' })
        })
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUserOpen = (params) => {
        dispatch({
            type: 'GET_USERS_FOR_GROUP',
            payload: params
        })
        setGroupId(params)
        setOpenUser(true)
    };
    const handleUserRemove = (params) => {
        axios.delete(`/api/usergroup/remove/${params}/${groupId}`,{headers: !token ? {} : { 'Authorization': `Bearer ${token}` }})
        .then((reponse) => console.log(reponse))
        .then(() => dispatch({type: 'GET_USERS_FOR_GROUP', payload: groupId}))
        .catch((error) => console.log(error))
    };

    const userClose = () => {
        setOpenUser(false)
        setUserOptions(allUsers)
    };

    const userColumns = [
        { field: 'userName', headerName: 'Username', flex: 1, minWidth: 150 },
        { field: 'email', headerName: 'Email', flex: 1, minWidth: 150 },
        { field: 'phoneNumber', headerName: 'Phone Number', flex: 1, minWidth: 150 },
        { field: 'role', headerName: 'Role', flex: 1, minWidth: 150, renderCell: (params) => (
            <Autocomplete
                sx={{ width: '100%' }}
                id="user-roles"
                options={roles}
                defaultValue={params.row.role}
                isOptionEqualToValue={(option, value) => option.id === value.id }
                onChange={(event, newValue) => {
                if ( newValue ){
                    axios.put(`/api/usergroup`, {
                    ApplicationUserId: params.row.id,
                    GroupId: groupId,
                    GroupRoleId: newValue.id
                    },
                    {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }})
                    .then(() => Toast.fire({
                    icon: 'success',
                    title: 'User Role Updated'
                    }))
                    .catch((error) => {
                    console.log(error)
                    Toast.fire({
                        icon: 'error',
                        title: 'Error updating user role'
                    })
                    })
                }
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(x) => <TextField {...x} label="User Roles" variant="standard" />}
            />
        )},
        { field: 'id', headerName: 'Remove', flex: 1, minWidth: 150, renderCell: (params) => (
            <Button
                variant="contained"
                color="error"
                onClick={() => handleUserRemove(params.row.id)}
            >
                Remove
            </Button>
        )}
    ];

    const columns = [
        { field: 'name', headerName: 'Group Name', flex: 1, minWidth: 150, editable: true },
        { field: 'parentGroupId', headerName: 'Parent Group', flex: 1, minWidth: 150 },
        { field: 'isDefault', headerName: 'Is Default', type: 'boolean', flex: 1, minWidth: 150 },
        { field: 'created', headerName: 'Created', flex: 1, minWidth: 150, renderCell: (params) => (moment.utc(params.row.created).local().format('MM/DD/YY, h:mm a')) },
        { field: 'users', headerName: 'View Users', flex: 1, minWidth: 150, renderCell: (params) => (
            <Button
                variant="contained"
                color="primary"
                onClick={() => handleUserOpen(params.id)}
            >
                Users
            </Button>
        )},
        { field: 'remove', headerName: 'Remove', flex: 1, minWidth: 150, renderCell: (params) => (
            <Button
                variant="contained"
                color="error"
                onClick={() => {
                    dispatch({ type: 'PRE_DELETE_GROUP', payload: params.row.id });
                    setDeleteGroup(true);
                    setDeleteId(params.row.id);
                } }
            >
                Remove
            </Button>
        )}
    ];

    const data = [];
    groups.map((x, i) => {
        const parentName = groups.find((group) => group.id === x.parentGroupId);
        return data.push({id: x.id, name: x.name, parentGroupId: parentName ? parentName.name : '', isDefault: x.isDefault, created: x.created, updated: x.updated, users: x.id});
    });

    let userData = [];
    users.map((x, i) => {
        return userData.push({id: x.id, userName: x.userName, email: x.email, phoneNumber: x.phoneNumber, role: x.role});
    });

    return (
        <Box>
            <div>
                <Button
                    sx={{marginBottom: '15px'}}
                    color="primary"
                    variant="contained"
                    onClick={handleOpen}
                >
                    <Plus /> Group
                </Button>
                <Modal
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    open={open} 
                    onClose={handleClose}
                >
                    <NewGroup close={setOpen} data={props.data} />
                </Modal>
                <Popup close={userClose} open={openUser}>
                    <Box
                        sx={{ display: 'flex', alignItems: 'center'}}
                    >
                        <Autocomplete
                            multiple
                            id="user-box"
                            disableCloseOnSelect
                            limitTags={1}
                            isOptionEqualToValue={(option, value) => option.id === value.id }
                            options={allUsers.filter(({ id: id1 }) => !users.some(({ id: id2 }) => id2 === id1))}
                            onChange={(event, newValue) => {
                            setUsersToAdd(newValue)
                            }}
                            getOptionLabel={(option) => option.userName}
                            sx={{width: 500, margin: '15px'}}
                            renderInput={(params) => <TextField {...params} label="Add Users" variant="outlined" />}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ height: '56px'}}
                            onClick={saveUsers}
                        >
                            <Plus/> Add
                        </Button>
                    </Box>
                    <Box 
                        sx={{ margin: '15px', overflow: 'scroll', height: '100%' }}
                    >
                        <DataGrid
                            columns={userColumns}
                            rows={userData}
                        />
                    </Box>
                </Popup>
                <Popup open={deleteGroup} close={() => setDeleteGroup(false)}>
                    <DeleteView groupId={deleteId}/>
                </Popup>
            </div>
            <div style={{ width: '100%', height: 800 }}>
                <DataGrid
                    components={{
                        Toolbar: GridToolbar
                    }}
                    editMode="row"
                    onRowEditCommit={handleSave}
                    editRowsModel={editRowsModel}
                    onEditRowsModelChange={handleEditRowsModelChange}
                    columns={columns}
                    rows={data}
                />
            </div>
        </Box>
    )
}

export default AdminGroups;