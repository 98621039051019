import { combineReducers } from "redux";

const CanSPN = (state = [], action) => {
    switch (action.type) {
        case "SET_CAN_SPN":
            // console.log("info in reducer:", action.payload);
            return action.payload;
        default:
            return state;
    }
}

const CanPGN = (state = [], action) => {
    switch (action.type) {
        case "SET_PGN":
            //console.log("info in reducer:", action.payload);
            return action.payload[0];
        default:
            return state;
    }
}

const CanGroup = (state = [], action) => {
    switch (action.type) {
        case "SET_CAN_GROUP":
            // console.log('1 action', action.payload);
            if(action.payload.length === 0){
                // console.log('2 in if');
                return action.payload;
            } else {

                // console.log('3 state and payload', state, action.payload);
                if (state.length > 0){
                    let canBool = true; // checks if there are multiple of the same can group
                    for (let x of state){
                        if (action.payload[0].name === x.name){
                            // console.log('4 action pay name & x name ____', action.payload[0].name, "____", x.name);
                            canBool = false;
                        }
                    }

                    if(canBool){
                        state = [...state, ...action.payload]
                        // console.log("5 can group reducer:", state);
                    }
                    
                } else {
                    // console.log('6 can group else', state, action.payload);
                    state = action.payload;
                }
            }
            return state;
        default:
            return state;
    }
}

const CanGroupPGNS = (state = [], action) => {
    switch (action.type) {
        case "SET_CAN_GROUP_PGN":
            // console.log("can group PGNs reducer:", action.payload, state);
            return action.payload;
        default:
            return state;
    }
}

export default combineReducers({
    CanSPN,
    CanPGN,
    CanGroup,
    CanGroupPGNS
});