import React from 'react';
import {
    Box,
    Container
} from '@mui/material';
import Page from '../../components/Page';
import Results from './Groups';
import Toolbar from './Toolbar';
import { styled } from '@mui/system';
import { useSelector } from 'react-redux';

const CustomPage = styled(Page)(({theme}) => ({
    minHeight: '100%',
    marginTop: '15px'
}))

const MemberListView = () => {
    const groups = useSelector((state) => state.setUser.allGroups);
    const userRole = useSelector((state) => state.setUser.currentRole);

    return (
        <CustomPage
           title="Group"
        >
            <Container maxWidth={false}>
                { userRole.name !== 'Read' ?
                <Toolbar />
                : ""
                }
                <Box mt={3}>
                <Results customers={groups} />
                </Box>
            </Container>
        </CustomPage>
    );
};

export default MemberListView;
