import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  MenuItem,
  Checkbox,
  TextField,
  Autocomplete,
  Chip,  
} from '@mui/material';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    CheckBoxOutlineBlank,
    CheckBox as CheckBoxIcon
} from '@mui/icons-material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

const Toolbar = (props) => {
    const groups = useSelector((state) => state.setUser.allGroups);
    const devices = useSelector((state) => state.DashboardReducer.creationDevices);
    const deviceLoading = useSelector((state) => state.DashboardReducer.deviceListLoading);
    const fleetService = useSelector((state) => state.deviceList.Fleet_Service); //
    const selectedGroup = useSelector((state) => state.deviceList.SelectedGroup); //
    const [limit, setLimit] = React.useState(100);
    // const [deviceList, setDeviceList] = React.useState(devices.length !== 0 && fleetService ? () => incomingDevice() : []);
    const [deviceList, setDeviceList] = React.useState([]);
    const [submitDevices, setSubmitDevices] = React.useState([]);
    const [group, setGroup] = React.useState(selectedGroup ? selectedGroup : '');
    const [all, setAll] = React.useState(false);
    const dispatch = useDispatch();    
    const [startValue, setStartValue] = React.useState(moment().subtract(7, "days").startOf('day').format()); 
    const [endValue, setEndValue] = React.useState(moment().format());
    const [selectDevice, setSelectDevice] = React.useState({});

    if (devices && fleetService && deviceList.length === 0) {
        for(let x of devices){ 
            if (x.serialNumber === fleetService.commonName){
                setDeviceList([x.serialNumber]);
                setSelectDevice({ externalId: x.externalId, serialNumber: x.name, id: x.id })
            }
        }
    } 

    useEffect(() => {
        dispatch({ type: 'SET_MACHINE_STATUS', payload: [], path: 'serviceToolbar_useEffect_Clear'});
        dispatch({ type: 'SET_FAULT_DATA', payload: [], path: 'serviceToolbar_useEffect_Clear'}) 
    }, [])

    const icon = <CheckBoxOutlineBlank fontSize='small' />
    const checkedIcon = <CheckBoxIcon fontSize='small' />
    // console.log("start in toolbar", startValue);
    // console.log("end in toolbar", endValue);
    // console.log("devices :", devices);
    // console.log("device list :", deviceList);
    // console.log("select device :", selectDevice);

    const handleSubmit = () => {    
        // console.log("select device", 'B'+selectDevice.serialNumber);   
        dispatch({ type: 'SET_MACHINE_STATUS', payload: [], path: 'serviceToolbar_Submit'});
        dispatch({ type: 'SET_FAULT_DATA', payload: [], path: 'serviceToolbar_Submit'}) 

        window.google = undefined;
        dispatch({type: 'DATA_TABLE', payload: [selectDevice.id], serialNumber: selectDevice.serialNumber, limit: 100, startDate: startValue, endDate: endValue, parent: "", fetchRaw: props.hideLimit !== true, path: 'serviceToolbar_Submit'}); // datagrid info
        dispatch({type: 'SERVICE_INFO', payload: [selectDevice.id], startDate: startValue, endDate: endValue, externalId: [selectDevice.externalId], parent: "", fetchRaw: props.hideLimit !== true, path: 'serviceToolbar_Submit'});
        dispatch({ type: 'FAULT_SERVICE_VIEW', payload: selectDevice.id, startValue, endValue});
        dispatch({type: 'SEARCH_CLICKED', payload: true, path: 'serviceToolbar_Submit'});
    }
    // console.log("devices: ", devices, "device list: ", deviceList);
  return (
    <Card sx={{ display: 'flex', justifyContent: 'space-evenly' }} >
        <Box sx={{ padding: '10px', flex: 1 }} >
            <TextField
                label="Group"
                select
                focused
                variant="outlined"
                value={group}
                sx={{ width: '100%', color: 'black' }}
                onChange={(event) => {
                    dispatch({ type: 'FLEET_SERVICE', payload: {}});
                    dispatch({type: 'SET_DASH_DEVICES', payload: []});
                    dispatch({type: "GET_DASH_DEVICES", payload: { id: event.target.value}});
                    setDeviceList([]);
                    setAll(false);
                    setGroup(event.target.value);
                }}
            >
                {groups.map((x, i) => {
                    return <MenuItem key={i} value={x.id}>{x.name}</MenuItem>
                })}
            </TextField>
        </Box>
        <Box sx={{ padding: '10px', flex: 1 }}>
            <Autocomplete 
                sx={{ width: '100%' }}
                options={deviceLoading ? [] : [...devices]}
                multiple
                disableClearable={false}
                // disableCloseOnSelect
                value={deviceList}
                isOptionEqualToValue={(option, value) => option.serialNumber === value}
                renderTags={(value, getTagProps) => {
                    const numTags = value.length;
                    const limitTags = 1;
                    return (
                      <>
                        {value.slice(0, limitTags).map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={index}
                            label={option}
                          />
                        ))}
                        {numTags > limitTags && ` +${numTags - limitTags}`}
                      </>
                    );
                }}
                loading={deviceLoading}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                    let values = []
                    newValue.forEach((x) => {
                        setSelectDevice({ externalId: x.externalId, serialNumber: x.name, id: x.id})
                        x.name ? values.push(x.name) : values.push(x)
                    }) // new
                    if (values.length >= 1)
                    {
                        if (values.length > 1)
                        {
                            if (values[0] !== values[1])
                            {
                                values.splice(0, 1);
                            }
                        }
                        setDeviceList(values)
                    }
                }}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        {/* <Checkbox 
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={deviceList.indexOf(option.name) > -1}
                        /> */}
                        {option.name}
                    </li>
                )}
                renderInput={(params) => <TextField {...params} focused label="Machine" variant='outlined'/>}
            />
        </Box>
        {/* {!props.hideLimit && 
        <Box
            sx={{ padding: '10px', flex: 1 }}
        >
            <TextField
                label="Limit"
                select
                focused
                variant="outlined"
                value={limit}
                sx={{ width: '100%', color: 'black' }}
                onChange={(event) => setLimit(event.target.value)}
            >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={1000}>1,000</MenuItem>
                <MenuItem value={10000}>10,000</MenuItem>
            </TextField>
        </Box>
        } */}
        <Box sx={{ padding: '10px', flex: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Start Date"
                    value={startValue}
                    onChange={(newValue) => {
                        setStartValue(newValue);
                        // dispatch({ type: 'SET_START', payload: newValue})
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        </Box>
        <Box sx={{ padding: '10px', flex: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="End Date"
                    value={endValue}
                    onChange={(newValue) => {
                        setEndValue(newValue);
                        // dispatch({ type: 'SET_END', payload: newValue})
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        </Box>
        <Box sx={{ padding: '10px' }}>
            <Button
                variant='contained'
                sx={{ height: '56px' }}
                onClick={handleSubmit}>
                Search
            </Button>
        </Box>
    </Card>
  );
};

export default Toolbar;
