import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import authService from '../../components/api-authorization/AuthorizeService';
import Toast from '../../mixins/Toast';

var token = authService.getAccessToken();
// This is for a group admin. They get all the information for every device in their family of groups.
function* getGroupAdminData(action){
    yield put({
        type: "ADMIN_LOADING",
        payload: true
    });
    
    try {
        let allGroups = [];
        let allDevices = [];
        let allUsers = [];

        const roles = yield axios.get('/api/GroupRole', { headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
        // Change this in future for when we have system hooked up for can groups per group and have the hardware versioning done.
        const firmwares = yield axios.get('/api/FirmwareVersion', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
        const allFirmwares = {Signum:{STM: [], ESP:[]}, Bobcat:{CAB:[], HUB:[]}, Moog:{EVCM:[]}};
        firmwares.data.forEach((x) => {
            switch (x.controllerCompany) {
                case 0:
                    if (x.controllerType === 0) return allFirmwares.Signum.STM.push(x);
                    else return allFirmwares.Signum.ESP.push(x);
                case 1:
                    if (x.controllerType === 2) return allFirmwares.Bobcat.CAB.push(x);
                    else return allFirmwares.Bobcat.HUB.push(x);
                case 2:
                    if (x.controllerType === 4) return allFirmwares.Moog.EVCM.push(x);
                default:
                    break;
            }
        })

        yield put({ type: "SET_FIRMWARE", payload: allFirmwares });
        yield put({ type: "SET_ALL_FIRMWARE", payload: firmwares.data });

        yield put({
            type: "ADMIN_SET_ROLE",
            payload: roles.data
        });

        const hardware = yield axios.get('/api/HardwareFamily', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

        yield hardware.data.forEach(element => {
            let data = []
            axios({
                method: 'get',
                url: `/api/HardwareVersion/${element.id}`,
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(response => {
                if(response.data){
                    data = response.data
                }
                element.version = data
            })
        });

        yield put({
            type: "SET_HARDWARE",
            payload: hardware.data
        });

        const hardwareVersions = yield axios.get('/api/HardwareVersion', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

        yield put({
            type: "ADMIN_HARDWARE_VERSIONS",
            payload: hardwareVersions.data
        });

        let canElements = yield axios.get('/api/CanElement', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

        yield put ({
          type: "ADMIN_CAN_ELEMENTS",
          payload: canElements.data
        });

        let canGroups = yield axios.get('/api/CanGroups', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

        yield put({
          type: "ADMIN_CAN_GROUPS",
          payload: canGroups.data
        });
        // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
        const userGroups = yield axios.get(`/api/usergroup/admin/${action.payload.id}/${action.role}`, { headers: !token ? {} : { 'Authorization' : `Bearer ${token}` }});

        for ( const x of userGroups.data ) {
            const group = yield axios.get(`/api/group/${x.groupId}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
            allGroups.push(group.data);
            const subGroup = yield axios.get(`/api/group/sub/${x.groupId}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
            if ( subGroup.data.length > 0 ) {
                for ( const i of subGroup.data ) {
                    allGroups.push(i)
                } 
            }
        };

        yield put({
            type:"ADMIN_SET_GROUP",
            payload: allGroups
        });

        for ( const x of allGroups ) {
            const devices = yield axios.get(`/api/groupdevices/${x.id}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
            for ( const i of devices.data ) {
                let device = yield axios.get(`/api/device/${i.deviceId}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
                if ( device.data !== "" ){
                    let group = yield axios.get(`/api/group/${i.groupId}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}`  }});
                    device.data.group = group.data;
                    allDevices.push(device.data);
                }
                else{
                    continue;
                }
            }
            const users = yield axios.get(`/api/usergroup/group/${x.id}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
            for ( const u of users.data ) {
                const user = yield axios.get(`/api/user/${u.applicationUserId}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
                allUsers.push(user.data);
            }
        };

        yield put({
            type: "ADMIN_DEVICES",
            payload: allDevices
        });

        yield put({
            type: "ADMIN_USERS",
            payload: allUsers
        });

        yield put({
            type: "ADMIN_LOADING",
            payload: false
        });
        
    } catch (error) {
        console.log(`Error getting group admin data: ${error}`);
    }
}

function* preGroupDelete(action) {
    const allDevices = [];
    const allUsers = [];
    yield put({ type: "DELETE_GROUP_LOADING", payload: true });
    const users = yield axios.get(`/api/usergroup/group/${action.payload}`, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}});
    for (const x of users.data) {
        const user = yield axios.get(`/api/User/${x.applicationUserId}`, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}});
        allUsers.push(user.data)
    }
    const devices = yield axios.get(`/api/groupdevices/${action.payload}`, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}});
    for (const x of devices.data) {
        const device = yield axios.get(`/api/device/${x.deviceId}`, {headers: !token ? {} : {'Authorization': `Bearer ${token}`}});
        const firmwares = yield axios.get(`/api/FirmwareVersion/devicetofirmware/${x.deviceId}`);
        device.data.stm = {};
        device.data.esp = {};
        device.data.cab = {};
        device.data.hub = {};
        device.data.evcm = {};
        for (const x of firmwares.data) {
            if (x.firmwareVersion.controllerType === 0) device.data.stm = x.firmwareVersion;
            if (x.firmwareVersion.controllerType === 1) device.data.esp = x.firmwareVersion;
            if (x.firmwareVersion.controllerType === 2) device.data.cab = x.firmwareVersion;
            if (x.firmwareVersion.controllerType === 3) device.data.hub = x.firmwareVersion;
            if (x.firmwareVersion.controllerType === 4) device.data.evcm = x.firmwareVersion;
        }
        allDevices.push(device.data)
    }
    yield put({ type: 'DELETE_GROUP_USERS', payload: allUsers });
    yield put({ type: 'DELETE_GROUP_DEVICES', payload: allDevices });
    yield put({ type: "DELETE_GROUP_LOADING", payload: false });
}

function* deleteUserGroup(action) {
    const removeUserGroup = yield axios.delete(`/api/group/${action.payload}`, {header: !token ? {} : { 'Authorization': `Bearer ${token}`}});
    removeUserGroup.status === 200 ? Toast.fire({ title: 'Group Deleted', icon: 'success' }) : Toast.fire({ title: 'Delete Failed', icon: 'error' });
}

function* adminDeleteUser(action) {
    const deleteUser = yield axios.delete(`/api/User/${action.payload}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}`}});
    Toast.fire({ title: 'User deleted', icon: 'success' });
}

function* GroupAdminSaga() {
    yield takeLatest("PRE_DELETE_GROUP", preGroupDelete);
    yield takeLatest("DELETE_USER_GROUP", deleteUserGroup);
    yield takeLatest("GRAB_GROUP_ADMIN", getGroupAdminData);
    yield takeLatest("ADMIN_DELETE_USER", adminDeleteUser);
}

export default GroupAdminSaga;