import { createTheme } from '@mui/material/styles';
import { colors } from '@mui/material';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
    overrides: {
        MuiListItem: {
            "&$selected": {
                color: colors.blue[500],
                backgroundColor: colors.lightBlue[500]
            }
        }
    },
    palette: {
        background: {
            dark: '#F4F6F8',
            default: colors.common.white,
            paper: colors.common.white
        },
        primary: {
           main: '#0e5e8a'
        },
        secondary: {
            main: '#fff'
        },
        text: {
            primary: colors.blueGrey[900],
            secondary: colors.blueGrey[600]
        }
    },
    shadows,
    typography
});

export default theme;
