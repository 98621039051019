import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import authService from '../../components/api-authorization/AuthorizeService';
// Upload for DBC Files
function* UploadData(action){
    try {
        yield put({
            type: "SET_FILE_LOADING",
            payload: true
        })
        let token = yield authService.getAccessToken();
        const File = yield axios.post('/api/CanFile', action.file, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
        for ( const x of action.payload ) {
            let obj = {
                CanIdent: parseInt(x.canId),
                Name: x.name,
                ByteLength: parseInt(x.byteLength),
                Sender: x.sender,
                CanFileId: File.data
            }
            const PGN = yield axios.post('/api/CanPGN', obj, {headers: !token ? {} : { 'Authorization': `Bearer ${token}`}})
            yield put({
                type: "FILE_UP"
            })
            for ( const i of x.SPNs ) {
                let SPN = {
                    CanPGNId: PGN.data,
                    Name: i.name,
                    BitStart: parseInt(i.bitStart),
                    BitLength: parseInt(i.bitLength),
                    EndiannessType: parseInt(i.endianness),
                    Scale: parseFloat(i.scale),
                    Offset: parseFloat(i.offset),
                    Min: parseFloat(i.min),
                    Max: parseFloat(i.max),
                    Unit: i.unit,
                    Receiver: i.receiver,
                    isSigned: i.signed

                }
                if ( SPN.Name ) {
                    const newSPN = yield axios.post('/api/CanSPN', SPN, {headers: !token ? {} : { 'Authorization': `Bearer ${token}`}});
                }
                else {
                    continue;
                }
                yield put({
                    type: "FILE_UP"
                })
            }
        }
        yield put({
            type: "SET_FILE_LOADING",
            payload: false
        })
        yield put({
            type: "FILE_RESET"
        })
    } catch (error) {
        console.log(`Error with file upload: ${error}`)
    }
};
// Uploads DBC file and Creates new CAN Group in the system
function* UploadAndCreate(action){
    try {
        yield put({
            type: "SET_FILE_LOADING",
            payload: true
        })
        let token = yield authService.getAccessToken();
        const File = yield axios.post('/api/CanFile', action.file, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
        const Group = yield axios.post('/api/CanGroups', {Name: action.payload.name, GroupId: action.file.GroupId}, {headers: !token ? {} : { 'Authorization': `Bearer ${token}`}});
        for ( const x of action.payload.data ) {
            let obj = {
                CanIdent: parseInt(x.canId),
                Name: x.name,
                ByteLength: parseInt(x.byteLength),
                Sender: x.sender,
                CanFileId: File.data
            };
            const PGN = yield axios.post('/api/CanPGN', obj, {headers: !token ? {} : { 'Authorization': `Bearer ${token}`}});
            let addPGN = {
                Name: x.name,
                CanGroupId: Group.data,
                CanPGNId: PGN.data,
                CanTriggerType: x.triggerType ? parseInt(x.triggerType) : 1,
                CollectionFrequency: parseInt(x.collectionFrequency),
                ReportFrequency: parseInt(x.reportFrequency),
                CanFileId: File.data
            }
            if (x.collectionFrequency && x.reportFrequency) {
                const addToGroup = yield axios.post('/api/CanGroupPGN', addPGN, {headers: !token ? {} : { 'Authorization': `Bearer ${token}`}});
            }
            yield put({
                type: "FILE_UP"
            })
            for ( const i of x.SPNs ) {
                let SPN = {
                    CanPGNId: PGN.data,
                    Name: i.name,
                    BitStart: parseInt(i.bitStart),
                    BitLength: parseInt(i.bitLength),
                    EndiannessType: parseInt(i.endianness),
                    Scale: parseFloat(i.scale),
                    Offset: parseFloat(i.offset),
                    Min: parseFloat(i.min),
                    Max: parseFloat(i.max),
                    Unit: i.unit,
                    Receiver: i.receiver,
                    isSigned: i.signed
                }
                if ( SPN.Name ) {
                    const newSPN = yield axios.post('/api/CanSPN', SPN, {headers: !token ? {} : { 'Authorization': `Bearer ${token}`}});
                }
                else {
                    continue;
                }
                yield put({
                    type: "FILE_UP"
                })
            }
        }
        yield put({
            type: "SET_FILE_LOADING",
            payload: false
        })
        yield put({
            type: "FILE_RESET"
        })
    } catch (error) {
        console.log(`Error with file upload: ${error}`)
    }
};

function* CreateCanFile(action){
    console.log("in create can file", action);
    let canFile = {Name: action.payload}
    
    try {
        const response = yield axios.post(`/api/CanFile/`, canFile) 
        console.log("response in create can file:", response);
        yield put({ type: "GET_CAN_FILES", payload: action.payload, token: action.token, groupIds: action.groupIds})

    } catch (e) {
        console.log("error in creating Can File", e);
    }
    
}

function* DeleteCanFile(action){
    console.log("in Delete can file", action);
    
    try {
        const response = yield axios.delete(`/api/CanFile/${action.payload.id}`) 
        console.log("response in create can file:", response);
        yield put({ type: "GET_CAN_FILES", payload: action.payload, token: action.token, groupIds: action.groupIds})

    } catch (e) {
        console.log("error in creating Can File", e);
    }
    
}
function* Upload(){
    yield takeLatest("UPLOAD_FILE", UploadData);
    yield takeLatest("UPLOAD_CREATE", UploadAndCreate);
    yield takeLatest("NEW_CAN_FILE", CreateCanFile)
    yield takeLatest("DELETE_CAN_FILE", DeleteCanFile)
};

export default Upload;