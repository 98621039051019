import React from 'react';
import {
  Box,
  Button,
  Card,
  MenuItem,
  Checkbox,
  TextField,
  Autocomplete,
  Chip,
} from '@mui/material';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    CheckBoxOutlineBlank,
    CheckBox as CheckBoxIcon
} from '@mui/icons-material'
import Toast from '../../../mixins/Toast';
import Popup from "../../../mixins/Popup";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

const Toolbar = (props) => {
    const groups = useSelector((state) => state.setUser.allGroups);
    const devices = useSelector((state) => state.DashboardReducer.creationDevices);
    const deviceLoading = useSelector((state) => state.DashboardReducer.deviceListLoading);
    const [limit, setLimit] = React.useState(100);
    const [deviceList, setDeviceList] = React.useState([]);
    const [submitDevices, setSubmitDevices] = React.useState([]);
    const [group, setGroup] = React.useState('');
    const [all, setAll] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const [startDate, setStartDate] = React.useState(moment().subtract(7, "days").startOf('day')); 
    const [endDate, setEndDate] = React.useState(moment()); 
    const [reportSelect, setReportSelect] = React.useState(''); //only used for reports page
    const [displayPage, setDisplayPage] = React.useState(""); 
    // console.log('start and end', startDate, endDate);
    const parent = props.parent;

    const dispatch = useDispatch();
    // console.log('devices: ', devices);
    // console.log('device list: ', deviceList);
    // console.log('parent and group', parent, group);
    if (parent === 'fleet' && group === ''){
        // console.log('should clear');
        dispatch({ type: 'SET_FLEET_DATA', payload: [], origin: 'fleetStatus'});
    }
    
    const icon = <CheckBoxOutlineBlank fontSize='small' />
    const checkedIcon = <CheckBoxIcon fontSize='small' />

    const onSubmit = () => {
        let submitDevice = []
        for(let x of devices){
            for(let y of deviceList){
                if(y === x.name){
                    submitDevice.push(x.externalId);
                }
            }
        }
        // console.log('submit devices: ', submitDevices);
        if (reportSelect === "Core Report"){
            // console.log('in core report submit', reportSelect);
            if(deviceList.length <1) {
                Toast.fire({icon: 'error', title: 'No Devices Selected'});
                return;
            }
            if(startDate > endDate) {
                Toast.fire({icon: 'error', title: 'Invalid Date Range'});
                return;
            }
            setDisplayPage("Core")
            dispatch({type: 'CORE_REPORT', payload: {'deviceList': submitDevice, 'startDate': startDate, 'endDate': endDate}});
            dispatch({type: 'CORE_LOADING', payload: true})
        } 

        if (parent === 'fleet'){
            dispatch({ type: 'SET_SELECT_GROUP', payload: group});
            dispatch({ type: 'FLEET_DATAGRID', payload: submitDevices, Imei: submitDevice})
        }
        if (reportSelect !== "Core Report"){
            dispatch({type: 'DATA_TABLE', payload: submitDevices, limit: limit, fetchRaw: props.hideLimit != true});
        }

    }

  return (
    <Card sx={{ display: 'flex', justifyContent: 'space-evenly' }} >
        <Box sx={{ padding: '10px', flex: 1 }} >
            <TextField
                label="Group"
                select
                focused
                variant="outlined"
                value={group? group : ""}
                sx={{ width: '100%', color: 'black' }}
                onChange={(event) => {
                    dispatch({type: 'SET_DASH_DEVICES', payload: []});
                    dispatch({type: "GET_DASH_DEVICES", payload: { id: event.target.value}});
                    setDeviceList([]);
                    setAll(false);
                    setGroup(event.target.value);
                }}
            >
                {groups.length? groups.map((x, i) => {
                    return <MenuItem key={i} value={x.id}>{x.name}</MenuItem>
                }): <p>Loading</p>}
            </TextField>
        </Box>
        <Box
            sx={{ padding: '10px', flex: 1 }}
        >
            <Autocomplete 
                sx={{ width: '100%' }}
                options={deviceLoading ? [] : [{name: all ? 'DESELECT' : 'ALL'}, ...devices]}
                multiple
                disableClearable={true}
                disableCloseOnSelect
                value={deviceList}
                isOptionEqualToValue={(option, value) => option.name === value}
                renderTags={(value, getTagProps) => {
                    const numTags = value.length;
                    const limitTags = 1;
                    return (
                      <>
                        {value.slice(0, limitTags).map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={index}
                            label={option}
                          />
                        ))}
                        {numTags > limitTags && ` +${numTags - limitTags}`}
                      </>
                    );
                  }}
                loading={deviceLoading}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                    let values = []
                    let submitValues = []
                    newValue.forEach((x) => x.name ? values.push(x.name) : values.push(x))
                    newValue.forEach((x) => x.id ? submitValues.push(x.id) : "")
                    if (values.includes('ALL') && !values.includes('DESELECT')) {
                        let newList = []
                        let submitList = []
                        setAll(true)
                        devices.map((x) => {
                            newList.push(x.name)
                            submitList.push(x.id);
                        });
                        setDeviceList(newList)
                        setSubmitDevices(submitList)
                    }
                    else if (values.includes('DESELECT')) {
                        setAll(false)
                        setDeviceList([])
                        setSubmitDevices([])
                    }
                    else {
                        setDeviceList(values)
                        setSubmitDevices( [...submitDevices, ...submitValues])
                    }
                }}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox 
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={deviceList.indexOf(option.name) > -1}
                        />
                        {option.name}
                    </li>
                )}
                renderInput={(params) => <TextField {...params} focused label="Machines" variant='outlined'/>}
            />
        </Box>
        {props.parent === "report" && 
        <Box sx={{ padding: '10px', flex: 1 }}>
            <TextField
                label="Report Type"
                select
                focused
                variant="outlined"
                value={reportSelect}
                sx={{ width: '100%', color: 'black' }}
                onChange={(event) => {
                    setReportSelect(event.target.value);
                }}
            >
                <MenuItem value='Core Report'>Core Report</MenuItem>
            </TextField>
        </Box> }
        <br></br>
        {props.parent === "report" && reportSelect === "Core Report" &&
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ m: 1}} >
            <DatePicker
                className='DatePicker'
                label="Start Date"
                value={startDate}
                onChange={(newValue) => {
                    setStartDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
            />
        </Box>
        <Box sx={{ m: 1}} >
            <DatePicker
                className='DatePicker'
                label="End Date"
                value={endDate}
                onChange={(newValue) => {
                    setEndDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
            />
        </Box>
    </LocalizationProvider>
        }
        {!props.hideLimit && 
        <Box
            sx={{ padding: '10px', flex: 1 }}
        >
            <TextField
                label="Limit"
                select
                focused
                variant="outlined"
                value={limit}
                sx={{ width: '100%', color: 'black' }}
                onChange={(event) => setLimit(event.target.value)}
            >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={1000}>1,000</MenuItem>
                <MenuItem value={10000}>10,000</MenuItem>
                <MenuItem value={50000}>50,000</MenuItem>
                <MenuItem value={100000}>100,000</MenuItem>
            </TextField>
        </Box>
}
        <Box sx={{ padding: '10px' }} >
            <Button
                variant='contained'
                sx={{ height: '56px' }}
                onClick={onSubmit}
            >
                { displayPage === "Core" && reportSelect === "Status Report"  ? "Switch" : "Search"}
            </Button>
        </Box>
    </Card>
  );
};

export default Toolbar;
