import React, { useState, useEffect } from 'react';
import {
    Box,
} from "@mui/material";
import { useSelector } from 'react-redux';
import ChartService from "../../../utils/DashboardChartService";
import moment from 'moment';

const CoreReport = (props) => {
    const deviceList = useSelector((state) => state.setUser.coreReportDevices);
    const columns = [
        {field:"machineCommonName", headerName: "Serial Number", minWidth: 120, flex: 1},
        {field:"startDate", headerName: "Start Date", flex: 1},
        {field:"endDate", headerName: "End Date", flex: 1},
        {field:"machineCumulativeHoursUsed", headerName: "Cumulative Hours Used", flex: 1},
        {field:"machineHoursUsedInPeriod", headerName: "Hours Used in Period", flex: 1},
        {field:"machineCumulativeEnergyUsed", headerName: "Cumulative Energy Used", flex: 1},
        {field:"machineEnergyUsedInPeriod", headerName: "Energy Used in Period", flex: 1},
        {field:"averageEnergyDischargeDuringNonIdleOperation", headerName: "Average Energy Discharge", flex: 1},
        {field:"percentOfTimeOperatingInSB535DisadvantagedCommunities", headerName: "% Disadvantaged", flex: 1},
        {field:"percentOfTimeOperatingInAB1550LowIncomeCommunities", headerName: "% Low Income", flex: 1},
        {field:"percentOfTimeOperatingInSB535AndAB1550Communities", headerName: "% Disadvantaged & Low Income", flex: 1},
        {field:"percentOfTimeOperatingInAB1550LowIncomeCommunitiesWithinHalfMileOfASB535DisadvantagedCommunity", headerName: "Low Income Comm & 1/2 mile from Disadvantaged", flex: 1},
        {field:"percentOfTimeOperatingInPotentialLowIncomeHouseholdsWithinHalfMileOfADisadvantagedCommunity", headerName: "Low Income Households & 1/2 mile from Disadvantaged", flex: 1}
    ];
    for(let device of deviceList){
        device.startDate = moment(device.periodStartDate).format("MM/DD/YY HH:MM")
        device.endDate = moment(device.periodEndDate).format("MM/DD/YY HH:MM")
    }

    return (
        <Box width={'140%'} sx={{ height: 800, paddingRight: 3}}>
            <ChartService data={{type: 'check chart', title: 'CORE Report', columns: columns, list: deviceList}} />
        </Box>
    )
}

export default CoreReport;
