import React from "react";
import {
    Box,
    Container,
    Tab,
    Tabs,
    AppBar,
    Card,
    Backdrop,
    CircularProgress,
} from "@mui/material";
import Page from '../../components/Page';
import FleetMap from './FleetMap/index';
import CoreReport from '../ReportsPage/CoreReport/index';
import FleetStatus from './FleetStatus/index';
import { connect, useSelector } from 'react-redux';
import theme from "../../theme";
import Toolbar from '../information/InformationtView/Toolbar';
import { maxHeight } from "@mui/system";
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';

const MyPage = styled(Page)({
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
})

const FleetManagementView = (props) => {
    const [data, setData] = React.useState([]);

    return (
        <MyPage title="Fleet Management" >
            <Container maxWidth={false}>
                <Toolbar data={data} setData={setData} hideLimit={true} parent={"fleet"} />
                <Box mt={3}>
                    <Box sx={{ height: 800}} >
                        <Box height={'50%'} width={'100%'} >
                            <FleetMap title="I'm the map." />
                        </Box>
                        <Box height={'50%'} width={'100%'} >
                            <FleetStatus />
                        </Box>
                    </Box>
                </Box>
            </Container>
        </MyPage>
    );
}
export default FleetManagementView;
