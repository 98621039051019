import { combineReducers } from "redux";

const deviceListLoading = ( state = false, action ) => {
    switch (action.type) {
        case "DEVICE_LIST_LOADING":
            state = action.payload
            return state;    
        default:
            return state;
    }
}

const creationDevices = ( state = [], action ) => {
    switch (action.type) {
        case "SET_DASH_DEVICES":
            state = action.payload;
            return state;
        default:
            return state;
    }
}
const coreReportStart = ( state = [], action ) => {
    switch (action.type) {
        case "CORE_REPORT_START":
            state = action.payload;
            return state;
        default:
            return state;
    }
}
const coreReportEnd = ( state = [], action ) => {
    switch (action.type) {
        case "CORE_REPORT_END":
            state = action.payload;
            return state;
        default:
            return state;
    }
}
const creationDataPoints = ( state = [], action ) => {
    switch (action.type) {
        case "SET_DASH_DATA_POINTS":
            for (const x of action.payload[0]) {
                if( state.indexOf(x) === -1 ){
                    state.push(x)
                }
            }
            return state;
        case "CLEAR_DASH_POINTS":
            state = []
            return state;
        default:
            return state;
    }
}

const allChartData = ( state = [], action ) => {
    switch (action.type) {
        case "SET_CHART_DATA":
            state = action.payload;
            return state;
    
        default:
            return state;
    }
}

const loading = ( state = true, action ) => {
    switch (action.type) {
        case "SET_CHART_LOADING":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const fileLoading = ( state = true, action ) => {
    switch (action.type) {
        case "SET_FILE_LOADING":
            state = action.payload;
            return state;
        default:
            return state;
    }
} 

const fileProgress = ( state = 0, action ) => {
    switch (action.type) {
        case "FILE_UP":
            state++
            return state;
        case "FILE_RESET":
            state = 0;
            return state; 
        default:
            return state;
    }
}

export default combineReducers({
    creationDevices,
    coreReportStart,
    coreReportEnd,
    creationDataPoints,
    allChartData,
    loading,
    fileLoading,
    fileProgress,
    deviceListLoading
})