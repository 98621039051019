import React from "react";
import {
    Box,
    Button,
    Card,
} from "@mui/material";
import { 
    DataGrid,
    GridToolbar
} from "@mui/x-data-grid";
import { Save, FilePlus, ArrowLeft, XSquare, Plus } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Swal from 'sweetalert2';
import Toast from "../../../mixins/Toast";
import ImportModal from "./ImportModal";
import PgnCreation from "./Creation";
import Popup from "../../../mixins/Popup";
import PgnDetails from "../CanFiles/Details";


const CanElements = () => {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('');
    const [addType, setAddType] = React.useState('');
    const [source, setSource] = React.useState('');
    const [destination, setDestination] = React.useState('');
    const [pgn, setPGN] = React.useState('');
    const [spns, setSpns] = React.useState([]);
    const [bit, setBit] = React.useState('');
    const [element, setElement] = React.useState('');
    const [scale, setScale] = React.useState('');
    const [offset, setOffset] = React.useState('');
    const [editRowsModel, setEditRowsModel] = React.useState({});
    const [modal, setModal] = React.useState(false);
    const dispatch = useDispatch();
    const token = useSelector((state) => state.setUser.token);
    const userRole = useSelector((state) => state.setUser.currentRole);
    const canPGNs = useSelector((state) => state.deviceList.canPGNs);
    const canFiles = useSelector((state) => state.deviceList.canFiles);

    const columns = [
        { field: 'name', headerName: "Name", flex: 1, minWidth: 150 },
        { field: 'canFileId', headerName: "File", flex: 1, minWidth: 150, renderCell: (params) => (
            canFiles.length > 0 ? canFiles.find((x) => x.id == params.row.canFileId).name : "N/A"
        )},
        { field: 'canIdent', headerName: "PGN ID", flex: 1, minWidth: 150 },
        { field: 'byteLength', headerName: 'Byte Length', flex: 1, minWidth: 150 },
        { field: 'sender', headerName: 'Sender', flex: 1, minWidth: 150 },
        { field: 'spn', headerName: 'Details', flex: 1, minWidth: 150, renderCell: (params) => (
            <Button
                variant="contained"
                onClick={() => {
                    axios.get(`/api/CanSPN/${params.row.id}`,{headers: !token ? {} : { 'Authorization' : `Bearer ${token}`}})
                    .then((response) => setSpns(response.data))
                    .then(() => setPGN(params.row))
                    .then(() => setModal(true))
                    .catch((error) => console.log(error))
                }}
            >
                View
            </Button>
        ) }
    ]
    
    const handleEditRowsModelChange = React.useCallback((model) => {
        setEditRowsModel(model);
    }, []);

    const handleClick = (type) => {
        setOpen(!open)
        setAddType(type)
    };

    const handleClose = () => {
        setModal(false);
        setEditRowsModel({});
    }

    const handleSave = () => {
        axios.post('/api/CanElement', {
            Name: name,
            Source: Number(source),
            Destination: Number(destination),  
            PGN: Number(pgn),
            StartBit: Number(bit),
            ElementType: Number(element),
            Scale: Number(scale),
            Offset: Number(offset)
        },{headers: !token ? {} : { 'Authorization': `Bearer ${token}` }}).then((response) => {
            if(response.status === 200){
                setName('')
                setSource('')
                setDestination('')
                setPGN('')
                setBit('')
                setElement('')
                setScale('')
                setOffset('')
            }
        })
        .then(() => {
            let timerInterval
            Swal.fire({
            title: 'Can Element Added',
            icon: 'success',
            timer: 2000,
            timerProgressBar: true,
            willClose: () => {
                clearInterval(timerInterval)
                setOpen(!open)
            }
            })
        })
        .then(() => dispatch({ type: "GRAB_CAN_ELEMENTS"}))
        .catch((error) => console.log(error))
    };

    const handleEditPGN = () => {
        const objId = Object.keys(editRowsModel)[0];
        const obj = Object.values(editRowsModel)[0];
        console.log(`ID: ${objId} OBJ: ${ JSON.stringify(obj)}`);
        axios.put(`/api/CanPGN/${objId}`, {
            Name: obj.name.value,
            ByteLength: obj.byteLength.value,
            Sender: obj.sender.value,
            CanIdent: Number(obj.canIdent.value)

        },{headers : !token ? {} : { 'Authorization': `Bearer ${token}`}})
        .then(() => dispatch({ type: "GRAB_CAN_ELEMENTS"}))
        .then(() => Toast.fire({
            icon: 'success',
            title: 'PGN Updated'
        }))
        .catch((error) => {
            console.log(`error with updating pgn: ${error}`)
            Toast.fire({
                icon: 'error',
                title: 'Error updating PGN'
            })
        })
    };

    return (
        <Box sx={{ height: '100%' }}>
        { userRole.name !== "Read" ?
            <Box sx={{ width: '100%' }}>
                { !open ?
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                </Box>
                :
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleClick("")}
                    >
                        <ArrowLeft/> Back
                    </Button>
                </Box> }
                {Object.keys(editRowsModel).length !== 0 && modal === false ?
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleEditPGN}
                >
                    <Save/> Save
                </Button>
                : '' }
            </Box>
        : "" }
            { open === false ?
                <Card 
                    sx={{ height: 800, marginTop: '5px' }}
                >
                <DataGrid 
                    rows={canPGNs}
                    columns={columns}
                    editRowsModel={editRowsModel}
                    editMode="row"
                    onRowEditCommit={handleEditPGN}
                    onEditRowsModelChange={handleEditRowsModelChange}
                    components={{
                        Toolbar: GridToolbar
                    }}
                />
                <Popup
                    open={modal}
                    close={handleClose}
                >
                    <PgnDetails pgn={pgn} spn={spns} />
                </Popup>
                </Card>
            :
                addType === 'import' ?
                <ImportModal close={() => setOpen(false)} />
                :
                <Card
                    sx={{ height: 500, display: 'flex', justifyContent: 'center', marginTop: '5px' }}
                >
                    <PgnCreation/>
                </Card>
            }
        </Box>
    )
};

export default CanElements;