import React from 'react';
import {
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from "react-redux"
import { styled } from '@mui/system';
import theme from '../../../theme';
import Toast from '../../../mixins/Toast';

const Paper = styled('div')({
    position: 'absolute',
    width: '50%',
    height: '50%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center'
})

const Content = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'center',
    width: '100%'
})

const Text = styled('div')({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
})

const ButtonContainer = styled('div')({
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
})

const ButtonGroup = styled('div')({
    display: 'flex',
    width: '70%',
    justifyContent: 'space-between',
    marginTop: '20px'
})

const NewGroup = React.forwardRef((props, ref) => {
    const [options] = React.useState(props.data);
    const [parent, setParent] = React.useState(null);
    const [name, setName] = React.useState(null);
    const [check, setCheck] = React.useState(false);
    const token = useSelector((state) => state.setUser.token);
    const dispatch = useDispatch();

    const handleChange = (event) => {
        setName(event.target.value)
    }

    const handleCheck = (event) => {
        setCheck(event.target.checked);
    }

    const handleSubmit = () => {
        const group = {
            Name: name,
            IsDefault: check,
            ParentGroupId: parent
        }
        if (name === null) {
            return Toast.fire({
                icon: 'error',
                title: 'Enter Group Name'
            })
        }
        else {
            fetch('/api/group', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(group)
            })
                .then(response => {
                    if(response.status === 200){
                        Toast.fire({
                            icon: 'success',
                            title: 'Group Created'
                        })
                    }
                })
                .then(() => dispatch({type: "GRAB_ADMIN"}))
                .catch(error => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Problem creating new group'
                    })
                    console.error('Unable to add group.', error)
                });
        }
    }

    return (
        <Paper>
            <Content>
                <h1 style={{ textAlign: "center", marginBottom: '20px' }}>Group Creation Menu</h1>
                <Text>
                    <TextField style={{ width: '30%' }} onChange={handleChange} label="Group name" variant="outlined" />
                    <Autocomplete
                        id="group-box"
                        options={options}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => {
                            setParent(newValue.id)
                        }}
                        style={{ width: '30%' }}
                        renderInput={(params) => <TextField {...params} label="Parent Group" variant="outlined" />}
                    />       
                    <FormControlLabel
                        value="Default"
                        control={<Checkbox checked={check} onChange={handleCheck} color="primary"/>}
                        label="Default"
                        labelPlacement="bottom"
                    />
                </Text>
                <ButtonContainer>
                    <ButtonGroup>
                        <Button
                            onClick={() => props.close(false)}
                            variant="contained"
                            color="secondary"
                        >
                            cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Accept
                        </Button>
                    </ButtonGroup>
                </ButtonContainer>
            </Content>
        </Paper>
    )
})

export default NewGroup;