import React, { version } from "react";
import { 
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Autocomplete,
    TextField,
    Alert,
    Typography,
    Button
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Toast from "../../../mixins/Toast";

const DeleteGroup = (props) => {
    const { groupId, close } = props;
    const devices = useSelector((state) => state.deviceList.canGroupDevices);
    const allFirmware = useSelector((state) => state.admin.firmwareList);
    const allVersions = useSelector((state) => state.admin.hardwareVersions);
    const allCANGroups = useSelector((state) => state.deviceList.canGroup);
    const currentGroup = useSelector((state) => state.setUser.currentGroup);
    const dispatch = useDispatch();
    const token = useSelector((state) => state.setUser.token);
    return (
        <Box sx={{ height: '100%' }}>
            { devices.length > 0 ? <Alert severity="error">DEVICES STILL BELONG TO THIS GROUP - PLEASE CHANGE CAN GROUPS BEFORE DELETING</Alert> : ''}
            <Box p={3}  sx={{ height: '100%' }}>
                { devices.length > 0 ?
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Group</TableCell>
                                <TableCell>External ID</TableCell>
                                <TableCell>Hardware Version</TableCell>
                                <TableCell>Firmware Version</TableCell>
                                <TableCell>CAN Group</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {devices.map((x, i) => {
                                console.log(x)
                                console.log(x.group)
                                const firmwareName = allFirmware.find((firmware) => firmware.id === x.firmwareVersionId)
                                const versionName = allVersions.find((version) => version.id === x.hardwareVersionId)
                                return (
                                    <TableRow key={i}>
                                        <TableCell>{x.name}</TableCell>
                                        <TableCell>{x.group ? x.group.name : ''}</TableCell>
                                        <TableCell>{x.externalId}</TableCell>
                                        <TableCell>{versionName.name}</TableCell>
                                        <TableCell>{firmwareName.name}</TableCell>
                                        <TableCell>
                                            <Autocomplete 
                                                options={allCANGroups}
                                                defaultValue={allCANGroups.find((c) => c.id === x.canGroupId)}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                getOptionLabel={(option) => option.name}
                                                renderInput={(params) => <TextField {...params} label="CAN Group" variant="standard" />}
                                                onChange={(event, newValue) => {
                                                    axios.get(`/api/device/CanGroup/Update/${x.id}/${newValue.id}`, {headers: { 'Authorization': `Bearer ${token}` }})
                                                    .then(() => dispatch({type: "GRAB_CAN_GROUP_DEVICES", payload: groupId}))
                                                    .then(() => Toast.fire({ icon: 'success', title: 'Can Group Updated' }))
                                                    .catch((error) => {
                                                        Toast.fire({ icon: 'error', title: JSON.stringify(error) })
                                                    })
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>   
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                : 
                <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography variant="h2">Are you sure you want to delete this CAN Group?</Typography>
                    <Button 
                        color="error"
                        variant="contained"
                        sx={{ width: '50%', marginTop: '15px' }}
                        onClick={() => {
                            axios.delete(`/api/CanGroups/${groupId}`, {headers: {'Authorization': `Bearer ${token}`}})
                            .then(() => dispatch({ type: 'GRAB_CAN_GROUPS', payload: currentGroup.id }))
                            .then(() => Toast.fire({ icon: 'success', title: 'CAN Group Removed' }))
                            .then(() => close())
                        }}
                    >
                        Delete
                    </Button>
                </Box>
                }
            </Box>
        </Box>
    )
};

export default DeleteGroup;