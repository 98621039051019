import React, { forwardRef } from "react";
import { 
    Box,
    Autocomplete,
    Button,
    TextField,
    Card
 } from "@mui/material";
import { Plus } from 'react-feather';
import moment from 'moment';
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Toast from "../../../mixins/Toast";

const AdminFirmwareHardware = forwardRef((props, ref) => {
    const [versionsToAdd, setVersionsToAdd] = React.useState([]);
    const [data, setData] = React.useState([]);
    const token = useSelector((state) => state.setUser.token);
    const allHardware = useSelector((state) => state.admin.hardwareVersions);
    const hardwareVersions = useSelector((state) => state.admin.hardwareVersions);
    const dispatch = useDispatch();
    
    React.useEffect(() => {
        setData(props.data)
    }, [props.data])

    const hardwareColumns = [
        { field: 'hardwareVersionId', headerName: 'Hardware Version', flex: 1, minWidth: 150, renderCell: (params) => {
            let result = ''
            hardwareVersions.some(x => result = x.id === params.row.hardwareVersionId ? x.name : "")
            return result
        }},
        { field: 'updated', headerName: 'Updated', flex: 1, minWidth: 150, renderCell: (params) => (moment.utc(params.row.updated).local().format('MM/DD/YY, h:mm a')) },
        { field: 'remove', headerName: 'Remove', flex: 1, minWidth: 150, renderCell: (params) => (
            <Button
                variant="contained"
                color="error"
                onClick={() => handleVersionDelete(params.row.id)}
            >
                Remove                
            </Button>
        ) }
    ]

    const handleVersionDelete = (id) => {
        axios.put(`/api/HardwareVersionFirmwareVersion/Delete/${id}`, {}, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }})
        .then(() => {
            dispatch({
                type: "GRAB_FIRMWARE_HARDWARE",
                payload: props.current
            })
        })
        .then(() => Toast.fire({
            title: 'Hardware version deleted',
            icon: 'success'
        }))
        .catch((error) => {
            console.log(error)
            return Toast.fire({
            title: 'Error removing version',
            icon: 'error'
        })})
    }

    const handleAddVersions = () => {
        for (const x of versionsToAdd) {
            axios.post(`/api/HardwareVersionFirmwareVersion`,{
                HardwareVersionId: x.id,
                FirmwareVersionId: props.current
            },{headers: !token ? {} : { 'Authorization': `Bearer ${token}` }}).then((response) => {
                dispatch({
                    type: "GRAB_FIRMWARE_HARDWARE",
                    payload: props.current
                })
            })
            .catch((error) => console.log(error));
        }
    }

    return (
        <Card
            sx={{ margin: '15px', height: '100%' }}
        >   
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Autocomplete
                    multiple
                    id="Hardware-Versions"
                    disableCloseOnSelect
                    limitTags={1}
                    options={allHardware}
                    onChange={(event, newValue) => {
                        setVersionsToAdd(newValue)
                    }}
                    getOptionLabel={(option) => option.name}
                    sx={{ width: 500, margin: '15px' }}
                    renderInput={(params) => <TextField {...params} label="Hardware Versions" variant="outlined" />}
                />
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ height: '56px' }}
                    onClick={handleAddVersions}
                >
                    <Plus/> Add
                </Button>
            </Box>
            <DataGrid 
                columns={hardwareColumns}
                rows={data}
            />
        </Card>
    )
});

export default AdminFirmwareHardware;