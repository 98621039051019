import React from 'react';
import {
  Box,
  Container
} from '@mui/material';
import Page from '../../../../src/components/Page';
import Notifications from './Notifications';
import Password from './Password';
import { styled } from '@mui/system';
import theme from '../../../theme';

const MyPage = styled(Page)({
  backgroundColor: theme.palette.background.default,
  minHeight: '100%',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3)
})

const SettingsView = () => {

  return (
    <MyPage
      title="Settings"
    >
      <Container maxWidth="lg">
        <Notifications />
        <Box mt={3}>
          <Password />
        </Box>
      </Container>
    </MyPage>
  );
};

export default SettingsView;
