import React from "react";
import {
    Box,
    Container,
    Tab,
    Tabs,
    AppBar,
    Card,
    Backdrop,
    CircularProgress,
} from "@mui/material";
import { connect, useSelector } from 'react-redux';
import { maxHeight } from "@mui/system";
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import Page from "../../components/Page";
import theme from "../../theme";
import LandingInfo from "./LandingInfo";
import ChartService from "../../utils/DashboardChartService";

const MyPage = styled(Page)({
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
})

const LandingIndex = (props) => {
    const landingData = useSelector(state => state.LandingReducer.LandingData);
    // console.log("index land data", landingData);
    let data = {};

    if (landingData !== 0) {
        let gpsDay = landingData.gpS24;
        let gpsMonth = landingData.gpsLastMonth - gpsDay;
        let gpsRemain = landingData.totalGPS - gpsMonth
        data = {
            title: "GPS",
            data: {
                labels: ['GPS Within Day ', 'GPS Within Month', 'GPS total remaining'],
                datasets: [
                    {
                        data: [gpsDay, gpsMonth, gpsRemain],
                        backgroundColor: [
                            'rgba(241, 235, 236, 0.8)', // white
                            'rgba(10, 10, 10, 0.8)', // black
                            'rgba(255, 83, 0, 0.8)', // orange
                        ],
                        borderColor: [
                            'rgba(5, 4, 4, 1)', // white (black outline) 
                            'rgba(95, 84, 86, 1)', // black
                            'rgba(255, 83, 0, 1)', // orange
                        ],
                        borderWidth: 1,
                    }
                ]
            },
            // type: "pie",
            type: "doughnut",
        }
    }

    let data2 = {};

    if (landingData !== 0) {
        let inactive = landingData.machineCount - landingData.machineActive24;
        let deactivated = 0
        data2 = {
            title: "Devices",
            data: {
                labels: [ 'Active', 'Inactive', 'Deactivated'],
                datasets: [
                    {
                        data: [ landingData.machineActive24, inactive, deactivated],
                        backgroundColor: [
                            'rgba(255, 54, 0, 0.8)', // orange
                            'rgba(10, 10, 10, 0.8)', // black
                            'rgba(241, 235, 236, 0.8)', // white
                        ],
                        borderColor: [
                            'rgba(255, 54, 0, 1)', // orange
                            'rgba(10, 10, 10, 1)', // black
                            'rgba(5, 4, 4, 1)', // white (black outline)
                        ],
                        borderWidth: 1,
                        cutout: '30%'
                    }
                ]
            },
            // type: "pie",
            type: "doughnut",           
        }
    }

    return (
        <MyPage title="Home" >
            <Container maxWidth={false} >
                <LandingInfo />
                <div style={{ display: "flex", justifyContent: "space-evenly", height: 500 }}>
                    {landingData === 0 ? "" : <ChartService data={data} />}
                    {landingData === 0 ? "" : <ChartService data={data2} />}
                </div>
            </Container>
        </MyPage>
    );
}
export default LandingIndex;