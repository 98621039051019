import React, {useEffect} from "react";
import {
    Box,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Modal
 } from "@mui/material";
 import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { Plus, ArrowDown, FilePlus, Trash2 } from "react-feather";
import Popup from "../../../mixins/Popup";
import PgnDetails from "./Details";
import ImportDBC from "./ImportDBC";
import PgnCreation from "./Single";
import Swal from "sweetalert2";
import CanFileCreation from "./CreateCanFile";

const CanFiles = () => {
    const files = useSelector((state) => state.deviceList.canFiles);
    const [spns, setSpns] = React.useState([]);
    const [pgn, setPgn] = React.useState([]);
    const [single, setSingle] = React.useState(false);
    const [CanFile, setCanFile] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [dbc, setDbc] = React.useState(false);
    const token = useSelector((state) => state.setUser.token);
    const dispatch = useDispatch();
    const spn = useSelector((state) => state.CanSPNReducer.CanSPN);
    const groups = useSelector((state) => state.setUser.groups);
    // console.log(" groups in the can index is: ", groups);
    // console.log(" groups 2: ", groups[0]);
    // console.log(" groups.id 3 ", groups[0].id);
    const subGroups = groups[0].subGroups
    const groupIds = subGroups.map(g => g.id);
    groupIds.push(groups[0].id);

    // console.log(" subgroups 4: ", subGroups);
    // console.log(" group ids 5: ", groupIds); 
    if (files.length>0){
        for (let i of files){
            for (let k of i.pgn){
                k.canIdent = k.canIdent.toString(16); 
            }
        }
    }

    // console.log("files in ", files);

    useEffect(() => {
        // dispatch({ type: 'FETCH_CAN_SPN', payload: params.row.id, token: token }); // can't do here with "params" only existing in the button
    }, [])

    const columns = [
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 150 },
        { field: 'byteLength', headerName: 'Byte Length', flex: 1, minWidth: 150 },
        { field: 'canIdent', headerName: 'PGN ID', flex: 1, minWidth: 150  },
        { field: 'sender', headerName: 'Sender', flex: 1, minWidth: 150 },
        { field: 'spn', headerName: 'Details', flex: 1, minWidth: 100, renderCell: (params) => (
            <Button
                variant="contained"
                onClick={() => {
                    dispatch({ type: 'FETCH_PGN', payload: params.row.id, token: token });
                    dispatch({ type: 'FETCH_CAN_SPN', payload: params.row.id, token: token });
                    // setPgn(params.row);
                    // console.log("params after call: ", params, "row after call: ", params.row);
                    // console.log("spn in click, ", spn);
                    setSpns(spn);
                    setPgn(params.row);
                    setOpen(true);
                    // axios.get(`/api/CanSPN/${params.row.id}`,{headers: !token ? {} : { 'Authorization' : `Bearer ${token}`}})
                    // .then((response) => setSpns(response.data))
                    // .then(() => setPgn(params.row))
                    // .then(() => setOpen(true))
                    // .catch((error) => console.log(error))
                }}
            >
                VIEW
            </Button>
        ) },
        { field: 'delete', headerName: 'Delete', flex: 1, minWidth: 100, renderCell: (params) => (
            <Button
                variant="contained"
                color="error"
                onClick={() => {
                    Swal.fire({
                        title: "ARE YOU SURE",
                        text: `you want to delete PGN ${params.row.name}, and all of the SPNS within?`,
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: 'DELETE',
                        confirmButtonColor: '#dd6b55',
                        cancelButtonText: 'Cancel',
                    })
                        .then((willDelete) => {
                            if (willDelete.isConfirmed) {
                                dispatch({ type: 'DELETE_PGN', payload: params.row.id, token: token, groupIds });
                            }
                        })                   
                }}
                >
                    DELETE
                </Button>
            ) }
        
    ]
    
    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: "space-between" }}>
                <Button startIcon={<FilePlus/>} onClick={() => setDbc(!dbc)} variant="contained" color="primary">
                    Import
                </Button>
                <Button startIcon={<Plus/>} onClick={() => setCanFile(!CanFile)} variant="contained" color="primary">
                    Can File
                </Button>
                <Button startIcon={<Plus/>} onClick={() => setSingle(!single)} variant="contained" color="primary">
                    PGN
                </Button>
            </Box>
            <Popup open={dbc} close={() => setDbc(false)}>
                <ImportDBC close={() => setDbc(false)}/>
            </Popup>
            <Popup open={single} close={() => setSingle(false)}>
                <PgnCreation/>
            </Popup>
            <Modal sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} open={CanFile} onClose={() => setCanFile(!CanFile)}>
                <Box sx={{ backgroundColor: "white", padding: '2vw'}}>
                    <CanFileCreation setCanFile={setCanFile} groupIds={groupIds} />
                </Box>
            </Modal>
            <Popup open={open} close={() => setOpen(false)}>
                <PgnDetails pgn={pgn} spn={spn}/>
            </Popup>
            <Box sx={{ marginTop: '5px' }}>
                {files.map((x, i) => {
                    return (
                        <Accordion key={i} sx={{ border: '1px solid #e0e0e0', marginTop: '5px', textAlign: "center" }}>
                            <AccordionSummary expandIcon={<ArrowDown/>}>
                                {x.name}
                                <button style={{marginLeft: "auto", marginRight: "5px", paddingLeft: "2px", paddingRight: "2px" }} onClick={()=> {
                                    Swal.fire({
                                        title: "ARE YOU SURE",
                                        text: `you want to delete Can File "${x.name}" and all PGNs and SPNs belonging to it?`,
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonText: 'DELETE',
                                        confirmButtonColor: '#dd6b55',
                                        cancelButtonText: 'Cancel',
                                    })
                                        .then((willDelete) => {
                                            if (willDelete.isConfirmed) {
                                                dispatch({ type: 'DELETE_CAN_FILE', payload: x, groupIds });
                                            }                            
                                        })         
                                }}> 
                                    <Trash2 style={{color: "red"}} />
                                </button>
                            </AccordionSummary>
                            <AccordionDetails >
                                <Box sx={{ height: '400px' }}>
                                    <DataGrid
                                        rows={x.pgn}
                                        columns={columns}
                                    />
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </Box>
        </Box>
    )
};

export default CanFiles;