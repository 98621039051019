import React from "react";
import {
    Box,
    Button
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
    Plus,
    ArrowLeft
} from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import CreateCanGroup from "./CreateCanGroup";
import moment from 'moment';
import axios from "axios";
import ElementEdit from "./ElementEdit";
import Popup from "../../../mixins/Popup";
import DeleteGroup from "./DeleteGroup";

const CanGroups = () => {
    const [open, setOpen] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const [remove, setRemove] = React.useState(false);
    const [groupDevices, setGroupDevices] = React.useState([]);
    const [edit, setEdit] = React.useState([]);
    const [editId, setEditId] = React.useState(null);
    const userRole = useSelector((state) => state.setUser.currentRole);
    const rows = [];
    const canGroups = useSelector((state) => state.deviceList.canGroup);
    const token = useSelector((state) => state.setUser.token);
    const dispatch = useDispatch();
    const columns = [
        { field: 'created', headerName: 'Created', flex: 1, minWidth: 150 },
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 150 },
        { field: 'PGN', headerName: 'PGNs', flex: 1, minWidth: 150, renderCell: (params) => (
            <Button
                variant="contained"
                color="primary"
                onClick={() => getElements(params.row.id)}
            >
                PGNs
            </Button>
        )},
        { field: 'delete', headerName: 'Delete', flex: 1, minWidth: 150, renderCell: (params) => (
            <Button
                variant="contained"
                color="error"
                disabled={userRole === 'Read'}
                onClick={() => handleRemove(params.row.id)}
            >
                Remove
            </Button>
        ) }
    ]

    const handleRemove = (x) => {
        setGroupDevices(x)
        dispatch({type: 'GRAB_CAN_GROUP_DEVICES', payload: x})
        setRemove(true)
    }
    const getElements = (x) => {
        axios.get(`/api/CanGroupPGN/${x}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }})
        .then((response) => setEdit(response.data))
        .then(() => setEditId(x))
        .then(() => setModal(true))
        .catch((error) => console.log(error))
    }
    const handleClick = () => {
        setOpen(!open)
    }
    const handleClose = () => {
        setModal(false)
    }
    canGroups.map((x) => {
        return rows.push({id: x.id, created: moment.utc(x.created).local().format('lll'), name: x.name });
    })
    return (
        <Box sx={{ height: '100%' }}>
            <Popup open={modal} close={handleClose}>
                <ElementEdit data={edit} refresh={getElements} id={editId}/>
            </Popup>
            <Popup open={remove} close={() => setRemove(false)}>
                <DeleteGroup groupId={groupDevices} close={() => setRemove(false)}/>
            </Popup>
            { userRole.name !== 'Read' ?
            <Button
                variant="contained"
                color="primary"
                onClick={handleClick}
            >
                { open ? <React.Fragment> <ArrowLeft/> Exit </React.Fragment> : <React.Fragment> <Plus/> Add </React.Fragment> }
            </Button>
            : ""
            }
            { open ?
            <Box sx={{ marginTop: '5px' }}>
                <CreateCanGroup close={handleClick}/>
            </Box>
            :
            <Box sx={{ height: 800, marginTop: '5px' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                />
            </Box> }
        </Box>
    )
}

export default CanGroups;