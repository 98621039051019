import React from "react";
import {
    Typography,
    Button,
    Box,
    Card,
    CardHeader
} from "@mui/material";

const CreateGroup = React.forwardRef((props, ref) => {

    return(
        <Card
            sx={{ height: '80%', width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
        >
            <CardHeader />
            <Box
                sx={{ margin: '20px' }}
            >
                <Typography
                    variant="h4"
                >
                    You are currenty not apart of a Group.
                </Typography>
            </Box>
            <Box>
                <Button sx={{ margin: "10px" }} variant="contained">Cancel</Button>
                <Button sx={{ margin: "10px" }} variant="contained">Create Group</Button>
            </Box>
        </Card>
    )
})

export default CreateGroup