import { combineReducers } from "redux";

const firmwareList = (state = [], action) => {
    switch (action.type) {
        case "SET_FIRMWARE":
            state = action.payload
            return state;
    
        default:
            return state;
    }
}

const allFirmware = (state = [], action) => {
    switch (action.type) {
        case "SET_ALL_FIRMWARE":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const hardwareList = (state = [], action) => {
    switch (action.type) {
        case "SET_HARDWARE":
            state = action.payload
            return state;
    
        default:
            return state;
    }
}
const hardwareVersions = (state = [], action) => {
    switch (action.type) {
        case "ADMIN_HARDWARE_VERSIONS":
            state = action.payload
            return state;
    
        default:
            return state;
    }
}

const hardwareFamily = (state = [], action) => {
    switch (action.type) {
        case "SET_FAMILY":
            state = action.payload
            return state;
    
        default:
            return state;
    }
}

const adminGroups = (state = [], action) => {
    switch (action.type) {
        case "ADMIN_SET_GROUP":
            state = action.payload
            return state;
    
        default:
            return state;
    }
}

const roles = (state = [], action) => {
    switch (action.type) {
        case "ADMIN_SET_ROLE":
            state = action.payload
            return state;
    
        default:
            return state;
    }
}

const adminloading = (state = true, action) => {
    switch (action.type) {
        case "ADMIN_LOADING":
            state = action.payload;
            return state
        default:
            return state
    }
}

const users = (state = [], action) => {
    switch (action.type) {
        case "ADMIN_USERS":
            state = action.payload
            return state
    
        default:
            return state;
    }
}

const devices = (state = [], action) => {
    switch (action.type) {
        case "ADMIN_DEVICES":
            state = action.payload            
            return state    
        default:
            return state
    }
}
const canElements = ( state = [], action ) => {
    switch (action.type) {
        case "ADMIN_CAN_ELEMENTS":
            state = action.payload;
            return state;
        default:
            return state;
    }
}
const canGroups = ( state = [], action ) => {
    switch (action.type) {
        case "ADMIN_CAN_GROUPS":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

export default combineReducers({
    firmwareList,
    hardwareFamily,
    hardwareList,
    adminloading,
    adminGroups,
    hardwareVersions,
    roles,
    users,
    devices,
    canElements,
    canGroups,
    allFirmware
});