import React from 'react';
import { TextField, Button, Typography } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { connect, useSelector } from 'react-redux';
import { styled } from '@mui/system';
import Toast from '../../../mixins/Toast';

const MyButton = styled(Button)({
    width: '30%',
    minHeight: '50px'
})

const Input = styled(TextField)({
    margin: '10px'
})

const DropDown = styled('div')({
    display: 'flex',
    justifyContent: 'center'
})

const Submit = styled('div')({
    width: '50%',
    display: 'flex',
    justifyContent: 'space-between'
})

const Paper = styled('div')({
    backgroundColor: 'white',
    width: '80%',
    height: '80%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    border: '1px solid black'
})

const Form = styled('div')({
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column'
})

const CreateDevice = React.forwardRef((props, ref) => {
    const [externalId, setExternalId] = React.useState(null);
    const [hardware, setHardware] = React.useState(null);
    const [firmware, setFirmware] = React.useState(null);
    const [name, setName] = React.useState(null);
    const [config, setConfig] = React.useState(null);
    const [versions, setVersions] = React.useState([]);
    const [group, setGroup] = React.useState(null);
    const [canGroup, setCanGroup] = React.useState(null);
    const token = useSelector((state) => state.setUser.token);
    const groups = useSelector((state) => state.admin.adminGroups);
    const canGroups = useSelector((state) => state.deviceList.canGroup);

    const handleChange = (type, event) => {
        switch (type) {
            case 'id':
                return setExternalId(event.target.value);
            case 'hardware':
                return setHardware(event);
            case 'firmware':
                return setFirmware(event);
            case 'name':
                return setName(event.target.value);
            case 'config':
                return setConfig(event.target.value);
            case 'group':
                return setGroup(event)
            case 'canGroup':
                return setCanGroup(event)
            default:
                break;
        }
    }

    const handleSubmit = () => {
        if ( name === null ) {
            return Toast.fire({
                icon: 'error', 
                title: 'Please enter name'
            })
        }
        else if ( externalId === null ) {
            return Toast.fire({
                icon: 'error',
                title: 'Please enter external ID'
            })
        }
        else if ( group === null ) {
            return Toast.fire({
                icon: 'error',
                title: 'Please select Group'
            })
        }
        else if ( hardware === null ) {
            return Toast.fire({
                icon: 'error',
                title: 'Select hardware version'
            })
        }
        else if ( firmware === null ) {
            return Toast.fire({
                icon: 'error',
                title: 'Select firmware version'
            })
        }
        const item = {
            Name: name,
            FirmwareVersionId: firmware,
            HardwareVersionId: hardware,
            Configuration: config,
            ExternalId: externalId,
            GroupId: group,
            CanGroupId: canGroup
        };
        fetch('/api/device', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(item)
        })
            .then(response => {
                if(response.status === 200){
                    Toast.fire({
                        icon: 'success',
                        title: 'Device Created'
                    })
                }
            }).then(() => props.dispatch({type: "GRAB_ADMIN"}))
            .then(() => props.close())
            .catch(error => console.error('Unable to add item.', error));
    }

    return (
        <Paper>
            <div>
                <Typography
                    variant="h1"
                >
                    ADD NEW DEVICE
                </Typography>
            </div>
            <Form>
                <DropDown>
                    <Autocomplete
                        id="Hardware-Family"
                        options={props.hardware}
                        style={{ width: '300px', margin: '5px' }}
                        onChange={(event, newValue) => {
                            if (!newValue) {
                                setVersions([])
                                return
                            }
                            else {
                                if (!newValue.version) {
                                    setVersions([])
                                }
                                else {
                                    setVersions(newValue.version)
                                }
                            }
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Hardware Family" variant="outlined" />}
                    />
                    <Autocomplete
                        id="Hardware"
                        options={versions}
                        style={{ width: '300px', margin: '5px' }}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                handleChange('hardware', newValue.id)
                            }
                            else {
                                return
                            }
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Hardware Version" variant="outlined" />}
                    />
                </DropDown>
                <DropDown>
                    <Autocomplete
                        id="Firmware"
                        options={props.firmware.Signum.STM}
                        sx={{ width: '300px', margin: '5px' }}
                        onChange={(event, newValue) => {
                            handleChange('firmware', newValue.id)
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Firmware Version" variant="outlined" />}
                    />
                    <Autocomplete
                        id="Group"
                        options={groups}
                        sx={{ width: '300px', margin: '5px' }}
                        onChange={(event, newValue) => {
                            handleChange('group', newValue.id)
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Group" variant="outlined" />}
                    />
                    <Autocomplete 
                        id="Can-Group"
                        options={canGroups}
                        sx={{ width: '300px' }}
                        onChange={(event, newValue) => {
                            handleChange('canGroup', newValue.id)
                        }}
                        defaultValue={canGroups.find((x) => x.id === canGroup )}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Can Group" variant="outlined"/>}
                    />
                </DropDown>
                <div>
                    <Input
                        required
                        id="externalId"
                        label="External Id"
                        defaultValue={externalId}
                        variant="outlined"
                        onChange={(event) => handleChange('id', event)}
                    />
                    <Input
                        required
                        id="Name"
                        label="Name"
                        defaultValue={name}
                        variant="outlined"
                        onChange={(event) => handleChange('name', event)}
                    />
                </div>
                <br />
            </Form>
            <Submit>
                <MyButton
                    variant="contained"
                    color="primary"
                    onClick={props.close}
                >
                    Cancel
                </MyButton>
                <MyButton
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    Submit
                </MyButton>
            </Submit>
        </Paper>
    )
})

export default connect()(CreateDevice);