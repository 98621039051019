import React from "react";
import {
    Button,
    Modal,
    Box,
    Card
} from "@mui/material";
import {
    DataGrid,
    GridToolbar
} from "@mui/x-data-grid";
import {
    Plus,
    Save
} from "react-feather";
import NewUser from './UserCreation'
import { useSelector, useDispatch } from "react-redux";

const UserTable = (props) => {
    const [editRowsModel, setEditRowsModel] = React.useState({});
    const [openUser, setOpenUser] = React.useState(false);
    const token = useSelector((state) => state.setUser.token);
    const groups = useSelector((state) => state.admin.adminGroups);
    const user = useSelector((state) => state.setUser.setUser);
    const systemAdmin = useSelector((state) => state.setUser.systemAdmin);
    const role = useSelector((state) => state.setUser.currentRole);
    const dispatch = useDispatch();

    const columns = [
        { field: 'userName', headerName: 'Username', flex: 1, minWidth: 150, editable: true },
        { field: 'email', headerName: 'Email', flex: 1, minWidth: 150, editable: true },
        { field: 'emailConfirmed', headerName: 'Confirmed Email?', flex: 1, minWidth: 150 },
        { field: 'phoneNumber', headerName: 'Phone Number', flex:  1, minWidth: 150, editable: true },
        { field: 'delete', headerName: 'Delete', flex: 1, minWidth: 150, renderCell: (params) => (
            <Button
                variant="contained"
                color="error"
                onClick={() => {
                    if (systemAdmin) {
                        dispatch({ type: "SYSTEM_ADMIN_DELETE_USER", payload: params.row.id })
                    }
                    else {
                        dispatch({ type: "ADMIN_DELETE_USER", payload: params.row.id })
                        dispatch({ type: 'GRAB_GROUP_ADMIN', payload: user, role: role.id })
                    }
                }}
            >
                Delete
            </Button>
        )}
    ]
    let data = [];
    props.data.map((x, i) => {
        return data.push({id: x.id, userName: x.userName, email: x.email, emailConfirmed: `${x.emailConfirmed}`, phoneNumber: x.phoneNumber})
    })
    const userOpen = () => {
        setOpenUser(true)
    }
    const userClose = () => {
        setOpenUser(false)
    }

    const handleSave = () => {
        const objId = Object.keys(editRowsModel)[0]
        const obj = Object.values(editRowsModel)[0]
        const item = {
            Id: objId,
            Email: obj.email.value,
            UserName: obj.userName.value,
            PhoneNumber: obj.phoneNumber.value,
            EmailConfirmed: Boolean(true)
        };
        fetch('/api/user/'+ objId, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(item)
        })
        .then(response => {
            if(response.status === 200){
                return dispatch({type: "GRAB_ADMIN"})
            }
        })
        .catch(error => console.error('Unable to update user.', error));
    }
          
    const handleEditRowsModelChange = React.useCallback((model) => {
        setEditRowsModel(model);
      }, []);

    return (
        <Box> 
            <Box
                style={{marginBottom: "15px", display: 'flex', justifyContent: 'space-between'}}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={userOpen}
                >
                    <Plus/> User
                </Button>
                {Object.keys(editRowsModel).length !== 0? 
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                >
                    <Save/> save
                </Button>
                : ''}
            </Box>
            <Modal
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                open={openUser}
                onClose={userClose}
            >
                <NewUser close={userClose} groups={groups} />
            </Modal>
            <Card sx={{ height: 800 }}>
                <DataGrid
                    title="Users"
                    editRowsModel={editRowsModel}
                    editMode="row"
                    onRowEditCommit={handleSave}
                    onEditRowsModelChange={handleEditRowsModelChange}
                    columns={columns}
                    rows={data}
                    components={{
                        Toolbar: GridToolbar
                    }}
                />
            </Card>
        </Box>
    )
}

export default UserTable;