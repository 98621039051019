import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    AppBar,
    Badge,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    LinearProgress
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import Logo from '../../../src/components/Logo';
import { useSelector } from 'react-redux';

const TopBar = ({ className, onMobileNavOpen }) => {
  const [notifications] = useState([]);
  const loading = useSelector((state) => state.deviceList.devicePage)

    return (
        <AppBar sx={{ backgroundColor: '#0e5e8a' }} elevation={0} >
            <Toolbar>
                <RouterLink to="/app/dashboard">
                    <Logo />
                </RouterLink>
                <Box flexGrow={1} />
                <Hidden>
                    <IconButton color="inherit" size="large">
                        <Badge
                            badgeContent={notifications.length}
                            color="default"
                            variant="dot"
                            overlap='rectangular'
                        >
                            <NotificationsIcon color="secondary" />
                        </Badge>
                    </IconButton>
                </Hidden>
                <Hidden mdUp >
                    <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
                        <MenuIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
            { loading ? <LinearProgress sx={{ height: 10 }} /> : '' }
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

export default TopBar;
