import { put, takeLatest} from 'redux-saga/effects';
import axios from 'axios';
import authService from "../../components/api-authorization/AuthorizeService";

var groups = [];
var allGroups = [];

function* SubGroupRecursive(x){
    let token = yield authService.getAccessToken();

    const subGroups = yield axios.get('/api/group/sub/' + x.id, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

    allGroups.push(x);

    if ( subGroups.data.length > 0 ) {
        x.subGroups = subGroups.data
        for (const x of subGroups.data) {
            yield SubGroupRecursive(x)
        }
    }
    else{
        return
    }
}

function* getData(action){
    try {
        groups = [];
        allGroups = [];
        let token = yield authService.getAccessToken();

        yield put({
            type: "SET_USER_TOKEN",
            payload: token
        });
        // Grabs all groups for user account
        const user = yield axios.get('/api/usergroup/' + action.payload.id, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
        // Sets all groups the user belongs to
        for (const x of user.data) {
            const group = yield axios.get('/api/group/' + x.groupId, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
            allGroups.push(group.data)
            const subGroups = yield axios.get('/api/group/sub/' + group.data.id, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
            if ( subGroups.data.length > 0 ){
                group.data.subGroups = subGroups.data;
                const found = groups.some(el => el.subGroups.some(x => x.name === group.data.name))
                if (!found) groups.push(group.data)
                for (const x of subGroups.data) {
                    yield SubGroupRecursive(x)
                }
            }
            else {
                const found = groups.some(el => el.name === group.data.name)
                if (!found) groups.push(group.data)
            }
        };

        yield put({
            type: "SET_USER_GROUPS",
            payload: groups
        });
        yield put({
            type: "SET_ALL_USER_GROUPS",
            payload: allGroups
        });
        yield put({
            type: "GET_CHART_DATA",
            payload: action.id
        });
        // Checks to see if you have any groups set to default to set that as Current Group
        let storageGroup = localStorage.getItem("group");
        storageGroup = JSON.parse(storageGroup)
        if ( storageGroup ) {
            yield put({
                type: 'CHANGE_GROUP',
                payload: storageGroup,
                user: action.payload
            })
        }
        else {
            let isDefault = groups[0]
            for (const x of groups) {
                if(x.isDefault === true){
                    isDefault = x
                }
            }
            // See ChangeGroupSaga.js for next steps

            yield put({
                type: 'CHANGE_GROUP',
                payload: isDefault,
                user: action.payload
            });
        }

    } catch (error) {
        console.log(`error with getting data:  ${error}`)
    }
}

function* getUsersForGroups(action){
    try {
        let usersInGroup = []
        let token = yield authService.getAccessToken();

        const groupUsers = yield axios.get(`/api/usergroup/group/${action.payload}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }})

        if(groupUsers.data.length > 0){
            for (const x of groupUsers.data) {
                const getUserData = yield axios.get(`/api/User/${x.applicationUserId}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }})
                if(getUserData.data != "")
                {
                    const userGroupRole = yield axios.get(`/api/usergroup/role/${getUserData.data.id}/${action.payload}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
                    const userRole = yield axios.get(`/api/GroupRole/${userGroupRole.data.groupRoleId}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}`}})
                    getUserData.data.role = userRole.data
                    usersInGroup.push(getUserData.data)
                }
            }
        };

        yield put({
            type: "SET_USERS_IN_GROUP",
            payload: usersInGroup
        });
        
    } catch (error) {
        console.log(`Error grabbing users group: ${error}`)
    }
}

function* Saga(){
    yield takeLatest("GET_USER_DATA", getData);
    yield takeLatest("GET_USERS_FOR_GROUP", getUsersForGroups);
}

export default Saga;