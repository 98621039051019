import React from 'react';
import {
    TextField,
    Button,
    Typography,
    styled
} from '@mui/material';
import { connect, useSelector } from "react-redux"

const Paper = styled('div')({
    backgroundColor: 'white',
    width: '60%',
    height: '60%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    border: '1px solid black'
})

const MyButton = styled(Button)({
    width: '30%',
    minHeight: '50px'
})

const Input = styled(TextField)({
    margin: '10px'
})

const CreateFamily = React.forwardRef((props, ref) => {
    const [name, setName] = React.useState(null);
    const token = useSelector((state) => state.setUser.token);

    const handleChange = (type, event) => {
        return setName(event.target.value);
    }

    const handleSubmit = () => {
        const item = {
            Name: name,
          };
        fetch('/api/HardwareFamily', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization' : `Bearer ${token}`
            },
            body: JSON.stringify(item)
          })
            .then(response => {
                if(response.status === 200){
                    props.dispatch({type: "GRAB_ADMIN"})
                }
            })
            .catch(error => console.error('Unable to add item.', error));
        }
    
    return (
        <Paper>
            <div>
                <Typography
                    variant="h1"
                >
                    ADD HARDWARE FAMILY
                </Typography>
            </div>
            <div
                style={{
                    width: '100%',
                    textAlign: 'center',
                }}
            >
                <Input
                    required
                    id="name"
                    label="Name"
                    defaultValue={name}
                    variant="outlined"
                    onChange={(event) => handleChange('name', event)}
                />
                <br />
            </div>
            <div 
                style={{
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <MyButton
                    variant="contained"
                    color="primary"
                    onClick={props.close}
                >
                    Cancel
                </MyButton>
                <MyButton
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    Sumbit
                </MyButton>
            </div>
        </Paper>
    )
})

export default connect()(CreateFamily);