async function process(files) {
    const file = files;
    const reader = new FileReader();
    var pgns = [];
    var total = 0;
    let done = false;
    const utils = new Utils();
    let lastPgn;

    reader.onload = function(progressEvent){
        var lines = this.result.split('\n');
        for ( var line = 0; line < lines.length; line++ ) {
            if( lines[line].startsWith("BO_") ) {
                let pgn = new PGN(lines[line]);
                if( pgn ) {
                    total++
                    pgns.push(pgn);
                    lastPgn = pgn;
                }
            } 
            if ( lines[line].startsWith("SG_") || lines[line].startsWith(" SG_") ) {
                let spn = new SPN(lastPgn, lines[line]);
                if( spn ) {
                    total++
                    lastPgn.SPNs.push(spn);
                }
            }
        }
        done = true;
    };
    reader.readAsText(file);

    while( !done ){
        await utils.sleep(500);
    }

    return({pgns: pgns, total: total});
}

class PGN {
    constructor(BO_Line) {
        // console.log(BO_Line)
        let lineParts = BO_Line.split(' ');
        if(lineParts[0] !== "BO_") {
            console.log("ERROR: Improper line used to create PGN");
            return null;
        }
        this.id = lineParts[1];
        this.canId = lineParts[1];
        this.name = lineParts[2].split(':')[0];
        this.byteLength = lineParts[3]
        this.sender = lineParts.length > 4 ? lineParts[4].trim() : 'Vector__XXX';
        this.SPNs = [];
    }
    
    getHtml() {
        // console.log(' - PGN');
        if(this.ByteLength !== 8) return;
        let canId = parseInt(this.CanId).toString(16);
        let result = {
            id: canId,
            canId: canId,
            name: this.name,
            byteLength: this.byteLength,
            sender: this.sender,
        }
        this.SPNs.forEach( (spn) => {
            result = result + spn.getHtml();
        });
        return result;
    }
  }
  
  class SPN {
    constructor(pgn, SG_Line) {
        if(!SG_Line.startsWith(" SG_")) {
            console.log("ERROR: Improper line used to create SPN");
            return null;
        }
        let top = SG_Line.split(' : ');
        this.name = top[0].slice(5);
        let level2 = top[1].split(' ');
        this.bitStart = level2[0].split('|')[0];
        this.bitLength = level2[0].split('|')[1].split('@')[0];
        if(level2[0].split('|')[1].includes('@1')) {
            this.endianness = 1;
        }
        else {
            this.endianness = 0;
        }
        if(level2[0].split('|')[1].includes('+')) {
            this.signed = false;
        }
        else if(level2[0].split('|')[1].includes('-')) {
            this.signed = true;
        }
        this.scale = level2[1].split('(')[1].split(')')[0].split(',')[0];
        this.offset = level2[1].split('(')[1].split(')')[0].split(',')[1];
        this.min = level2[2].split('[')[1].split(']')[0].split('|')[0];
        this.max = level2[2].split('[')[1].split(']')[0].split('|')[1];
        this.unit = level2[3].split('"')[1].split('"')[0];
        this.receiver = level2.length > 5 ? level2[4] : 'Vector__XXX';
        this.id = this.name;
    }
    
      getHtml() {
        //console.log(' - - SPN');
        if(this.endianness === "little")
        {
            if( (this.bitLength % 8) !== 0)
            {
                if(this.bitLength > 8)
                {
                  console.log("spn: " + this.name);
                }
            }
        }
        let result = {
            id: this.id,
            name: this.Name,
            bitStart: this.BitStart,
            bitLength: this.BitLength,
            endianness: this.Endianness,
            scale: this.Scale,
            offset: this.Offset,
            min: this.Min,
            max: this.Max,
            unit: this.Unit,
            receiver: this.Receiver
        }
        return result;
    }
  }

  class Utils {
	// constructor() {}
	
	sleep(ms) {
	  return new Promise(resolve => setTimeout(resolve, ms));
	}
	
	downloadObjectAsJson(exportObj, exportName){
      var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
      var downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute("href",     dataStr);
      downloadAnchorNode.setAttribute("download", exportName + ".json");
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    }
}

  
export default process;