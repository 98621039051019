import React from "react";
import {
    Box,
    Container,
    Tab,
    Tabs,
    AppBar,
    Card,
    Backdrop,
    CircularProgress,
} from "@mui/material";
import Page from '../../components/Page';
import Group from './Groups/index';
import Firmware from './Firmware/index';
import Hardware from './Hardware/index';
import { connect, useSelector } from 'react-redux';
import UserTable from './Users/index';
import DeviceTables from "./Devices";
import theme from "../../theme";


function TabPanel(props) {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
        >
            {value === index && (
                <Box sx={{ paddingTop: theme.spacing(1)}} >
                    {children}
                </Box>
            )}
        </div>
    );
}

const Admin = (props) => {
    const [value, setValue] = React.useState(0);
    const data = useSelector((state) => state.admin.hardwareList);
    const loading = useSelector((state) => state.admin.adminloading);
    const groups = useSelector((state) => state.admin.adminGroups);
    const users = useSelector((state) => state.admin.users);
    const devices = useSelector((state) => state.admin.devices);
    const systemAdmin = useSelector((state) => state.setUser.systemAdmin);
    const user = useSelector((state) => state.setUser.setUser);
    const role = useSelector((state) => state.setUser.currentRole);

    React.useEffect(() => {
        if ( systemAdmin ) {
            props.dispatch({ type: "GRAB_ADMIN" })
        }
        else {
            props.dispatch({
                type: 'GRAB_GROUP_ADMIN',
                payload: user,
                role: role.id
            })
        }
      },[]);
      React.useEffect(() => {
        if ( (window.location.pathname === '/app/admin' && role.name !== 'Admin') & !systemAdmin ) {
            window.location.pathname = '/app/dashboard'
        }
    }, [role]);

    const handleChange = (event, newValue) => {
        setValue(newValue)
    };
    
    if(loading === false){
    return (
        <Page title="Admin">
            <Container maxWidth={false} sx={{ paddingTop: theme.spacing(1) }}>
                <Card>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            <Tab label="Groups" />
                            <Tab label="Users"/>
                            <Tab label="Devices"/>
                            { systemAdmin === true ?
                                <Tab label="Firmware" />
                            : "" }
                            { systemAdmin === true ?
                                <Tab label="Hardware" />
                            : "" }
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <Group data={groups}/>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <UserTable data={users}/>
                    </TabPanel>
                    <TabPanel value={value} index={2} >
                        <DeviceTables data={devices}/>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Firmware data={props.data.firmwareList}/>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <Hardware data={data}/>
                    </TabPanel>
                </Card>
            </Container>
        </Page>
    )
    }else{
        return(
            <Container
                maxWidth={false}
                sx={{
                    display:"flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center"
                }}
            >
            <Backdrop
              sx={{ color: '#fff' }}
              open={true}
            >
              <CircularProgress color="primary" thickness={5} size={100}/>
            </Backdrop>
          </Container>
        )
    }
}
const mapStateToProps = (state) => ({
    data: state.admin
  })
export default connect(mapStateToProps)(Admin);