import React from "react";
import {
    Container,
    ToggleButtonGroup,
    ToggleButton,
    Box,
    Button,
    Modal,
    Card,
    Skeleton,
    Menu,
    MenuItem,
    TextField
} from "@mui/material";
import { styled } from '@mui/system'
import Page from "../../../src/components/Page";
import { WidthProvider, Responsive } from 'react-grid-layout';
import { useDispatch, useSelector } from "react-redux";
import theme from "../../theme";
import { Plus, MoreHorizontal, Trash2, Edit, RefreshCw } from "react-feather";
import "../../../node_modules/react-grid-layout/css/styles.css"
import "../../../node_modules/react-resizable/css/styles.css"
import Widget from "./Widget";
import ChartService from "../../utils/DashboardChartService";
import axios from "axios";
import Toast from "../../mixins/Toast";
import { CircularProgress } from "@mui/material";

const MyPage = styled(Page)({
    backgroundColor: theme.palette.background,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3) 
})

const ResponsiveGridLayout = WidthProvider(Responsive)

const Dashboard = () => {
  const [alignment, setAlignment] = React.useState('View');
  const [edit, setEdit] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  const [newName, setNewName] = React.useState('');
  const [editName, setEditName] = React.useState(false);
  const [test] = React.useState({
    isDraggable: true,
    isResizable: true,
    className: 'layout',
    items: 8,
    rowHeight: 30,
    onLayoutChange: function(){},
    cols: 12
  })
  const [anchor, setAnchor] = React.useState(null);
  const filterRef = React.useRef();
  const token = useSelector((state) => state.setUser.token);
  const isLoading = useSelector((state) => state.DashboardReducer.loading);
  const chartData = useSelector((state) => state.DashboardReducer.allChartData);
  const user = useSelector((state) => state.setUser.setUser);
  const dispatch = useDispatch();
  const open = Boolean(anchor);
  
  const handleClick = (event, id) => {
        setDeleteId(id)
        setAnchor(event.currentTarget);
  }

  const handleMenuClose = () => {
     setAnchor(null);
  }

  const handleChange = (event, newAlignment) => {
    if ( newAlignment !== null ) {
        if ( newAlignment === "Edit" ) {
            setEdit(true)
        }
        else {
            setEdit(false)
        }
        setAlignment(newAlignment)
    }
  };

  const handleClose = () => {
     setModal(false);
  }

  const handleLayoutStop = (layout) => {
    for ( const x of layout ) {
        const item = {
            Id: x.i,
            w: x.w,
            h: x.h,
            x: x.x,
            y: x.y
        }
        axios.put('/api/Dashboard', item, {
            headers: {
            'Authorization': 'Bearer ' + token
            }
        })
      .catch((error) => console.log(`error handling layout save: ${error}`))
    }
    // send put updating layout in database
  }

  const onLayoutChange = (layout) => {
     test.onLayoutChange(layout);
  }

  const stopPropagation = e => {
    switch (e.key) {
        case "ArrowDown":
        case "ArrowUp":
        case "Home":
        case "End":
            break;
        default:
            e.stopPropagation();
    }
  }

  const moveFocusToInput = e => {
    if (e.key === "Tab" || e.key === "ArrowRight") {
        e.stopPropagation();
        e.preventDefault();
        filterRef.current.focus();
    }
  }
  
    return (
        <MyPage title="Dashboard">
            <Container maxWidth="xl">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                <Box sx={{width: '110px', marginLeft: '10px'}}>
                    <Button
                        sx={{ height: '56px', color: 'white' }}
                        onClick={() => dispatch({ type: 'GET_CHART_DATA', payload: user.id })}
                        variant="contained"
                    >
                        <RefreshCw/>
                    </Button>
                </Box>
                <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                >
                    <ToggleButton value="Edit" >Edit</ToggleButton>
                    <ToggleButton value="View" >View</ToggleButton>
                </ToggleButtonGroup>
                <Button
                    sx={{marginRight: '10px'}}
                    color="primary"
                    variant="contained"
                    onClick={() => setModal(true)}
                >
                    <Plus/> Widget
                </Button>
                </Box>
                <Modal
                    open={modal}
                    onClose={handleClose}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                   <Widget close={handleClose} />
                </Modal>
                { chartData.length > 0 ?
                    <ResponsiveGridLayout 
                        className="layout"
                        breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                        cols={{xl: 12, lg: 12, md: 12, sm: 6, xs: 6, xxs: 6}}
                        onLayoutChange={onLayoutChange}
                        rowHeight={30}
                        onDragStop={(drag) => handleLayoutStop(drag)}
                        onResizeStop={(size) => handleLayoutStop(size)}
                        isResizable={edit}
                        isDraggable={edit}
                    >
                        {chartData.map((x) => {
                            return (
                                <Card key={x.id} style={{ border: '2px solid #0e5e8a' }} data-grid={{x: x.layout.x, y: x.layout.y, h: x.layout.h, w: x.layout.w}}>
                                    <div style={{ position: 'absolute', display: 'flex', flexDirection: 'row-reverse', width: '100%', zIndex: '6' }}>
                                    <Button 
                                        sx={{ padding: '0px'}}
                                        onClick={(event) => handleClick(event, x.id)}  
                                    >
                                        <MoreHorizontal/>
                                    </Button>
                                    <Menu
                                        anchorEl={anchor}
                                        open={open}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem
                                        sx={{ color: 'blue' }}
                                        >
                                        <Button
                                            color="info"
                                            variant="text"
                                            startIcon={<Edit/>}
                                            onClick={() => setEditName(!editName)}
                                        >
                                            Edit Name
                                        </Button>
                                        </MenuItem> 
                                        { editName ? 
                                        [
                                            <MenuItem
                                            onKeyDown={moveFocusToInput}
                                            >
                                            <TextField
                                                sx={{ width: '100%', maxWidth: '190px' }}
                                                type={"text"}
                                                onKeyDown={stopPropagation}
                                                onChange={(event) => setNewName(event.target.value)}
                                            />
                                            </MenuItem>,
                                            <MenuItem
                                            sx={{ display: 'flex', justifyContent: 'center' }}
                                            >
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                if (newName === '') {
                                                    return Toast.fire({
                                                    icon: 'error',
                                                    title: 'please enter vaild name'
                                                    })
                                                }
                                                else {
                                                    axios.put('/api/Dashboard/Name', {Id: deleteId, name: newName}, {headers: { 'Authorization': 'Bearer ' + token }})
                                                    .then(() => {
                                                    dispatch({
                                                        type: 'GET_CHART_DATA',
                                                        payload: user.id
                                                    })
                                                    })
                                                    .then(() => {
                                                    setAnchor(false);
                                                    setEditName(false);
                                                    Toast.fire({
                                                        icon: 'success',
                                                        title: 'Name Changed'
                                                    })
                                                    })
                                                    .catch((error) => console.log(`error handling layout save: ${error}`))
                                                }
                                                }}
                                            >
                                                Save
                                            </Button>
                                            </MenuItem>
                                        ]
                                        : '' }
                                        <MenuItem
                                        sx={{ color: 'red' }}
                                        >
                                        <Button 
                                            color="error" 
                                            variant="text" 
                                            startIcon={<Trash2/>}
                                            onClick={() => {
                                            axios.delete(`/api/Dashboard/${deleteId}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }})
                                            .then(() => {
                                                setAnchor(null)
                                            })
                                            .then(() => dispatch({
                                                type: 'GET_CHART_DATA',
                                                payload: user.id
                                            }))
                                            .then(() => Toast.fire({
                                                icon: 'success',
                                                title: 'widget removed'
                                            }))
                                            .then(() => handleMenuClose)
                                            .catch((error) => console.log(`error removing widget: ${error}`))
                                            }}
                                        >
                                            Remove
                                        </Button>
                                        </MenuItem>
                                    </Menu>
                                    </div>
                                    { isLoading ? 
                                    <Skeleton sx={{ height: '100%', width: '100%'}} animation="wave" variant="rectangular" />
                                    :
                                    <ChartService data={x} />
                                    }
                                </Card>
                            )
                        })}
                    </ResponsiveGridLayout>
                : 
                isLoading ?
                    <div style={{ height: '100%', width: '100%', marginTop: '30px', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                        <CircularProgress
                            size={50}
                            thickness={4}
                        />
                    </div>
                : 
                    <div style={{marginTop: '20px'}}>
                        <h4 style={{textAlign: 'center'}}>
                        Nothing to show add widget to start viewing data
                        </h4>
                    </div>
                }
            </Container>
        </MyPage>
    );
};

export default Dashboard;