import React from "react";
import {
    Box,
    Button,
    FormControl,
    IconButton,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
    Select,
    Checkbox,
    FormControlLabel
} from "@mui/material";
import { DataGrid, GridFooter} from "@mui/x-data-grid";
import { Edit, PlusCircle } from "react-feather";
import renderCellExpand from "../../../utils/DataCellExpansion";
// import axios from "axios";
//import Toast from "../../../mixins/Toast";
import { useSelector, useDispatch } from "react-redux";
//import { number } from "prop-types";
import { SignumUtils } from '../../../utils/SignumUtils';
import Swal from "sweetalert2";

let example = {
    id: 0,
    name: '',
    bitStart: 0,
    bitLength: 0,
    endiannessType: 1,
    scale: 1,
    offset: 0,
    min: 0,
    max: 0,
    unit: '',
    receiver: '',
    signed: false
}



const PgnDetails = (props) => {
    const { pgn, spn } = props;
    // const constDisplayPgn = '' + pgn + ' (' +  pgn.toString(16) + ')';
    const token = useSelector((state) => state.setUser.token);
    const [edit, setEdit] = React.useState(false);
    const [spnEdit, setSpnEdit] = React.useState(false);
    const [editRowsModel, setEditRowsModel] = React.useState({});
    const [edited, setEdited] = React.useState(false);
    const pgns = useSelector((state) => state.CanSPNReducer.CanPGN);
    const [editPgn, setEditPgn] = React.useState(pgns);
    //const testSPN = useSelector((state) => state.CanSPNReducer.CanSPN);
    const [rows, setRows] = React.useState(spn);
    const [index, setIndex] = React.useState(1);
    const dispatch = useDispatch();
    const currentRole = useSelector((state) => state.setUser.currentRole);
    // console.log("editpgn, ", editPgn);

    // console.log("spn: ", spn, "pgns, ", pgns, "pgn:", pgn);

    // console.log("rows: ", rows);

    React.useEffect(() => {
        setEditPgn(pgns)
        setRows(spn);
    },[pgn,spn])

    function customFooter() {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'transparent' }}>
                <Tooltip title="Add Row">
                    <IconButton 
                        sx={{ margin: '5px' }}
                        disabled={!spnEdit}
                        onClick={() => {
                            setIndex(index + 1);
                            return setRows([...rows, {...example, id: index}]);
                        }}
                    >
                        <PlusCircle/>
                    </IconButton>
                </Tooltip>
                <GridFooter/>
            </Box>
        )
    }

    const columns = [
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 150, renderCell: renderCellExpand, editable: spnEdit },
        { field: 'bitStart', headerName: 'Bit Start', flex: 1, minWidth: 100, editable: spnEdit },
        { field: 'bitLength', headerName: 'Bit Length', flex: 1, minWidth: 100, editable: spnEdit },
        { field: 'endiannessType', headerName: 'Endianness Type', flex: 1, minWidth: 100, editable: spnEdit, renderEditCell: (params) => {
            return (
                <FormControl fullWidth>
                    <Select
                        value={params.row.endiannessType}
                        label="Endianness"
                        sx={{ height: '100%' }}
                        disabled={!spnEdit}
                        variant="standard"
                        onChange={(event) => {
                            const newItems = rows.map(item => {
                                if (params.row.id === item.id) {
                                    Object.values(editRowsModel)[0].endiannessType.value = event.target.value
                                    return {...item, endiannessType: event.target.value}
                                }

                                return item;
                            })
                            setRows(newItems);
                        }}
                    >
                        <MenuItem value={0}>Big</MenuItem>
                        <MenuItem value={1}>Little</MenuItem>
                    </Select>
                </FormControl>
            )
        }, renderCell: (params) => {
            return (params.row.endiannessType === 0 ? "Big" : "Little")
        }},
        { field: 'receiver', headerName: 'Receiver', flex: 1, minWidth: 100, renderCell: renderCellExpand, editable: spnEdit },
        { field: 'max', headerName: 'Max', flex: 1, minWidth: 100, editable: spnEdit },
        { field: 'min', headerName: 'Min', flex: 1, minWidth: 100, editable: spnEdit },
        { field: 'scale', headerName: 'Scale', flex: 1, minWidth: 100, editable: spnEdit },
        { field: 'offset', headerName: 'Offset', flex: 1, minWidth: 100, editable: spnEdit },
        { field: 'unit', headerName: 'Unit', flex: 1, minWidth: 100, editable: spnEdit },
        { field: 'isSigned', headerName: 'Signed', flex: 1 , minWidth: 100, editable: spnEdit, renderEditCell: (params) => (
            <FormControlLabel
            label={JSON.stringify(params.row.isSigned)}
            control={
                <Checkbox 
                    checked={params.row.isSigned}
                    onClick={(event) => {
                        const newItems = rows.map(item => {
                            if (params.row.id === item.id) {
                                Object.values(editRowsModel)[0].isSigned.value = event.target.checked
                                return {...item, isSigned: event.target.checked}
                            }
                            return item;
                        })
                        setRows(newItems)
                    }} 
                />
            }
            />
        )},
        { field: 'delete', headerName: 'Delete', flex: 1, minWidth: 150, renderCell: (params) => (
            <Button
                variant="contained"
                color="error"
                onClick={() => {
                    Swal.fire({
                        title: "ARE YOU SURE",
                        text: `you want to delete SPN ${params.row.name}?`,
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: 'DELETE',
                        confirmButtonColor: '#dd6b55',
                        cancelButtonText: 'Cancel',
                    })
                        .then((willDelete) => {
                            if (willDelete.isConfirmed) {
                                dispatch({ type: 'DELETE_SPN', payload: params.row, token: token });
                            }                            
                        })             
                }}
                >
                    DELETE
                </Button>
            ) }
    ];
    
    const handlePgnEdit = (name, value) => {
        switch (name) {
            case "name":
                return setEditPgn({...editPgn, name: value});
            case "byteLength":
                return setEditPgn({...editPgn, byteLength: value});
            case "canIdent":
                return setEditPgn({...editPgn, canIdent: value.toString(10)});
            case "sender":
                return setEditPgn({...editPgn, sender: value});
            default:
                break;
        }
    }

    const handlePgnCommit = () => {
        const dataSend = {
            Name: editPgn.name,
            ByteLength: Number(editPgn.byteLength),
            Sender: editPgn.sender,
            CanIdent: parseInt(editPgn.canIdent,16)
        }
        // console.log("data before dispatch ", pgn.canFileId, parseInt(pgn.canFileId, 16 ))
        dispatch({ type: 'EDIT_PGN', id: editPgn.id, payload: dataSend, fileId: pgn.canFileId, token})
        // axios.put(`/api/CanPGN/${editPgn.id}`, {
        //     Name: editPgn.name,
        //     ByteLength: Number(editPgn.byteLength),
        //     Sender: editPgn.sender,
        //     CanIdent: Number(editPgn.canIdent)
        // }, {headers: {'Authorization': `Bearer ${token}`}})
        // .then(() => dispatch({ type: "GRAB_FILE_PGN", payload: pgn.canFileId }))
        // .then(() => Toast.fire({ icon: 'success', title: 'PGN Updated' }))
        // .then(() => setEdited(true))
        // .catch((error) => {
        //     console.log(error)
        //     Toast.fire({ icon: 'error', title: "Error Updating" })
        // })
    }

    const handleSpnEdit = () => {
        const objId = Object.keys(editRowsModel)[0];
        const obj = Object.values(editRowsModel)[0];
        console.log("objId, ", objId, "obj, ", obj);
        if (objId < 100) {
            // console.log("111111 obj <100");
            console.log("pgns: ", pgns);
            const dataSend = {
                CanPGNId: editPgn.id,
                Name: obj.name.value,
                BitStart: parseInt(obj.bitStart.value),
                BitLength: parseInt(obj.bitLength.value),
                EndiannessType: parseInt(obj.endiannessType.value),
                Receiver: obj.receiver.value,
                Max: parseFloat(obj.max.value),
                Min: parseFloat(obj.min.value),
                Scale: parseFloat(obj.scale.value),
                Offset: parseFloat(obj.offset.value),
                Unit: obj.unit.value,
                isSigned: obj.isSigned.value
            }
            dispatch({ type: "NEW_CAN_SPN", payload: dataSend, token, PGNId: pgns.id, canFile: pgn.canFileId })
            // axios.post(`/api/CanSPN`, {
            //     CanPGNId: editPgn.id,
            //     Name: obj.name.value,
            //     BitStart: parseInt(obj.bitStart.value),
            //     BitLength: parseInt(obj.bitLength.value),
            //     EndiannessType: parseInt(obj.endiannessType.value),
            //     Receiver: obj.receiver.value,
            //     Max: parseFloat(obj.max.value),
            //     Min: parseFloat(obj.min.value),
            //     Scale: parseFloat(obj.scale.value),
            //     Offset: parseFloat(obj.offset.value),
            //     Unit: obj.unit.value,
            //     isSigned: obj.isSigned.value
            // },{headers: {'Authorization': `Bearer ${token}`}})
            // .then(() => dispatch({ type: "GRAB_FILE_PGN", payload: pgn.canFileId }))
            // .then(() => Toast.fire({ icon: 'success', title: 'Saved' }))
            // .catch((error) => {
            //     console.log(`error with saving edit of can element: ${error}`)
            //     Toast.fire({ icon: 'error', title: 'Error updating SPN' })
            // })
        }
        else {
            // console.log("2222222 in the else");
            const dataSend = {
                Name: obj.name.value,
                BitStart: parseInt(obj.bitStart.value),
                BitLength: parseInt(obj.bitLength.value),
                EndiannessType: parseInt(obj.endiannessType.value),
                Receiver: obj.receiver.value,
                Max: parseFloat(obj.max.value),
                Min: parseFloat(obj.min.value),
                Scale: parseFloat(obj.scale.value),
                Offset: parseFloat(obj.offset.value),
                Unit: obj.unit.value,
                isSigned: obj.isSigned.value
            }
            dispatch({ type: "EDIT_CAN_SPN", payload: dataSend, id: objId, PGNId: pgns.id, canFile: pgn.canFileId, token})
            /*axios.put(`/api/CanSPN/${objId}`, {
                Name: obj.name.value,
                BitStart: parseInt(obj.bitStart.value),
                BitLength: parseInt(obj.bitLength.value),
                EndiannessType: parseInt(obj.endiannessType.value),
                Receiver: obj.receiver.value,
                Max: parseFloat(obj.max.value),
                Min: parseFloat(obj.min.value),
                Scale: parseFloat(obj.scale.value),
                Offset: parseFloat(obj.offset.value),
                Unit: obj.unit.value,
                isSigned: obj.isSigned.value
            },{headers: {'Authorization': `Bearer ${token}`}})
            .then(() => console.log("in the .then, ", row), dispatch({ type: 'FETCH_CAN_SPN', payload: spn.CanPGNId, token: token }))
            .then(() => dispatch({ type: "GRAB_FILE_PGN", payload: pgn.canFileId }))
            .then(() => Toast.fire({ icon: 'success', title: 'Saved' }))
            .catch((error) => {
                console.log(`error with saving edit of can element: ${error}`)
                Toast.fire({ icon: 'error', title: 'Error updating SPN' })
            })*/
        }
    };

    const handleEditRowsModelChange = React.useCallback((model) => {
        setEditRowsModel(model);
    }, []);

    const editPgnMode = (value) => {
        if (value === false && edited === false) setEditPgn(pgns)
        setEdit(value);
    }
    
    return (
        <>
            <Box p={1}>
                <Typography textAlign={"center"} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="h3">
                    PGN Info <IconButton size="sm" color="primary" disabled={currentRole.name === "Read"} onClick={() => editPgnMode(!edit)}><Edit/></IconButton>
                </Typography>
                { edit ? <Typography color={"red"} textAlign={"center"} variant="body1"> Editing Mode </Typography> : "" }
            </Box>
            <Box p={3} sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <TextField variant="outlined" disabled={!edit} label="Name" value={editPgn.name ? editPgn.name : ""} onChange={((event) => handlePgnEdit("name", event.target.value))}/>
                <TextField variant="outlined" disabled={!edit} label="Byte Length" value={editPgn.byteLength ? editPgn.byteLength : ""} onChange={((event) => handlePgnEdit("byteLength", event.target.value))}/>
                <TextField variant="outlined" disabled={!edit} label="PGN ID" value={editPgn.canIdent ? SignumUtils.pgnIdDisplayValue(editPgn.canIdent):""} onChange={((event) => handlePgnEdit("canIdent", event.target.value.toString(10)))}/>
                <TextField variant="outlined" disabled={!edit} label="Sender" value={editPgn.sender ? editPgn.sender : ""} onChange={((event) => handlePgnEdit("sender", event.target.value))}/>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                { edit ? <Button variant="contained" sx={{ width: '50%' }} onClick={handlePgnCommit} color="primary"> Update </Button> : "" }
            </Box>
            <Box p={2}>
                <Typography textAlign={"center"} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="h3">
                    List of SPNs <IconButton color="primary" size="sm" disabled={currentRole.name === "Read"} onClick={() => setSpnEdit(!spnEdit)}><Edit/></IconButton>
                </Typography>
                { spnEdit ? <Typography color={"red"} textAlign={"center"} variant="body1"> Editing Mode </Typography> : "" }
            </Box>
            <Box p={2} sx={{ height: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    editRowsModel={editRowsModel}
                    onRowEditCommit={handleSpnEdit}
                    onEditRowsModelChange={handleEditRowsModelChange}
                    components={{
                        Footer: customFooter
                    }}
                />
            </Box>
        </>
    )
};

export default PgnDetails;