import { combineReducers } from "redux";

const EngGraph = (state = [], action) => {
    switch (action.type) {
        case "SET_ENG_GRAPH":
            return action.payload;
        default:
            return state;
    }
}

const EngExtraData = (state = [], action) => {
    switch (action.type) {
        case "SET_ENG_EXTRA_DATA":
            return action.payload;
        default:
            return state;
    }
}

const EngLoading = (state = false, action) => {
    switch (action.type) {
        case "SET_ENG_LOADING":
            return action.payload;
        default:
            return state;
    }
}

export default combineReducers({
    EngGraph,
    EngExtraData,
    EngLoading,
});