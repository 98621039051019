import React from 'react';
import {
    TextField,
    Button,
    Box,
    Autocomplete
} from '@mui/material';
import { styled } from '@mui/system';
import theme from '../../theme';
import { useDispatch, useSelector } from 'react-redux';
import Toast from '../../mixins/Toast';
import axios from 'axios';

const Text = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
})

const ButtonGroup = styled(Box)({
    display: 'flex',
    width: '70%',
    justifyContent: 'space-between',
    marginTop: '20px'
})

const ButtonContainer = styled(Box)({
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
})

const Paper = styled(Box)({
    position: 'absolute',
    width: '50%',
    height: '50%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center'
})

const Content = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'center',
    width: '100%'
})


const NewGroup = React.forwardRef((props, ref) => {
    const [parent, setParent] = React.useState(null);
    const [name, setName] = React.useState(null);
    const [check, setCheck] = React.useState(false);
    const options = useSelector((state) => state.setUser.groups);
    const currentGroup = useSelector((state) => state.setUser.currentGroup);
    const user = useSelector((state) => state.setUser.setUser);
    const dispatch = useDispatch();
    const token = useSelector((state) => state.setUser.token);


    const handleChange = (event) => {
        setName(event.target.value)
    }
    const handleCheck = (event) => {
        setCheck(event.target.checked);
    }

    const handleSubmit = () => {
        if( name === null ) {
            return Toast.fire({
                icon: 'error',
                title: 'Enter Group Name'
            })
        }
        else {
            axios.post(`/api/group/${user.id}`, {
                Name: name,
                ParentGroupId: parent.id,
                IsDefault: check
            },{headers: !token ? {} : { 'Authorization': `Bearer ${token}` }}).then(() => dispatch({type: 'GET_USER_DATA', payload: {id: user.id}}))
            .then(() => Toast.fire({
                icon: 'success',
                title: 'Group Created'
            }))
            .then(() => props.close(false))
            .catch((error) => console.log(error))
        }
    }


    return (
        <Paper>
            <Content>
                <h1 style={{ textAlign: "center", marginBottom: '20px' }}>Group Creation Menu</h1>
                <Text>
                    <TextField style={{ width: '35%' }} onChange={handleChange} label="Group name" variant="outlined" />
                    <Autocomplete
                        id="group-box"
                        options={options}
                        onChange={(event, newValue) => {
                            setParent(newValue)
                        }}
                        getOptionLabel={(option) => option.name}
                        style={{ width: '35%' }}
                        renderInput={(params) => <TextField {...params} label="Parent Group" variant="outlined" />}
                    />
                </Text>
                <ButtonContainer>
                    <ButtonGroup>
                        <Button onClick={() => props.close(false)} variant="contained" color="secondary">
                            cancel
                        </Button>
                        <Button 
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Accept
                        </Button>
                    </ButtonGroup>
                </ButtonContainer>
            </Content>
        </Paper>
    )
}
)
export default NewGroup