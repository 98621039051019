import React from 'react';
import {
    TextField,
    Button,
    Typography,
    Box,
    Grid,
    Select,
    MenuItem,
    InputLabel,
    FormControl
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux"
import { styled } from '@mui/system';
import Toast from '../../../mixins/Toast';
import axios from 'axios';

const MyButton = styled(Button)({
    width: '30%',
    minHeight: '50px'
})

const Paper = styled('div')({
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    border: '1px solid black',
    flexDirection: 'column'
})

const CreateFirmware = React.forwardRef((props, ref) => {
    const [minor, setMinor] = React.useState(null);
    const [file, setFile] = React.useState(null);
    const [major, setMajor] = React.useState(null);
    const [name, setName] = React.useState(null);
    const [revision, setRevision] = React.useState(null);
    const [type, setType] = React.useState(null);
    const [company, setCompany] = React.useState(null);
    const [selection, setSelection] = React.useState([]);
    const token = useSelector((state) => state.setUser.token);
    const dispatch = useDispatch();

    React.useEffect(() => {
        switch (company) {
            case 0:
                return setSelection([<MenuItem value={0}>STM</MenuItem>, <MenuItem value={1}>ESP</MenuItem>]);
            case 1:
                return setSelection([<MenuItem value={2}>Cab</MenuItem>, <MenuItem value={3}>Hub</MenuItem>]);
            case 2:
                return setSelection([<MenuItem value={4}>EVCM</MenuItem>]);
        }
    }, [company])

    const handleUpload = () => {
        if ( name === null ) return Toast.fire({ icon: 'error', title: 'Please enter name' })
        else if ( file === null ) return Toast.fire({ icon: 'error', title: 'Enter file path' })
        else if ( minor === null ) return Toast.fire({ icon: 'error', title: 'Enter minor version' })
        else if ( major === null ) return Toast.fire({ icon: 'error', title: 'Enter major version' })
        else if ( revision === null ) return Toast.fire({ icon: 'error', title: 'Enter revision version' })
        else if ( company === null ) return Toast.fire({ icon: 'error', title: 'Select Company' })
        else if ( type === null ) return Toast.fire({ icon: 'error', title: 'Select type' })
        let typeName = '';
        let companyName = '';
        //console.log(type, company)
        if ( type === 0 ) typeName = 'stm';
        else if ( type === 1 ) typeName = 'esp';
        else if ( type === 2 ) typeName = 'cab';
        else if ( type === 3 ) typeName = 'hub';
        else if ( type === 4 ) typeName = 'evcm';

        if ( company === 0 ) companyName = 'signum';
        else if ( company === 1 ) companyName = 'bobcat';
        else if ( company === 2 ) companyName = 'moog';

        const formData = new FormData();
        console.log(companyName , typeName)
        formData.append('file', file);
        formData.append('name', name);
        formData.append('majorVersion', major);
        formData.append('minorVersion', minor);
        formData.append('revisionVersion', revision);
        formData.append('controllerCompany', company);
        formData.append('companyName', companyName);
        formData.append('typeName', typeName);
        formData.append('controllerType', type);
        axios.post('/api/FirmwareVersion', formData, {headers: { 'Authorization': `Bearer ${token}` }})
        .then((response) => {
            if ( response.status === 200 ) Toast.fire({ icon: 'success', title: 'Upload Complete' });
            else Toast.fire({ icon: 'error', title: 'Error Uploading' });
        })
        .then(() => props.close())
        .then(() => dispatch({ type: "GRAB_ADMIN_FIRMWARE" }))
    }

    const handleChange = (type, event) => {
        switch (type) {
            case 'minor':
                return setMinor(event.target.value);
            case 'major':
                return setMajor(event.target.value);
            case 'name': 
                return setName(event.target.value);
            case 'revision':
                return setRevision(event.target.value);
            case 'company':
                return setCompany(event.target.value);
            case 'type':
                return setType(event.target.value);
            default:
                break;
        }
    };
    
    return (
        <Paper>
            <Grid container spacing={3} sx={{ width: '80%' }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                        textAlign={"center"}
                        variant="h1"
                    >
                        ADD Firmware Version
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        defaultValue={name}
                        variant="outlined"
                        onChange={(event) => handleChange('name', event)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                        required
                        fullWidth
                        id="majorVersion"
                        label="Major Version"
                        defaultValue={major}
                        variant="outlined"
                        type="number"
                        onChange={(event) => handleChange('major', event)}
                    />
                </Grid>
                {console.log(type)}
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                        required
                        fullWidth
                        id="minorVersion"
                        label="Minor Version"
                        defaultValue={minor}
                        variant="outlined"
                        type="number"
                        onChange={(event) => handleChange('minor', event)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                        required
                        fullWidth
                        id="revision"
                        label="Revision Version"
                        defaultValue={revision}
                        variant="outlined"
                        type="number"
                        onChange={(event) => handleChange('revision', event)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <FormControl fullWidth>
                        <InputLabel id='company'>Company</InputLabel>
                        <Select
                            label="Company"
                            fullWidth
                            id='company'
                            onChange={(event) => handleChange('company', event)}
                        >
                            <MenuItem value={0}>Signum</MenuItem>
                            <MenuItem value={1}>Bobcat</MenuItem>
                            <MenuItem value={2}>Moog</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <FormControl fullWidth>
                        <InputLabel id='type'>Type</InputLabel>
                        <Select
                            label="Type"
                            fullWidth
                            id='type'
                            onChange={(event) => handleChange('type', event)}
                        >
                            {selection}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField 
                        required
                        fullWidth
                        type={"file"}
                        onChange={(event) => setFile(event.target.files[0])}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <MyButton
                        fullWidth
                        variant="outlined"
                        color="primary"
                        onClick={props.close}
                    >
                        Cancel
                    </MyButton>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <MyButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleUpload}
                    >
                        Submit
                    </MyButton>
                </Grid>
            </Grid>
        </Paper>
    )
})

export default CreateFirmware;