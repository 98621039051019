import { Autocomplete, Box, Button, Input, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

function CanFileCreation({ setCanFile, groupIds }) {
    const [fileName, setFileName] = useState("");
    const dispatch = useDispatch();

    function handleSubmit() {
        console.log("submit", fileName);
        if (fileName !== "") {
            dispatch({ type: 'NEW_CAN_FILE', payload: fileName, groupIds })
            setCanFile(false);
        }
    }

    return (
        <Box
            sx={{ display: 'ce', flexDirection: 'column' }}
        >
            <Box>
                <label>Enter New Can File Name</label>
                <Input
                    variant='outlined'
                    label='Can File Name'
                    value={fileName}
                    sx={{ marginLeft: "100px" }}
                    // error={name}
                    onChange={(event) => setFileName(event.target.value)}
                />
                <Button onClick={() => handleSubmit()} variant="contained" > Submit </Button>
            </Box>
        </Box>
    )
}

export default CanFileCreation;