import React from 'react';
import {
    TableRow,
    IconButton,
    TableCell,
    Typography,
    TableBody,
    TableHead,
    Table,
    Collapse,
    Box
} from '@mui/material';
import {ArrowDown, ArrowUp} from 'react-feather';

const Row = (props) => {
    const { row, spn } = props;
    const [open, setOpen] = React.useState(false);
  
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                    >
                    {open ? <ArrowUp /> : <ArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell>{row.Name}</TableCell>
                <TableCell>{row.CanIdent}</TableCell>
                <TableCell>{row.ByteLength}</TableCell>
                <TableCell>{row.Sender}</TableCell>
                <TableCell>{row.canFile.name}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                        <Typography variant="h6" gutterBottom component="div">
                        SPNs
                        </Typography>
                        <Table size="small" aria-label="purchases">
                        <TableHead>
                            <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Bit Start</TableCell>
                            <TableCell>Bit Length</TableCell>
                            <TableCell>Endianness</TableCell>
                            <TableCell>Scale</TableCell>
                            <TableCell>Offset</TableCell>
                            <TableCell>Min</TableCell>
                            <TableCell>Max</TableCell>
                            <TableCell>Unit</TableCell>
                            <TableCell>Receiver</TableCell>
                            <TableCell>Signed</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {spn.map((spn) => (
                            <TableRow key={spn.id}>
                                <TableCell component="th" scope="row">{spn.name}</TableCell>
                                <TableCell>{spn.bitStart}</TableCell>
                                <TableCell>{spn.bitLength}</TableCell>
                                <TableCell>{spn.endiannessType}</TableCell>
                                <TableCell>{spn.scale}</TableCell>
                                <TableCell>{spn.offset}</TableCell>
                                <TableCell>{spn.min}</TableCell>
                                <TableCell>{spn.max}</TableCell>
                                <TableCell>{spn.unit}</TableCell>
                                <TableCell>{spn.receiver}</TableCell>
                                <TableCell>{JSON.stringify(spn.signed)}</TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                        </Table>
                    </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
  }

  export default Row;