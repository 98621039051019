import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import authService from "../../components/api-authorization/AuthorizeService";
import Swal from 'sweetalert2';

function* GetEngGraphData(action){
    try {
        // console.log('action payload', action.payload);
        let token = yield authService.getAccessToken();
        let goal = action.payload.ExternalId.length;
        let something = action.payload;
        let dataArray = [];
        
        for (let i=0; i < action.payload.ExternalId.length; i++){
            Swal.fire({
                title: "Loading...",
                imageUrl: '/static/images/loading-gif.gif',
                text: 'Fetching device ' + (i+1) + " of " + goal,
                showConfirmButton: false,
                backdrop: "false",
            })
            const filter = {...something, Id: action.payload.Id[i], ExternalId: action.payload.ExternalId[i]};
            let response = yield axios.post("/api/device/EngineeringService", filter, { headers: { 'Authorization': `Bearer ${token}` }})
            // console.log("response in graph data: ", response);
            dataArray.push(response.data);
        }
        Swal.close();
        // console.log('data array', dataArray);
        yield put({ type: 'SET_ENG_LOADING', payload: false})
        yield put({ type: 'SET_ENG_GRAPH', payload: dataArray });

    } catch (e) {
        Swal.close();
        console.error("error in get engineering graph data saga", e);
        yield put({ type: 'SET_ENG_LOADING', payload: false})
    }
}

function* EngineeringSaga(){
    yield takeLatest("FETCH_ENG_GRAPH", GetEngGraphData);

};
export default EngineeringSaga;