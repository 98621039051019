import React, { useState, useEffect } from 'react';
import {
    Box,
} from "@mui/material";
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Tooltip, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import ChartService from "../../../utils/DashboardChartService";
import moment from 'moment';
import { 
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridOverlay
} from '@mui/x-data-grid';
import Swal from 'sweetalert2';
// import LoadingGif from "../../../../public/static/images/loading-gif.gif"

const Graph = (props) => {
    const loadingMessage = "loading . . .";

    const graphData = useSelector((state) => state.EngGraph.EngGraph);
    const extraData = useSelector((state) => state.EngGraph.EngExtraData);
    const isLoading = useSelector((state) => state.EngGraph.EngLoading)
    let arrayGrid = []; // for the data grid
    let datasets = []
    let title = "No Title";
    //console.log("extra data in graph", extraData);
    //console.log("Loading", isLoading);
    // console.log("graph data", graphData);


    // if (isLoading === true){
    //     Swal.fire({
    //         title: "Loading...",
    //         text: 'may take several minutes depending on device usage within timeframe',
    //         imageUrl: '/static/images/loading-gif.gif',
    //         showConfirmButton: false,
    //         backdrop: "false",
    //     })
    // };
    // if (isLoading === false){
    //     Swal.close();
    // };

    let colorArray = ['#00B3E6', '#0e5e8a', '#33DAFF',
    '#4D80CC', '#4DB3FF', '#40F0E5', '#99FF99', '#FF6633', '#B34D4D',
    '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF', '#FFFF99'
    ]
    
    for (let j=0; j<graphData.length; j++){
        let arrayGraph = []; // for the graph, resets every time through
        if (graphData[j].length > 0) {
            for (let i=0; i<graphData[j].length; i++) {

                // for the graph
                arrayGraph.push({ x: moment.utc(graphData[j][i].created).local().format(), y: graphData[j][i].spnValue, commonName: graphData[j][i].commonName });
                graphData[j][i].id = i;
                graphData[j][i].spnValue = Number(graphData[j][i].spnValue).toFixed(1)
                if (graphData[j][i].spnUnit) {
                    graphData[j][i].valueUnits = `${graphData[j][i].spnValue} ${graphData[j][i].spnUnit}`;
                } else {
                    graphData[j][i].valueUnits = `${graphData[j][i].spnValue}`;
                }

                // for the data grid
                arrayGrid.push(graphData[j][i]);

            }
            // data sets for info
            // console.log('1 array graph j', DataGrid[j]);
            // console.log('2 array graph', arrayGraph);
            datasets.push({
                label: arrayGraph[0].commonName,
                fill: false,
                borderColor: colorArray[j],
                // backgroundColor: colorArray[j+1],
                data: arrayGraph,
                barPercentage: 0.5,
                barThickness: 6,
                maxBarThinkness: 8,
                minBarLength: 2,
                borderWidth: 2,
            })
        }
    }

    // console.log('array grid', arrayGrid);
    // console.log('data set', datasets);
    if (graphData.length > 0 && graphData[0].length > 0) { title = graphData[0][0].spn };
    
    let chartData = {
        datasets: datasets,
        type: "line",
        title: title,
        layout: { x: 0, y: 0, w: 6, h: 8 }
    }
    // console.log('chart data', chartData);
    
    const columns = [
        { field: 'commonName', headerName: 'Serial Number', flex: 1, minWidth: 150 },
        { field: 'pgn', headerName: 'PGN', flex: 1, minWidth: 150 },
        { field: 'spn', headerName: 'SPN', flex: 1, minWidth: 150 },
        { field: 'valueUnits', headerName: 'SPN Value', flex: 1, minWidth: 150 },
        // { field: 'canGroup', headerName: 'Can Group', flex: 1, minWidth: 150 },
        { field: 'created', headerName: 'Check-in', flex: 1, minWidth: 150, renderCell: (params) => (params.row.checkin === 'N/A' ? 'N/A' : moment.utc(params.row.created).local().format('MM/DD/YY, h:mm:ss.SSS a')) }
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    <GridToolbarExport />
                </Box>
            </GridToolbarContainer>
        );
    }

    //console.log("graphData in graph", graphData);

    return (
        <div>
            <Box sx={{ height: '550px', width: '100%', display: 'flex', margin: '20px' }}>
                <ChartService data={chartData} />
            </Box>
            <Box sx={{ height: '80%', width: '80%', display: 'flex', margin: '20px' }}>
                <DataGrid
                    key={arrayGrid.id}
                    style={{ position: 'absolute', width: '80%', height: '95%' }}
                    columns={columns}
                    rows={arrayGrid}
                    density="compact"
                    components={{
                        Toolbar: CustomToolbar
                    }}
                />
            </Box>
        </div>
    )
}
export default Graph;