import React from 'react';

const Logo = (props) => {
    return (
        <img
            alt="Logo"
            height="25%"
            width="25%"
            src="/static/signumWhite.png"
            // style={{backgroundColor: "rgba(255 ,255, 255, 0.4)", paddingLeft: "5px", paddingRight: "10px", paddingBottom: "3px", borderRadius: "15px" }}
            {...props}
        />
    );
};

export default Logo;
