import { combineReducers } from "redux";

const LandingData = (state = 0, action) => {
    switch (action.type) {
        case "SET_LANDING_DATA":
            return action.payload;
        default:
            return state;
    }
}

const CanTotalCount = (state = 0, action) => {
    switch (action.type) {
        case "SET_CAN_TOTAL":
            return action.payload;
        default:
            return state;
    }
}

const CanMonthCount = (state = 0, action) => {
    switch (action.type) {
        case "SET_CAN_MONTH":
            return action.payload;
        default:
            return state;
    }
}

const CanDayCount = (state = 0, action) => {
    switch (action.type) {
        case "SET_CAN_DAY":
            return action.payload;
        default:
            return state;
    }
}

export default combineReducers({
    LandingData,
    CanTotalCount,
    CanMonthCount,
    CanDayCount,
});