import { all } from 'redux-saga/effects';
import DataSaga from './getAllDevicesSaga';
import SystemAdminSaga from './SystemAdminSaga';
import GroupAdminSaga from './GroupAdminSaga';
import UserSaga from './userSaga'
import GroupChange from './ChangeGroupSaga';
import DashboardSaga from './DashboardSaga';
import RawSaga from './getRawDataSaga';
import Upload from './FileUploadSaga';
import FaultSaga from './getFaultDataSaga';
import CoreReportSaga from './CoreReportSaga';
import CanSPNSaga from './CanSpnSaga';
import EngineeringSaga from './EngineeringSaga';
import LandingData from './LandingSaga';

export default function* rootSaga() {
    yield all([
        DataSaga(),
        SystemAdminSaga(),
        UserSaga(),
        GroupChange(),
        DashboardSaga(),
        GroupAdminSaga(),
        Upload(),
        RawSaga(),
        FaultSaga(),
        CoreReportSaga(),
        CanSPNSaga(),
        EngineeringSaga(),
        LandingData(),
    ]);
}
