import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import NavBar from './NavBar';
import TopBar from './TopBar';
import GroupView from '../../views/group/';
import DashboardView from '../../views/dashboard';
import Devices from '../../views/devices';
import DataListView from '../../views/information/InformationtView'
import SettingsView from '../../views/settings/SettingsView';
import ChartsView from '../../views/charts';
import AdminView from '../../views/admin/index'
import FaultView from '../../views/Faults/index'
import CanGroup from '../../views/can';
import FleetManagementView from '../../views/FleetManagement'
import ServiceManagementView from '../../views/Service'
import EngineeringView from '../../views/EngineeringView'
import ReportView from '../../views/ReportsPage/Index';
import NotFoundView from '../../views/errors/NotFoundView';
import { 
    Box, 
    styled
} from '@mui/system';
import theme from '../../theme';
import { useSelector } from 'react-redux';
import Page from '../../components/Page';
import {
    Container,
    Backdrop,
    CircularProgress,
    Modal
} from "@mui/material";
import CreateGroup from './CreateGroup'
import { useLocation } from 'react-router-dom'
import LandingIndex from '../../views/HomeLanding/LandingIndex';

const Root = styled(Box)({
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    backgroundColor: theme.palette.background.default
})

const Wrapper = styled(Box)({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
})

const ContentContainer = styled(Box)({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
})

const Content = styled(Box)({
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    borderLeft: '1px solid rgb(0, 0, 0, 0.12)'
})
const MyPage = styled(Page)({
    backgroundColor: theme.palette.background,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
})
const MyBackdrop = styled(Backdrop)({
    zIndex: theme.zIndex.mobileStepper,
    color: "#fff"
})
const Loading = styled(Container)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
})


const DashboardLayout = () => {
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const loading = useSelector((state) => state.deviceList.loadingDevice)
    const [modal, setModal] = useState(false);
    const location = useLocation();

    if ( loading === true && !location.pathname.includes("FleetManagement") ){
        return (
            <MyPage title="Dashboard">
                <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
                <NavBar
                onMobileClose={() => setMobileNavOpen(false)}
                openMobile={isMobileNavOpen}
                />
                <Loading maxWidth={false}>
                    <MyBackdrop
                        open={loading}
                    >
                        <CircularProgress color="primary" thickness={5} size={100}/>
                    </MyBackdrop>
                </Loading>
            </MyPage>
        )
    }
    else{
    return (
        <Root>
            <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
            <NavBar
                onMobileClose={() => setMobileNavOpen(false)}
                openMobile={isMobileNavOpen}
            />
            <Wrapper>
            <ContentContainer>
                <Content>
                    <Modal
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                        open={modal}
                        onClose={() => setModal(false)}
                    >
                        <CreateGroup/>
                    </Modal>
                    <Switch>
                        <Route path='/app/home' component={LandingIndex} />
                        <Route path='/app/dashboard' component={DashboardView} />
                        <Route path='/app/group' component={GroupView} />
                        <Route path='/app/devices' component={Devices} />
                        <Route path='/app/data' component={DataListView}/>
                        <Route path='/app/charts' component={ChartsView}/>
                        <Route path='/app/admin' component={AdminView}/>
                        <Route path='/app/faults' component={FaultView}/>
                        <Route path='/app/FleetManagement' component={FleetManagementView}/>
                        <Route path='/app/Reports' component={ReportView} />
                        <Route path='/app/Service' component={ServiceManagementView}/>
                        <Route path='/app/EngineeringView' component={EngineeringView}/>
                        <Route path='/app/canGroup' component={CanGroup} />
                        <Route path='*' exact={true} component={NotFoundView}/>
                    </Switch>
                </Content>
            </ContentContainer>
            </Wrapper>
        </Root>
        );
    }
};

export default DashboardLayout;
