import React from "react";
import {
    Button,
    Card,
    Modal,
} from "@mui/material";
import { Plus, ArrowLeft } from "react-feather";
import CreateHardware from './CreateHardware';
import CreateFamily from './CreateFamily';
import { useDispatch, useSelector } from "react-redux";
import {
    DataGrid,
    GridToolbar
} from "@mui/x-data-grid";
import axios from "axios";
import moment from "moment";
import Toast from "../../../mixins/Toast";
import Popup from "../../../mixins/Popup";

const AdminHardware = (props) => {
    const [open, setOpen] = React.useState(false);
    const [family, setFamily] = React.useState(false);
    const [id, setId] = React.useState(null);
    const [edit, setEdit] = React.useState(false);
    const [versionOpen, setVersionOpen] = React.useState(false);
    const [familyId, setFamilyId] = React.useState('');
    const versions = useSelector((state) => state.admin.hardwareFamily);
    const dispatch = useDispatch();
    const token = useSelector((state) => state.setUser.token);

    const handleClose = () => {
        setId(null)
        setOpen(false);
    }
    const openFamily = () => {
        setFamily(true)
    }
    const closeFamily = () => {
        setFamily(false)
    }
    const handleVersionClose = () => {
        setVersionOpen(false)
    }
    const handleVersionClick = (params) => {
        setFamilyId(params)
        setEdit(false)
        axios.get(`/api/HardwareVersion/${params}`,{headers: !token ? {} : { 'Authorization': `Bearer ${token}` }})
        .then((response) => {dispatch({type: 'SET_FAMILY', payload: response.data})})
        .then(() => setVersionOpen(true))
        .catch((error) => console.log(error))
    }
    const handleClick = () => {
        setEdit(!edit)
    }
    const data = []
    const columns = [
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 150 },
        { field: 'id', headerName: 'ID', flex: 1, minWidth: 150 },
        { field: 'created', headerName: 'Created', flex: 1, minWidth: 150, renderCell: (params) => (moment.utc(params.row.created).local().format('MM/DD/YY, h:mm a')) },
        { field: 'updated', headerName: 'Updated', flex: 1, minWidth: 150, renderCell: (params) => (moment.utc(params.row.updated).local().format('MM/DD/YY, h:mm a')) },
        { field: 'children', headerName: 'Family Versions', flex: 1, minWidth: 150, renderCell: (params) => (
            <Button
                variant="contained"
                color="primary"
                onClick={() => handleVersionClick(params.id)}
            >
                Versions
            </Button>
        ) }
    ]
    {props.data.map((x, i) => {
        return data.push({name: x.name, id: x.id, created: x.created, updated: x.updated, versions: x.versions})
    })}
    const versionColumns = [
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 150 },
        { field: 'majorVersion', headerName: 'Major Version', flex: 1, minWidth: 150 },
        { field: 'minorVersion', headerName: 'Minor Version', flex: 1, minWidth: 150 },
        { field: 'revisionVersion', headerName: 'Revision Version', flex: 1, minWidth: 150 },
        { field: 'updated', headerName: 'Updated', flex: 1, minWidth: 150, renderCell: (params) => (moment.utc(params.row.updated).local().format('MM/DD/YY, h:mm a')) },
        { field: 'delete', headerName: 'Delete', flex: 1, minWidth: 150, renderCell: (params) => (
            <Button
                variant="contained"
                color="error"
                onClick={() => {
                    axios.delete(`/api/HardwareVersion/${params.id}`,{headers: !token ? {} : { 'Authorization': `Bearer ${token}` }})
                    .then(() => handleVersionClick(familyId))
                    .then(() => Toast.fire({
                        icon: 'success',
                        title: 'Hardware Version Deleted'
                    }))
                    .catch((error) => console.log(error))
                }}
            >
                Delete
            </Button>
        )}
    ]
    
    return (
        <div>
            <div>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={openFamily}
                >
                    <Plus /> Hardware Family
                </Button>
                <Modal
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    open={family}
                    onClose={closeFamily}
                >
                    <CreateFamily close={closeFamily}/>
                </Modal>
                <Popup
                    open={versionOpen}
                    close={handleVersionClose}
                >
                    <Card sx={{ height: '100%', margin: '15px'}}>
                        <Button
                            sx={{ margin: '5px' }}
                            variant="contained"
                            onClick={handleClick}
                        >
                            { !edit ? <> <Plus/> Add </> : <> <ArrowLeft/> Back </> }
                        </Button>
                        { !edit ? 
                        <DataGrid
                            columns={versionColumns}
                            rows={versions}
                        />
                        : <CreateHardware id={familyId} refresh={handleVersionClick} change={handleClick}/> }
                    </Card>
                </Popup>
                <Modal
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    open={open}
                    onClose={handleClose}
                >
                    <CreateHardware close={handleClose} id={id}/>
                </Modal>
            </div>
            <div style={{ marginTop: '20px', height: 800 }}>
                <DataGrid
                    columns={columns}
                    rows={data}
                    components={{
                        Toolbar: GridToolbar
                    }}
                />
            </div>
        </div>
    )
}

export default AdminHardware;