import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios'; 
import authService from "../../components/api-authorization/AuthorizeService";
import FaultReducer from '../reducers/FaultReducer';

function* faultDataTable( action ) {
    // console.log("1 fault data table saga action:", action);
    try {
        yield put({
            type: 'FAULT_DATA_LOADING',
            payload: true
        })
        let newData = [];
        let token = yield authService.getAccessToken();
        for (const x of action.payload) {
            let faultData = yield axios.get(`/api/Fault/faults/${x}`,{ headers: { 'Authorization': `Bearer ${token}` }});
            // let faultData = yield axios.get(`/api/Fault/faults/${x}/${action.startTime}/${action.endTime}`,{ headers: { 'Authorization': `Bearer ${token}` }});
            // console.log('fault data', faultData);
            newData.push(...faultData.data)
        }
        
        yield put({
            type: 'SET_FAULT_DATA',
            payload: newData
        })

        yield put({
            type: 'FAULT_DATA_LOADING',
            payload: false
        })
    } catch (error) {
        console.log(error)
    }
}

function* FaultServiceView( action ){
    try {
        // console.log('--------fault service', action);
        let token = yield authService.getAccessToken();
        yield put({
            type: 'FAULT_LOADING',
            payload: true
        })
        let response = yield axios.get(`/api/Fault/faultcode/${action.payload}/${action.startValue}/${action.endValue}`, {headers: {Authorization: 'Bearer ' + token}});
        // console.log("--------reponse in fault service view", response);
        yield put({ type: 'SET_FAULT_DATA', payload: response.data})
        yield put({
            type: 'FAULT_LOADING',
            payload: false
        })
    } catch (error) {
        console.log(error)
        yield put({
            type: 'FAULT_LOADING',
            payload: false
        })
    }
}

function* FaultSaga() {
    yield takeLatest('FAULT_DATA_TABLE', faultDataTable);
    yield takeLatest('FAULT_SERVICE_VIEW', FaultServiceView);
}

export default FaultSaga;