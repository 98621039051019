import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import authService from "../../components/api-authorization/AuthorizeService";
import Toast from '../../mixins/Toast';

// System admin has access to everything in the app and all devices

function* getAdminData() {
    yield put({
        type: "ADMIN_LOADING",
        payload: true
    })
    try{
        let token = yield authService.getAccessToken();
        //console.log("made it here2")
        const response = yield axios.get('/api/FirmwareVersion', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
        const allFirmwares = {Signum:{STM: [], ESP:[]}, Bobcat:{CAB:[], HUB:[]}, Moog:{EVCM:[]}};
        response.data.forEach((x) => {
            switch (x.controllerCompany) {
                case 0:
                    if (x.controllerType === 0) return allFirmwares.Signum.STM.push(x);
                    else return allFirmwares.Signum.ESP.push(x);
                case 1:
                    if (x.controllerType === 2) return allFirmwares.Bobcat.CAB.push(x);
                    else return allFirmwares.Bobcat.HUB.push(x);
                case 2:
                    if (x.controllerType === 4) return allFirmwares.Moog.EVCM.push(x);
                default:
                    break;
            }
        })

        yield put({ type: "SET_FIRMWARE", payload: allFirmwares });
        yield put({ type: "SET_ALL_FIRMWARE", payload: response.data });

        const roles = yield axios.get('/api/GroupRole', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

        yield put({
            type: "ADMIN_SET_ROLE",
            payload: roles.data
        });

        const devices = yield axios.get('/api/device', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

        for (const x of devices.data) {
            const deviceGroup = yield axios.get(`/api/groupdevices/device/${x.id}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
            if(deviceGroup.data.groupId){
                const group = yield axios.get(`/api/group/${deviceGroup.data.groupId}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
                x.group = group.data
            }
            const firmwares = yield axios.get(`/api/FirmwareVersion/devicetofirmware/${x.id}`);
            x.stm = {};
            x.esp = {};
            x.cab = {};
            x.hub = {};
            x.evcm = {};
            for (const y of firmwares.data) {
                if (y.firmwareVersion.controllerType === 0) x.stm = y.firmwareVersion;
                if (y.firmwareVersion.controllerType === 1) x.esp = y.firmwareVersion;
                if (y.firmwareVersion.controllerType === 2) x.cab = y.firmwareVersion;
                if (y.firmwareVersion.controllerType === 3) x.hub = y.firmwareVersion;
                if (y.firmwareVersion.controllerType === 4) x.evcm = y.firmwareVersion;
            }
        };

        yield put({
            type: "ADMIN_DEVICES",
            payload: devices.data
        });

        const groups = yield axios.get('/api/group', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

        yield put({
            type: "ADMIN_SET_GROUP",
            payload: groups.data
        });

        const users = yield axios.get('/api/User', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

        yield put({
            type: "ADMIN_USERS",
            payload: users.data
        });

        const hardware = yield axios.get('/api/HardwareFamily', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

        yield hardware.data.forEach(element => {
            let data = []
            axios({
                method: 'get',
                url: `/api/HardwareVersion/${element.id}`,
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(response => {
                if(response.data){
                    data = response.data
                }
                element.version = data
            })
        });

        yield put({
            type: "SET_HARDWARE",
            payload: hardware.data
        });

        const hardwareVersions = yield axios.get('/api/HardwareVersion', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

        yield put({
            type: "ADMIN_HARDWARE_VERSIONS",
            payload: hardwareVersions.data
        });

        let canElements = yield axios.get('/api/CanElement', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

        yield put ({
          type: "ADMIN_CAN_ELEMENTS",
          payload: canElements.data
        });

        let canGroups = yield axios.get('/api/CanGroups', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

        yield put({
          type: "ADMIN_CAN_GROUPS",
          payload: canGroups.data
        });

        yield put({
            type: "ADMIN_LOADING",
            payload: false
        });
    }
    catch (error) {
        console.log(`problem getting firmware versions: ${error}`);
    }
}

function* getHardware(){
    let token = yield authService.getAccessToken();

    const hardware = yield axios.get('/api/HardwareFamily', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

    yield hardware.data.forEach(element => {
        let data = []
        axios({
            method: 'get',
            url: `/api/HardwareVersion?${element.hardwareFamilyId}`,
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        }).then(response => {
            if(response.data){
                data = response.data
            }
            element.version = data
        })
    });

    yield put({
        type: "SET_HARDWARE",
        payload: hardware.data
    });
}
function* getFirmware(){
    let token = yield authService.getAccessToken();
    //console.log("made it here")
    const response = yield axios.get('/api/FirmwareVersion', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
    const allFirmwares = {Signum:{STM: [], ESP:[]}, Bobcat:{CAB:[], HUB:[]}, Moog:{EVCM:[]}};
    response.data.forEach((x) => {
        switch (x.controllerCompany) {
            case 0:
                if (x.controllerType === 0) return allFirmwares.Signum.STM.push(x);
                else return allFirmwares.Signum.ESP.push(x);
            case 1:
                if (x.controllerType === 2) return allFirmwares.Bobcat.CAB.push(x);
                else return allFirmwares.Bobcat.HUB.push(x);
            case 2:
                if (x.controllerType === 4) return allFirmwares.Moog.EVCM.push(x);
            default:
                break;
        }
    });
    
    yield put({ type: "SET_FIRMWARE", payload: allFirmwares });
    yield put({ type: "SET_ALL_FIRMWARE", payload: response.data });
}

function* getDevices(){
    let token = yield authService.getAccessToken();

    const devices = yield axios.get('/api/device', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

    for (const x of devices.data) {
        const deviceGroup = yield axios.get(`/api/groupdevices/device/${x.id}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
        const firmwares = yield axios.get(`/api/FirmwareVersion/devicetofirmware/${x.id}`);
        x.stm = {};
        x.esp = {};
        x.cab = {};
        x.hub = {};
        x.evcm = {};
        for (const y of firmwares.data) {
            if (y.firmwareVersion.controllerType === 0) x.stm = y.firmwareVersion;
            if (y.firmwareVersion.controllerType === 1) x.esp = y.firmwareVersion;
            if (y.firmwareVersion.controllerType === 2) x.cab = y.firmwareVersion;
            if (y.firmwareVersion.controllerType === 3) x.hub = y.firmwareVersion;
            if (y.firmwareVersion.controllerType === 4) x.evcm = y.firmwareVersion;
        }
        if(deviceGroup.data.groupId){
            const group = yield axios.get(`/api/group/${deviceGroup.data.groupId}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
            x.group = group.data
        }
    };

    yield put({
        type: "ADMIN_DEVICES",
        payload: devices.data
    });
}
function* getCanElements(){
    let token = yield authService.getAccessToken();

    let canElements = yield axios.get('/api/CanElement', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

    yield put ({
      type: "ADMIN_CAN_ELEMENTS",
      payload: canElements.data
    });
  }

function* getCanGroups(){
    let token = yield authService.getAccessToken();

    let canGroups = yield axios.get('/api/CanGroups', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

    yield put({
      type: "ADMIN_CAN_GROUPS",
      payload: canGroups.data
    });
  }

  function* getGroups(){
      let token = yield authService.getAccessToken();

      let groups = yield axios.get('/api/group', {headers: !token ? {} : { 'Authorization': `Bearer ${token}`}});

      yield put({
          type: "ADMIN_SET_GROUP",
          payload: groups.data
      })
  }

  function* getFirmwareHardware(x){
        let token = yield authService.getAccessToken();
        
        let hardwareFirmware = yield axios.get(`/api/HardwareVersionFirmwareVersion/${x.payload}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
        
        yield put({
            type: "SET_FIRMWARE_HARDWARE",
            payload: hardwareFirmware.data
        });
  } 

  function* adminDeleteUser(action) {
      let token = yield authService.getAccessToken();
      const deleteUser = yield axios.delete(`/api/User/${action.payload}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}`}});
      const users = yield axios.get('/api/User', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
      yield put({
          type: "ADMIN_USERS",
          payload: users.data
      });
      Toast.fire({
          title: 'User deleted',
          icon: "success"
      })
  }

function* AdminSaga() {
    yield takeLatest("GRAB_ADMIN", getAdminData);
    yield takeLatest("GRAB_HARDWARE", getHardware);
    yield takeLatest("GRAB_ADMIN_FIRMWARE", getFirmware);
    yield takeLatest("GRAB_ADMIN_DEVICES", getDevices);
    yield takeLatest("GRAB_ADMIN_CAN_ELEMENTS", getCanElements);
    yield takeLatest("GRAB_ADMIN_CAN_GROUPS", getCanGroups);
    yield takeLatest("GRAB_ADMIN_GROUPS", getGroups);
    yield takeLatest("GRAB_FIRMWARE_HARDWARE", getFirmwareHardware);
    yield takeLatest("SYSTEM_ADMIN_DELETE_USER", adminDeleteUser);
}

export default AdminSaga;