import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    useTheme,
    Menu,
    MenuItem,
    Button
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux'

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

const Charts = (props, { className, ...rest }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [time, setTime] = React.useState('Month');
    const theme = useTheme();

    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = (x) => {
        setAnchorEl(null);
        setTime(x)
    }
    let now = new Date()
    let ticks = ''
    if (time === 'All') {
        ticks = ''
    }
    else if (time === 'Month') {
        ticks = now.setMonth(now.getMonth() - 1)
    }
    else if (time === '3-Month') {
        ticks = now.setMonth(now.getMonth() - 3)
    }
    else if (time === '3-Day') {
        ticks = now.setDate(now.getDate() - 3)
    }
    else if (time === 'Week') {
        ticks = now.setDate(now.getDate() - 7)
    }

    const options = {
        animation: false,
        cornerRadius: 20,
        layout: { padding: 0 },
        legend: { display: true },
        maintainAspectRatio: false,
        borderWidth: 1,
        responsive: true,
        scales: {
            xAxes: [
                {
                    barPercentage: 0.5,
                    categoryPercentage: 0.5,
                    ticks: {
                        fontColor: theme.palette.text.secondary,
                        min: ticks
                    },
                    gridLines: {
                        display: false,
                        drawBorder: false
                    },
                    type: 'time',
                    distribution: 'linear',
                    time: {
                        unit: 'day'
                    },
                }
            ],
            yAxes: [
                {
                    ticks: {
                        fontColor: theme.palette.text.secondary,
                        callback: function (value) {
                                return value + ' °C';
                        }
                    },
                    gridLines: {
                        borderDash: [2],
                        borderDashOffset: [2],
                        color: theme.palette.divider,
                        drawBorder: false,
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2],
                        zeroLineColor: theme.palette.divider
                    }
                }
            ]
        },
        tooltips: {
            backgroundColor: theme.palette.background.default,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            footerFontColor: theme.palette.text.secondary,
            titleFontColor: theme.palette.text.primary
        }
    };
    return (
        <Card
            sx={{height: '100%'}}
        >
            <CardHeader
                action={(
                    <Button
                     color="primary"
                     variant='outlined'
                     style={{width: '200px'}}
                     onClick={handleOpen}>
                        {time}
                    </Button>
                )}
                title={'Temperatures'}
            />
            <StyledMenu
                id='menu'
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleClose(time)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <MenuItem onClick={() => handleClose('3-Day')}>3 Day</MenuItem>
                <MenuItem onClick={() => handleClose('Month')}>Month</MenuItem>
                <MenuItem onClick={() => handleClose('3-Month')}>3 Months</MenuItem>
                <MenuItem onClick={() => handleClose('All')}>All</MenuItem>
            </StyledMenu>
            <Divider />
            <CardContent>
                <Box
                    height={500}
                    position="relative"
                >
                    <Line
                        data={props.data.deviceList.chartData}
                        options={options}
                        height={35}
                        width={100}
                    />
                </Box>
            </CardContent>
            <Divider />
            <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
            >
            </Box>
        </Card>
    );
};

Charts.propTypes = {
    className: PropTypes.string
};
const mapStateToProps = (state) => ({
    data: state.deviceList
})

export default connect(mapStateToProps)(Charts);
