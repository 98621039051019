import React, { useState, useEffect } from 'react';
import {
    Box,
} from "@mui/material";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { GrandCentralDispatch } from '../../../utils/GrandCentralDispatch';
import authService from '../../../components/api-authorization/AuthorizeService';
import { Tooltip, Typography } from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';
import ChartService from "../../../utils/DashboardChartService";
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { ExternalLink } from 'react-feather';
import Swal from 'sweetalert2';

const FleetStatus = (props) => {
    const fleetData = useSelector((state) => state.ViewReducer.FleetData);
    const isLoading = useSelector((state) => state.ViewReducer.FleetLoad);
    const loadingMessage = "loading . . .";
    const [deviceList, setDeviceList] = useState([]);
    const [fleetList, setFleetList] = useState([]);
    const dispatch = useDispatch();
    const columns = [
        {field:"commonName", headerName: "Serial Number", flex: 1, cellClassName: 'links', renderCell: (params) => <SerialCell params={params} cell={params.row.commonName} parent='serial' />},
        {field:"stateOfCharge", headerName: "State of Charge", flex: 1},
        // {field:"externalId", headerName: "IMEI", flex: 1},
        // {field:"status", headerName: "Status", flex: 1},
        {field:"faults", headerName: "Active Faults", flex: 1, cellClassName: (params) => params.row.faults === 'View Fault' ? "fault" : "none", renderCell: (params) => <SerialCell params={params} cell={params.row.faults} parent='fault' />},
        {field:"operationHours", headerName: "Operation Hours", flex: 1},
        {field:"idleHours", headerName: "Idle Hours", flex: 1},
        {field:"totalHours", headerName: "Total Hours", flex: 1},
        {field:"reportDate", headerName: "Report Date", flex: 1}
    ];
    const navigate = useHistory();
    let myTimeout = false;
    let allDevices = [];
    let limit = 0;
    let myStatusData = [];
    let version = 0;
    let isActive = true;
    
    useEffect(() => {
        setFleetList(fleetData)
    },[fleetData])
    // console.log('device list', deviceList); //
    // console.log('fleet data', fleetData)
    // console.log('fleet list', fleetList)

    if(fleetList.length > 0 && fleetData.length > 0 && isLoading === false) {
        if (fleetList[0].faults !== fleetData[0].faults){
            setFleetList(fleetData);
        }
    }

    if (isLoading === true){
        Swal.fire({
            title: "Loading...",
            imageUrl: '/static/images/loading-gif.gif',
            showConfirmButton: false,
            backdrop: "false",
        })
    };
    if (isLoading === false){
        Swal.close();
    };
    
    const SerialCell = ({params, cell, parent}) => {
        let display = '';
        if(parent === 'serial' || cell === 'View Fault'){
            display = <><u>{cell}</u> <ExternalLink style={{ marginBottom: -2, height: 16, width: 16}}/> </>;
        } else {
            display = cell;
        }
        
        return <p onClick={ () => {
            params.row.serialNumber = params.row.commonName;
            window.google = undefined
            dispatch({ type: 'FLEET_SERVICE', payload: params.row, path: 'fleetCellClick'});
            // console.log('______ params row', params.row);
            dispatch({type: 'DATA_TABLE', payload: [params.row.id], externalId: params.row.externalId, serialNumber: params.row.serialNumber, startDate: moment().subtract(7, "days").startOf('day').format(), endDate: moment().format(), parent: "", limit: 100, fetchRaw: props.hideLimit !== true, path: 'fleetCellClick'});
            dispatch({type: 'SERVICE_INFO', payload: [params.row.id], externalId: params.row.externalId, startDate: moment().subtract(7, "days").startOf('day').format(), endDate: moment().format(), serialNumber: params.row.serialNumber, parent: "", limit: 100, fetchRaw: props.hideLimit !== true, path: 'fleetCellClick'});
            dispatch({type: 'FAULT_SERVICE_VIEW', payload: params.row.id, startValue: moment().subtract(7, "days").startOf('day').format(), endValue: moment().format(), path: 'fleetCellClick'});
            dispatch({type: 'SEARCH_CLICKED', payload: true, path: 'fleetCellClick'});
            navigate.push("/app/Service");
            
        }}> {display} </p> 
    }

    // const getStatus = (value) => {
    //     if(value == 0) return "Inactive";
    //     if(value == 1) return "Active";
    //     if(value == 2) return "Error";
    //     return "Unavailable ";
    // }

    // const clearMyTimeout = () => {
    //     if(myTimeout) {
    //         clearTimeout(myTimeout);
    //         myTimeout = false;
    //     }
    // }

    // const updateDriveActive = (index) => {
    //     let device = myStatusData[index];
    //     authService.getAccessToken()
    //     .then((token) => {
    //         axios.put(`/api/DeviceData/limitedchart`,{
    //             ids: [device.externalId],
    //             dataPoint: "DriveActive",
    //             pointLimit: 1
    //         },
    //         {
    //             headers: {
    //             Authorization: 'Bearer ' + token
    //             }
    //         })
    //         .then((retval) => {
    //             console.log('1 update drive active retval', retval);
    //             if(retval.data.length < 1) {
    //                 device.status = "No Data";
    //             }
    //             else {
    //                 device.status = getStatus((retval.data[0].value));
    //             }
    //             setDeviceList(JSON.parse(JSON.stringify(myStatusData)));
    //             myTimeout = setTimeout(fillHoles, 15);
    //         });
    //     });
    // }

    // const updateOpHours = (index) => {
    //     let device = myStatusData[index];
    //     authService.getAccessToken()
    //     .then((token) => {
    //         axios.put(`/api/DeviceData/limitedchart`,{
    //             ids: [device.externalId],
    //             dataPoint: "OperationHours",
    //             pointLimit: 1
    //         },
    //         {
    //             headers: {
    //             Authorization: 'Bearer ' + token
    //             }
    //         })
    //         .then((retval) => {
    //             console.log('2 update op hours retval', retval);
    //             if(retval.data.length < 1) {
    //                 device.operationHours = "No Data";
    //             }
    //             else {
    //                 device.operationHours = retval.data[0].value;
    //             }
    //             setDeviceList(JSON.parse(JSON.stringify(myStatusData)));
    //             myTimeout = setTimeout(fillHoles, 15);
    //         });
    //     });
    // }

    // // for(let i=0; i<fleetData.length; i++){
    // //     if (!fleetData.fault){
    // //         updateFaults(i);
    // //     }
    // // }

    // const updateFaults = (index) => {
    //     let device = fleetList[index];
    //     console.log("3.1 update faults", device);
    //     authService.getAccessToken()
    //     .then((token) => {
    //         axios.get(`/api/Fault/faults/${device.externalId}`,
    //         {
    //             headers: {
    //             Authorization: 'Bearer ' + token
    //             }
    //         })
    //         .then((retval) => {
    //             console.log('3.2 update faults retval', retval);
    //             let activeFaults = false;
    //             if(retval.code == 404) {
    //                 device.faults = "No Faults";
    //                 fleetData[index].faults = "No Faults";
    //             }
    //             else {
    //                 retval.data.forEach(fault => {
    //                     if(fault.EndTime == null) {
    //                         activeFaults = true;
    //                     }
    //                 });
    //                 device.faults = activeFaults ? "Faults" : "No Faults";
    //                 fleetData[index].faults = activeFaults ? "Faults" : "No Faults";
    //             }
    //             console.log('3.3 wtf: ', myStatusData );
    //             setDeviceList(JSON.parse(JSON.stringify(myStatusData)));
    //             myTimeout = setTimeout(fillHoles, 15);
    //         });
    //     });
    // }

    // const fillHoles = () => {
    //     if(!isActive) { return; }
    //     for(let i = 0; i < myStatusData.length; i++) {
    //         // if(myStatusData[i].operationHours === loadingMessage) {
    //         //     updateOpHours(i);
    //         //     i = Number.MAX_SAFE_INTEGER;
    //         // }
    //         if(myStatusData[i].faults === loadingMessage) {
    //             updateFaults(i);
    //             i = Number.MAX_SAFE_INTEGER;
    //         }
    //         // else if(myStatusData[i].status === loadingMessage) {
    //         //     updateDriveActive(i);
    //         //     i = Number.MAX_SAFE_INTEGER;
    //         // }
    //     }
    // }

    // const update = () => {
    //     setDeviceList([]);
    //     version++;
    //     authService.getAccessToken()
    //     .then((token) => {
    //         axios.put(`/api/Device/list/status/`, allDevices,
    //         {
    //             headers: {
    //             Authorization: 'Bearer ' + token
    //             }
    //         })
    //         .then((retval) => {
    //             console.log('4 update retval', retval);
    //             myStatusData = [];
    //             let usedIds = {};
    //             retval.data.forEach(dto => {
    //                 let device = dto.device;
    //                 myStatusData.push(
    //                     {
    //                         id: device.id, 
    //                         commonName: device.name, 
    //                         externalId: device.externalId, 
    //                         status: loadingMessage, // getStatus(device.value), 
    //                         faults: loadingMessage, 
    //                         operationHours: Math.round(dto.operationalHours) /10, // device.operationHours,
    //                         idleHours: Math.round(dto.idleHours * 10) /10, // device.idleHours,
    //                         totalHours: Math.round(dto.totalHours * 10) /10, // device.totalHours,
    //                         reportDate: moment(dto.reportDate).format('MM/DD/YY, h:mm a')
    //                     }
    //                 );
    //             });
    //             setDeviceList(myStatusData);
    //             myTimeout = setTimeout(fillHoles, 500);
    //             // setTimeout(updateDevices, 10000);
    //          });
    //     })
    // }

    // const updateDevices = (payload) => {
    //     clearMyTimeout();
    //     allDevices = payload.devices;
    //     limit = payload.limit;
    //     myTimeout = setTimeout(update, 500);
    // }

    // useEffect(() => {
    //     // console.log("Startup Called for: FleetStatus");
    //     GrandCentralDispatch.subscribe("SELECTED_SEARCH_DEVICES_UPDATED", updateDevices);
    //     return () => {
    //         // console.log("Cleanup Called for: FleetStatus");
    //         clearMyTimeout();
    //         GrandCentralDispatch.unsubscribe("SELECTED_SEARCH_DEVICES_UPDATED", updateDevices);
    //         isActive = false;
    //     }
    // }, []);
    // console.log("device list", deviceList);
    if(fleetList.length > 0) {
        return (
            <Box width={'100%'} height={'100%'}>
                <ChartService data={{type: 'special chart', title: 'Status', columns: columns, list: fleetList }} />
            </Box>
        )
    }
    else {
        return (
            <Box width={'100%'} height={'100%'}>
                Status: Not Available
            </Box>
        )
    }
}

export default FleetStatus;
