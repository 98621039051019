import React, { useState, useEffect } from 'react';
import {
    Box,
} from "@mui/material";
import axios from 'axios';
import { useSelector } from 'react-redux';
import { GrandCentralDispatch } from '../../../utils/GrandCentralDispatch';
import authService from '../../../components/api-authorization/AuthorizeService';
import { Tooltip, Typography } from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';
//import { RFC_2822 } from 'moment';
// import "./ServiceMap.css";
import { Helmet } from 'react-helmet';
import { MarkerClusterer } from "@googlemaps/markerclusterer";

class Bounds {
    constructor() {
        this.ne = {lat: NaN, lng: NaN};
        this.sw = {lat: NaN, lng: NaN};
    }

    extend(point) {
        this.ne.lat = isNaN(this.ne.lat) ? point.lat : this.ne.lat > point.lat ? this.ne.lat : point.lat;
        this.ne.lng = isNaN(this.ne.lng) ? point.lng : this.ne.lng > point.lng ? this.ne.lng : point.lng;
        this.sw.lat = isNaN(this.sw.lat) ? point.lat : this.sw.lat < point.lat ? this.sw.lat : point.lat;
        this.sw.lng = isNaN(this.sw.lng) ? point.lng : this.sw.lng < point.lng ? this.sw.lng : point.lng;
    }

    getCenterPoint() {
        return {
            lat: (this.ne.lat + this.sw.lat) / 2,
            lng: (this.ne.lng + this.sw.lng) / 2
        }
    }
} 

const FleetMap = (props) => {
    const [zoom, setZoom] = useState(10);
    const [points, setPoints] = useState([]);
    const [center, setCenter] = useState({lng:-96.7898,lat:46.8772});
    const [returnStatus, setReturnStatus] = useState(0);
    const selectedDevices = useSelector((state) => state.setUser.selectedSearchDevices);
    const [mapRef, setMapRef] = useState(React.createRef());

    const infoPoints = [];
    points.forEach(infoPusher);
    function infoPusher(points) {
        infoPoints.push({
            Device: points.id,
            Name: points.name,
            Location: points.lat/ points.lng,
            Created: points.created
        })
    };

    const updatePoints = (payload) => {
        setPoints([]);
        // console.log('update points fleet payload', payload);
        let devices = payload.devices;
        // let limit = payload.limit;
        authService.getAccessToken().then((token) => {
            axios.put(`/api/MachineService/FleetLocations`, devices,
            {
                headers: {
                  Authorization: 'Bearer ' + token
                }
            }).then((retval) => {
                // console.log('fleet loc retval', retval);
                if(retval.status === 200){
                    setReturnStatus(200);
                }
                let newPoints = [];
                let bounds = new Bounds();
                retval.data.forEach(element => {
                    if( element.lat && element.long ){
                        if (element.lat !== 0 && element.long !==0 ) {
                            let point = {
                                id: element.externalId,
                                lat: element.lat,
                                lng: element.long,
                                name: element.commonName,
                                created: element.reportDate
                            };
                            
                            bounds.extend(point);
                            newPoints.push(point);
                        } else {
                            setPoints([]);
                        }
                    } else {
                        setPoints([])
                    }
                });
                setPoints(newPoints);
                let centerPoint = bounds.getCenterPoint();
                setCenter(centerPoint);
                let west = bounds.ne.lng;
                let east = bounds.sw.lng;
                let north = bounds.ne.lat;
                let south = bounds.sw.lat;
                let angle = east - west < north - south ? east - west : north - south;
                while (angle < 0) {
                    angle += 360;
                }
                let myZoom = Math.round(Math.log(10000 /* <- magic number ¯\_(ツ)_/¯ */ / angle ) / Math.LN2);
                setZoom(myZoom);
            });
        });
        initMap();
    };

    function initMap() {
        if(window.google === undefined){
            return
        }
        const map = new window.google.maps.Map(document.getElementById("map"), {
          zoom: zoom,
          center: center,
        });

        const infoWindow = new window.google.maps.InfoWindow({
          content: infoPoints.Device,
          disableAutoPan: true,
        });

        // Create an array of alphabetical characters used to label the markers.
        // Add some markers to the map.
        const markers = points.map((position, i) => {
        //   const label = labels[i % labels.length];
          const label = `${points.indexOf(position)}`;
          const marker = new window.google.maps.Marker({
            position,
            label,
          });
          // markers can only be keyboard focusable when they have click listeners
          // open info window when marker is clicked
          const windowContent = `Name: ${points[i].name}<br/> Device: ${points[i].id}<br/> Created: ${points[i].created}<br/> Location: ${points[i].lat} / ${points[i].lng}`
          marker.addListener("click", () => {
            infoWindow.setContent(windowContent);
            infoWindow.open(map, marker);
          });
          return marker;
        });
      
        // Add a marker clusterer to manage the markers.
        new MarkerClusterer({ markers, map });
      }
      
    window.initMap = initMap;

    useEffect(() => {
        GrandCentralDispatch.subscribe("SELECTED_SEARCH_DEVICES_UPDATED", updatePoints);
        return function cleanup() {
            GrandCentralDispatch.unsubscribe("SELECTED_SEARCH_DEVICES_UPDATED", updatePoints);
        }
    });
    // site for on hover display: https://sudolabs.com/blog/react-google-maps-geodesic-polylines-polygons
    if(points.length > 0) {
        return (
            <Box width={'100%'} height={'100%'} value={points.length}>      
                <div id='map' className='map'>
                    <Helmet >  
                        <script sync defer src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDT5lF0ro8rCWUtQQHeXCMzGgfbpM_SafA&callback=initMap"></script>
                    </Helmet>
                </div>            
            </Box>
        )
    } else if (returnStatus === 200 && points.length === 0 ) {
        return (
          
                <div><p style={{color: 'red'}}>No map data for this device between dates.</p></div> 
    
        )
    } else {
        return (
                <div><p>Please select device to load Map data.</p></div>
        )
    }

}
export default FleetMap;