import React from "react";
import {
    Box,
    Tab,
    Tabs,
    AppBar,
    Card,
    Container
} from "@mui/material";

import Page from "../../components/Page";
import theme from "../../theme";
import CanGroups from "./CanGroups";
import CanElements from "./CanElements";
import CanFiles from "./CanFiles";

function TabPanel(props){
    const { children, value, index } = props;
    return (
        <Box
            role="tabpanel"
            hidden={ value !== index }
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
        >
            { value === index && (
                <Box sx={{ paddingTop: theme.spacing(1) }} >
                    { children }
                </Box>
            )}
        </Box>
    ) 
}

const CanGroup = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue)
    };

    return (
        <Page title="Can Groups">
            <Container maxWidth={false} sx={{ paddingTop: theme.spacing(1) }}>
                <Card>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            <Tab label="CAN Files" />
                            <Tab label="CAN Groups" />
                            <Tab label="CAN PGNs" />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <CanFiles/>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <CanGroups/>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <CanElements/>
                    </TabPanel>
                </Card>
            </Container>
        </Page>
    )
};

export default CanGroup;
