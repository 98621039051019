import { combineReducers } from 'redux';

const deleteGroupUsers = (state = [], action) => {
    switch (action.type) {
        case "DELETE_GROUP_USERS":
            state = action.payload;
            return state;
        default:
            return state;
    }
}
const deleteGroupDevices = (state = [], action) => {
    switch (action.type) {
        case "DELETE_GROUP_DEVICES":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const userGroupLoading = (state = false, action) => {
    switch (action.type) {
        case "DELETE_GROUP_LOADING":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const FleetData = (state = [], action) => {
    switch (action.type) {
        case "SET_FLEET_DATA":
            // console.log('1 set fleet', action);
            if (action.origin === 'fleetStatus'){
                state = action.payload;
            } else if (action.origin === 'fleetFaults'){
                for(let i=0; i<state.length; i++ ){
                    state[i].faults = action.payload[i];
                }
            }
            // console.log('2 set fleet ', state);
            return state;
        default:
            return state;
    }
}
const FleetLoad = (state = false, action) => {
    switch (action.type) {
        case "FLEET_DATA_LOADING":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const ServiceLoad = (state = false, action) => {
    switch (action.type) {
        case "SERVICE_DATA_LOADING":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const CoreLoad = (state = false, action) => {
    switch (action.type) {
        case "CORE_LOADING":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

export default combineReducers({
    deleteGroupUsers,
    deleteGroupDevices,
    userGroupLoading,
    FleetData,
    FleetLoad,
    ServiceLoad,
    CoreLoad
});