import React from "react";
import { useSelector } from "react-redux"
import { LinearProgress } from '@mui/material';
import Moment from 'moment';
import { 
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridOverlay
} from '@mui/x-data-grid';
import { Box } from '@mui/system';

const Faults = () => {
    const faultData = useSelector((state) => state.FaultReducer.faultData);
    const faultloading = useSelector((state) => state.FaultReducer.faultDataLoading);
    const [faultSortModel, setFaultSortModel] = React.useState([
        { field: 'StartTime', sort: 'desc' },
    ]);    

    console.log("fault data:", faultData);

function FaultCustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    )
};

function FaultCustomToolbar() {
    return (
        <GridToolbarContainer style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport  />
            </Box>
        </GridToolbarContainer>
    );
}

const faultcolumns = [
    { field: 'SerialNumber', headerName: 'Device', flex: 1, minWidth: 150, renderCell: (params) => params.row.devices.serialNumber ? params.row.devices.serialNumber : "N/A" },
    { field: 'FaultCodes', headerName: 'Code', flex: 1, renderCell: (params) => params.row.faultCodes ? params.row.faultCodes : "N/A"  },
    { field: 'Name', headerName: 'Description', flex: 1, renderCell: (params) => params.row.name ? params.row.name : "N/A"  },
    { field: 'OperatingHours', headerName: 'Operating Hours', flex: 1, renderCell: (params) => params.row.OH ? params.row.OH : "N/A"  },
    { field: 'TotalHours', headerName: 'Total Hours', flex: 1, renderCell: (params) => params.row.TH ? params.row.TH : "N/A"  },
    { field: 'StartTime', headerName: 'Start Date', flex: 1 , minWidth: 150, renderCell: (params) => (
        Moment.utc(params.row.created).local().format('M/D/YY, h:mm a')
    )}
]
    
    return(
        <Box sx={{ height: 800}}>
            <DataGrid
                title="Fault List"
                rows={faultData}
                columns={faultcolumns}
                density="compact"
                loading={faultloading}
                sortModel={faultSortModel}
                onSortModelChange={(x) => {
                    if (x[0] !== faultSortModel[0]) {
                        setFaultSortModel(x)
                    }
                }}
                components={{
                    Toolbar: FaultCustomToolbar,
                    LoadingOverlay: FaultCustomLoadingOverlay
                }}
            />
        </Box>
    )
};

export default Faults;