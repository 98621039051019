import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Modal
} from "@mui/material"
import {
    ArrowDown,
    Plus,
    Trash,
} from "react-feather"
import {
    DataGrid,
    GridToolbar
} from '@mui/x-data-grid'
import { Save } from "react-feather";
import CreateFirmware from './CreateFirmware'
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Toast from '../../../mixins/Toast';
import AdminFirmwareHardware from "./HardwareVersions";
import Popup from '../../../mixins/Popup';

const AdminFirmware = (props) => {
    const [open, setOpen] = React.useState(false)
    const [editRowsModel, setEditRowsModel] = React.useState({});
    const [hardwareView, setHardwareView] = React.useState(false);
    const [currentFirmwareId, setCurrentFirmwareId] = React.useState('')
    const dispatch = useDispatch();
    const token = useSelector((state) => state.setUser.token);
    const firmwareHardware = useSelector((state) => state.admin.firmwareHardware);
    const firmwareList = useSelector((state) => state.admin.firmwareList);
//console.log("FirmwareList:", firmwareList)
    const handleClick = (params) => {
        setCurrentFirmwareId(params)
        dispatch({
            type: "GRAB_FIRMWARE_HARDWARE",
            payload: params
        })
        setHardwareView(true);
    }
    const hardwareClose = () => {
        setCurrentFirmwareId('')
        setHardwareView(false);
    }
    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleSave = () => {
        const objId = Object.keys(editRowsModel)[0]
        const obj = Object.values(editRowsModel)[0]
        axios.put(`/api/FirmwareVersion/${objId}`, {
            Name: obj.name.value,
            MajorVersion: Number(obj.majorVersion.value),
            MinorVersion: Number(obj.minorVersion.value),
            FileUrl: obj.fileUrl.value,
            FileName: obj.fileName.value,
            RevisionVersion: Number(obj.revisionVersion.value),
            MD5: obj.mD5.value
        },{headers: !token ? {} : { 'Authorization': `Bearer ${token}` }}).then((response) => {
            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Firmware Updated'
                })   
            }
        })
        .then((response) => {
            dispatch({type: 'GRAB_ADMIN_FIRMWARE'})
        })
        .catch((error) => console.log(error))
    }
    const handleEditRowsModelChange = React.useCallback((model) => {
        setEditRowsModel(model);
      }, []);

    const columns = [
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 150, editable: true },
        { field: 'fileName', headerName: 'File', flex: 1, minWidth: 150, editable: true },
        { field: 'fileUrl', headerName: 'URL', flex: 1, minWidth: 150, editable: true },
        { field: 'majorVersion', headerName: 'Major Version', flex: 1, minWidth: 150, editable: true },
        { field: 'minorVersion', headerName: 'Minor Version', flex: 1, minWidth: 150, editable: true },
        { field: 'revisionVersion', headerName: 'Revision Version', flex: 1, minWidth: 150, editable: true},
        { field: 'mD5', headerName: 'MD5', flex: 1, minWidth: 150, editable: true },
        { field: 'updated', headerName: 'Updated', flex: 1 , minWidth: 150, renderCell: (params) => (moment.utc(params.row.updated).local().format('MM/DD/YY, h:mm a')) },
        { field: 'delete', headerName: 'Delete', flex: 1, minWidth: 150, renderCell: (params) => (
            <Button 
                variant="outlined" 
                color="error" 
                onClick={() => axios.put(`/api/FirmwareVersion/delete/${params.row.id}`,{},{ 'Authorization': `Bearer ${token}` }).then(() => dispatch({type: 'GRAB_ADMIN_FIRMWARE'}))}
            >
                <Trash/>
            </Button>
        )}
    ]

    return (
        <div>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleOpen}
                    >
                        <Plus /> Firmware Version
                    </Button>
                    {Object.keys(editRowsModel).length !== 0? 
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                    >
                        <Save/> save
                    </Button>
                    : ''}
                </div>
                <Popup
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    open={open}
                    close={handleClose}
                >
                    {/* <UploadFirmware/> */}
                    <CreateFirmware close={handleClose}/>
                </Popup>
                <Popup
                    open={hardwareView}
                    close={hardwareClose}
                >
                    <AdminFirmwareHardware data={firmwareHardware} current={currentFirmwareId}/>
                </Popup>
            </div>
            <div style={{ marginTop: '20px', height: 800, overflow: 'scroll' }}>
                <Accordion sx={{ border: '1px solid #e0e0e0', marginTop: '5px' }}>
                    <AccordionSummary expandIcon={<ArrowDown/>}>
                        {Object.keys(firmwareList)[0].toUpperCase()}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Accordion sx={{ border: '1px solid #e0e0e0', marginTop: '5px' }}>
                            <AccordionSummary expandIcon={<ArrowDown/>}>
                                {Object.keys(firmwareList.Signum)[0]}
                            </AccordionSummary>
                            <AccordionDetails sx={{ height: 500 }}>
                                <DataGrid
                                    components={{
                                        Toolbar: GridToolbar
                                    }}
                                    editRowsModel={editRowsModel}
                                    editMode="row"
                                    onRowEditCommit={handleSave}
                                    onEditRowsModelChange={handleEditRowsModelChange}
                                    columns={columns}
                                    rows={firmwareList.Signum.STM}
                                />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{ border: '1px solid #e0e0e0', marginTop: '5px' }}>
                            <AccordionSummary expandIcon={<ArrowDown/>}>
                                {Object.keys(firmwareList.Signum)[1]}
                            </AccordionSummary>
                            <AccordionDetails sx={{ height: 500 }}>
                                <DataGrid
                                    components={{
                                        Toolbar: GridToolbar
                                    }}
                                    editRowsModel={editRowsModel}
                                    editMode="row"
                                    onRowEditCommit={handleSave}
                                    onEditRowsModelChange={handleEditRowsModelChange}
                                    columns={columns}
                                    rows={firmwareList.Signum.ESP}
                                />
                            </AccordionDetails>
                        </Accordion>                        
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{ border: '1px solid #e0e0e0', marginTop: '5px' }}>
                    <AccordionSummary expandIcon={<ArrowDown/>}>
                        {Object.keys(firmwareList)[1].toUpperCase()}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Accordion sx={{ border: '1px solid #e0e0e0', marginTop: '5px' }}>
                            <AccordionSummary expandIcon={<ArrowDown/>}>
                                {Object.keys(firmwareList.Bobcat)[0]}
                            </AccordionSummary>
                            <AccordionDetails sx={{ height: 500 }}>
                                <DataGrid
                                    components={{
                                        Toolbar: GridToolbar
                                    }}
                                    editRowsModel={editRowsModel}
                                    editMode="row"
                                    onRowEditCommit={handleSave}
                                    onEditRowsModelChange={handleEditRowsModelChange}
                                    columns={columns}
                                    rows={firmwareList.Bobcat.CAB}
                                />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{ border: '1px solid #e0e0e0', marginTop: '5px' }}s>
                            <AccordionSummary expandIcon={<ArrowDown/>}>
                                {Object.keys(firmwareList.Bobcat)[1]}
                            </AccordionSummary>
                            <AccordionDetails sx={{ height: 500 }}>
                                <DataGrid
                                    components={{
                                        Toolbar: GridToolbar
                                    }}
                                    editRowsModel={editRowsModel}
                                    editMode="row"
                                    onRowEditCommit={handleSave}
                                    onEditRowsModelChange={handleEditRowsModelChange}
                                    columns={columns}
                                    rows={firmwareList.Bobcat.HUB}
                                />
                            </AccordionDetails>
                        </Accordion>                        
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={{ border: '1px solid #e0e0e0', marginTop: '5px' }}>
                <AccordionSummary expandIcon={<ArrowDown/>}>
                        {Object.keys(firmwareList)[2].toUpperCase()}
                    </AccordionSummary>
                    <AccordionDetails>
                    <AccordionSummary expandIcon={<ArrowDown/>}>
                    {Object.keys(firmwareList.Moog)[0]}
                    </AccordionSummary>
                    <AccordionDetails sx={{ height: 500 }}>
                        <DataGrid
                            components={{
                                Toolbar: GridToolbar
                            }}
                            editRowsModel={editRowsModel}
                            editMode="row"
                            onRowEditCommit={handleSave}
                            onEditRowsModelChange={handleEditRowsModelChange}
                            columns={columns}
                            rows={firmwareList.Moog.EVCM}
                        />
                    </AccordionDetails>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
}

export default AdminFirmware;