import { all, join, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios'; 
import authService from "../../components/api-authorization/AuthorizeService";
import Toast from '../../mixins/Toast';

function* getDeviceData( action ) {
    try {
        yield put({
            type: 'IS_LOADING',
            payload: true
        });

        yield put({
            type: 'GET_USER_DATA',
            payload: action.payload
        });

        const token = yield authService.getAccessToken();

        const user = yield axios.get(`/api/User/${action.payload.id}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

        yield put ({
            type: "SET_SYSTEM_ADMIN",
            payload: user.data.isSystemAdmin
        });

        // let canGroups = yield axios.get('/api/CanGroups', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

        // yield put({
        //     type: "SET_CAN_GROUPS",
        //     payload: canGroups.data
        // });
        
    } catch (error) {
        console.log('Error with getting all device data', error);
    }
};

function* getCanElements(){
    let token = yield authService.getAccessToken();

    let canElements = yield axios.get('/api/CanElement', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

    yield put ({
        type: "SET_CAN_ELEMENTS",
        payload: canElements.data
    })
};

function* getFilePGNs(action){
    let token = yield authService.getAccessToken();
    let PGNs = yield axios.get(`/api/CanFile/PGN/${action.payload}`, {headers: {'Authorization': `Bearer ${token}`}});
    yield put({ type: "UPDATE_CAN_PGN", payload: PGNs.data });
    yield put({ type: "UPDATE_CAN_FILE", payload: PGNs.data });
}

function* getCanPGNs(){
    let token = yield authService.getAccessToken();
    let canPGNs = yield axios.get('/api/CanPGN', {headers: !token ? {} : {'Authorization': `Bearer ${token}`}});
    yield put({ type: "SET_CAN_PGN", payload: canPGNs.data });
}

function* getCanGroups(action){
    let token = yield authService.getAccessToken();
    let canGroups = yield axios.get(`/api/CanGroups/${action.payload}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
    yield put({ type: "SET_CAN_GROUPS", payload: canGroups.data })
};

function* getDeviceInCanGroup(action) {
    let token = yield authService.getAccessToken();
    const devices = yield axios.get(`/api/device/CANGroup/${action.payload}`, {headers: {'Authorization': `Bearer ${token}`}});
    for (const x of devices.data) {
        let swing = yield axios.get(`/api/groupdevices/device/${x.id}`, {headers: {'Authorization': `Bearer ${token}`}});
        if (swing.data.groupId) {
            let group = yield axios.get(`/api/group/${swing.data.groupId}`, {headers: {'Authorization': `Bearer ${token}`}})
            x.group = group.data
        }
    }
    yield put({type: "SET_CAN_GROUP_DEVICES", payload: devices.data})
}

function* BatchFW(action){ //
    try {
        // console.log("batch fw action payload", action.payload) 
        let token = yield authService.getAccessToken();
        let group = action.payload.group;
        let type = action.payload.type
        let fw = action.payload.fw;
        let allDevices = []
        Toast.fire({ title: 'starting update', icon: 'info' });
        /* 
        need to:
        - grab the devices from groups
        - grab firmware version TO BE replaced
        - 
        */
        if (action.payload.group.subGroups !== undefined){
            let device = yield axios.get(`/api/CanGroups/getBatchInfo/${group.id}/${type}`, {headers: {'Authorization': `Bearer ${token}`}});
            allDevices = [...allDevices, ...device.data ];
            for ( let sub of group.subGroups ){
                // console.log('sub groups in loop', sub);
                let devices = yield axios.get(`/api/CanGroups/getBatchInfo/${sub.id}/${type}`, {headers: {'Authorization': `Bearer ${token}`}});
                // console.log('return group ', devices);
                allDevices = [...allDevices, ...devices.data ];
            }
        } else {
            // console.log('in else, no sub groups', group.id);
            let devices = yield axios.get(`/api/CanGroups/getBatchInfo/${group.id}/${type}`, {headers: {'Authorization': `Bearer ${token}`}}); 
            // console.log('return from the thing', devices);
            allDevices = devices.data;
        }
        // device, old, new
        for (let device of allDevices){
            // console.log(`/api/FirmwareVersion/devicetofirmware/update/${device.deviceId}/${device.firmIds}/${fw.id}`);
            if (device.firmIds == '00000000-0000-0000-0000-000000000000' ){
                const NewFW = {
                    DeviceId: device.deviceId,
                    FirmwareVersionId: fw.id
                }
                axios.post('/api/FirmwareVersion/devicetofirmware/new', NewFW, { headers: { 'Authorization': `Bearer ${token}` } })
            } else {
                yield axios.put(`/api/FirmwareVersion/devicetofirmware/update/${device.deviceId}/${device.firmIds}/${fw.id}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
            }
            
        
        }
        Toast.fire({ icon: 'success', title: 'Update Complete' })
    } catch (e) {
        console.log('Error in Batch FW Update: ', e);
        Toast.fire({ icon: 'error', title: 'Error Assigning Firmware' })
    }
}

// Quick update for devices so you don't have to make a large call everytime you update something
function* updateSingleDevice(action) {
    let token = yield authService.getAccessToken();
    yield put({type: "DEVICE_TABLE_LOADING", payload: true});
    yield put({type: "SET_DATA", payload: []});

    let allDevices = [...action.device];
    const device = yield axios.get('/api/device/' + action.id, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
    if(device.status === 200){
        const firmwares = yield axios.get(`/api/FirmwareVersion/devicetofirmware/${action.id}`);
        device.data.stm = null;
        device.data.esp = null;
        device.data.cab = null;
        device.data.hub = null;
        device.data.evcm = null;
        device.data.allFirmwares = firmwares.data;
        for (const x of firmwares.data) {
            if (x.firmwareVersion.controllerType === 0) device.data.stm = x.firmwareVersion;
            if (x.firmwareVersion.controllerType === 1) device.data.esp = x.firmwareVersion;
            if (x.firmwareVersion.controllerType === 2) device.data.cab = x.firmwareVersion;
            if (x.firmwareVersion.controllerType === 3) device.data.hub = x.firmwareVersion;
            if (x.firmwareVersion.controllerType === 4) device.data.evcm = x.firmwareVersion;
        }
        const status = yield axios.get(`/api/DeviceStatus/${action.id}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
        if ( status.data ) {
            let message = {}
            message.statusMessage = JSON.parse(status.data.statusMessage)
            message.created = status.data.created
            device.data.latest = message
        }
        let gnssbool = false;
        if(action.obj){
            if(action.obj.type === 'gnssRate'){
                gnssbool = true;
            }
        }
        allDevices.map((x, i) => {
            if (x.id === device.data.id){
                // console.log('x gnss', x.gnssRate)
                if(gnssbool === true){
                    device.data.gnssRate = action.obj.value;
                    // console.log('device data gnss', device.data.gnssRate);
                }
                allDevices.splice(i, 1)
                allDevices.push(device.data)
            }
        })
        //console.log("All Devices: ", allDevices);
        yield put({type: "SET_DATA", payload: allDevices })
        yield put({type: "DEVICE_TABLE_LOADING", payload: false});
    }
}
function* grabGnssRecord(action) {
    yield put({ type: 'GNSS_RECORD_LOADING', payload: true });
    yield put({ type: 'GNSS_RECORD_NOT_FOUND', payload: false });
    let token = yield authService.getAccessToken();
    let url = '/api/GnssData/latest/' + action.payload;
    //console.log(url);
    try {
        const retval = yield axios.get(url, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
        if(retval.data){
            yield put({ type: "GNSS_RECORD", payload: retval.data });
        }
        else {
            yield put({ type: 'GNSS_RECORD_NOT_FOUND', payload: true });
        }
    } catch (error) {
        yield put({ type: 'GNSS_RECORD_NOT_FOUND', payload: true });
    } finally {
        yield put({ type: 'GNSS_RECORD_LOADING', payload: false });
    }

}

function* DataSaga() {
    yield takeLatest('GRAB_DATA', getDeviceData);
    yield takeLatest('GRAB_CAN_ELEMENTS', getCanElements);
    yield takeLatest('GRAB_CAN_PGNS', getCanPGNs);
    yield takeLatest('GRAB_FILE_PGN', getFilePGNs);
    yield takeLatest('GRAB_CAN_GROUPS', getCanGroups);
    yield takeLatest('GRAB_CAN_GROUP_DEVICES', getDeviceInCanGroup);
    yield takeLatest('UPDATE_DEVICELIST', updateSingleDevice);
    yield takeLatest('GRAB_GNSS_RECORD', grabGnssRecord);
    yield takeLatest('BATCH_FW_UPDATE', BatchFW)
}

export default DataSaga;
