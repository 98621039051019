import React from 'react';
import {
    Button,
    Stepper,
    Step,
    StepLabel,
    Box,
    TextField,
    IconButton,
    Tooltip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Checkbox
} from '@mui/material';
import { DataGrid, GridFooter } from '@mui/x-data-grid';
import { styled } from '@mui/system';
import { PlusCircle, CornerRightDown } from 'react-feather';
import Row from './Row';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Toast from '../../../mixins/Toast';
  

function getSteps() {
    return ['PGN', 'SPN', 'Overview & Save']
};

const Input = styled(TextField)({
    margin: '5px'
})

let example = {
    id: 0,
    name: '',
    bitStart: '',
    bitLength: '',
    endiannessType: '',
    scale: '',
    offset: '',
    min: '',
    max: '',
    unit: '',
    receiver: '',
    signed: false
}

const PgnCreation = () => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [pgn, setPgn] = React.useState({
        CanIdent: null,
        Name: null,
        ByteLength: null,
        Sender: null
    });
    const [index, setIndex] = React.useState(1);
    const [editRowsModel, setEditRowsModel] = React.useState({});
    const [rows, setRows] = React.useState([example]);
    const [name, setName] = React.useState(false);
    const [canId, setCanId] = React.useState(false);
    const [byteLength, setByteLength] = React.useState(false);
    const [sender, setSender] = React.useState(false);
    const token = useSelector((state) => state.setUser.token);
    const steps = getSteps();

    const handleNext = () => {
        if (activeStep === 2) {
            axios.post('/api/CanPGN', {
                CanIdent: Number(pgn.CanIdent),
                Name: pgn.Name,
                ByteLength: Number(pgn.ByteLength),
                Sender: pgn.Sender
            }, {headers: {'Authorization': `Bearer ${token}`}})
            .then((response) => {
                for (const x of rows) {
                    let item = {
                        CanPGNId: response.data,
                        Name: x.name,
                        BitStart: Number(x.bitStart),
                        BitLength: Number(x.bitLength),
                        EndiannessType: Number(x.endiannessType),
                        Scale: parseFloat(x.scale),
                        Offset: parseFloat(x.offset),
                        Min: parseFloat(x.min),
                        Max: parseFloat(x.max),
                        Unit: x.unit,
                        Receiver: x.receiver,
                        IsSigned: x.signed
                    }
                    axios.post('/api/CanSPN', item, {headers: {'Authorization': `Bearer ${token}`}})
                    .catch((error) => console.log(error))
                }
            })
            .then(() => Toast.fire({ icon: 'success', title: 'PGN Created' }))
            .then(() => {
                setActiveStep(0)
                setRows([example])
                setIndex(1)
                setPgn({
                    CanIdent: null,
                    Name: null,
                    ByteLength: null,
                    Sender: null
                })
            })
            .catch((error) => console.log(error))
        }
        else {
            if (activeStep === 0) {
                if (!pgn.Name) {
                    return setName(true)
                }
                else {
                    setName(false)
                }
                if (!pgn.CanIdent) {
                    return setCanId(true)
                }
                else {
                    setCanId(false)
                }
                if(!pgn.ByteLength) {
                    return setByteLength(true)
                }
                else {
                    setByteLength(false)
                }
                if(!pgn.Sender) {
                    return setSender(true)
                }
                else {
                    setSender(false)
                }
                return setActiveStep((prevActiveStep) => prevActiveStep + 1)
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
        }
    };

    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

    const columns = [
        { field: 'name', headerName: 'Name', flex: 3, minWidth: 150, editable: true },
        { field: 'bitStart', headerName: 'Bit Start', flex: 2, minWidth: 50, editable: true },
        { field: 'bitLength', headerName: 'Bit Length', flex: 2, minWidth: 50, editable: true },
        { field: 'endiannessType', headerName: 'Endianness Type', flex: 2, minWidth: 50, editable: true },
        { field: 'scale', headerName: 'Scale', flex: 2, minWidth: 50, editable: true },
        { field: 'offset', headerName: 'Offset', flex: 2, minWidth: 50, editable: true },
        { field: 'scale', headerName: 'Scale', flex: 2, minWidth: 50, editable: true },
        { field: 'min', headerName: 'Min', flex: 2, minWidth: 50, editable: true },
        { field: 'max', headerName: 'Max', flex: 2, minWidth: 50, editable: true },
        { field: 'unit', headerName: 'Unit', flex: 2, minWidth: 50, editable: true },
        { field: 'receiver', headerName: 'Receiver', flex: 2, minWidth: 50, editable: true },
        { field: 'signed', headerName: 'Signed', flex: 2, minWidth: 50, renderCell: (params) => (
            <Checkbox checked={params.row.signed} onClick={(event) => params.row.signed = event.target.checked } />
        ) },
        { field: 'copy', headerName: 'Copy', flex: 2, minWidth: 30, renderCell: (params) => (
            <IconButton
                variant='outlined'
                onClick={() => {
                    setIndex(index + 1)
                    return (setRows([...rows, {...params.row, id: index}])
                    )
                }}
            >
                <CornerRightDown/>
            </IconButton>
        ) }
    ]

    const handleEditRowsModelChange = React.useCallback((model) => {
        setEditRowsModel(model);
    }, []);

    function customFooter() {
        return (
            <Box
                sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'transparent' }}
            >
                <Tooltip title='New Row'>
                    <IconButton
                        sx={{ margin: '5px' }}
                        onClick={() => {
                            setIndex(index + 1)
                            return (setRows([...rows, {...example, id: index}])
                            )
                        }}
                    >
                        <PlusCircle/>
                    </IconButton>
                </Tooltip>
                <GridFooter/>
            </Box>
        )
    }

    const handleCommit = () => {
        const objId = Object.keys(editRowsModel)[0];
        const obj = Object.values(editRowsModel)[0];
        const newObj = {id: Number(objId), bitLength: obj.bitLength.value, bitStart: obj.bitStart.value, endiannessType: obj.endiannessType.value, max: obj.max.value, min: obj.min.value, name: obj.name.value, offset: obj.offset.value, receiver: obj.receiver.value, scale: obj.scale.value, signed: rows[objId].signed, unit: obj.unit.value }
        setRows((current) => current.map((x) => {
            return x.id === Number(objId) ? { ...newObj } : x
        }))
    }
    function getStepContent(stepIndex) {
        let display = '';
        switch (stepIndex) {
            case 0:
                display = (
                    <Box
                        sx={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <Box>
                            <Input
                                variant='outlined'
                                label='Name'
                                error={name}
                                onChange={(event) => setPgn({...pgn, Name: event.target.value})}
                            />
                            <Input
                                variant='outlined'
                                label='CanId'
                                error={canId}
                                onChange={(event) => setPgn({...pgn, CanIdent: event.target.value})}
                            />
                        </Box>
                        <Box>
                            <Input
                                variant='outlined'
                                label='Byte Length'
                                type={'number'}
                                error={byteLength}
                                onChange={(event) => setPgn({...pgn, ByteLength: event.target.value})}
                            />
                            <Input
                                variant='outlined'
                                label='Sender'
                                error={sender}
                                onChange={(event) => setPgn({...pgn, Sender: event.target.value})}
                            />
                        </Box>
                    </Box>
                )
                return display
            case 1:
                display = (
                <Box
                    sx={{ height: '300px', width: '98%'}}
                >
                    <DataGrid 
                        columns={columns}
                        rows={rows}
                        editMode="row"
                        onRowEditStop={handleCommit}
                        onEditRowsModelChange={handleEditRowsModelChange}
                        onRowEditCommit={handleCommit}
                        components={{
                            Footer: customFooter,
                          }}
                        
                    />
                </Box>
                )
                return display;           
            case 2:
                display = (
                    <Box
                        sx={{ width: '100%', height: '300px', overflow: 'auto' }}
                    >
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>Name</TableCell>
                                        <TableCell>Can Id</TableCell>
                                        <TableCell>Byte Length</TableCell>
                                        <TableCell>Sender</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <Row key={pgn.Name} row={pgn} spn={rows} />
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )
                return display;
            default:
                return display;
        }
    }
    return (
        <Box
            sx={{ width: '95%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}
        >
            <Stepper
                activeStep={activeStep}
                alternativeLabel
            >
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
                <Box
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}
                >
                    {getStepContent(activeStep)}
                </Box>
                <Box
                    sx={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px'}}
                >
                    <Button
                        variant='outlined'
                        color='error'
                        disabled={activeStep === 0 ? true : false}
                        onClick={handleBack}
                    >
                        Back
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleNext}
                    >
                        {activeStep === 2 ? 'Save' : 'Next'}
                    </Button>
                </Box>
        </Box>
    )
};

export default PgnCreation;