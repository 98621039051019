import { all, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import authService from "../../components/api-authorization/AuthorizeService";

var deviceArray = [];
var groupArray = [];
var fileArray = [];
var pgnArray = [];
var canArray = [];
// Loops thru all groups connected to your current group
function* SubGroupRecursive( x ) { 
    // console.log("SubGroupRecursive start: ", Date.now());
    let token = yield authService.getAccessToken();

    const subDevices = yield axios.get('/api/groupdevices/' + x.id, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
    // structures the device information for pages to display
    if (subDevices.data.length > 0){
        for (const i of subDevices.data) {
            const subDevice = yield axios.get('/api/device/'+ i.deviceId, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
            const status = yield axios.get(`/api/DeviceStatus/${i.deviceId}`, {headers: !token ? {} : { 'Authoriaztion': `Bearer ${token}`}});
            if(subDevice.status === 200){
                subDevice.data.stm = {};
                subDevice.data.esp = {};
                subDevice.data.cab = {};
                subDevice.data.hub = {};
                subDevice.data.evcm = {};
                const firmwares = yield axios.get(`/api/FirmwareVersion/devicetofirmware/${i.deviceId}`);
                for (const x of firmwares.data) {
                    if (x.firmwareVersion.controllerType === 0) subDevice.data.stm = x.firmwareVersion;
                    if (x.firmwareVersion.controllerType === 1) subDevice.data.esp = x.firmwareVersion;
                    if (x.firmwareVersion.controllerType === 2) subDevice.data.cab = x.firmwareVersion;
                    if (x.firmwareVersion.controllerType === 3) subDevice.data.hub = x.firmwareVersion;
                    if (x.firmwareVersion.controllerType === 4) subDevice.data.evcm = x.firmwareVersion;
                }
                if(firmwares.data.length > 0) subDevice.data.allFirmwares = firmwares.data;
                if( status.data ) {
                    let message = {}
                    message.statusMessage = JSON.parse(status.data.statusMessage)
                    message.created = status.data.created
                    subDevice.data.latest = message
                }
                deviceArray.push(subDevice.data)
            }
        }
    };

    const subGroups = yield axios.get('/api/group/sub/' + x.id, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

    if ( subGroups.data.length > 0 ){
        x.subGroups = subGroups.data;
        for (const x of subGroups.data) {
            const files = yield axios.get(`/api/CanFile/${x.id}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
            if (files.data) {
                for (const y of files.data) {
                    const pgns = yield axios.get(`/api/CanFile/${y.id}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}`}});
                    if (pgns.data) {
                        y.pgn = pgns.data
                        pgns.data.forEach((x) => pgnArray.push(x))
                    }
                    else {
                        y.pgn = []
                    }
                    fileArray.push(y)   
                }
            }
            groupArray.push(x)
            yield SubGroupRecursive(x)
        }
    }
    else{
        // console.log("SubGroupRecursive end: ", Date.now());
        return;
    }
    // console.log("SubGroupRecursive end: ", Date.now());
}

// This function grabs all the data when you first load the page or change groups
function* NewData( action ) {
    // console.log("NewData start: ", Date.now()/1000);
    try {
        groupArray = [];
        deviceArray = [];
        fileArray = [];
        pgnArray = [];
        canArray = [];

        let usersInGroup = []

        yield put({
            type: 'IS_LOADING',
            payload: true
        });

        yield put({
            type: "RAW_DATA_LOADING",
            payload: true
        });
        // console.log("timestamp 100: ", Date.now()/1000);
        // Sets the Current viewing group
        yield put({
            type: "SET_CURRENT_GROUP",
            payload: action.payload
        });

        yield put({
            type: "GET_CHART_DATA",
            payload: action.user.id
        });

        // console.log("timestamp 200: ", Date.now()/1000);
        if (typeof action.payload != 'undefined')
        {
            let token = yield authService.getAccessToken();

            // grabs all sub groups for current group
            let retval = yield axios.get('/api/group/IncludeSubgroups/' + action.payload.id, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
            groupArray = retval.data;
            let groupIds = groupArray.map(g => g.id);

            // console.log("timestamp 210: ", Date.now()/1000);
            //Grabs all DBC files for the groups
            retval = yield axios.get(`/api/CanFile/ForGroups/${groupIds.join(",")}`, { headers: !token ? {} : { 'Authorization': `Bearer ${token}`} });
            fileArray = retval.data;

            // grabs all the pgns available.
            let canPGNs = yield axios.get('/api/CanPGN', {headers: !token ? {} : {'Authorization': `Bearer ${token}`}});
            pgnArray = canPGNs.data 

            // console.log("timestamp 220: ", Date.now()/1000);
            // creates data structure for devices
            retval = yield axios.get('/api/device/GetAllDescendantsForGroupId/' + action.payload.id, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
            deviceArray = retval.data;

            // console.log("timestamp 230: ", Date.now()/1000);
            yield put({
                type: "GRAB_CAN_GROUPS",
                payload: action.payload.id
            })
    
            // console.log("timestamp 240: ", Date.now()/1000);
            yield put({
                type: 'SET_DATA',
                payload: deviceArray
            });

            yield put({
                type: "SET_CAN_FILES",
                payload: fileArray
            });
            yield put({
                type: 'SET_CAN_PGN',
                payload: pgnArray
            })
            //console.log("*** ACTION PAYLOAD" + action)
            // console.log("timestamp 250: ", Date.now()/1000);
            const groupRole = yield axios.get(`/api/usergroup/role/${action.user.id}/${action.payload.id}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
    
            // console.log("timestamp 260: ", Date.now()/1000);
            if ( groupRole.data === '' ) {
                const parentGroupRole = yield axios.get(`/api/usergroup/role/${action.user.id}/${action.payload.id}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
                const parentRole = yield axios.get(`/api/GroupRole/${parentGroupRole.data.groupRoleId}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
                yield put({
                    type: "SET_CURRENT_ROLE",
                    payload: parentRole.data
                })
            }
            else{
                const role = yield axios.get(`/api/GroupRole/${groupRole.data.groupRoleId}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
                yield put({
                    type: "SET_CURRENT_ROLE",
                    payload: role.data
                })
            }

            // Grabs all the users that belong to current viewing group
            // console.log("timestamp 270: ", Date.now()/1000);
            const groupUsers = yield axios.get(`/api/usergroup/group/${action.payload.id}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
            // console.log("timestamp 270: ", Date.now()/1000);
            // let usersWithGroups = yield axios.get(`/api/usergroup/UsersWithRoles/${action.payload.id}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
            // let groupRoles = {};
            // let userIdToGroup = {};
            // console.log(usersWithGroups);

            // usersWithGroups.data.userGroupGroupRoles.foreach(uggr => {
            //     usersInGroup.data.applicationUsers.foreach(user =>) {

            //     }
            // });

            // console.log(retval);
            // console.log("timestamp 300: ", Date.now()/1000);
            if(groupUsers.data.length > 0){
                // console.log(groupUsers.data);
                for (const x of groupUsers.data) {
                    const getUserData = yield axios.get(`/api/User/${x.applicationUserId}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
                    // console.log(getUserData);
                    if(getUserData.data != "")
                    {
                        const userGroupRole = yield axios.get(`/api/usergroup/role/${getUserData.data.id}/${action.payload.id}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
                        const userRole = yield axios.get(`/api/GroupRole/${userGroupRole.data.groupRoleId}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}`}})
                        getUserData.data.role = userRole.data
                        usersInGroup.push(getUserData.data)
                    }
                }
            }
            // console.log(usersInGroup);
            // console.log("timestamp 310: ", Date.now()/1000);

            // This will be changed ///////////////////////////////
            //TODO: this is the spot I will bring in the firmware versions by groups
            const firmwares = yield axios.get('/api/FirmwareVersion', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
            // console.log("timestamp 320: ", Date.now()/1000);
            const allFirmwares = {Signum:{STM: [], ESP:[]}, Bobcat:{CAB:[], HUB:[]}, Moog:{EVCM:[]}};
            // console.log("timestamp 330: ", Date.now()/1000);
            firmwares.data.forEach((x) => {
                switch (x.controllerCompany) {
                    case 0:
                        if (x.controllerType === 0) return allFirmwares.Signum.STM.push(x);
                        else return allFirmwares.Signum.ESP.push(x);
                    case 1:
                        if (x.controllerType === 2) return allFirmwares.Bobcat.CAB.push(x);
                        else return allFirmwares.Bobcat.HUB.push(x);
                    case 2:
                        if (x.controllerType === 4) return allFirmwares.Moog.EVCM.push(x);
                    default:
                        break;
                }
            })
            // console.log("timestamp 340: ", Date.now()/1000);
            yield put({ type: "SET_FIRMWARE", payload: allFirmwares });
            yield put({ type: "SET_ALL_FIRMWARE", payload: firmwares.data })
            // console.log("timestamp 350: ", Date.now()/1000);
            const hardware = yield axios.get('/api/HardwareFamily', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
    
            // console.log("timestamp 360: ", Date.now()/1000);
            yield hardware.data.forEach(element => {
                let data = []
                axios({
                    method: 'get',
                    url: `/api/HardwareVersion/${element.id}`,
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                }).then(response => {
                    if(response.data){
                        data = response.data
                    }
                    element.version = data
                })
            });
    
            // console.log("timestamp 400: ", Date.now()/1000);
            yield put({
                type: "SET_HARDWARE",
                payload: hardware.data
            });
    
            // console.log("timestamp 500: ", Date.now()/1000);
            const hardwareVersions = yield axios.get('/api/HardwareVersion', {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
    
            yield put({
                type: "ADMIN_HARDWARE_VERSIONS",
                payload: hardwareVersions.data
            });
        }

        // console.log("timestamp 600: ", Date.now()/1000);
        yield put({
            type: "SET_CURRENT_GROUPS",
            payload: groupArray
        })

        // console.log("timestamp 700: ", Date.now()/1000);
        yield put({
            type: "SET_GROUP_USERS",
            payload: usersInGroup
        });

        // console.log("timestamp 800: ", Date.now()/1000);
        yield put({
            type: "RAW_DATA_LOADING",
            payload: false
        });

        // console.log("timestamp 900: ", Date.now()/1000);
        yield put({
            type: 'IS_LOADING',
            payload: false
        });

    } catch (error) {
        console.log(`error with change current group: ${error}`)
    }
    // console.log("NewData end: ", Date.now()/1000);
}

function* JustDevices(action){
    yield put({
        type: "DEVICE_PAGE_LOADING",
        payload: true
    })
    let token = yield authService.getAccessToken();
    deviceArray = [];

    const subGroups = yield axios.get('/api/group/sub/' + action.payload.id, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

    if (subGroups.data.length > 0){
        action.payload.subGroups = subGroups.data
        groupArray.push(action.payload)
        for (const x of subGroups.data) {
            groupArray.push(x)
            yield SubGroupRecursive(x)   
        }
    }
    else {
        groupArray.push(action.payload);
    }

    const devices = yield axios.get('/api/groupdevices/' + action.payload.id, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});

    for (const x of devices.data) {
        const device = yield axios.get('/api/device/' + x.deviceId, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }})
        if(device.status === 200){
            const firmwares = yield axios.get(`/api/FirmwareVersion/devicetofirmware/${x.deviceId}`);
            device.data.stm = {};
            device.data.esp = {};
            device.data.cab = {};
            device.data.hub = {};
            device.data.evcm = {};
            for (const x of firmwares.data) {
                if (x.firmwareVersion.controllerType === 0) device.data.stm = x.firmwareVersion;
                if (x.firmwareVersion.controllerType === 1) device.data.esp = x.firmwareVersion;
                if (x.firmwareVersion.controllerType === 2) device.data.cab = x.firmwareVersion;
                if (x.firmwareVersion.controllerType === 3) device.data.hub = x.firmwareVersion;
                if (x.firmwareVersion.controllerType === 4) device.data.evcm = x.firmwareVersion;
            }
            const status = yield axios.get(`/api/DeviceStatus/${x.deviceId}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }});
            if ( status.data ) {
                let message = {}
                message.statusMessage = JSON.parse(status.data.statusMessage)
                message.created = status.data.created
                device.data.latest = message
            }
            deviceArray.push(device.data)
        }
    }
    yield put({
        type: 'SET_DATA',
        payload: deviceArray
    });
    yield put({
        type: "DEVICE_PAGE_LOADING",
        payload: false
    })
}

function* GroupChange(){
    yield takeLatest("CHANGE_GROUP", NewData);
    yield takeLatest("GRAB_DEVICES", JustDevices);

}

export default GroupChange;