import React, { useState, useEffect } from 'react';
import {
    Box,
} from "@mui/material";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { GrandCentralDispatch } from '../../../utils/GrandCentralDispatch';
import authService from '../../../components/api-authorization/AuthorizeService';
import { Tooltip, Typography } from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';
//import Polyline from '../../../components/Polyline';
import "./ServiceMap.css";
import { Helmet } from 'react-helmet';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
class Bounds {
    constructor() {
        this.ne = {lat: NaN, lng: NaN};
        this.sw = {lat: NaN, lng: NaN};
    }

    extend(point) {
        this.ne.lat = isNaN(this.ne.lat) ? point.lat : this.ne.lat > point.lat ? this.ne.lat : point.lat;
        this.ne.lng = isNaN(this.ne.lng) ? point.lng : this.ne.lng > point.lng ? this.ne.lng : point.lng;
        this.sw.lat = isNaN(this.sw.lat) ? point.lat : this.sw.lat < point.lat ? this.sw.lat : point.lat;
        this.sw.lng = isNaN(this.sw.lng) ? point.lng : this.sw.lng < point.lng ? this.sw.lng : point.lng;
    }

    getCenterPoint() {
        return {
            lat: (this.ne.lat + this.sw.lat) / 2,
            lng: (this.ne.lng + this.sw.lng) / 2
        }
    }
} 

const FleetMap = (props) => {
    const [zoom, setZoom] = useState(10);
    const [coordinates, setCoordinates] = useState([]);
    const [points, setPoints] = useState([]);
    const [center, setCenter] = useState({lng:-96.7898,lat:46.8772});    
    const [mapRef, setMapRef] = useState(React.createRef());
    const [mapKey, setMapKey] = useState(0);
    const [pointReturn, setPointReturn ] = useState(0);
    const dispatch = useDispatch();
    // const searchDevice = useSelector((state) => state.setUser.selectedSearchDevices)
    // console.log('search device', searchDevice);
    // console.log('1 window google', window.google);
    const reset = () => {
        setMapKey(Math.random());
        // console.log("NUMBER",mapKey)
    }

    // console.log("POINTS", points);

    const dataPoints = [];
    points.forEach(pointPusher);
    function pointPusher(points) {
        dataPoints.push({
            lat: points.lat,
            lng: points.lng,
        })
    };
    // console.log("DataPoints in service",dataPoints);

    const infoPoints = [];
    points.forEach(infoPusher);
    function infoPusher(points) {
        infoPoints.push({
            IMEI: points.id,
            Serial: points.name,
            Location: points.lat/ points.lng,
            Created: points.created
        })
    };

    const updatePoints = (payload) => {
        setPoints([]);
        // console.log('payload in update points', payload);
        let devices = payload.devices;
        // let limit = payload.limit;
        authService.getAccessToken().then((token) => {
            axios.put(`/api/LocationHistory/Multiple/${payload.startDate}/${payload.endDate}`, devices,
            {
                headers: {
                  Authorization: 'Bearer ' + token
                }
            }).then((retval) => {
                // console.log('return location retval', retval);
                setPointReturn(retval.status)
                let newPoints = [];
                let tempCoords = []
                let bounds = new Bounds();
                let coordinate = {};
                if (retval.data.histories.length >= 2000){ //
                    retval.data.histories.forEach((element, i) => {
                        if (i % 4 === 0 ){
                            coordinate = {"lat":element.latitude,"lng":element.longitude};
                            tempCoords.push(coordinate)
                            let point = {
                                id: element.device.externalId,
                                lat: element.latitude,
                                lng: element.longitude,
                                name: retval.data.status[0].serialNumber,
                                created: element.created
                            };
                            bounds.extend(point);
                            newPoints.push(point);
                        }
                    });
                    dispatch({ type: 'GPS_PARING', payload: {reduce: '1/4', total:retval.data.histories.length, display: newPoints.length }});
                } else if (retval.data.histories.length >= 1000){
                    retval.data.histories.forEach((element, i) => {
                        if (i % 3 === 0 ){
                            coordinate = {"lat":element.latitude,"lng":element.longitude};
                            tempCoords.push(coordinate)
                            let point = {
                                id: element.device.externalId,
                                lat: element.latitude,
                                lng: element.longitude,
                                name: retval.data.status[0].serialNumber,
                                created: element.created
                            };
                            bounds.extend(point);
                            newPoints.push(point);
                        }
                    });
                    dispatch({ type: 'GPS_PARING', payload: {reduce: '1/3', total:retval.data.histories.length, display: newPoints.length }});
                } else {
                    retval.data.histories.forEach((element, i) => {
                        coordinate = {"lat":element.latitude,"lng":element.longitude};
                        tempCoords.push(coordinate)
                        let point = {
                            id: element.device.externalId,
                            lat: element.latitude,
                            lng: element.longitude,
                            name: retval.data.status[0].serialNumber,
                            created: element.created
                        };
                        bounds.extend(point);
                        newPoints.push(point);
                    });
                    dispatch({ type: 'GPS_PARING', payload: 0});
                }
                setCoordinates(tempCoords)
                setPoints(newPoints);                
                let centerPoint = bounds.getCenterPoint();
                setCenter(centerPoint);
                let west = bounds.ne.lng;
                let east = bounds.sw.lng;
                let north = bounds.ne.lat;
                let south = bounds.sw.lat;
                let angle = east - west < north - south ? east - west : north - south;
                while (angle < 0) {
                    angle += 360;
                }
                let myZoom = Math.round(Math.log(10000 /* <- magic number ¯\_(ツ)_/¯ */ / angle ) / Math.LN2);
                setZoom(myZoom);         
                });
            });
            initMap();
    };

    function initMap() {
        if(window.google === undefined){
            return
        }
        const map = new window.google.maps.Map(document.getElementById("map"), {
          zoom: zoom,
          center: center,
        });

        const infoWindow = new window.google.maps.InfoWindow({
          content: infoPoints.Device,
          disableAutoPan: true,
        });

        // Create an array of alphabetical characters used to label the markers.
        // Add some markers to the map.
        const markers = points.map((position, i) => {
        //   const label = labels[i % labels.length];
          const label = `${points.indexOf(position)}`;
          const marker = new window.google.maps.Marker({
            position,
            label,
          });
          // markers can only be keyboard focusable when they have click listeners
          // open info window when marker is clicked
          const windowContent = `Name: ${points[i].name}<br/> Device: ${points[i].id}<br/> Created: ${points[i].created}<br/> Location: ${points[i].lat} / ${points[i].lng}`
          marker.addListener("click", () => {
            infoWindow.setContent(windowContent);
            infoWindow.open(map, marker);
          });
          return marker;
        });
        const lineSymbol = {
            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          };
        const machinePath = new window.google.maps.Polyline({
            path: dataPoints,
            geodesic: true,
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 2,  
            icons: [
                {
                  icon: lineSymbol,
                  offset: "100%",
                },
              ],    
        })
        
        machinePath.setMap(map);
      
        // Add a marker clusterer to manage the markers.
        new MarkerClusterer({ markers, map });
      }
      
    window.initMap = initMap;

    useEffect(() => {
        GrandCentralDispatch.subscribe("SELECTED_SEARCH_DEVICES_UPDATED", updatePoints);
        return function cleanup() {
            GrandCentralDispatch.unsubscribe("SELECTED_SEARCH_DEVICES_UPDATED", updatePoints);
        }
    });
    if(points.length > 0) {
        return (
            <Box key={mapKey} width={'100%'} height={'100%'} value={points.length}>                    
                <div id='map' className='map'>
                    <Helmet >
                        <script async defer src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDT5lF0ro8rCWUtQQHeXCMzGgfbpM_SafA&callback=initMap"></script>
                    </Helmet>
                </div>
                
            </Box>
        )
    } else {
        return (
            <Box width={'100%'} height={'100%'}>
                { pointReturn === 0 ?
                    <div><p>Please select device to load Map data.</p></div>
                    : 
                        pointReturn === 200 ?
                        <div><p style={{color: 'red'}}>No map data for this device between dates.</p></div> 
                        :
                        <p>No Map Data for Machine</p>
                }
            </Box>
        )
    }
}
export default FleetMap;