import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    Autocomplete,
    TextField,
    Grid,
    Divider,
    Select,
    MenuItem
} from "@mui/material";
import { Plus, ArrowLeft, Save } from "react-feather";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Toast from "../../../mixins/Toast";

let triggers = [
    // {name: "RSVD", id: 0},
    {name: "PERIODIC", id: 1},
    // {name: "REQUEST", id: 2},
    {name: "ON_WAKE", id: 3},
    {name: "ON_SLEEP", id: 4},
    // {name: "GREATER_THAN", id: 5},
    // {name: "LESS_THAN", id: 6},
    // {name: "ENGINE_ON", id: 7}
];

const findName = (type) => {
    switch (type) {
        case 1:
            return "PERIODIC"
        case 2:
            return "REQUEST"
        case 3:
            return "ON_WAKE"
        case 4:
            return "ON_SLEEP"
        case 7:
            return "ENGINE_ON"
        default:
            return "PERIODIC"
    }
}

const ElementEdit = React.forwardRef((props, ref) => {
    const [open, setOpen] = React.useState(false);
    const [elementsToAdd, setElementsToAdd] = React.useState([]);
    const [editRowsModel, setEditRowsModel] = React.useState({});
    const canPGNs = useSelector((state) => state.deviceList.canPGNs);
    const token = useSelector((state) => state.setUser.token);
    const userRole = useSelector((state) => state.setUser.currentRole);
    const dispatch = useDispatch();    
    
    useEffect(() => {
        dispatch({ type: 'GRAB_CAN_PGNS' })
    },[]);

    const handleClick = () => {
        if(elementsToAdd.length === 0 ){
            return
        }
        else{
            setOpen(true)
        }
    };
    for (let pgn of props.data){
        for (let i = 0; i < canPGNs.length; i++ ){
            if (pgn.name === canPGNs[i].name){
                canPGNs.splice(i,1);
                i--;
            }
        }
    }
    
    const handleEdit = () => {
        const objId = Object.keys(editRowsModel)[0];
        const obj = Object.values(editRowsModel)[0];
        axios.put(`/api/CanGroupPGN/${objId}`, {
            CollectionFrequency: Number(obj.collectionFrequency.value),
            ReportFrequency: Number(obj.reportFrequency.value),
            CanTriggerType: Number(props.data[0].canTriggerType)
        },{headers: !token ? {} : { 'Authorization': `Bearer ${token}` }}).then((response) => {
            if ( response.status === 200 ) {
                props.refresh(props.id)
            }
        }).then(() => {
            Toast.fire({ icon:'success', title: 'PGN Updated'})
        })
        .catch((error) => console.log(`Problem with updating Can Element: ${error}`))

    };

    const handleEditRowsModelChange = React.useCallback((model) => {
        setEditRowsModel(model);
    }, []);

    const handleSave = () => {
        for (const x of elementsToAdd) {
            console.log(x.canTriggerType)
            axios.post('/api/CanGroupPGN', {
                Name: x.name,
                CanGroupId:  props.id,
                CanPGNId: x.id,
                CanTriggerType: x.canTriggerType === undefined ? 1 : x.canTriggerType,
                CollectionFrequency: x.collectionFrequency,
                ReportFrequency: x.reportFrequency
            },{headers: !token ? {} : { 'Authorization': `Bearer ${token}` }}).then((response) => props.refresh(props.id))
        }
        return setOpen(false)
    }

    const columns = [
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 150 },
        { field: 'canTriggerType', headerName: 'Trigger Type', flex: 1, minWidth: 150, renderCell: (params) => (
            <Autocomplete
                options={triggers}
                style={{ width: '100%' }}
                onChange={(event, newValue) => {
                    console.log(newValue);
                    console.log(params.row);
                    axios.put(`/api/CanGroupPGN/${params.row.id}`, {
                        CollectionFrequency: Number(params.row.collectionFrequency),
                        ReportFrequency: Number(params.row.reportFrequency),
                        CanTriggerType: Number(newValue.id)
                    },{headers: !token ? {} : { 'Authorization': `Bearer ${token}` }}).then((response) => {
                        if ( response.status === 200 ) {
                            props.refresh(props.id)
                        }
                    }).then(() => {
                        Toast.fire({ icon:'success', title: 'PGN Updated'})
                    })
                    .catch((error) => console.log(`Problem with updating Can Element: ${error}`))
                }}
                defaultValue={params.row.canTriggerType}
                isOptionEqualToValue={(option, value) => option.id === value}
                getOptionLabel={(option) => option.name ? option.name : findName(option)}
                renderInput={(params) => <TextField {...params} variant="standard"/>}
            />
        ) },
        { field: 'collectionFrequency', headerName: 'Collection Frequency', flex: 1, minWidth: 150, editable: true },
        { field: 'reportFrequency', headerName: 'Report Frequency', flex: 1, minWidth: 150, editable: true },
        { field: 'remove', headerName: 'Remove', flex: 1, minWidth: 150, renderCell: (params) => (
            <Button
                variant="contained"
                color="error"
                onClick={() => {
                    axios.delete(`/api/CanGroupPGN/${params.row.id}`, {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }})
                    .then((x)=> props.refresh(params.row.canGroupId))
                    .then(() => Toast.fire({ icon:'info', title: 'Element Deleted'}))
                    .catch((error) => console.log(`error with deleting Element from Group: ${error}`))
                }}
            >
                Remove
            </Button>
         ) }
    ];
    return (
        <Card
            sx={{ height: '100%', margin: '15px' }}
        >
            {open === false ?
            <React.Fragment>
                { userRole.name !== "Read" ?
                <Box
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                    <Box
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <Autocomplete
                            multiple
                            id="element-box"
                            disableCloseOnSelect
                            limitTags={1}
                            options={canPGNs.filter(({ id: id1 }) => !props.data.some(({ canPGNId: id2 }) => id2 === id1))}
                            onChange={(event, newValue) => {
                                setElementsToAdd(newValue)
                            }}
                            getOptionLabel={(option) => option.name}
                            sx={{width: 500, margin: '15px'}}
                            renderInput={(params) => <TextField {...params} label="Select PGNs" variant="outlined" />}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ height: '56px' }}
                            onClick={handleClick}
                        >
                            <Plus/> Add
                        </Button>
                    </Box>
                    <Box
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        {Object.keys(editRowsModel).length !== 0 ?
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleEdit}
                            sx={{ height: '56px' }}
                        >
                            <Save/> Save
                        </Button>
                        : '' }
                    </Box>
                </Box>
                : "" }
                    <DataGrid
                        rows={props.data}
                        columns={columns}
                        density="compact"
                        editRowsModel={editRowsModel}
                        editMode="row"
                        onRowEditCommit={handleEdit}
                        onEditRowsModelChange={handleEditRowsModelChange}
                        components={{
                            Toolbar: GridToolbar
                        }}
                    />
            </React.Fragment>
            :
            <Card sx={{ height: '100%', width: '100%', margin: '10px', overflow: 'scroll' }}>
                <Box
                    sx={{ display: 'flex', justifyContent: 'space-between'}}
                >
                    <Button
                        variant="contained"
                        sx={{ margin:  '5px' }}
                        onClick={() => setOpen(false)}
                    >
                        <ArrowLeft/> Back
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ margin:  '5px' }}
                        onClick={handleSave}
                    >
                        Submit
                    </Button>
                </Box>
                {elementsToAdd.map((x, i) => {
                    x.triggerType = 'PERIODIC'
                    return (
                        <div key={i}>
                            <Grid container spacing={3} sx={{ marginTop: '5px', marginBottom: '5px' }}>
                                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <TextField disabled label="Can Element" defaultValue={x.name} />
                                </Grid>
                                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Select
                                        defaultValue={1}
                                        onChange={(event) => x.canTriggerType = Number(event.target.value)}
                                    >
                                        <MenuItem value={1}>PERIODIC</MenuItem>
                                        <MenuItem value={2}>REQUEST</MenuItem>
                                        <MenuItem value={3}>ON WAKE</MenuItem>
                                        <MenuItem value={4}>ON SLEEP</MenuItem>
                                        <MenuItem value={7}>ENGINE ON</MenuItem>
                                    </Select>
                                </Grid>                                
                                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <TextField label="Collection Frequency" type="number" onChange={(event) => x.collectionFrequency = Number(event.target.value)} />
                                </Grid>
                                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <TextField label="Report Frequency" type="number" onChange={(event) => x.reportFrequency = Number(event.target.value)} />
                                </Grid>
                            </Grid>
                            <Divider/>
                        </div>
                    )
                })}
        </Card>
            }
        </Card>
    )
})

export default ElementEdit;