import React from 'react';
import {
    Button,
    Stepper,
    Step,
    StepLabel,
    Box,
    TextField,
    IconButton,
    Tooltip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Checkbox,
    Autocomplete,
    FormControl,
    Select,
    MenuItem
} from '@mui/material';
import { DataGrid, GridFooter } from '@mui/x-data-grid';
import { styled } from '@mui/system';
import { PlusCircle, CornerRightDown } from 'react-feather';
import Row from '../CanElements/Row';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Toast from '../../../mixins/Toast';
import Swal from 'sweetalert2';

function getSteps() {
    return ['PGN', 'SPN', 'Overview & Save']
};

const Input = styled(TextField)({
    margin: '5px'
})

let example = {
    id: 0,
    name: '',
    bitStart: '',
    bitLength: '',
    endiannessType: 1,
    scale: 1,
    offset: 0,
    min: '',
    max: '',
    unit: '',
    receiver: '',
    signed: false
}

const PgnCreation = () => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [pgn, setPgn] = React.useState({
        CanIdent: "",
        Name: "",
        ByteLength: "",
        Sender: "",
        canFile: null
    });
    const [index, setIndex] = React.useState(1);
    const [editRowsModel, setEditRowsModel] = React.useState({});
    const [rows, setRows] = React.useState([example]);
    const [name, setName] = React.useState(false);
    const [canId, setCanId] = React.useState(false);
    const [byteLength, setByteLength] = React.useState(false);
    const [sender, setSender] = React.useState(false);
    const canFiles = useSelector((state) => state.deviceList.canFiles);
    const token = useSelector((state) => state.setUser.token);
    const steps = getSteps();
    const dispatch = useDispatch(); 

    let missingData = false;
    
    console.log("pgn:", pgn);
    console.log("active step:", activeStep);
    console.log("canFile:", pgn.canFile);

    const handleNext = () => {
        if (activeStep === 2) {
            // console.log("rows in step 2", rows);
            axios.post('/api/CanPGN', {
                CanIdent: parseInt(pgn.CanIdent, 16),
                Name: pgn.Name,
                ByteLength: Number(pgn.ByteLength),
                Sender: pgn.Sender,
                CanFileId: pgn.canFile.id
            }, {headers: {'Authorization': `Bearer ${token}`}})
            .then((response) => {
                for (const x of rows) {
                    let item = {
                        CanPGNId: response.data,
                        Name: x.name,
                        BitStart: Number(x.bitStart),
                        BitLength: Number(x.bitLength),
                        EndiannessType: Number(x.endiannessType),
                        Scale: parseFloat(x.scale),
                        Offset: parseFloat(x.offset),
                        Min: parseFloat(x.min),
                        Max: parseFloat(x.max),
                        Unit: x.unit,
                        Receiver: x.receiver,
                        IsSigned: x.signed
                    }
                    axios.post('/api/CanSPN', item, {headers: {'Authorization': `Bearer ${token}`}})
                    .catch((error) => console.log(error))
                }
            })
            .then(() => dispatch({ type: "GRAB_FILE_PGN", payload: pgn.canFile.id }))
            .then(() => Toast.fire({ icon: 'success', title: 'PGN Created' }))
            .then(() => {
                setRows([example])
                setIndex(1)
                setPgn({
                    CanIdent: "",
                    Name: "",
                    ByteLength: "",
                    Sender: "",
                    canFile: pgn.canFile
                })
                setActiveStep(0)
            })
            .catch((error) => console.log(error))
        }
        else if (activeStep === 1){
            const test1 = handleCommit();
            //console.log("test1:", test1);
            for (let i = 0; i<rows.length; i++) {
                // name, bit start, bit length, min,max, reciever
                //looping through to check if all things are entered to send it...
                if (rows[i].name === "") {
                    missingData = true;
                    //console.log("name", missingData);
                    // alert("name");
                    // return;
                }
                if (rows[i].bitStart === "") {
                    missingData = true;
                    //console.log("bitstart", missingData);
                    // alert("Bit Start");
                    // return;
                }
                if (rows[i].bitLength === "") {
                    missingData = true;
                    //console.log("bitlength", missingData);
                    // alert("bit length");
                    // return;
                }
                if (rows[i].min === "") {
                    missingData = true;
                    //console.log("min", missingData);
                    // alert("min");
                    // return;
                }
                if (rows[i].max === "") {
                    missingData = true;
                    //console.log("max", missingData);
                    // alert("max");
                    // return;
                }
                if (rows[i].receiver === "") {
                    missingData = true;
                    console.log("receiver", missingData);
                    // alert("receiver");
                    // return;
                }
                console.log("on click", missingData);
            }
            missingData ? 
            Swal.fire({title: "Please Fill Information For:", text:"Name, Bit Start, Bit Length, Min, Max and Receiver", icon: "warning"}) : 
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
        }
        else {
            if (activeStep === 0) {
                if (!pgn.Name) {
                    return setName(true)
                }
                else {
                    setName(false)
                }
                if (!pgn.CanIdent) {
                    return setCanId(true)
                }
                else {
                    setCanId(false)
                }
                if(!pgn.ByteLength) {
                    return setByteLength(true)
                }
                else {
                    setByteLength(false)
                }
                if(!pgn.Sender) {
                    return setSender(true)
                }
                else {
                    setSender(false)
                }
                return setActiveStep((prevActiveStep) => prevActiveStep + 1)
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
        }
    };

    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

    const columns = [
        { field: 'name', headerName: 'Name', flex: 3, minWidth: 150, editable: true },
        { field: 'bitStart', headerName: 'Bit Start', flex: 2, minWidth: 50, editable: true },
        { field: 'bitLength', headerName: 'Bit Length', flex: 2, minWidth: 50, editable: true },
        { field: 'endiannessType', headerName: 'Endianness Type', flex: 2, minWidth: 50, editable: true, renderEditCell: (params) => {
            return (
                <FormControl fullWidth>
                    <Select
                        value={params.row.endiannessType}
                        label="Endianness"
                        sx={{ height: '100%' }}
                        variant='standard'
                        onChange={(event) => {
                            // setValue(event.target.value)
                            const newItems = rows.map(item => {
                                if (params.row.id === item.id) {
                                    return {...item, endiannessType: event.target.value}
                                }
                                return item;
                            })
                            setRows(newItems)
                        }}
                    >
                        <MenuItem value={0}>Big</MenuItem>
                        <MenuItem value={1}>Little</MenuItem>
                    </Select>
                </FormControl>
            )
        }, renderCell: (params) => {console.log(params.row.endiannessType) 
            return (params.row.endiannessType === 0 ? "Big" : "Little")}},
        { field: 'scale', headerName: 'Scale', flex: 2, minWidth: 50, editable: true },
        { field: 'offset', headerName: 'Offset', flex: 2, minWidth: 50, editable: true },
        { field: 'scale', headerName: 'Scale', flex: 2, minWidth: 50, editable: true },
        { field: 'min', headerName: 'Min', flex: 2, minWidth: 50, editable: true },
        { field: 'max', headerName: 'Max', flex: 2, minWidth: 50, editable: true },
        { field: 'unit', headerName: 'Unit', flex: 2, minWidth: 50, editable: true },
        { field: 'receiver', headerName: 'Receiver', flex: 2, minWidth: 50, editable: true },
        { field: 'signed', headerName: 'Signed', flex: 2, minWidth: 50, renderCell: (params) => (
            <Checkbox checked={params.row.signed} onClick={(event) => params.row.signed = event.target.checked } />
        ) },
        { field: 'copy', headerName: 'Copy', flex: 2, minWidth: 30, renderCell: (params) => (
            <IconButton
                variant='outlined'
                onClick={() => {
                    setIndex(index + 1)
                    return (setRows([...rows, {...params.row, id: index}])
                    )
                }}
            >
                <CornerRightDown/>
            </IconButton>
        ) }
    ]

    const handleEditRowsModelChange = React.useCallback((model) => {
        setEditRowsModel(model);
    }, []);

    function customFooter() {
        return (
            <Box
                sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'transparent' }}
            >
                <Tooltip title='New Row'>
                    <IconButton
                        sx={{ margin: '5px' }}
                        onClick={() => {
                            setIndex(index + 1)
                            return (setRows([...rows, {...example, id: index}])
                            )
                        }}
                    >
                        <PlusCircle/>
                    </IconButton>
                </Tooltip>
                <GridFooter/>
            </Box>
        )
    }

    const handleCommit = () => {
        if (Object.keys(editRowsModel)[0] === undefined){
            return;
        }
        const objId = Object.keys(editRowsModel)[0];
        const obj = Object.values(editRowsModel)[0];
        console.log(obj)
        const newObj = {id: Number(objId), bitLength: obj.bitLength.value, bitStart: obj.bitStart.value, endiannessType: rows[objId].endiannessType, max: obj.max.value, min: obj.min.value, name: obj.name.value, offset: obj.offset.value, receiver: obj.receiver.value, scale: obj.scale.value, signed: rows[objId].signed, unit: obj.unit.value }
        setRows((current) => current.map((x) => {
            return x.id === Number(objId) ? { ...newObj } : x
        }))
    }
    function getStepContent(stepIndex) {
        let display = '';
        switch (stepIndex) {
            case 0:
                display = (
                    <Box
                        sx={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <Box>
                            <Input
                                variant='outlined'
                                label='Name'
                                value={pgn.Name}
                                sx={{marginLeft: "0px"}}
                                error={name}
                                onChange={(event) => setPgn({...pgn, Name: event.target.value})}
                            />
                            <Input
                                variant='outlined'
                                label='Can Id (hexadecimal)'
                                value={pgn.CanIdent}
                                sx={{marginRight: "0px"}}
                                error={canId}
                                onChange={(event) => setPgn({...pgn, CanIdent: event.target.value})}
                            />
                        </Box>
                        <Box>
                            <Input
                                variant='outlined'
                                label='Byte Length'
                                value={pgn.ByteLength}
                                sx={{marginLeft: "0px"}}
                                type={'number'}
                                error={byteLength}
                                onChange={(event) => setPgn({...pgn, ByteLength: event.target.value})}
                            />
                            <Input
                                variant='outlined'
                                label='Sender'
                                value={pgn.Sender}
                                sx={{marginRight: "0px"}}
                                error={sender}
                                onChange={(event) => setPgn({...pgn, Sender: event.target.value})}
                            />
                        </Box>
                        <Box>
                            <Autocomplete 
                                options={canFiles}
                                sx={{marginTop: '5px'}}
                                value={pgn.canFile}
                                onChange={(event, newValue) => {setPgn({...pgn, canFile: newValue})}}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} label="CAN File" variant="outlined"/>}
                            />
                        </Box>
                    </Box>
                )
                return display
            case 1:
                display = (
                <Box
                    sx={{ height: '300px', width: '98%'}}
                >
                    <p style={{textAlign: "center", marginBottom: '15px'}}>Double click cells to edit</p>
                    <DataGrid 
                        columns={columns}
                        rows={rows}
                        editMode="row"
                        onRowEditStop={handleCommit}
                        onEditRowsModelChange={handleEditRowsModelChange}
                        onRowEditCommit={handleCommit}
                        components={{
                            Footer: customFooter,
                          }}
                        
                    />
                </Box>
                )
                return display;           
            case 2:
                display = (
                    <Box
                        sx={{ width: '100%', height: '300px', overflow: 'auto' }}
                    >
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Name</TableCell>
                                    <TableCell>CAN Id</TableCell>
                                    <TableCell>Byte Length</TableCell>
                                    <TableCell>Sender</TableCell>
                                    <TableCell>CAN File</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    <Row key={pgn.Name} row={pgn} spn={rows} />
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )
                return display;
            default:
                return display;
        }
    }
    return (
        <Box
            sx={{ width: '95%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}
        >
            {console.log(rows)}
            <Stepper
                activeStep={activeStep}
                alternativeLabel
            >
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
                <Box
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}
                >
                    {getStepContent(activeStep)}
                </Box>
                <Box
                    sx={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px'}}
                >
                    <Button
                        variant='outlined'
                        color='error'
                        disabled={activeStep === 0 ? true : false}
                        onClick={handleBack}
                    >
                        Back
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleNext}
                    >
                        {activeStep === 2 ? 'Save' : 'Next'}
                    </Button>
                </Box>
        </Box>
    )
};

export default PgnCreation;