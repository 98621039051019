import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Modal
} from '@mui/material';
import GroupPermission from './GroupPermissions'
import NewGroup from './NewGroup'



const Toolbar = ({ className, ...rest }) => {
    const [settings, setSettings] = React.useState(false);
    const [group, setGroup] = React.useState(false);

    const handleOpen = (x) => {
        if( x === 'settings'){
            return setSettings(true);
        }
        else if( x === 'group'){
            return setGroup(true);
        }
    };

    const handleClose = (x) => {
        if( x === 'settings'){
            return setSettings(false);
        }
        else if( x === 'group'){
            return setGroup(false);
        }
    };


    return (
        <div
            style={{width: '100%'}}
        >
            <Modal
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                open={settings}
                onClose={() => handleClose('settings')}
            >
                <GroupPermission close={setSettings} />
            </Modal>
            <Modal
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                open={group}
                onClose={() => handleClose('group')}
            >
                <NewGroup close={setGroup}/>
            </Modal>
            <Box
                display="flex"
                justifyContent="space-between"
            >
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleOpen('group')}
                >
                    New Group
                </Button>
            </Box>
        </div>
    );
};

Toolbar.propTypes = {
    className: PropTypes.string
};

export default Toolbar;
