import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import GlobalStyles from '../src/components/GlobalStyles';
import { connect } from "react-redux";
import '../src/mixins/chartjs';
import theme from '../src/theme';
import authService from './components/api-authorization/AuthorizeService';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import DashboardLayout from '../src/layouts/DashboardLayout';
import IdleTimer from './utils/IdleTimer';

const App = (props) => {
    const [authenticated, setAuthenticated] = React.useState(false);
    const [isTimeout, setIsTimeout] = React.useState(false);
    
    useEffect(() => {
        localStorage._expiredTime = 0
        let _subscription = authService.subscribe(() => populateState());
        populateState();
        return function cleanup(){
            authService.unsubscribe(_subscription);
        }
    },[]);

    // useEffect(() => {
    //   // If user in inactive for 1 Hour log out
    //   const timer = new IdleTimer({
    //     timeout: 3600,
    //     onTimeout: () => {
    //       window.location.pathname = ApplicationPaths.LogOut
    //       console.log(localStorage.getItem("_expiredTime"))
    //       setIsTimeout(true)
    //     },
    //     onExpired: () => {
    //       window.location.pathname = ApplicationPaths.LogOut
    //       console.log(localStorage.getItem("_expiredTime"))
    //       setIsTimeout(true)
    //     }
    //   });
    //   return () => {
    //     timer.cleanUp();
    //   };
    // },[]);

    const populateState = async () => {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        setAuthenticated(isAuthenticated)
        if(user){
            props.dispatch({type: 'SET_USER', payload: {name: user.name, auth: isAuthenticated, id: user.sub}})
            props.dispatch({type: 'GRAB_DATA', payload: {name: user.name, auth: isAuthenticated, id: user.sub}})
            props.dispatch({type: 'SET_ALL_USER_GROUPS', payload: {name: user.name, auth: isAuthenticated, id: user.sub}})
        }
    }
    
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            {(authenticated && window.location.pathname === "/") || (authenticated && window.location.pathname === "/app") ? <Redirect to={"/swagger/index.html"}/> : ''}
            <AuthorizeRoute path='/app' component={DashboardLayout}/>
            <Route exact path='/'>
                <Redirect to="/swagger/index.html" />
            </Route>
            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
        </ThemeProvider>
    );
};

const mapStateToProps = (state) => ({
    data: state.deviceList
})
export default connect(mapStateToProps)(App);