import React from 'react';
import { render } from 'react-dom';
import {useSelector} from 'react-redux';

const GNSS = (props) => {
    const record = useSelector((state) => state.deviceList.gnssRecord);
    const gnssRecordLoading = useSelector((state) => state.deviceList.gnssRecordLoading);
    const gnssRecordNotFound = useSelector((state) => state.deviceList.gnssRecordNotFound);

    if(gnssRecordLoading) {
        return(
            <div>
                <h3>Loading . . . </h3>
            </div>
        )
    }
    else if(gnssRecordNotFound) {
        return(
            <div>
                <h3>No Data Available</h3>
            </div>
        )
    }
    else {
        let visibleSats = "";
        for(let i=0;i<8;i++) {
            visibleSats += record.visibleSatalitesPerConstellation[i];
            if(i!=7) {
                visibleSats +=','
            }
        }
        let satsUsed = "";
        for(let i=0;i<8;i++) {
            satsUsed += record.numberOfSatalitesUsed[i];
            if(i!=7) {
                satsUsed +=','
            }
        }
        return (
            <div style={{margin:25}}>
                <h3>Fix Type: <span style={{color:'blue'}}>{record.fixType}</span></h3>
                <h3>GNSS Fix OK:	<span style={{color:'blue'}}>{record.gnssFixOK ? "true" : "false"}</span></h3>
                <h3>Differential Corrections Applied:	<span style={{color:'blue'}}>{record.differentialCorrectionsApplied ? "true" : "false"}</span></h3>
                <h3>Number Of Satellites:	<span style={{color:'blue'}}>{record.numberOfSatalites}</span></h3>
                <h3>Longitude (degrees):	<span style={{color:'blue'}}>{record.longitude}</span></h3>
                <h3>Latitude (degrees):	<span style={{color:'blue'}}>{record.latitude}</span></h3>
                <h3>Horizontal Accuracy (m):	<span style={{color:'blue'}}>{record.horizontalAccuracy}</span></h3>
                <h3>Vertical Accuracy (m):	<span style={{color:'blue'}}>{record.verticalAccuracy}</span></h3>
                <h3>Ground Speed (m/s):	<span style={{color:'blue'}}>{record.groundSpeed}</span></h3>
                <h3>Heading (degrees):	<span style={{color:'blue'}}>{record.heading}</span></h3>
                <h3>Speed Accuracy Estimate (m/s):	<span style={{color:'blue'}}>{record.speedAccuracyEstimate}</span></h3>
                <h3>Heading Accuracy Estimate (degrees):	<span style={{color:'blue'}}>{record.headingAccuracyEstimate}</span></h3>
                <h3>Position DOP:	<span style={{color:'blue'}}>{record.positionDOP}</span></h3>
                <h3>Visible Satellites Per Constellation:	<span style={{color:'blue'}}>{visibleSats}</span></h3>
                <h3>Number Of Satellites Used:	<span style={{color:'blue'}}>{satsUsed}</span></h3>
                <h3>Time To First Fix (s):	<span style={{color:'blue'}}>{record.msToFirstFix / 1000}</span></h3>
                <h3>Assist Now Online:	<span style={{color:'blue'}}>{record.assistNowOnline ? "true" : "false"}</span></h3>
                <h3>Month:	<span style={{color:'blue'}}>{record.month}</span></h3>
                <h3>Day:	<span style={{color:'blue'}}>{record.day}</span></h3>
                <h3>Hour:	<span style={{color:'blue'}}>{record.hour}</span></h3>
                <h3>Minute:	<span style={{color:'blue'}}>{record.minute}</span></h3>
                <h3>Second:	<span style={{color:'blue'}}>{record.second}</span></h3>
                <h3>Year:	<span style={{color:'blue'}}>{record.year}</span></h3>
                <h3>Created:	<span style={{color:'blue'}}>{record.created}</span></h3>
            </div>
        )
    }   
}

export default GNSS;
