import { all, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import authService from "../../components/api-authorization/AuthorizeService";
import { func } from 'prop-types';
import Toast from '../../mixins/Toast';

function* CanSPNSaga() {
    yield takeLatest('FETCH_CAN_SPN', FetchCanSPN);
    yield takeLatest('FETCH_CAN_GROUP',FetchCanGroups);
    yield takeLatest('FETCH_CAN_GROUP_PGN',FetchCanGroupPgns);
    yield takeLatest('EDIT_CAN_SPN', CanSPNEdit);
    yield takeLatest('NEW_CAN_SPN', NewCanSPN);
    yield takeLatest("EDIT_PGN", PGNEdit);
    yield takeLatest('FETCH_PGN', fetchPGN);
    yield takeLatest('DELETE_PGN', DeletePGN);
    yield takeLatest('DELETE_SPN', DeleteSPN);
    yield takeLatest('GET_CAN_FILES', GetCanFiles);
}

function* FetchCanSPN(action) {
    // console.log("fetch", action);
    try {
        const response = yield axios.get(`/api/CanSPN/${action.payload}`,{headers: !action.token ? {} : { 'Authorization' : `Bearer ${action.token}`}})
        // console.log("response fetch in can spn:", response);
        yield put({ type: "SET_CAN_SPN", payload: response.data})
    } catch(error) {
        //console.log('error in fetch can SPN saga.', error);
    }
}

function* FetchCanGroups(action) {
    // console.log("fetch can groups", action);
    try {
        for(let x of action.payload.id){
            const response = yield axios.get(`/api/CanGroups/getgroups/${x}`,{headers: !action.token ? {} : { 'Authorization' : `Bearer ${action.token}`}})        
            // console.log("can group response", response.data);
            yield put({ type: "SET_CAN_GROUP", payload: response.data})
        }
        // const response = yield axios.get(`/api/CanGroups/getgroups/${action.payload.id}`,{headers: !action.token ? {} : { 'Authorization' : `Bearer ${action.token}`}})        
    } catch(error) {
        console.log('error in fetch can groups saga.', error);
    }
}

function* FetchCanGroupPgns(action) {
    //console.log("fetch", action);
    try {
        const response = yield axios.get(`/api/CanGroupPGN/${action.payload.id}`,{headers: !action.token ? {} : { 'Authorization' : `Bearer ${action.token}`}})        
        // console.log("can group returned", response.data);
        yield put({ type: "SET_CAN_GROUP_PGN", payload: response.data})
    } catch(error) {
        console.log('error in fetch can groups pgn saga.', error);
    }
}
//    let PGNs = yield axios.get(`/api/CanFile/PGN/${action.payload}`, {headers: {'Authorization': `Bearer ${token}`}});

function* fetchPGN(action) {
    // console.log("fetch", action);
    try {
        const response = yield axios.get(`/api/CanPGN/GetPGN/${action.payload}`, {headers: {'Authorization': `Bearer ${action.token}`}});
        // console.log("response fetch PGN response:", response);
        yield put({ type: "SET_PGN", payload: response.data})
    } catch(error) {
        console.log('error in fetch PGN saga.', error);
    }
}

function* CanSPNEdit(action) {
    try {
        const response = yield axios.put(`/api/CanSPN/${action.id}`, action.payload,{headers: !action.token ? {} : { 'Authorization' : `Bearer ${action.token}`}})
        // console.log("response in post can spn:", response);
        if (response.status = 200){
            // console.log("stat 200");
            yield put({ type: "FETCH_CAN_SPN", payload: action.PGNId, token: action.token}) 
            // console.log("where does this fit?");
            yield put({ type: "GRAB_FILE_PGN", payload: action.canFile, })
            Toast.fire({ icon: 'success', title: 'Saved' })
        }
        
    } catch(error) {
        console.log('error in fetch can SPN saga.', error);
        Toast.fire({ icon: 'error', title: 'Error updating SPN' })
    }
}

function* NewCanSPN(action) {
    // console.log("new spn: ", action);
    try {
        const response = yield axios.post(`/api/CanSPN/`, action.payload, {headers: !action.token ? {} : { 'Authorization' : `Bearer ${action.token}`}})
        // console.log("response in post can spn:", response);
        if (response.status = 200){
            // console.log("stat 200");
            yield put({ type: "FETCH_CAN_SPN", payload: action.PGNId, token: action.token}) 
            yield put({ type: "GRAB_FILE_PGN", payload: action.canFile, })
            Toast.fire({ icon: 'success', title: 'Saved' })
        }
        
    } catch(error) {
        console.log('error in fetch can SPN saga.', error);
        Toast.fire({ icon: 'error', title: 'Error creating new SPN' })
    }
}

function* PGNEdit(action) {
    // console.log("edit pgn action,", action);
    try {
        const response = yield axios.put(`/api/CanPGN/${action.id}`, action.payload,{headers: !action.token ? {} : { 'Authorization' : `Bearer ${action.token}`}})
        // console.log("response in pgn edit:", response);
        if (response.status = 200){
            // console.log("stat 200");
            yield put({ type: "FETCH_PGN", payload: action.id, token: action.token}) 
            yield put({ type: "GRAB_FILE_PGN", payload: action.fileId, })
            Toast.fire({ icon: 'success', title: 'Saved' })
        }
        
    } catch(error) {
        console.log('error in fetch can SPN saga.', error);
        Toast.fire({ icon: 'error', title: 'Error updating PGN' })
    }
}

function* GetCanFiles(action){
    // console.log("group id", action.groupIds);

    // Grabs all DBC files for the groups
    const fileArray = yield axios.get(`/api/CanFile/ForGroups/${action.groupIds.join(",")}`, { headers: !action.token ? {} : { 'Authorization': `Bearer ${action.token}`} });
    // console.log("file array: ", fileArray);
    // console.log("file array: ", fileArray.data);
    yield put({type: "SET_CAN_FILES", payload: fileArray.data});
}


function* DeletePGN(action) {
    try {
        const response = yield axios.put(`/api/CanPGN/DeleteCanPGN/${action.payload}`,{headers: !action.token ? {} : { 'Authorization' : `Bearer ${action.token}`}})
        // console.log("response Delete PGN Saga:", response);
        yield put({ type: "GET_CAN_FILES", payload: action.payload, token: action.token, groupIds: action.groupIds})
    } catch(error) {
        console.log('error in Delete PGN Saga can SPN saga.', error);
    }
}

function* DeleteSPN(action) {
    // console.log("Delete SPN Saga",action);
    try {
        const response = yield axios.delete(`/api/CanSPN/DeleteCanSPN/${action.payload.id}`,{headers: !action.token ? {} : { 'Authorization' : `Bearer ${action.token}`}})
        // console.log("response Delete SPN Saga:", response);
        yield put({ type: "FETCH_CAN_SPN", payload: action.payload.canPGNId, token: action.token})
    } catch(error) {
        console.log('error in Delete SPN Saga can SPN saga.', error);
    }
}

export default CanSPNSaga;