import React from 'react';
import {
    Container,
    Backdrop,
    CircularProgress
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Page from './Page'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    backdrop: {
        zIndex: theme.zIndex.modal,
        color: "#000",
    },
    loading: {
        display:"flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center"
    }
}));

const Loading = (props) => {
    const classes = useStyles();
    return (
    <Page className={classes.root} title="Dashboard">
        <Container className={classes.loading} maxWidth={false}>
        <Backdrop
            className={classes.backdrop}
            open={true}
        >
            <CircularProgress color="primary" thickness={5} size={100}/>
        </Backdrop>
        </Container>
    </Page>
    );
};

export default Loading;
