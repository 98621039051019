import {
    Box,
    Container,
    Tab,
    Tabs,
    AppBar,
    Card,
    Backdrop,
    CircularProgress,
    Grid,
    Item,
} from "@mui/material";
import { connect, useDispatch, useSelector } from 'react-redux';
import { maxHeight } from "@mui/system";
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import { Database, Flag, Globe, Map } from "react-feather";
import { useEffect } from "react";


const LandingInfo = (props) => {
    const dispatch = useDispatch();
    const landingData = useSelector(state => state.LandingReducer.LandingData);
    const totalCount = useSelector(state => state.LandingReducer.CanTotalCount);
    const monthCount = useSelector(state => state.LandingReducer.CanMonthCount);
    const dayCount = useSelector(state => state.LandingReducer.CanDayCount);

    const color = "#336BFF"
    const iconSize = 50
    // console.log("landing data", landingData);
    // console.log("total Count", totalCount);
    // console.log("month Count", monthCount);
    // console.log("day Count", dayCount);

    useEffect(() => {
        if (landingData === 0){
            dispatch({ type: "FETCH_LANDING_DATA" })
        }
    }, [])

    return (
        <Box sx={{ flexGrow: 1, paddingTop: 5, fontSize: 20 }}>
            {/* #1 */}
            <Grid textAlign={'center'} container spacing={2} style={{ paddingBottom: "25px" }}>
                <Grid item xs={4} style={{ display: "flex", paddingBottom: "15px", justifyContent: 'center' }} >
                    <Globe size={iconSize} style={{ paddingRight: 5 }} color={color} />
                    <span>
                        <p style={{ color: color }}> <b> Total Machines</b></p>
                        <p >{landingData !== 0 ? landingData.machineCount.toLocaleString() : 'Processing'}</p>
                    </span>
                </Grid>
                <Grid item xs={4} >
                    <p style={{ color: color }}><b>Total Groups</b></p>
                    <p >{landingData !== 0 ? landingData.groupCount.toLocaleString() : 'Processing'}</p>
                </Grid>
                <Grid item xs={4} >
                    <p style={{ color: color }}><b>Active Last 24h</b></p>
                    <p >{landingData !== 0 ? landingData.machineActive24.toLocaleString() : 'Processing'}</p>
                </Grid>


                {/* #2 */}

                <Grid item xs={4} style={{ display: "flex", paddingBottom: "15px", justifyContent: 'center' }} >
                    <Database size={iconSize} style={{ paddingRight: 5 }} color={color} />
                    <span>
                        <p style={{ color: color }}><b> Total CAN</b></p>
                        <p >{totalCount !== 0 ? totalCount.totalCan.toLocaleString() : 'Processing'}</p>
                    </span>
                </Grid >
                <Grid item xs={4} >
                    <p style={{ color: color }}><b>CAN Last Month</b></p>
                    <p >{monthCount !== 0 ? monthCount.canLastMonth.toLocaleString() : 'Processing'}</p>
                </Grid >
                <Grid item xs={4} >
                    <p style={{ color: color }} ><b>CAN Last 24h</b></p>
                    <p >{dayCount !== 0 ? dayCount.can24.toLocaleString() : 'Processing'}</p>
                </Grid >


                {/* #3 */}

                <Grid item xs={4} style={{ display: "flex", paddingBottom: "15px", justifyContent: 'center' }} >
                    <Map size={iconSize} style={{ paddingRight: 5 }} color={color} />
                    <span>
                        <p style={{ color: color }}><b>Total GPS </b></p>
                        <p >{landingData !== 0 ? landingData.totalGPS.toLocaleString() : 'Processing'} </p>
                    </span>
                </Grid >
                <Grid item xs={4} >
                    <p style={{ color: color }}><b>GPS Last Month</b></p>
                    <p >{landingData !== 0 ? landingData.gpsLastMonth.toLocaleString() : 'Processing'}</p>
                </Grid>
                <Grid item xs={4} >
                    <p style={{ color: color }}><b>GPS Last 24h</b></p>
                    <p >{landingData !== 0 ? landingData.gpS24.toLocaleString() : 'Processing'}</p>
                </Grid>


                {/* #4 */}

                <Grid item xs={4} style={{ display: "flex", paddingBottom: "15px", justifyContent: 'center' }} >
                    <Flag size={iconSize} style={{ paddingRight: 5 }} color={color} />
                    <span>
                        <p style={{ color: color }}><b> Total Faults</b></p>
                        <p >{landingData !== 0 ? landingData.totalFault.toLocaleString() : 'Processing'}</p>
                    </span>
                </Grid >
                <Grid item xs={4}>
                    <p style={{ color: color }}><b>Faults Last Month</b></p>
                    <p >{landingData !== 0 ? landingData.faultLastMonth.toLocaleString() : 'Processing'}</p>
                </Grid >
                <Grid item xs={4} >
                    <p style={{ color: color }}><b>Faults Last 24h</b></p>
                    <p >{landingData !== 0 ? landingData.fault24.toLocaleString() : 'Processing'}</p>
                </Grid >
            </Grid>
            <hr />
        </Box>
    );
}
export default LandingInfo;