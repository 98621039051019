import { combineReducers } from 'redux';
import deviceList from './allDeviceReducer';
import setUser from './userReducer';
import admin from './AdminReducer';
import DashboardReducer from './DashboardReducer';
import ViewReducer from './ViewReducer';
import FaultReducer from './FaultReducer';
import CanSPNReducer from './CanSPNReducer';
import EngGraph from './EngGraph';
import LandingReducer from './LandingReducer';

const rootReducer = combineReducers({
    deviceList,
    setUser,
    admin,
    DashboardReducer,
    ViewReducer,
    FaultReducer,
    CanSPNReducer,
    EngGraph,
    LandingReducer,
});

export default rootReducer;
