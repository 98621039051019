import React from "react";         
import { Line, Bar, Pie, Doughnut } from 'react-chartjs-2';
import theme from "../theme";
import { Tooltip, Typography } from '@mui/material';
//import GoogleMapReact from 'google-map-react';
import RoomIcon from '@mui/icons-material/Room';
import { 
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridOverlay
} from '@mui/x-data-grid';
import { Box } from '@mui/system';


import moment from "moment";
import { useSelector } from "react-redux";

const ChartService = (props) => {
    const firmwareList = useSelector((state) => state.admin.firmwareList);
    const hardwareList = useSelector((state) => state.admin.hardwareVersions);
    const canGroups = useSelector((state) => state.deviceList.canGroup);
    // console.log("chart data:", props.data);
    // console.log("chart data data :", props.data.data);

    const Marker = ({ created, id }) => (
        <Tooltip title={
            <Typography>
                Device: {id} <br/>
                Created: {created}
            </Typography>
        }>
            <RoomIcon sx={{color: 'red'}} />
        </Tooltip>
    );

    function CustomToolbar() {
        return (
            <GridToolbarContainer style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    <GridToolbarExport />
                </Box>
            </GridToolbarContainer>
        );
    }


    const columns = [
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 150 },
        { field: 'hardwareVersion', headerName: 'Hardware Version', flex: 1, minWidth: 150 },
        { field: 'firmwareVersion', headerName: 'Firmware Version', flex: 1, minWidth: 150 },
        { field: 'canGroup', headerName: 'Can Group', flex: 1, minWidth: 150 },
        { field: 'checkin', headerName: 'Last Check-in', flex: 1, minWidth: 150, renderCell: (params) => (params.row.checkin === 'N/A' ? 'N/A' : moment.utc(params.row.checkin).local().format('MM/DD/YY, h:mm a')) }
    ];

    const checkColumns = [
        { field: 'deviceId', headerName: 'Device ID', flex: 1, minWidth: 150 }, 
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 150 },
        { field: 'value', headerName: 'Value', flex: 1, minWidth: 150 },
        { field: 'created', headerName: 'Created', flex: 1, minWidth: 150, renderCell: (params) => (moment.utc(params.row.created).local().format('MM/DD/YY, h:mm a')) }
    ];

    let options = {};
    // Chart options for Pie Chart
    if(props.data.type === 'pie' || props.data.type === 'doughnut'){
        options = {
            animation: false,
            cornerRadius: 20,
            layout: { padding: 0 },
            legend: { display: true, position: 'bottom' },
            maintainAspectRatio: false,
            title: {display: true, fontSize: 25 , text: props.data.title},
            responsive: true,
            tooltips: {
            backgroundColor: theme.palette.background.default,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            footerFontColor: theme.palette.text.secondary,
            titleFontColor: theme.palette.text.primary,
            
            }
        }
    }
    // Chart options for bar and line. Just have them the same for now since they're similar
    else{
        options = {
            animation: false,
            cornerRadius: 20,
            layout: { padding: 0 },
            legend: { display: true, position: 'chartArea' },
            maintainAspectRatio: false,
            title: {display: true, text: props.data.title},
            responsive: true,
            scales: {
            xAxes: [
                {
                ticks: {
                    fontColor: theme.palette.text.secondary,
                },
                gridLines: {
                    display: false,
                    drawBorder: false
                },
                type: 'time',
                distribution: 'linear',
                time: {
                    unit: 'day'
                },
                }
            ],
            yAxes: [
                {
                ticks: {
                    fontColor: theme.palette.text.secondary,
                },
                gridLines: {
                    borderDash: [2],
                    borderDashOffset: [2],
                    color: theme.palette.divider,
                    drawBorder: false,
                    zeroLineBorderDash: [2],
                    zeroLineBorderDashOffset: [2],
                    zeroLineColor: theme.palette.divider
                }
                }
            ]
            },
            tooltips: {
            backgroundColor: theme.palette.background.default,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            footerFontColor: theme.palette.text.secondary,
            titleFontColor: theme.palette.text.primary
            }
        };
    };

    switch (props.data.type) {
        case 'line':
            return (
                <div style={{ height: '100%', width: '100%' }}>
                    <Line options={options} data={props.data} />
                </div>
            )
        case 'bar':
            return(
                <div style={{ height: '100%', width: '100%' }}>
                    <Bar options={options} data={props.data} />
                </div>
            )
        case 'pie':
            return (
                <div style={{ height: '100%', width: '100%' }}>
                    <Pie options={options} data={props.data.data} />
                </div>
            )
        case 'doughnut':
            return (
                <div style={{ height: '100%', width: '100%' }}>
                    <Doughnut options={options} data={props.data.data} />
                </div>
            )
        case 'map':
            return (
                <div style={{ height: '100%', width: '100%' }}>
                    <div style={{position: 'absolute', textAlign: 'center', backgroundColor: 'white', zIndex: '5', width: '100%', height: '20px'}}>
                        {props.data.title}
                    </div>
                    {/* <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyDT5lF0ro8rCWUtQQHeXCMzGgfbpM_SafA' }}
                        defaultZoom={17}
                        defaultCenter={props.data.center}
                        options={map => ({ mapTypeId: map.MapTypeId.HYBRID, scrollwheel: true, fullscreenControl: false })}
                    >
                        { props.data.datasets.map((x, i) => {
                            return (
                                <Marker
                                    key={i}
                                    lat={x.lat}
                                    lng={x.lng}
                                    id={x.id}
                                    created={x.created}
                                />
                            )
                        } )}
                    </GoogleMapReact> */}
                </div>
            )
        case 'list':
            let rows = [];
            let latestCheck = 'N/A'
            let hardwareVersion = '';
            let firmwareVersion = '';
            let canVersion = '';
            props.data.list.map((x) => {
                if(x.data){
                    latestCheck = x.data
                }
                else{
                    latestCheck = 'N/A'
                }

                hardwareList.some(el => { return hardwareVersion = el.id === x.hardwareVersionId ? el.name : '' });

                firmwareList.Signum.STM.some(el => { return firmwareVersion = el.id === x.firmwareVersionId ? el.name : '' });

                canGroups.some(el => { return canVersion = el.id === x.canGroupId ? el.name : '' });

                return rows.push({id: x.id, name: x.name, hardwareVersion: hardwareVersion, firmwareVersion: firmwareVersion, canGroup: canVersion, checkin: latestCheck})
            })
            return(
                <div style={{ height: '100%', width: '100%' }}>
                    <div style={{ marginTop: '7px', textAlign: 'center', backgroundColor: 'white', zIndex: '5', width: '100%', height: '20px' }}>
                        {props.data.title}
                    </div>
                    <DataGrid 
                        style={{ position: 'absolute', width: '100%', height: '95%' }}
                        columns={columns}
                        rows={rows}
                        density="compact"
                    />
                </div>
            )
        case 'chart':
            return(
                <div style={{  height: '100%', width: '100%' }}>
                    <div style={{ marginTop: '7px', textAlign: 'center', backgroundColor: 'white', zIndex: '5', width: '100%', height: '20px'}}>
                        {props.data.title}
                    </div>
                    <DataGrid
                        style={{ position: 'relative', width: '100%', height: '95%' }}
                        columns={props.data.columns}
                        rows={props.data.list}
                        density="compact"
                        components={{
                            Toolbar: CustomToolbar
                        }}
                    />
                </div>
            )
            case 'check chart':
            return(
                <div style={{  height: '100%', width: '100%' }}>
                    <div style={{ marginTop: '7px', textAlign: 'center', backgroundColor: 'white', zIndex: '5', width: '100%', height: '20px'}}>
                        {props.data.title} 
                    </div>
                    <DataGrid
                        style={{ position: 'relative', width: '100%', height: '95%' }}
                        columns={props.data.columns}
                        rows={props.data.list}
                        checkboxSelection 
                        density="compact"
                        components={{
                            Toolbar: CustomToolbar
                        }}
                    />
                </div>
            )
        case 'special chart': // used in fleet view to handle the serial number and fault color change and cursor change
            return(
                <Box sx={{  height: '100%', width: '100%', 
                    '& .links': {
                        color: "blue",
                        '&:hover': {
                            color: 'red',
                            cursor: "pointer"
                        },
                    },
                    '& .fault': {
                        color: 'red',
                        '&:hover': {
                            cursor: "pointer",
                            color: 'maroon'
                        },
                    },
                    '& .none': {
                        color: 'green',
                    }
                }}>
                    <div style={{ marginTop: '7px', textAlign: 'center', backgroundColor: 'white', zIndex: '5', width: '100%', height: '20px' }}>
                        {props.data.title}
                    </div>
                    <DataGrid
                        style={{ position: 'relative', width: '100%', height: '95%' }}
                        columns={props.data.columns}
                        rows={props.data.list}
                        density="compact"
                        components={{
                            Toolbar: CustomToolbar
                        }}
                    />
                </Box>
            )
        case 'check':
            return(
                <div style={{  height: '100%', width: '100%' }}>
                    <div style={{ marginTop: '7px', textAlign: 'center', backgroundColor: 'white', zIndex: '5', width: '100%', height: '20px' }}>
                        {props.data.title}
                    </div>
                    <DataGrid
                        style={{ position: 'absolute', width: '100%', height: '95%' }}
                        columns={checkColumns}
                        rows={props.data.list}
                        density="compact"
                    />
                </div>
            )
        case 'query':
            return(
                <div style={{  height: '100%', width: '100%' }}>
                    <div style={{ marginTop: '7px', textAlign: 'center', backgroundColor: 'white', zIndex: '5', width: '100%', height: '20px' }}>
                        {props.data.title}
                    </div>
                    <DataGrid
                        style={{ position: 'absolute', width: '100%', height: '95%' }}
                        columns={checkColumns}
                        rows={props.data.list}
                        density="compact"
                    />
                </div>
            )
        case 'none':
            console.log("showing none chart");
            return (
                <div style={{ height: '100%', width: '100%' }}>
                <div style={{position: 'absolute', textAlign: 'center', backgroundColor: 'white', zIndex: '5', width: '100%', height: '20px'}}>
                    {props.data.title}
                </div>
                <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h4>No Location Data</h4>
                </div>
            </div>
            )
        default:
            console.log("Are we missing a chart type?");
            return;
    }
};


export default ChartService;