import React from "react";
import {
    Box,
    Grid,
    Typography,
    Card,
    Divider,
    Button,
    CircularProgress,
    Backdrop
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

const DeleteView = (props) => {
    const users = useSelector((state) => state.ViewReducer.deleteGroupUsers);
    const devices = useSelector((state) => state.ViewReducer.deleteGroupDevices);
    const loading = useSelector((state) => state.ViewReducer.userGroupLoading);
    const systemAdmin = useSelector((state) => state.setUser.systemAdmin);
    const user = useSelector((state) => state.setUser.setUser);
    const role = useSelector((state) => state.setUser.currentRole);
    const dispatch = useDispatch();

    const handleDelete = () => {
        // console.log(props.groupId)
        dispatch({ type: "DELETE_USER_GROUP", payload: props.groupId })
        if ( systemAdmin ) dispatch({ type: "GRAB_ADMIN" })
        else dispatch({ type: 'GRAB_GROUP_ADMIN', payload: user, role: role.id })
    }

    return (
        <Box>
            <Backdrop open={loading} sx={{ color: "#fff", zIndex: 5 }}>
                <CircularProgress color="primary" thickness={5} size={100}/>
            </Backdrop>
            <Typography textAlign={"center"} sx={{ pt: '10px' }} variant="h2">ARE YOU SURE?</Typography>
            <Typography textAlign={"Center"} variant="body1">(USERS AND DEVICES WILL BE REMOVED FROM GROUP)</Typography>
            <Grid p={3} container spacing={5}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Card sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', height: '300px' }}>
                        <Typography variant="h3">Users</Typography>
                        <Divider sx={{ width: '100%', mb: '10px', mt: '10px' }}/>
                        <ul>
                            {users.map((x) => <li>{x.userName}</li>)}
                        </ul>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Card sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', height: '300px' }}>
                        <Typography variant="h3">Devices</Typography>
                        <Divider sx={{ width: '100%', mb: '10px', mt: '10px' }}/>
                        <ul>
                            {devices.map((x) => <li>{x.name}</li>)}
                        </ul>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleDelete}
                        fullWidth
                    >
                        DELETE
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
};

export default DeleteView;