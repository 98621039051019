import { combineReducers } from 'redux';

const deviceList = (state = [], action) => {
    switch (action.type) {
        case 'SET_DATA':
            return action.payload;
        default:
            return state;
    }
};
const devices = (state = [], action) => {
    switch (action.type){
        case 'SET_DEVICE':
           return action.payload;
        default:
            return state;
    }
};
const loadingDevice = (state = true, action) => {
    switch (action.type) {
        case 'IS_LOADING':
           return action.payload
        default:
            return state;
    }
}
const devicePage = (state = false, action) => {
    switch (action.type) {
        case "DEVICE_PAGE_LOADING":
            state = action.payload;
            return state;
        default:
           return state;
    }
}
const rawData = (state = [], action) => {
    switch (action.type) {
        case 'SET_RAW_DATA':
           state = action.payload
        //    console.log("rawData Reduxer", state, "payload ", action);
            return state;
        default:
            return state
    }
}
const rawInfo = (state = [], action) => {
    switch (action.type) {
        case 'SET_RAW_INFO':
           state = action.payload        
            return state;
        default:
            return state
    }
}

const machineStatus = (state = [], action) => {
    switch (action.type) {
        case 'SET_MACHINE_STATUS': 
           state = action.payload        
            return state;
        default:
            return state
    }
}

const searchClicked = (state = [], action) => {
    switch (action.type) {
        case 'SEARCH_CLICKED':
           state = action.payload        
            return state;
        default:
            return state
    }
}

const canElement = ( state = [], action ) => {
    switch (action.type) {
        case "SET_CAN_ELEMENTS":
            state = action.payload
            return state;  
        default:
            return state;
    }
}
const canPGNs = ( state = [], action ) => {
    switch (action.type) {
        case "SET_CAN_PGN":
            state = action.payload;
            return state;
        case "UPDATE_CAN_PGN":
            let array = state.filter((x) => x.canFileId !== action.payload[0].canFileId);
            action.payload.forEach((x) => { array.push(x) });
            state = array;
            return state;
        default:
            return state;
    }
}
const canFiles = ( state = [], action ) => {
    switch (action.type) {
        case "SET_CAN_FILES":
            state = action.payload;            
            return state;
        case "UPDATE_CAN_FILE":
            for (const x of state) {                
                if (x.id === action.payload[0].canFileId) x.pgn = action.payload
            }
            //console.log("State",state)
            return state;
        default:
            return state;
    }
}
const canGroup = ( state = [], action ) => {
    switch (action.type) {
        case "SET_CAN_GROUPS":
            state = action.payload;
            return state;
        default:
            return state;
    }
}
const canGroupDevices = (state = [], action) => {
    switch (action.type) {
        case "SET_CAN_GROUP_DEVICES":
            state = action.payload
            return state;
        default:
            return state
    }
}

const deviceTableLoading = (state = false, action) => {
    switch (action.type) {
        case "DEVICE_TABLE_LOADING":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const gnssRecord = (state = false, action) => {
    switch (action.type) {
        case "GNSS_RECORD":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const gnssRecordNotFound = (state = false, action) => {
    switch (action.type) {
        case "GNSS_RECORD_NOT_FOUND":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const gnssRecordLoading = (state = false, action) => {
    switch (action.type) {
        case "GNSS_RECORD_LOADING":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const SelectedGroup = (state = "", action) => {
    switch (action.type) {
        case "SET_SELECT_GROUP":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const Fleet_Service = (state = {}, action) => {
    switch (action.type) {
        case "FLEET_SERVICE":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const gpsParing = (state = 0, action) => {
    switch (action.type) {
        case "GPS_PARING":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

export default combineReducers({
    deviceList,
    devices,
    loadingDevice,
    rawData,
    canElement,
    canGroup,
    canPGNs,
    canFiles,
    devicePage,
    canGroupDevices,
    deviceTableLoading,
    gnssRecordLoading,
    gnssRecordNotFound,
    gnssRecord,
    rawInfo,
    searchClicked,
    machineStatus,
    Fleet_Service,
    SelectedGroup,
    gpsParing,
});
