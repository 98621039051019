import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import authService from "../../components/api-authorization/AuthorizeService";
// This Saga is not really used anymore
function* getRawData() {
    yield put({
        type: 'IS_LOADING',
        payload: true
    });
    try {
        let token = yield authService.getAccessToken();

        const response = yield axios.get('/api/demo', { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });

        yield put({
            type: 'RAW_DATA',
            payload: response.data
        });

        yield put({
            type: 'IS_LOADING',
            payload: false
        });

    } catch (error) {
        console.log('Error with getting all device data', error);
    }
}

function* dataTable(action) {
    // console.log('1 data table action .. this one', action);
    try {
        yield put({
            type: 'CORE_DATA_LOADING',
            payload: false
        });
        yield put({
            type: 'RAW_DATA_LOADING',
            payload: true
        });
        yield put({
            type: 'SET_SELECTED_SEARCH_DEVICES',
            payload: { devices: action.payload, startDate: action.startDate, endDate: action.endDate, path: action.path + '/dataTableSaga' }
            // payload: { devices: action.payload }
        });
        if (action.fetchRaw) {
            let token = yield authService.getAccessToken();
            let newData = [];
            for (const x of action.payload) {
                let deviceData = yield axios.get(`/api/DeviceData/raw/${x}/${action.limit}`, { headers: { 'Authorization': `Bearer ${token}` } });
                // console.log('device data in raw', x, deviceData);
                newData.push(...deviceData.data)
            }
            yield put({
                type: 'SET_RAW_DATA',
                payload: newData
            })
        }
        yield put({
            type: 'RAW_DATA_LOADING',
            payload: false
        })
    } catch (error) {
        console.log(error)
    }
}

function* serviceInfo(action) {
    // console.log("????????? service info ", action);
    try {
        let token = yield authService.getAccessToken();
        yield put({
            type: 'RAW_DATA_LOADING',
            payload: true
        });
        yield put({
            type: 'SET_SELECTED_SEARCH_DEVICES',
            payload: { devices: action.externalId, startDate: action.startDate, endDate: action.endDate, path: action.path+"/serviceInfoSaga" }
        });
    
        let machineStatus = yield axios.get(`/api/MachineService/${action.payload}`, { headers: { 'Authorization': `Bearer ${token}` } });
        // console.log('machine status in service info', machineStatus);
        yield put({
            type: 'SET_MACHINE_STATUS',
            payload: machineStatus.data
        });
        
        yield put({
            type: 'RAW_DATA_LOADING',
            payload: false
        })

        
    } catch (error) {
        console.log(error)
        yield put({
            type: 'RAW_DATA_LOADING',
            payload: false
        })
    }
}

function* FleetDataGrid( action ){
    try {
        // console.log('1--------Fleet data grid', action);
        let token = yield authService.getAccessToken();
        yield put({
            type: 'FLEET_DATA_LOADING',
            payload: true
        })
        let data = [];
        let fleetFaults = []
        let q = 0;
        for(let device of action.payload){
            // console.log("2--------device inloop", action.Imei);
            let response = yield axios.get(`/api/MachineService/FleetStatus/${device}`, { headers: { 'Authorization': `Bearer ${token}` } });
            // console.log("2--------response in fleet data grid", response);
            if(response.data.noStat === null){
                // console.log('is null', response.data);
                // do a thing here for there to be a message when faults are loading
                response.data.faults = "loading . . .";
                response.data.externalId = [action.Imei[q]];
                data.push(response.data)
            } else if (response.data.noStat === 'No Stat'){
                data.push({id: device, commonName: device, stateOfCharge: "no data" })
            }
            q++;
        }
        
        // console.log("3--------data in fleet data grid", data);
        yield put({ type: 'SET_FLEET_DATA', payload: data, origin: 'fleetStatus'})

        for(let device of action.payload){
            let activeFaults = false;
            let response = yield axios.get(`/api/Fault/faults/${device}`,{headers: {Authorization: 'Bearer ' + token}})
            // console.log("4--------response in fleet faults", response);
            if (response.data.noStat === "No Config"){
                fleetFaults.push("None")
            } else {
                response.data.forEach(fault => {
                    if(fault.EndTime == null) {
                        activeFaults = true;
                    }
                });
                fleetFaults.push(activeFaults ? "View Fault" : "None")
            }

        }

        yield put({ type: 'SET_FLEET_DATA', payload: fleetFaults, origin: 'fleetFaults'})

        yield put({
            type: 'FLEET_DATA_LOADING',
            payload: false
        })
    } catch (error) {
        console.log(error)
        yield put({
            type: 'FLEET_DATA_LOADING',
            payload: false
        })
    }
}

function* RawSaga() {
    yield takeLatest('DATA_TABLE', dataTable);
    yield takeLatest('SERVICE_INFO', serviceInfo);
    yield takeLatest('GRAB_RAW_DATA', getRawData);
    yield takeLatest('FLEET_DATAGRID', FleetDataGrid)

}

export default RawSaga;
