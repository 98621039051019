import React from "react";
import {
    Button,
    Stepper,
    Step,
    StepLabel,
    Typography,
    TextField,
    Box,
    Card,
    Modal,
    LinearProgress,
    styled,
    AppBar,
    Autocomplete,
    Tab,
    Tabs,
    linearProgressClasses,
    InputAdornment
} from '@mui/material';
import theme from "../../../theme";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import process from "../../../utils/j1939-dbc";
import renderCellExpand from "../../../utils/DataCellExpansion";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Toast from "../../../mixins/Toast";

function GetSteps() {
    return ["Select File", "Review", "Can Group", "Confirm"];
}

function TabPanel(props){
    const { children, value, index } = props;
    return (
        <Box
            role="tabpanel"
            hidden={ value !== index }
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            sx={{ width: '100%', height: '100%' }}
        >
            { value === index && (
                <Box sx={{ paddingTop: theme.spacing(1) }}>
                    { children }
                </Box>
            )}
        </Box>
    )
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));
function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <BorderLinearProgress variant="determinate" value={props.value}/>
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2">
                    {`${props.value}%`}
                </Typography>
            </Box>
        </Box>
    )
};


const ImportModal = React.forwardRef((props, ref) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [pgn, setPgn] = React.useState([]);
    const [spn, setSpn] = React.useState([]);
    const [total, setTotal] = React.useState(0);
    const [progress, setProgress] = React.useState(0);
    const [name, setName] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const fileLoading = useSelector((state) => state.DashboardReducer.fileLoading);
    const fileProgress = useSelector((state) => state.DashboardReducer.fileProgress);
    const steps = GetSteps();
    const dispatch = useDispatch();

    React.useEffect(() => {
        setProgress(fileProgress)
    }, [fileProgress])

    let triggers = [
        // {name: "RSVD", id: 0}, future
        {name: 'PERIODIC', id: 1},
        // {name: "REQUEST", id: 2},
        {name: "ON_WAKE", id: 3},
        {name: "ON_SLEEP", id: 4},
        // {name: "GREATER_THAN", id: 5}, future
        // {name: "LESS_THAN", id: 6}, future
        // {name: "ENGINE_ON", id: 7}
    ];

    const pgnColumns = [
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 100, renderCell: renderCellExpand },
        { field: 'byteLength', headerName: 'Byte Length', flex: 1, minWidth: 100 },
        { field: 'canId', headerName: 'Can ID', flex: 1, minWidth: 100 },
        { field: 'spn', headerName: 'SPNs', flex: 1, minWidth: 100, renderCell: (params) => (
            <Button
                variant="contained"
                onClick={() => {
                    setSpn(params.row.SPNs)
                    setOpen(true)
                }}
            >
                SPNs
            </Button>
        )}
    ]

    const columns = [
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 100, renderCell: renderCellExpand },
        { field: 'bitStart', headerName: 'Bit Start', flex: 1, minWidth: 100 },
        { field: 'bitLength', headerName: 'Bit Length', flex: 1, minWidth: 100 },
        { field: 'endianness', headerName: 'Endianness', flex: 1, minWidth: 100 },
        { field: 'scale', headerName: 'Scale', flex: 1, minWidth: 100 },
        { field: 'offset', headerName: 'Offset', flex: 1, minWidth: 100 },
        { field: 'min', headerName: 'Min', flex: 1, minWidth: 100 },
        { field: 'max', headerName: 'Max', flex: 1, minWidth: 100 },
        { field: 'unit', headerName: 'Unit', flex: 1, minWidth: 100 },
        { field: 'receiver', headerName: 'Receiver', flex: 1, minWidth: 100 },
        { field: 'signed', headerName: 'Signed', flex: 1, minWidth: 100 }
    ];

    const createColumns = [
        { field: 'name', headerName: 'PGN', flex: 1, minWidth: 100 },
        { field: 'triggerType', headerName: 'Trigger Type', flex: 1, minWidth: 100, renderCell: (params) => (
            <Autocomplete
                id="trigger-type"
                options={triggers}
                style={{ width: '100%' }}
                onChange={(event, newValue) => {
                    params.row.triggerType = newValue.id
                }}
                defaultValue={params.row.triggerType}      
                isOptionEqualToValue={(option, value) => option.id === value}
                getOptionLabel={(option) => option.name ? option.name : "PERIODIC"}
                renderInput={(params) => <TextField {...params} variant="standard"/>}
            />
        )},
        { field: 'collectionFrequency', headerName: 'Collection Frequency', flex: 1, minWidth: 100, renderCell: (params) => (
            <TextField 
                sx={{ width: '100%' }}
                variant="standard"
                type="number"
                defaultValue={params.row.collectionFrequency}
                onChange={(event) => params.row.collectionFrequency = event.target.value}
                InputProps={{
                    endAdornment: <InputAdornment position="end">ms</InputAdornment>
                }}    
            />
        )},
        { field: 'reportFrequency', headerName: 'Report Frequency', flex: 1, minWidth: 100, renderCell: (params) => (
            <TextField
                sx={{ width: '100%' }}
                variant="standard"
                type="number"
                defaultValue={params.row.reportFrequency}
                onChange={(event) => params.row.reportFrequency = event.target.value}
                InputProps={{
                    endAdornment: <InputAdornment position="end">ms</InputAdornment>
                }}    
            />
        )}
    ]

    const normalise = (value) => {
        let result = (value * 100) / total;
        if (isNaN(result)){
            result = 0
        }
        return Math.round(result);
    }

    const handleNext = () => {
        if (activeStep === 0 && pgn.length ===  0){
            return Toast.fire({
                icon: 'error',
                title: 'Please choose file'
            })
        }
        else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleUpload = () => {
        dispatch({type: "UPLOAD_FILE", payload: pgn})
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    };

    const handleGroupCreation = () => {
        if (name === ''){
            return Toast.fire({
                icon: 'error',
                title: "please enter group name"
            })
        }
        dispatch({type: "UPLOAD_CREATE", payload:{data: pgn, name: name}});
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleFile = ( event ) => {
        if (event.name.includes(".dbc")){
            process( event ).then((result) => {
                setPgn(result.pgns)
                setTotal(result.total)
            })
            .catch((error) => {
                Toast.fire({
                    icon: 'error',
                    title: 'Problem with processing file. Make sure it is a .dbc file'
                })
                console.log(`Error processing file: ${error}`)
            });
        }
        else { 
            return Toast.fire({
                icon: 'error',
                title: 'Please use .dbc file'
            })
        }
    };
    function GetStepContent(stepIndex){
        let display = '';

        switch (stepIndex) {
            case 0:
                display = (
                    <Box
                        sx={{ minHeight: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <TextField variant="outlined" onChange={(event) => handleFile(event.target.files[0]) } type="file" accept=".dbc" />
                    </Box>
                )
                return display;
            case 1:
                pgn.map((x) => {
                    x.reportFrequency = 120000
                    x.collectionFrequency = 60000
                    x.triggerType = 1
                })
                display = (
                    <Box
                        sx={{ height: '100%', width: '100%', minHeight: '500px' }}
                    >
                        <DataGrid 
                            columns={pgnColumns}
                            rows={pgn}
                            style={{ minHeight: '500px' }}
                            components={{
                                Toolbar: GridToolbar
                            }}
                        />
                        <Modal
                            open={open}
                            onClose={() => setOpen(false)}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <Card
                                sx={{ height: '80%', width: '80%' }}
                            >
                                <DataGrid
                                    columns={columns}
                                    rows={spn}
                                    components={{
                                        Toolbar: GridToolbar
                                    }}
                                />
                            </Card>
                        </Modal>
                    </Box>
                )
                return display;
            case 2:
                display = (
                    <Card sx={{ minHeight: '500px', height: '100%', width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }} >
                        <AppBar position="static" color="default">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                            >
                                <Tab label="Create New Group"/>
                                <Tab label="Skip"/>
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            <Box sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >
                                <TextField sx={{ width: '50%', margin: '10px' }} label="Name" onChange={(event) => setName(event.target.value)} />
                                <DataGrid 
                                    columns={createColumns}
                                    rows={pgn}
                                    density="compact"
                                    components={{
                                        Toolbar: GridToolbar
                                    }}
                                    style={{ minHeight: '500px', width: '100%' }}
                                />
                                <Box sx={{ width: '50%', display: 'flex', justifyContent: 'space-between', margin: '10px' }}>
                                    <Button
                                        variant="outlined"
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"                             
                                        onClick={handleGroupCreation}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Box>  
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Box sx={{ minHeight: '500px', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >
                                <Typography
                                    variant="h4"
                                >
                                    Skipping will add PGN's to CAN Library
                                </Typography>
                                <Box
                                    sx={{ width: '25%', marginTop: '25px', display: 'flex', justifyContent: 'space-between' }}
                                >
                                    <Button
                                        variant="outlined"
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        onClick={handleUpload}
                                        variant="contained"
                                    >
                                        Continue
                                    </Button>
                                </Box>
                            </Box>
                        </TabPanel>
                    </Card>
                )
                return display;
            case 3:
                display = (
                    <Card
                        sx={{ minHeight: '500px', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
                    >
                        { fileLoading ? 
                        <Box sx={{ width: '80%' }}>
                            <LinearProgressWithLabel value={normalise(progress)} />
                        </Box>
                        :
                        <React.Fragment>
                            <Typography
                                variant="h4"
                            >
                                File has been added to CAN Library
                            </Typography>
                            <Box
                                sx={{ width: '50%', display: 'flex', justifyContent: 'space-between', marginTop: '150px' }}
                            >
                                <Button
                                    variant="contained"
                                    color="info"
                                    onClick={() => setActiveStep(0)}
                                >
                                    <RestartAltIcon/> restart
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        dispatch({type: "GRAB_CAN_PGNS"})
                                        props.close()
                                    }}
                                >
                                    exit
                                </Button>
                            </Box>
                        </React.Fragment>
                        }
                    </Card>
                )
                return display;
            default:
                break;
        }
    }

    return (
        <Card sx={{ marginTop: '5px' }}>
            <Stepper
                sx={{ paddingTop: '10px' }}
                activeStep={activeStep}
                alternativeLabel
            >
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box
                sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
            >
                <Box
                    sx={{ marginTop: '15px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    {GetStepContent(activeStep)}
                </Box>
                { activeStep === 3 || activeStep === 2 ? "" :
                <Box
                    sx={{ marginTop: '15px', marginBottom: '15px', width: '50%', display: 'flex', justifyContent: 'space-between' }}
                >
                    <Button
                        onClick={handleBack}
                        sx={{ marginRight: theme.spacing(1) }}
                        disabled={activeStep === 0}
                    >
                        back
                    </Button>
                    { activeStep !== 2 ?
                    <Button
                        onClick={handleNext}
                        variant="contained"
                        color="primary"
                    >
                        Next
                    </Button>
                    : 
                    <Button
                        onClick={handleUpload}
                        variant="contained"
                        color="primary"
                    >
                        Submit
                    </Button>
                    }
                </Box>
                }
            </Box>
        </Card>
    )
});

export default ImportModal;