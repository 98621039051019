import React from "react";
import {
    Dialog,
    AppBar,
    Toolbar,
    Box,
    IconButton,
    Slide
} from "@mui/material";
import { XSquare } from "react-feather";
import Logo from "../components/Logo";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Popup = (props) => {
    const { close, open, children } = props;
    return (
        <Dialog
            fullScreen
            open={open}
            TransitionComponent={Transition}
            onClose={close}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: '#0e5e8a' }}>
                <Toolbar>
                    <Box sx={{ display: 'flex' }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={close}
                        >
                            <XSquare/>
                        </IconButton>
                        <Box sx={{ height: '56px', marginLeft: '15px', width: '165px' }}>
                            <Logo style={{ height: '90%', width: '100%' }}/>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            {children}
        </Dialog>
    )
};

export default Popup;