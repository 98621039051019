import { combineReducers } from 'redux';
import { GrandCentralDispatch } from '../../utils/GrandCentralDispatch';

const setUser = (state = [], action) => {
    switch (action.type) {
        case 'SET_USER':
            return action.payload
        default:
            return state;
    }
}
const allGroups = (state = [], action) => {
    switch (action.type) {
        case "SET_ALL_USER_GROUPS":
            state = action.payload;
            return state;
        default:
            return state;
    }
}
const currentGroups = (state = [], action) => {
    switch (action.type) {
        case "SET_CURRENT_GROUPS":
            state = action.payload;
            return state;
        default:
            return state;
    }
}
const groups = (state = [], action) => {
    switch (action.type) {
        case "SET_USER_GROUPS":
            state = action.payload
            return state;
        default:
            return state;
    }
}

const currentGroup = (state = [], action) => {
    switch (action.type) {
        case "SET_CURRENT_GROUP":
            if(typeof action.payload === 'undefined'){
                state = [];
            }
            else {
                state = action.payload;
            }
            return state;
        default:
            return state;
    }
}
const currentDevices = (state = [], action) => {
    switch (action.type) {
        case "SET_CURRENT_DEVICES":
            state = action.payload
            return state;
        default:
            return state;
    }
}
const currentRole = (state = [], action) => {
    switch (action.type) {
        case "SET_CURRENT_ROLE":
            state = action.payload
            return state;
        default:
            return state;
    }
}
const groupUsers = (state = [], action) => {
    switch (action.type) {
        case "SET_GROUP_USERS":
            state = action.payload;
            return state;
        default:
            return state;
    }
}
const usersInGroup = (state = [], action) => {
    switch (action.type) {
        case "SET_USERS_IN_GROUP":
            state = action.payload;
            return state;
        default:
            return state;
    }
}
const rawDataLoading = ( state = false, action ) => {
    switch (action.type) {
        case "RAW_DATA_LOADING":
            state = action.payload;
            return state;
        default:
            return state;
    }
}
const coreDataLoading = ( state = false, action ) => {
    switch (action.type) {
        case "CORE_DATA_LOADING":
            state = action.payload;
            return state;
        default:
            return state;
    }
}
const showCoreWizard = ( state = false, action ) => {
    switch (action.type) {
        case "SHOW_CORE_WIZARD":
            state = action.payload;
            return state;
        default:
            return state;
    }
}
const selectedSearchDevices = ( state = [], action ) => {
    switch (action.type) {
        case "SET_SELECTED_SEARCH_DEVICES":
            state = action.payload;
            // console.log('selected search state & action:', state, action);
            GrandCentralDispatch.publish("SELECTED_SEARCH_DEVICES_UPDATED", action.payload);
            return state;
        default:
            return state;
    }
}
const coreReportDevices = ( state = [], action ) => {
    switch (action.type) {
        case "SET_CORE_REPORT_DEVICES":
            state = action.payload;
            return state;
        default:
            return state;
    }
}
const faultDataLoading = ( state = false, action ) => {
    switch (action.type) {
        case "FAULT_DATA_LOADING":
            state = action.payload;
            return state;
        default:
            return state;
    }
}
const token = ( state = '', action ) => {
    switch (action.type) {
        case "SET_USER_TOKEN":
            state = action.payload;
            return state;
        default:
            return state;
    }
}
const systemAdmin = ( state = false, action ) => {
    switch (action.type) {
        case "SET_SYSTEM_ADMIN":
            state = action.payload;
            return state;
        default:
            return state;
    }
}
  
export default combineReducers({
    setUser,
    groups,
    allGroups,
    currentGroup,
    currentDevices,
    currentRole,
    groupUsers,
    usersInGroup,
    rawDataLoading,
    token,
    systemAdmin,
    currentGroups,
    faultDataLoading,
    selectedSearchDevices,
    coreDataLoading,
    showCoreWizard,
    coreReportDevices
});