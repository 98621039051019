import { combineReducers } from 'redux';


const faultPage = (state = false, action) => {
    switch (action.type) {
        case "FAULT_LOADING":
            state = action.payload;
            return state;
        default:
           return state;
    }
}
const faultData = (state = [], action) => { // was used by the faults page that is no more, now used by service management faults
    switch (action.type) {
        case 'SET_FAULT_DATA':
            // console.log('1 set fault data, state, payload', state, action.payload);
            if (action.payload.length === 0 ){
                action.payload.push({ id: 1, faultCode: 'No faults reported between dates' });
            }
            state = action.payload            
            return state;
        default:
            return state
    }
}

const faultTableLoading = (state = false, action) => {
    switch (action.type) {
        case "FAULT_TABLE_LOADING":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

export default combineReducers({
    faultTableLoading,
    faultData,
    faultPage,
});