import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import authService from "../../components/api-authorization/AuthorizeService";
import Swal from 'sweetalert2';

function* LandingDataSaga(action) {
    try {
        let token = yield authService.getAccessToken();
        // console.log('action payload', action.payload);

        let response = yield axios.get("/api/Landing/LandingData", { headers: { 'Authorization': `Bearer ${token}` } });
        // console.log("response in Landing data saga: ", response);
        yield put({ type: "SET_LANDING_DATA", payload: response.data })

        let dayCount = yield axios.get("/api/Landing/CanDay", { headers: { 'Authorization': `Bearer ${token}` } });
        // console.log("response in Landing data saga: ", dayCount);
        yield put({ type: "SET_CAN_DAY", payload: dayCount.data })
 
        let monthCount = yield axios.get("/api/Landing/CanMonth", { headers: { 'Authorization': `Bearer ${token}` } });
        // console.log("response in Landing data saga: ", monthCount);
        yield put({ type: "SET_CAN_MONTH", payload: monthCount.data })

        let totalCount = yield axios.get("/api/Landing/CanTotal", { headers: { 'Authorization': `Bearer ${token}` } });
        // console.log("response in Landing data saga: ", totalCount);
        yield put({ type: "SET_CAN_TOTAL", payload: totalCount.data })

    } catch (e) {
        console.error("error in get landing data saga", e);
    }
}

function* LandingData() {
    yield takeLatest("FETCH_LANDING_DATA", LandingDataSaga);

};
export default LandingData;